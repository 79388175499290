import React from 'react';
import Editor from "@monaco-editor/react";


export const CodeEditor = ({
  code = '',
  language = "json",
}) => {

  return (
    <Editor
      height="70vh"
      defaultLanguage={language}
      defaultValue={code}
      value={code}
      theme="vs-dark"
      options={{
        "folding": true,
      }}
   />
  );
};
