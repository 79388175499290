import React from "react";

import { Brands } from '../constants';
import Store from "../utils/store";

export const Header = ({ brand }) => (
  <header className={`App-header ${brand === Brands.pb ? 'app-pb-brand' : 'app-ibx-brand'}`}>
    <div className="header-container">
      <div className="App-title">
        <h3 style={{ margin: '10px' }}>Test Order Generator</h3>
      </div>
      <div className="App-navbar">
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start', marginRight: '10px' }}>
          <h5 style={{ margin: '0px 0px 5px 0px' }}>Signed in as:</h5>
          <span>{Store.userEmail()}</span>
        </div>
        <button
          type="button"
          className={`App-navbar__sign-out ${brand === Brands.pb ? 'app-navbar-pb-sign-out' : 'app-navbar-ibx-sign-out'}`}
          onClick={() => Store.signOut()}
        >
          Sign out
        </button>
      </div>
    </div>
  </header>
);
