export const osloAddresses = [
  {
    streetName: "Schultz gate",
    streetNumber: "18A",
    postalCode: "0365",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ringeriksveien",
    streetNumber: "26",
    postalCode: "3400",
    city: "Lier",
    country: "Norge",
  },
  {
    streetName: "Øvre Smestadvei",
    streetNumber: "22B",
    postalCode: "0378",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sjølyst plass",
    streetNumber: "4",
    postalCode: "0278",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Folke Bernadottes vei",
    streetNumber: "21",
    postalCode: "0862",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lusetjernveien",
    streetNumber: "8B",
    postalCode: "1253",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gamleveien",
    streetNumber: "1B",
    postalCode: "1473",
    city: "Lørenskog",
    country: "Norge",
  },
  {
    streetName: "Trosterudveien",
    streetNumber: "23C",
    postalCode: " 0778",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Munkedamsveien",
    streetNumber: "35",
    postalCode: "0250",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Raschs vei",
    streetNumber: "32C",
    postalCode: "1178",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Trondheimsveien",
    streetNumber: "610",
    postalCode: "0964",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Østerlisvingen",
    streetNumber: "6",
    postalCode: "1153",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holmenkollveien",
    streetNumber: "97A",
    postalCode: "0784",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skausnaret",
    streetNumber: "4D",
    postalCode: "1262",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hallagerbakken",
    streetNumber: "21",
    postalCode: "1256",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thomles gate",
    streetNumber: "4",
    postalCode: "0270",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Trondheimsveien",
    streetNumber: "375",
    postalCode: "0953",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Maridalsveien",
    streetNumber: "30A",
    postalCode: "0175",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fagerheimgata",
    streetNumber: "6",
    postalCode: "0567",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bygdøy Terrasse",
    streetNumber: "15",
    postalCode: "0287",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karenslyst Allé",
    streetNumber: "11",
    postalCode: "0278",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ullernchausseen",
    streetNumber: "26",
    postalCode: "0379",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Spångbergveien",
    streetNumber: "22B",
    postalCode: "0853",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lusetjernveien",
    streetNumber: "76A",
    postalCode: "1253",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bervens Løkke",
    streetNumber: "4",
    postalCode: "0254",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Drammensveien",
    streetNumber: "282",
    postalCode: "0283",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Engebrets vei",
    streetNumber: "4",
    postalCode: "0275",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Cappelens vei",
    streetNumber: "19D",
    postalCode: "1162",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grønland",
    streetNumber: "10",
    postalCode: "0188",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Løchenveien",
    streetNumber: "18B",
    postalCode: "0286",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hellerudgrenda",
    streetNumber: "109",
    postalCode: "0672",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hovfaret",
    streetNumber: "17b",
    postalCode: "0275",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hovseterveien",
    streetNumber: "64A",
    postalCode: "0768",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stamhusveien",
    streetNumber: "83",
    postalCode: "1181",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Etterstadsletta",
    streetNumber: "122",
    postalCode: "0659",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Monolitveien",
    streetNumber: "38",
    postalCode: "0375",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Haugerudveien",
    streetNumber: "74",
    postalCode: "0674",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sandakerveien",
    streetNumber: "10",
    postalCode: "0473",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vestlisvingen",
    streetNumber: "13",
    postalCode: "0986",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Drammensveien",
    streetNumber: "270",
    postalCode: "0283",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Oscarshallveien",
    streetNumber: "6",
    postalCode: "0287",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bølerbakken",
    streetNumber: "2",
    postalCode: "0690",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Olav Kyrres gate",
    streetNumber: "9",
    postalCode: "0273",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Smalvollveien",
    streetNumber: "30B",
    postalCode: "0667",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Arkitekt Steckmests vei",
    streetNumber: "4A",
    postalCode: "0198",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Steinspranget",
    streetNumber: "26",
    postalCode: "1156",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Strandpromenaden",
    streetNumber: "2",
    postalCode: "0252",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grenseveien",
    streetNumber: "17B",
    postalCode: "0571",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ostadalsveien",
    streetNumber: "61",
    postalCode: "0753",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karenslyst Allé",
    streetNumber: "8",
    postalCode: "0278",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lillogata",
    streetNumber: "5",
    postalCode: "0484",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bygdøynesveien",
    streetNumber: "8D",
    postalCode: "0286",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Klemetsrudveien",
    streetNumber: "1",
    postalCode: "1278",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Urtegata",
    streetNumber: "6B",
    postalCode: "0187",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hovfaret",
    streetNumber: "17",
    postalCode: "0275",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Omsens gate",
    streetNumber: "3",
    postalCode: "0478",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Trasoppveien",
    streetNumber: "27",
    postalCode: "0671",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gråsteinveien",
    streetNumber: "30",
    postalCode: "1157",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ljansbrukveien",
    streetNumber: "3",
    postalCode: "1250",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Trondheimsveien",
    streetNumber: "640",
    postalCode: "0964",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sognsveien",
    streetNumber: "165",
    postalCode: "0860",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frederik Stangs gate",
    streetNumber: "8",
    postalCode: "0272",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kaptein Oppegaards vei",
    streetNumber: "26",
    postalCode: "1152",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Orknøygata",
    streetNumber: "6",
    postalCode: "0658",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ansgar Sørlies vei",
    streetNumber: "57",
    postalCode: "0576",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sømveien",
    streetNumber: "10B",
    postalCode: "0493",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nobels gate",
    streetNumber: "16B",
    postalCode: "0268",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thorvald Meyers gate",
    streetNumber: "30",
    postalCode: "0555",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Munkebekken",
    streetNumber: "192",
    postalCode: "1061",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rundhaugveien",
    streetNumber: "11H",
    postalCode: "0495",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bygdøy Kapellvei",
    streetNumber: "10B",
    postalCode: "0287",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thomas Heftyes gate",
    streetNumber: "2B",
    postalCode: "0272",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sørengkaia",
    streetNumber: "76",
    postalCode: "0194",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Pastor Blaauws vei",
    streetNumber: "22C",
    postalCode: "0962",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ole Vigs gate",
    streetNumber: "3",
    postalCode: "0357",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Keysers gate",
    streetNumber: "7C",
    postalCode: "0165",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jacob Aalls gate",
    streetNumber: "12A",
    postalCode: "0368",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Statsråd Mathiesens vei",
    streetNumber: "8",
    postalCode: "0598",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lindbäckveien",
    streetNumber: "16E",
    postalCode: "1163",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Diakonveien",
    streetNumber: "10",
    postalCode: "0370",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kirkegårdsgata",
    streetNumber: "6",
    postalCode: "0558",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Idas vei",
    streetNumber: "35",
    postalCode: "0981",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gråkamveien",
    streetNumber: "8A",
    postalCode: "0779",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Johan Castbergs vei",
    streetNumber: "32",
    postalCode: "0673",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Årvollveien 42",
    streetNumber: "44",
    postalCode: "0590",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Betzy Kjelsbergs vei",
    streetNumber: "10",
    postalCode: "0486",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bentsebrugata",
    streetNumber: "18E",
    postalCode: "0476",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dalbakkveien",
    streetNumber: "47",
    postalCode: "0682",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Risbakken",
    streetNumber: "14",
    postalCode: "0374",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ekebergveien",
    streetNumber: "312",
    postalCode: "1166",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Alf Bjerckes vei",
    streetNumber: "30",
    postalCode: "0596",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gaustadveien",
    streetNumber: "15B",
    postalCode: "0372",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Munkedamsveien",
    streetNumber: "15",
    postalCode: "0250",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Harald Sohlbergs Vei",
    streetNumber: "17",
    postalCode: "1064",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Prost Hallings vei",
    streetNumber: "2",
    postalCode: "0666",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bøkkerveien",
    streetNumber: "20",
    postalCode: "0579",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sørbyhaugen",
    streetNumber: "21",
    postalCode: "0377",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fjellstuveien",
    streetNumber: "64",
    postalCode: "0982",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skogfaret",
    streetNumber: "6A",
    postalCode: "2040",
    city: "Kløfta",
    country: "Norway",
  },
  {
    streetName: "Niels Juels gate",
    streetNumber: "4A",
    postalCode: "0272",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Låveveien",
    streetNumber: "16C",
    postalCode: "0682",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sognsveien",
    streetNumber: "143",
    postalCode: "0860",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stovner Senter",
    streetNumber: "30",
    postalCode: "0985",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Arnulf Øverlands vei",
    streetNumber: "163",
    postalCode: "0763",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Biskop Gunnerus gate",
    streetNumber: "6",
    postalCode: "0155",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vækerøveien",
    streetNumber: "118C",
    postalCode: "0383",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Strømsveien",
    streetNumber: "314A",
    postalCode: "1081",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tuengen allé",
    streetNumber: "32",
    postalCode: "0374",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bogstadveien",
    streetNumber: "46",
    postalCode: "0366",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Østre Aker vei",
    streetNumber: "264",
    postalCode: "0977",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sturlas vei",
    streetNumber: "14",
    postalCode: "0772",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sannergata",
    streetNumber: "6D",
    postalCode: "0557",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tåsenveien",
    streetNumber: "10B",
    postalCode: "0853",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fernanda Nissens Gate",
    streetNumber: "3",
    postalCode: "0484",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Linderudveien",
    streetNumber: "13",
    postalCode: "0594",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kjetils vei",
    streetNumber: "8A",
    postalCode: "0494",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stålfjæra",
    streetNumber: "26",
    postalCode: "0975",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Olav Nygards veg",
    streetNumber: "212A",
    postalCode: "0688",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Øvre Ullern Terrasse",
    streetNumber: "29B",
    postalCode: "0380",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fossumberget",
    streetNumber: "51",
    postalCode: "0983",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Valhallveien",
    streetNumber: "55",
    postalCode: "0196",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Drammensveien",
    streetNumber: "100",
    postalCode: "0273",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vallegata",
    streetNumber: "2B",
    postalCode: "0454",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karoline Kristiansens vei",
    streetNumber: "3",
    postalCode: "0661",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dovresvingen",
    streetNumber: "25B",
    postalCode: "1184",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sofies gate",
    streetNumber: "50",
    postalCode: "0168",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Akebakkeskogen",
    streetNumber: "53",
    postalCode: "0490",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grønland",
    streetNumber: "10",
    postalCode: "0188",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Dag Hammarskjølds vei",
    streetNumber: "59",
    postalCode: "0580",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dyretråkket",
    streetNumber: "6",
    postalCode: "1251",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Robert Millars vei",
    streetNumber: "20",
    postalCode: "1185",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sjursøya",
    streetNumber: "11",
    postalCode: "0193",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rundtjernveien",
    streetNumber: "74B",
    postalCode: "0672",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Myklegardgata",
    streetNumber: "1B",
    postalCode: "0656",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tennisveien",
    streetNumber: "3G",
    postalCode: "0777",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frysjaveien",
    streetNumber: "1",
    postalCode: "0883",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vitaminveien 7",
    streetNumber: "9",
    postalCode: "0485",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Høybråtenveien",
    streetNumber: "55",
    postalCode: "1086",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dronning Eufemias gate",
    streetNumber: "30",
    postalCode: "0191",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gimleveien",
    streetNumber: "20B",
    postalCode: "0266",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gråsteinveien",
    streetNumber: "3",
    postalCode: "1157",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Risbakken",
    streetNumber: "26A",
    postalCode: "0374",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vennersborgveien",
    streetNumber: "2",
    postalCode: "0281",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Turbinveien",
    streetNumber: "20",
    postalCode: "0195",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hellerud Gårdsvei",
    streetNumber: "5",
    postalCode: "0671",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Akersgata",
    streetNumber: "42",
    postalCode: "0180",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Munkebekken",
    streetNumber: "170",
    postalCode: "1061",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gregers Grams vei",
    streetNumber: "6",
    postalCode: "0382",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lunden",
    streetNumber: "66",
    postalCode: "0598",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gamle Bygdevei",
    streetNumber: "102",
    postalCode: "1284",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jernbaneveien",
    streetNumber: "5",
    postalCode: "0667",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Enebakkveien",
    streetNumber: "172",
    postalCode: "0680",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ullevålsveien",
    streetNumber: "68",
    postalCode: "0454",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Holmenveien",
    streetNumber: "43C",
    postalCode: "0376",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Heimdalsgata",
    streetNumber: "26C",
    postalCode: "0561",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Slemdalsveien",
    streetNumber: "81D",
    postalCode: "0373",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lillebergveien",
    streetNumber: "32",
    postalCode: "0662",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Etterstadkroken",
    streetNumber: "5H",
    postalCode: "0659",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Haakon VIIs gate",
    streetNumber: "1",
    postalCode: "0161",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Arnulf Øverlands vei",
    streetNumber: "128",
    postalCode: "0763",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fv168",
    streetNumber: "58",
    postalCode: "0366",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nedre Slottsgate",
    streetNumber: "5",
    postalCode: "0157",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ostadalsveien",
    streetNumber: "66",
    postalCode: "0753",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Røaknekken",
    streetNumber: "2",
    postalCode: "0754",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hellerudveien",
    streetNumber: "9",
    postalCode: "0672",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stortingsgata",
    streetNumber: "28",
    postalCode: "0161",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sørbyhaugen",
    streetNumber: "18F",
    postalCode: "0377",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tusentrippen",
    streetNumber: "5",
    postalCode: "0361",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fredriksborgveien",
    streetNumber: "34",
    postalCode: "0286",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nordlisvingen",
    streetNumber: "5",
    postalCode: "0952",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Johan Castbergs vei",
    streetNumber: "58",
    postalCode: "0673",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Professor Birkelands",
    streetNumber: "vei",
    postalCode: "1081",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Finnhaugveien",
    streetNumber: "15",
    postalCode: "0760",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Libakkveien",
    streetNumber: "15C",
    postalCode: "1184",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hagapynten",
    streetNumber: "50",
    postalCode: "0673",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Orreveien",
    streetNumber: "12A",
    postalCode: "0789",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ullernkollen",
    streetNumber: "18",
    postalCode: "0382",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Toftes Gate",
    streetNumber: "15",
    postalCode: "0556",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Åsdalsveien",
    streetNumber: "45",
    postalCode: "1166",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Steinborgveien",
    streetNumber: "21B",
    postalCode: "0678",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Storgata",
    streetNumber: "3",
    postalCode: "0155",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Østre Aker vei",
    streetNumber: "64",
    postalCode: "0581",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lillevannsveien",
    streetNumber: "80B",
    postalCode: "0788",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frognerseterveien",
    streetNumber: "12",
    postalCode: "0775",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brannfjellveien",
    streetNumber: "67B",
    postalCode: "1181",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nedre Slottsgate",
    streetNumber: "2B",
    postalCode: "0153",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kristins vei",
    streetNumber: "36",
    postalCode: "0669",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hartmanns vei",
    streetNumber: "24",
    postalCode: "0284",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Maridalsveien",
    streetNumber: "87",
    postalCode: "0461",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Haugerudveien",
    streetNumber: "36",
    postalCode: "0674",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rolf Wickstrøms vei",
    streetNumber: "2",
    postalCode: "0873",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grenseveien",
    streetNumber: "97",
    postalCode: "0663",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Drammensveien",
    streetNumber: "201",
    postalCode: "0281",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jerpefaret",
    streetNumber: "27C",
    postalCode: "0788",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stovner Senter",
    streetNumber: "30",
    postalCode: "0985",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Fossumveien",
    streetNumber: "77",
    postalCode: "1359",
    city: "Eiksmarka",
    country: "Norge",
  },
  {
    streetName: "Vesteråsveien",
    streetNumber: "9C",
    postalCode: "0382",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karenslyst Allé",
    streetNumber: "9",
    postalCode: "0278",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hellerudsvingen",
    streetNumber: "18",
    postalCode: "0684",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Damfaret",
    streetNumber: "42C",
    postalCode: "0682",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rosenhoffgata",
    streetNumber: "9",
    postalCode: "0569",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Baglerfaret",
    streetNumber: "14C",
    postalCode: "0677",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Enebakkveien",
    streetNumber: "71",
    postalCode: "0196",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bjørnveien",
    streetNumber: "83",
    postalCode: "0773",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Erlandstuveien",
    streetNumber: "31E",
    postalCode: "1178",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Henriks vei",
    streetNumber: "1",
    postalCode: "0768",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Henrik Ibsens gate",
    streetNumber: "1",
    postalCode: "10",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stordamsnaret",
    streetNumber: "22",
    postalCode: "0671",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nordstrandveien",
    streetNumber: "5B",
    postalCode: "1170",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hallagerbakken",
    streetNumber: "106",
    postalCode: "1256",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stortorvet",
    streetNumber: "8",
    postalCode: "0155",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sollerudveien",
    streetNumber: "19D",
    postalCode: "0283",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Manglerudveien",
    streetNumber: "60",
    postalCode: "0678",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Klemetsrudveien",
    streetNumber: "2",
    postalCode: "1278",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ryenbergveien",
    streetNumber: "55",
    postalCode: "0677",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stenersgata",
    streetNumber: "1",
    postalCode: "0050",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Freidigveien",
    streetNumber: "15B",
    postalCode: "1163",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hoffsveien",
    streetNumber: "17",
    postalCode: "0275",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skogbrynet",
    streetNumber: "1",
    postalCode: "0283",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Arnebråtveien",
    streetNumber: "26B",
    postalCode: "0376",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gjøvikgata",
    streetNumber: "4B",
    postalCode: "0470",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lerdalstoppen",
    streetNumber: "6",
    postalCode: "1258",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jordstjerneveien",
    streetNumber: "97",
    postalCode: "1283",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sørengkaia",
    streetNumber: "120",
    postalCode: "0194",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Paal Bergs vei",
    streetNumber: "93",
    postalCode: "0692",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Erling Skjalgssons gate",
    streetNumber: "19",
    postalCode: "0267",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grorudveien",
    streetNumber: "55",
    postalCode: "0976",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Geitmyrsveien",
    streetNumber: "35B",
    postalCode: "0171",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dorthes vei",
    streetNumber: "4D",
    postalCode: "0287",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lilloseterveien",
    streetNumber: "99",
    postalCode: "0957",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thorn Dønhaugs vei",
    streetNumber: "82",
    postalCode: "1271",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ingiers vei",
    streetNumber: "57",
    postalCode: "1167",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karenslyst Allé",
    streetNumber: "56",
    postalCode: "0277",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Østensjøveien",
    streetNumber: "116A",
    postalCode: "0682",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Utsynsveien",
    streetNumber: "4",
    postalCode: "1176",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ulsrudveien",
    streetNumber: "10D",
    postalCode: "0690",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sognsveien",
    streetNumber: "82",
    postalCode: "0855",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Selvbyggerveien",
    streetNumber: "37",
    postalCode: "0591",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hallagerbakken",
    streetNumber: "8J",
    postalCode: "1256",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kirkeveien",
    streetNumber: "60",
    postalCode: "0368",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tråkka",
    streetNumber: "43B",
    postalCode: "0774",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sponstuveien",
    streetNumber: "47B",
    postalCode: "1263",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ullern allé",
    streetNumber: "101",
    postalCode: "0381",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Slimeveien",
    streetNumber: "2A",
    postalCode: "1275",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hegdehaugsveien",
    streetNumber: "34",
    postalCode: "0352",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Drammensveien",
    streetNumber: "159",
    postalCode: "0277",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Furulundsveien",
    streetNumber: "7C",
    postalCode: "0282",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ekebergveien",
    streetNumber: "131A",
    postalCode: "1178",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karenslyst Allé",
    streetNumber: "1",
    postalCode: "0278",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Veslekroken",
    streetNumber: "1D",
    postalCode: "0379",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Johan Castbergs vei",
    streetNumber: "39",
    postalCode: "0673",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stjerneveien",
    streetNumber: "39",
    postalCode: "0779",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hausmanns gate",
    streetNumber: "31a",
    postalCode: "0182",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Strandpromenaden",
    streetNumber: "11",
    postalCode: "0252",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Østre Aker vei",
    streetNumber: "29",
    postalCode: "0581",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Smålensgata",
    streetNumber: "6",
    postalCode: "0657",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ulsrudveien",
    streetNumber: "40",
    postalCode: "0690",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stålfjæra",
    streetNumber: "18",
    postalCode: "0975",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fredensborgveien",
    streetNumber: "42",
    postalCode: "0177",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ullevålsveien",
    streetNumber: "47",
    postalCode: "0171",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kittel-nielsens vei",
    streetNumber: "53",
    postalCode: "1163",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Statsråd Mathiesens vei",
    streetNumber: "23",
    postalCode: "0594",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kapellveien",
    streetNumber: "120",
    postalCode: "0493",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Filipstadveien",
    streetNumber: "25",
    postalCode: "0250",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thorn Dønhaugs vei",
    streetNumber: "42",
    postalCode: "1271",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sørsletta",
    streetNumber: "13",
    postalCode: "0756",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hellinga",
    streetNumber: "10",
    postalCode: "1151",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hoffsveien",
    streetNumber: "9",
    postalCode: "0275",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thaulows vei",
    streetNumber: "4A",
    postalCode: "0369",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bygdøy allé",
    streetNumber: "2",
    postalCode: "0257",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ammerudgrenda",
    streetNumber: "166",
    postalCode: "0960",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rådyrlia",
    streetNumber: "57",
    postalCode: "1270",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lindebergåsen",
    streetNumber: "38",
    postalCode: "1068",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tollbugata",
    streetNumber: "17",
    postalCode: "0152",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gregers Grams vei",
    streetNumber: "21",
    postalCode: "0382",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ekraveien",
    streetNumber: "84",
    postalCode: "0757",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dronning Eufemias gate",
    streetNumber: "28",
    postalCode: "0191",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rådhusgata",
    streetNumber: "14",
    postalCode: "0151",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grefsenveien",
    streetNumber: "43",
    postalCode: "0485",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sognsveien",
    streetNumber: "165",
    postalCode: "0864",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Akershusstranda",
    streetNumber: "21",
    postalCode: "0150",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "St. Olavs Gate",
    streetNumber: "32",
    postalCode: "0166",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lundliveien",
    streetNumber: "21B",
    postalCode: "0584",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ammerudveien",
    streetNumber: "176",
    postalCode: "0958",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tiurveien",
    streetNumber: "76",
    postalCode: "0852",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Betzy Kjelsbergs vei",
    streetNumber: "7",
    postalCode: "0486",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vestliveien",
    streetNumber: "2",
    postalCode: "0750",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bånkallstubben",
    streetNumber: "18B",
    postalCode: "0964",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Torshovgata",
    streetNumber: "4",
    postalCode: "0476",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Drammensveien",
    streetNumber: "50",
    postalCode: "0271",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Konventveien",
    streetNumber: "27",
    postalCode: "0377",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ammerudgrenda",
    streetNumber: "176",
    postalCode: "0960",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stortingsgata",
    streetNumber: "16",
    postalCode: "0161",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Røahagan",
    streetNumber: "35A",
    postalCode: "0754",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dronning Margretes Vei",
    streetNumber: "11",
    postalCode: "0663",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lysåsbakken",
    streetNumber: "33",
    postalCode: "1473",
    city: "Røykås",
    country: "Norge",
  },
  {
    streetName: "Falsens gate",
    streetNumber: "24B",
    postalCode: "0556",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nedre Silkestrå",
    streetNumber: "12",
    postalCode: "0375",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Torgny Segerstedts",
    streetNumber: "Vei",
    postalCode: "0372",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Olav Aukrusts vei",
    streetNumber: "8",
    postalCode: "0785",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fougners vei",
    streetNumber: "2A",
    postalCode: "0571",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frøyas gate",
    streetNumber: "10",
    postalCode: "0273",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Glitreveien",
    streetNumber: "4",
    postalCode: "0686",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hauchs gate",
    streetNumber: "7",
    postalCode: "0175",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Jacobine Ryes vei",
    streetNumber: "21",
    postalCode: "0987",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Pilestredet Park",
    streetNumber: "19",
    postalCode: "0176",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Toppåsveien",
    streetNumber: "8C",
    postalCode: "1262",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Arnstein Arnebergs vei",
    streetNumber: "17",
    postalCode: "0274",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bogstadveien",
    streetNumber: "58",
    postalCode: "0366",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Voll Terrasse",
    streetNumber: "33",
    postalCode: "1358",
    city: "Jar",
    country: "Norge",
  },
  {
    streetName: "Nils Collett Vogts vei",
    streetNumber: "41",
    postalCode: "0766",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Elmholt allé",
    streetNumber: "14",
    postalCode: "0275",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skådalsveien",
    streetNumber: "25D",
    postalCode: "0781",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sjursøya",
    streetNumber: "13",
    postalCode: "0193",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Teisenveien",
    streetNumber: "3D",
    postalCode: "0664",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hoffsveien",
    streetNumber: "20",
    postalCode: "0275",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sons gate",
    streetNumber: "5A",
    postalCode: "0654",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kongshavnveien",
    streetNumber: "9",
    postalCode: "0193",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skovveien",
    streetNumber: "12",
    postalCode: "0257",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tuengen allé",
    streetNumber: "14",
    postalCode: "0374",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vetlandsveien",
    streetNumber: "8",
    postalCode: "0671",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Høyenhall Plass",
    streetNumber: "23",
    postalCode: "0667",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Enebakkveien",
    streetNumber: "150",
    postalCode: "0680",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bekkenstenveien",
    streetNumber: "36F",
    postalCode: "0976",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ringnesveien",
    streetNumber: "23",
    postalCode: "0978",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brannfjellveien",
    streetNumber: "84",
    postalCode: "1181",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Wilhelm Stenersens vei",
    streetNumber: "4",
    postalCode: "0671",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bernhard Herres vei",
    streetNumber: "12",
    postalCode: "0376",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skøyenveien 17",
    streetNumber: "19",
    postalCode: "0375",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Aslakveien",
    streetNumber: "27",
    postalCode: "0753",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "P. T. Mallings vei",
    streetNumber: "26B",
    postalCode: "0286",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Robsrudskogen",
    streetNumber: "5",
    postalCode: "1470",
    city: "Lørenskog",
    country: "Norge",
  },
  {
    streetName: "Bogerudveien",
    streetNumber: "15B",
    postalCode: "0692",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Olaf Bulls vei",
    streetNumber: "28",
    postalCode: "0765",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Telthusbakken",
    streetNumber: "13B",
    postalCode: "0172",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bergensgata",
    streetNumber: "45",
    postalCode: "0468",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kirkesvingen",
    streetNumber: "2",
    postalCode: "0962",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fyrstikkbakken",
    streetNumber: "2",
    postalCode: "0667",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Alvheimveien",
    streetNumber: "10D",
    postalCode: "0198",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Steinhammerveien",
    streetNumber: "37",
    postalCode: "1177",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Seterhøyveien",
    streetNumber: "29",
    postalCode: "1176",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brekkeveien 13",
    streetNumber: "17",
    postalCode: "0884",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bølerlia",
    streetNumber: "159",
    postalCode: "0689",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Keysers gate",
    streetNumber: "4",
    postalCode: "0180",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sørligata",
    streetNumber: "41",
    postalCode: "0651",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grensen",
    streetNumber: "9",
    postalCode: "0159",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tennisveien",
    streetNumber: "4",
    postalCode: "0777",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skogbrynet",
    streetNumber: "11D",
    postalCode: "0283",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Agathe Grøndahls gate",
    streetNumber: "43",
    postalCode: "0475",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Schweigaards gate",
    streetNumber: "63",
    postalCode: "0656",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stanseveien",
    streetNumber: "2",
    postalCode: "0975",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Haakon Tveters vei",
    streetNumber: "42",
    postalCode: "0686",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bjørn Bondes vei",
    streetNumber: "111",
    postalCode: "1253",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hallagerbakken",
    streetNumber: "14D",
    postalCode: "1256",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ole Messelts vei",
    streetNumber: "3",
    postalCode: "0676",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Voksenkollveien",
    streetNumber: "59",
    postalCode: "0790",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Trosterudveien",
    streetNumber: "15B",
    postalCode: "0778",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Landingsveien",
    streetNumber: "38",
    postalCode: "0767",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Birger Olivers vei",
    streetNumber: "10",
    postalCode: "1176",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kirkeveien",
    streetNumber: "153",
    postalCode: "0451",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brochmanns gate",
    streetNumber: "5",
    postalCode: "0470",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Akebakkeskogen",
    streetNumber: "25",
    postalCode: "0490",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Drammensveien",
    streetNumber: "60",
    postalCode: "0271",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Plogveien",
    streetNumber: "22",
    postalCode: "0681",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thulstrups gate",
    streetNumber: "2D",
    postalCode: "0451",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Økern Torgvei",
    streetNumber: "6",
    postalCode: "0580",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vetlandsveien",
    streetNumber: "49",
    postalCode: "0671",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vollsveien P",
    streetNumber: "Hus",
    postalCode: "1366",
    city: "Lysaker",
    country: "Norge",
  },
  {
    streetName: "Sporveisgata",
    streetNumber: "22",
    postalCode: "0354",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ekebergveien",
    streetNumber: "70",
    postalCode: "1181",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bergensgata",
    streetNumber: "2",
    postalCode: "0468",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Harald Løvenskiolds vei",
    streetNumber: "41",
    postalCode: "0760",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gabels gate",
    streetNumber: "10",
    postalCode: "0272",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Professor Birkelands vei",
    streetNumber: "42",
    postalCode: "1081",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Åsliveien",
    streetNumber: "5C",
    postalCode: "1178",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Langerudsvingen",
    streetNumber: "19",
    postalCode: "1187",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Melumveien",
    streetNumber: "68B",
    postalCode: "0760",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Spireaveien",
    streetNumber: "12",
    postalCode: "0580",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skogfaret",
    streetNumber: "12",
    postalCode: "0382",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hegdehaugsveien",
    streetNumber: "6",
    postalCode: "0167",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Eugenies gate",
    streetNumber: "17",
    postalCode: "0168",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lyngveien",
    streetNumber: "32",
    postalCode: "1182",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Østmarkveien",
    streetNumber: "7C",
    postalCode: "0687",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Betzy Kjelsbergs vei",
    streetNumber: "26F",
    postalCode: "0486",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Erlandstuveien",
    streetNumber: "33B",
    postalCode: "1178",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ensjøsvingen",
    streetNumber: "4B",
    postalCode: "0661",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brønnveien",
    streetNumber: "17B",
    postalCode: "0283",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lysehagan",
    streetNumber: "31",
    postalCode: "0383",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hjalmar Brantings Vei",
    streetNumber: "8",
    postalCode: "0662",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skovveien",
    streetNumber: "8",
    postalCode: "0257",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Akershusstranda",
    streetNumber: "35",
    postalCode: "0150",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ekebergveien",
    streetNumber: "65",
    postalCode: "1181",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Linderudveien",
    streetNumber: "24",
    postalCode: "0594",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ullernchausseen",
    streetNumber: "70",
    postalCode: "0310",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sturlas vei",
    streetNumber: "1A",
    postalCode: "0772",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lyder Sagens gate",
    streetNumber: "20",
    postalCode: "0358",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Øvre Smestadvei",
    streetNumber: "31B",
    postalCode: "0378",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kabelgaten",
    streetNumber: "39B",
    postalCode: "0580",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ruglandveien 36",
    streetNumber: "42",
    postalCode: "1358",
    city: "Jar",
    country: "Norge",
  },
  {
    streetName: "Hovinveien",
    streetNumber: "37",
    postalCode: "0576",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kringveien",
    streetNumber: "12B",
    postalCode: "1084",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gullhaug Torg",
    streetNumber: "4B",
    postalCode: "0484",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Wedels vei",
    streetNumber: "1",
    postalCode: "0287",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nydalsveien",
    streetNumber: "28",
    postalCode: "0484",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Tyslevkroken",
    streetNumber: "3B",
    postalCode: "1163",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Maurtuveien",
    streetNumber: "3",
    postalCode: "1291",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Øvre Slottsgate",
    streetNumber: "29",
    postalCode: "0157",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tuengen allé",
    streetNumber: "29",
    postalCode: "0374",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Solveien",
    streetNumber: "85",
    postalCode: "1162",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brobekkveien",
    streetNumber: "100E",
    postalCode: "0582",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Valhallveien",
    streetNumber: "66",
    postalCode: "0196",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Høybråtenveien",
    streetNumber: "45C",
    postalCode: "1086",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Agmund Bolts vei",
    streetNumber: "50",
    postalCode: "0664",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dorthes vei",
    streetNumber: "4",
    postalCode: "0287",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brobekkveien",
    streetNumber: "104C",
    postalCode: "0582",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Otto Sogns vei",
    streetNumber: "16",
    postalCode: "0681",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jorines vei",
    streetNumber: "10",
    postalCode: "0981",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ryenbergveien",
    streetNumber: "25",
    postalCode: "0196",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Geitmyrsveien",
    streetNumber: "73E",
    postalCode: "0455",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hallings gate",
    streetNumber: "1B",
    postalCode: "0170",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "John Colletts allé",
    streetNumber: "33",
    postalCode: "0852",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Munkerudveien",
    streetNumber: "28A",
    postalCode: "1163",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Storgata",
    streetNumber: "28A",
    postalCode: "0184",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Micheletveien",
    streetNumber: "37",
    postalCode: "1053",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Elisenbergveien",
    streetNumber: "16",
    postalCode: "0265",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fagerborggata",
    streetNumber: "48B",
    postalCode: "0360",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skjellveien",
    streetNumber: "3C",
    postalCode: "0198",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bleikøya",
    streetNumber: "815",
    postalCode: "0150",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lavrans vei",
    streetNumber: "21",
    postalCode: "0670",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Edvard Griegs allé",
    streetNumber: "15B",
    postalCode: "0479",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ole Messelts vei",
    streetNumber: "5",
    postalCode: "0676",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lovisenberggata",
    streetNumber: "6",
    postalCode: "0456",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thor Olsens gate",
    streetNumber: "6B",
    postalCode: "0177",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ivar Aasens vei",
    streetNumber: "21B",
    postalCode: "0373",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Maria Dehlis vei",
    streetNumber: "15",
    postalCode: "1084",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Olaf Ryes plass",
    streetNumber: "11",
    postalCode: "0552",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Omsens gate",
    streetNumber: "10",
    postalCode: "0478",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gladvollveien",
    streetNumber: "37",
    postalCode: "1168",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Slemdalsveien",
    streetNumber: "72",
    postalCode: "0373",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Schweigaards gate",
    streetNumber: "26",
    postalCode: "0191",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Årvollveien",
    streetNumber: "25",
    postalCode: "0590",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rødbergveien",
    streetNumber: "80E",
    postalCode: "0593",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Giskehagen",
    streetNumber: "22",
    postalCode: "0376",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dagaliveien",
    streetNumber: "32C",
    postalCode: "0783",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Drammensveien",
    streetNumber: "72",
    postalCode: "0271",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Trondheimsveien",
    streetNumber: "455B",
    postalCode: "0962",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nordlysveien",
    streetNumber: "35",
    postalCode: "0489",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ammerudgrenda",
    streetNumber: "267",
    postalCode: "0960",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hospitsveien",
    streetNumber: "11C",
    postalCode: "0789",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kløfterhagen",
    streetNumber: "115",
    postalCode: "1067",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Steinspranget",
    streetNumber: "2",
    postalCode: "1156",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thereses gate",
    streetNumber: "14",
    postalCode: "0452",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hoffsveien",
    streetNumber: "74",
    postalCode: "0377",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ensjøveien",
    streetNumber: "5",
    postalCode: "0655",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Haakon Tveters vei",
    streetNumber: "57",
    postalCode: "0686",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Høybråtenveien",
    streetNumber: "66A",
    postalCode: "1086",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sandstuveien",
    streetNumber: "31B",
    postalCode: "1184",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sognsvannsveien",
    streetNumber: "32B",
    postalCode: "0372",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kristian Bogneruds vei",
    streetNumber: "1B",
    postalCode: "0956",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Elmholtveien",
    streetNumber: "2",
    postalCode: "0281",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Forskningsveien",
    streetNumber: "3",
    postalCode: "0373",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tuengen allé",
    streetNumber: "19A",
    postalCode: "0374",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kransen",
    streetNumber: "4",
    postalCode: "1166",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hasleveien",
    streetNumber: "66",
    postalCode: "0575",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Munkerudtunet",
    streetNumber: "20",
    postalCode: "1164",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ullevålsveien",
    streetNumber: "113C",
    postalCode: "0359",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Strømsveien",
    streetNumber: "308",
    postalCode: "1081",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Oppsalstubben",
    streetNumber: "4C",
    postalCode: "0685",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lørenveien",
    streetNumber: "63",
    postalCode: "0580",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Drammensveien",
    streetNumber: "159",
    postalCode: "0277",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Østre Aker vei",
    streetNumber: "203",
    postalCode: "0975",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frøyas gate",
    streetNumber: "12",
    postalCode: "0273",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Torjusbakken",
    streetNumber: "20F",
    postalCode: "0378",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Chr. Michelsens gate",
    streetNumber: "63",
    postalCode: "0474",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Schwensens gate",
    streetNumber: "10",
    postalCode: "0171",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Mosseveien",
    streetNumber: "264",
    postalCode: "1169",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Titutveien",
    streetNumber: "18B",
    postalCode: "0668",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rustadsaga",
    streetNumber: "855",
    postalCode: "0693",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vækerø Terrasse",
    streetNumber: "9C",
    postalCode: "0282",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Filipstadveien",
    streetNumber: "13",
    postalCode: "0250",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Eckersbergs gate",
    streetNumber: "24",
    postalCode: "0260",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kjelsåsveien",
    streetNumber: "28F",
    postalCode: "0488",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Neuberggata",
    streetNumber: "20",
    postalCode: "0367",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Alfasetveien",
    streetNumber: "1",
    postalCode: "0668",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hoffsjef Løvenskiolds vei",
    streetNumber: "17",
    postalCode: "0382",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Maridalsveien",
    streetNumber: "366",
    postalCode: "0881",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thomas Heftyes gate",
    streetNumber: "42D",
    postalCode: "0264",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ulvenveien",
    streetNumber: "92C",
    postalCode: "0581",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dr. Holms vei",
    streetNumber: "9C",
    postalCode: "0787",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vækerøveien",
    streetNumber: "120B",
    postalCode: "0383",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sigurd Hoels vei",
    streetNumber: "53",
    postalCode: "0655",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nøklesvingen",
    streetNumber: "67",
    postalCode: "0689",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kirkeveien",
    streetNumber: "49B",
    postalCode: "0368",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vækerøveien",
    streetNumber: "3",
    postalCode: "0281",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tjernveien",
    streetNumber: "14",
    postalCode: "0957",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sportsveien",
    streetNumber: "1",
    postalCode: "0382",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vålveien",
    streetNumber: "12",
    postalCode: "0852",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hammerstads gate",
    streetNumber: "14B",
    postalCode: "0363",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ole Messelts vei",
    streetNumber: "74B",
    postalCode: "0676",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skogbrynet",
    streetNumber: "27A",
    postalCode: "0283",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Birch-reichenwalds gate",
    streetNumber: "20",
    postalCode: "0483",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jernkroken",
    streetNumber: "9",
    postalCode: "0976",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hospitsveien",
    streetNumber: "11B",
    postalCode: "0789",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holmenkollveien",
    streetNumber: "23",
    postalCode: "0376",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vilbergveien",
    streetNumber: "6",
    postalCode: "0687",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Micheletveien",
    streetNumber: "38F",
    postalCode: "1053",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Voll Terrasse",
    streetNumber: "19B",
    postalCode: "1358",
    city: "Jar",
    country: "Norge",
  },
  {
    streetName: "Monolitveien",
    streetNumber: "2",
    postalCode: "0375",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thorvald Meyers gate",
    streetNumber: "83B",
    postalCode: "0552",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lillogata",
    streetNumber: "5K",
    postalCode: "0484",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ullern allé",
    streetNumber: "41",
    postalCode: "0381",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Arnljot Gellines vei",
    streetNumber: "31",
    postalCode: "0657",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Mylskerudveien",
    streetNumber: "41F",
    postalCode: "1152",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Askergata",
    streetNumber: "59",
    postalCode: "0475",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nåkkves vei",
    streetNumber: "3",
    postalCode: "0670",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Middelthuns gate",
    streetNumber: "25B",
    postalCode: "0368",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gråsteinveien",
    streetNumber: "30",
    postalCode: "1157",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bjørnveien",
    streetNumber: "87",
    postalCode: "0773",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kristinelundveien",
    streetNumber: "1A",
    postalCode: "0268",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hafrsfjordgata",
    streetNumber: "7",
    postalCode: "0273",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vækerøveien",
    streetNumber: "153",
    postalCode: "0751",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Steinborgveien 23",
    streetNumber: "25",
    postalCode: "0678",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ankerveien",
    streetNumber: "121",
    postalCode: "0766",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grønlandsleiret",
    streetNumber: "17A",
    postalCode: "0190",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sørkedalsveien",
    streetNumber: "173A",
    postalCode: "0754",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bråtenalléen",
    streetNumber: "13",
    postalCode: "0488",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sandakerveien",
    streetNumber: "62B",
    postalCode: "0477",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ankerveien",
    streetNumber: "12",
    postalCode: "0782",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Østre Aker vei",
    streetNumber: "219",
    postalCode: "0976",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sloreåsen",
    streetNumber: "36A",
    postalCode: "1257",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bergtunveien",
    streetNumber: "5B",
    postalCode: "1087",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brannfjellveien",
    streetNumber: "86A",
    postalCode: "1181",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bislettgata",
    streetNumber: "6",
    postalCode: "0170",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Østre Aker vei",
    streetNumber: "99",
    postalCode: "0596",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hegdehaugsveien",
    streetNumber: "27",
    postalCode: "0352",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gabels gate",
    streetNumber: "5",
    postalCode: "0272",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Økernveien",
    streetNumber: "205",
    postalCode: "0584",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Oscars gate",
    streetNumber: "9",
    postalCode: "0352",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Østre Aker",
    streetNumber: "vei",
    postalCode: "0981",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ullernchausseen",
    streetNumber: "56",
    postalCode: "0379",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gråkamveien",
    streetNumber: "1",
    postalCode: "0779",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lillevannsveien",
    streetNumber: "85A",
    postalCode: "0788",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holmlia Senter vei",
    streetNumber: "18",
    postalCode: "1255",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ludvig Karstens vei",
    streetNumber: "8",
    postalCode: "1064",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grensen",
    streetNumber: "2",
    postalCode: "0159",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Åssiden Terrasse",
    streetNumber: "22",
    postalCode: "1160",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kristian IVs gate",
    streetNumber: "8",
    postalCode: "0164",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Freserveien",
    streetNumber: "12",
    postalCode: "0195",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Huldreveien",
    streetNumber: "9C",
    postalCode: "0781",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Drammensveien",
    streetNumber: "155",
    postalCode: "0277",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tøyenbekken",
    streetNumber: "6",
    postalCode: "0188",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sverre Iversens vei",
    streetNumber: "9",
    postalCode: "0972",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Måkeveien",
    streetNumber: "6",
    postalCode: "0139",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Elgtråkket",
    streetNumber: "12A",
    postalCode: "1270",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bergsliens gate",
    streetNumber: "11D",
    postalCode: "0354",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Møllesvingen",
    streetNumber: "1D",
    postalCode: "0854",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ekebergveien",
    streetNumber: "225",
    postalCode: "1162",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kjelsåsveien",
    streetNumber: "148G",
    postalCode: "0491",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grensen",
    streetNumber: "1",
    postalCode: "0159",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Fagerliveien",
    streetNumber: "35",
    postalCode: "0587",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Magnus gate",
    streetNumber: "13",
    postalCode: "0650",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lachmanns vei",
    streetNumber: "71B",
    postalCode: "0495",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Moltke Moes vei",
    streetNumber: "16",
    postalCode: "0851",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Welding Olsens vei",
    streetNumber: "5",
    postalCode: "0694",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Øvre Skjoldvei",
    streetNumber: "17",
    postalCode: "0881",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jarlsborgveien",
    streetNumber: "14",
    postalCode: "0379",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vækerøveien",
    streetNumber: "5",
    postalCode: "0281",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hedmarksgata",
    streetNumber: "1",
    postalCode: "0658",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skøyen Terrasse",
    streetNumber: "1",
    postalCode: "0276",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fougstads gate",
    streetNumber: "19",
    postalCode: "0173",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Enebakkveien 223",
    streetNumber: "225",
    postalCode: "0680",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rolf E. Stenersens allé",
    streetNumber: "2",
    postalCode: "0858",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stockholmgata",
    streetNumber: "14",
    postalCode: "0566",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sandstuveien",
    streetNumber: "16",
    postalCode: "1178",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thorleifs allé",
    streetNumber: "8",
    postalCode: "0489",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lille Grensen",
    streetNumber: "9",
    postalCode: "0158",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Carl Kjelsens Vei",
    streetNumber: "69B",
    postalCode: "0880",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grenseveien",
    streetNumber: "54",
    postalCode: "0579",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Akersgata",
    streetNumber: "34",
    postalCode: "0180",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hovfaret",
    streetNumber: "17b",
    postalCode: "0275",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Maridalsveien",
    streetNumber: "261C",
    postalCode: "0872",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Odvar Solbergs vei",
    streetNumber: "208",
    postalCode: "0973",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Professor Birkelands vei",
    streetNumber: "34B",
    postalCode: "1081",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gydas vei",
    streetNumber: "34",
    postalCode: "1413",
    city: "Tårnåsen",
    country: "Norge",
  },
  {
    streetName: "Gullhaugveien",
    streetNumber: "9",
    postalCode: "0484",
    city: "Oslo",
    country: "Noruega",
  },
  {
    streetName: "Myrhaugen",
    streetNumber: "1B",
    postalCode: "0752",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Strandpromenaden",
    streetNumber: "5",
    postalCode: "0252",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stortingsgata",
    streetNumber: "14",
    postalCode: "0161",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Løvsetdalen",
    streetNumber: "12B",
    postalCode: "1188",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Geitmyrsveien",
    streetNumber: "52B",
    postalCode: "0455",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Krokkleiva",
    streetNumber: "1B",
    postalCode: "1170",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fayes gate",
    streetNumber: "6",
    postalCode: "0455",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vingolfveien",
    streetNumber: "16C",
    postalCode: "1170",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rugveien",
    streetNumber: "7",
    postalCode: "0679",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Alnagata",
    streetNumber: "20A",
    postalCode: "0192",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rødtvetveien",
    streetNumber: "33",
    postalCode: "0955",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Planetveien",
    streetNumber: "5",
    postalCode: "0779",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Liaveien",
    streetNumber: "11B",
    postalCode: "0587",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Schweigaards gate",
    streetNumber: "93",
    postalCode: "0656",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Haraldrudveien",
    streetNumber: "11",
    postalCode: "0581",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Charlotte Andersens vei",
    streetNumber: "27A",
    postalCode: "0375",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Oslo gate",
    streetNumber: "6",
    postalCode: "0192",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stovnerfaret",
    streetNumber: "58",
    postalCode: "0982",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holmenkollveien",
    streetNumber: "46",
    postalCode: "0773",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ljabrubakken",
    streetNumber: "7",
    postalCode: "1165",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ravnkollbakken",
    streetNumber: "3",
    postalCode: "0971",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kanonhallveien",
    streetNumber: "22D",
    postalCode: "0585",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tunfaret",
    streetNumber: "12",
    postalCode: "0373",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Korsgata",
    streetNumber: "25",
    postalCode: "0551",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gyldenløves gate",
    streetNumber: "3",
    postalCode: "0260",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lusetjernveien",
    streetNumber: "68A",
    postalCode: "1253",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holmlia Senter vei",
    streetNumber: "9",
    postalCode: "1255",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bestumveien",
    streetNumber: "24",
    postalCode: "0281",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sigurd Iversens vei",
    streetNumber: "1",
    postalCode: "0275",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rosenkrantz gate",
    streetNumber: "1",
    postalCode: "0159",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fossilveien",
    streetNumber: "5B",
    postalCode: "0198",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brattvollveien",
    streetNumber: "25",
    postalCode: "1164",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sandakerveien",
    streetNumber: "138",
    postalCode: "0484",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Olasrudveien",
    streetNumber: "25",
    postalCode: "1284",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gabels gate",
    streetNumber: "4A",
    postalCode: "0272",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Åsjordet",
    streetNumber: "1",
    postalCode: "0381",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holbergs gate",
    streetNumber: "30",
    postalCode: "0166",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hauketoveien",
    streetNumber: "5C",
    postalCode: "1266",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thereses gate",
    streetNumber: "7A",
    postalCode: "0358",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nils Hansens vei",
    streetNumber: "12",
    postalCode: "0667",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Furubråtveien",
    streetNumber: "15C",
    postalCode: "1170",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Risbakken",
    streetNumber: "8B",
    postalCode: "0374",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nedre Kalbakkvei",
    streetNumber: "22",
    postalCode: "0950",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grev Wedels Plass",
    streetNumber: "5",
    postalCode: "0151",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bjørnveien",
    streetNumber: "24",
    postalCode: "0774",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ole Moes vei",
    streetNumber: "14A",
    postalCode: "1165",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Solveien",
    streetNumber: "120B",
    postalCode: "1170",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Slemdalsveien",
    streetNumber: "97B",
    postalCode: "0373",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kollstien",
    streetNumber: "1D",
    postalCode: "1169",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sørkedalsveien",
    streetNumber: "17A",
    postalCode: "0369",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Arnulf Øverlands vei",
    streetNumber: "7",
    postalCode: "0763",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tordenskiolds gate",
    streetNumber: "1",
    postalCode: "0160",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sørkedalsveien",
    streetNumber: "283B",
    postalCode: "0754",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sars gate 2A",
    streetNumber: "2B",
    postalCode: "0562",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Etterstadgata",
    streetNumber: "55D",
    postalCode: "0658",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Danmarks gate",
    streetNumber: "36",
    postalCode: "0658",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karenslyst Allé",
    streetNumber: "51",
    postalCode: "0279",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gyldenløves gate",
    streetNumber: "48",
    postalCode: "0260",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brolandsveien",
    streetNumber: "26",
    postalCode: "0980",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Finnmarkgata",
    streetNumber: "44",
    postalCode: "0563",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lillevannsskogen",
    streetNumber: "15",
    postalCode: "0788",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hovfaret",
    streetNumber: "18",
    postalCode: "0275",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Knud Bryns vei",
    streetNumber: "10",
    postalCode: "0581",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Monolitveien",
    streetNumber: "3",
    postalCode: "0375",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Munkebekken",
    streetNumber: "267",
    postalCode: "1061",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Haugenstien",
    streetNumber: "12",
    postalCode: "1084",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Økernveien",
    streetNumber: "214",
    postalCode: "0584",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grenseveien",
    streetNumber: "95",
    postalCode: "0663",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Freidigveien",
    streetNumber: "17A",
    postalCode: "1163",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Schleppegrells gate",
    streetNumber: "30A",
    postalCode: "0556",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frognerveien",
    streetNumber: "6",
    postalCode: "0257",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Øvre Stabburvei",
    streetNumber: "9",
    postalCode: "0873",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karlsborgveien",
    streetNumber: "5",
    postalCode: "0193",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Anders Winsvolds vei",
    streetNumber: "23",
    postalCode: "0579",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Haraløkka",
    streetNumber: "34",
    postalCode: "0689",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Oberst Rodes vei",
    streetNumber: "118",
    postalCode: "1165",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Blåbærsvingen",
    streetNumber: "8",
    postalCode: "0789",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jomfrubråtveien",
    streetNumber: "72C",
    postalCode: "1179",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Landingsveien",
    streetNumber: "108",
    postalCode: "0767",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lysaker Brygge",
    streetNumber: "33",
    postalCode: "1366",
    city: "Lysaker",
    country: "Norge",
  },
  {
    streetName: "Ullevålsveien",
    streetNumber: "61B",
    postalCode: "0171",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bregneveien",
    streetNumber: "34B",
    postalCode: "0855",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Priorveien",
    streetNumber: "1B",
    postalCode: "0377",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lillogata",
    streetNumber: "3A",
    postalCode: "0484",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grasveien",
    streetNumber: "2",
    postalCode: "0681",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ulsrudveien",
    streetNumber: "12",
    postalCode: "0690",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kranveien",
    streetNumber: "8",
    postalCode: "0684",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jegerveien",
    streetNumber: "48",
    postalCode: "0777",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Strømsveien",
    streetNumber: "266",
    postalCode: "0668",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stensgata",
    streetNumber: "21",
    postalCode: "0358",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sørliveien",
    streetNumber: "30",
    postalCode: "1279",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Maria Dehlis vei",
    streetNumber: "35",
    postalCode: "1084",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Spireaveien",
    streetNumber: "10B",
    postalCode: "0580",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Åsdalsveien",
    streetNumber: "1",
    postalCode: "1166",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Gullhaugveien",
    streetNumber: "7",
    postalCode: "0484",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dalsåsen",
    streetNumber: "120",
    postalCode: "1285",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rugdeveien",
    streetNumber: "10",
    postalCode: "0778",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dalsbergstien",
    streetNumber: "17",
    postalCode: "0170",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kongeveien",
    streetNumber: "40",
    postalCode: "0787",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Olaves Hvervens vei",
    streetNumber: "11",
    postalCode: "1266",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bølerlia",
    streetNumber: "8",
    postalCode: "0691",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kongens gate",
    streetNumber: "2",
    postalCode: "0153",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Oslo gate",
    streetNumber: "2",
    postalCode: "0192",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jacob Aalls gate",
    streetNumber: "15",
    postalCode: "0366",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ekraveien",
    streetNumber: "76",
    postalCode: "0757",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "C. J. Hambros plass",
    streetNumber: "2",
    postalCode: "0164",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fiskekroken",
    streetNumber: "18C",
    postalCode: "0139",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Østre Aker vei",
    streetNumber: "100",
    postalCode: "0596",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dalsbergstien",
    streetNumber: "18",
    postalCode: "0170",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gustav Vigelands vei",
    streetNumber: "14",
    postalCode: "0274",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bånkallstubben",
    streetNumber: "14",
    postalCode: "0964",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nebbejordet",
    streetNumber: "34",
    postalCode: "1266",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Strandveien",
    streetNumber: "15",
    postalCode: "1366",
    city: "Lysaker",
    country: "Norge",
  },
  {
    streetName: "Bygdøyveien",
    streetNumber: "41",
    postalCode: "0287",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Filips gate",
    streetNumber: "2",
    postalCode: "0655",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Tråkka",
    streetNumber: "14",
    postalCode: "0774",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Åsdalsveien",
    streetNumber: "13B",
    postalCode: "1166",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Filipstadveien",
    streetNumber: "1B",
    postalCode: "0250",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hoffsjef Løvenskiolds vei",
    streetNumber: "10",
    postalCode: "0382",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Blakkens vei",
    streetNumber: "96",
    postalCode: "1281",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tjuvholmen allé",
    streetNumber: "21",
    postalCode: "0252",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vidars gate",
    streetNumber: "11",
    postalCode: "0452",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Løvenskiolds gate",
    streetNumber: "8",
    postalCode: "0263",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tvetenveien",
    streetNumber: "150",
    postalCode: "0671",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sommerrogata",
    streetNumber: "13",
    postalCode: "0255",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Professor Kohts Vei",
    streetNumber: "5",
    postalCode: "1366",
    city: "Lysaker",
    country: "Norge",
  },
  {
    streetName: "Ljansbrukveien",
    streetNumber: "4B",
    postalCode: "1250",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hauketoveien",
    streetNumber: "9B",
    postalCode: "1266",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Åsdalsveien",
    streetNumber: "3",
    postalCode: "1166",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rasmus Engers vei",
    streetNumber: "2B",
    postalCode: "0956",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gamle Ringeriksvei",
    streetNumber: "9",
    postalCode: "1368",
    city: "Stabekk",
    country: "Norge",
  },
  {
    streetName: "Haakon Tveters vei",
    streetNumber: "42",
    postalCode: "0686",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bygdøy allé",
    streetNumber: "35",
    postalCode: "0262",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holgerslystveien",
    streetNumber: "10E",
    postalCode: "0280",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Griniveien",
    streetNumber: "34A",
    postalCode: "0756",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rugdeveien",
    streetNumber: "21",
    postalCode: "0778",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bygdøy allé",
    streetNumber: "60C",
    postalCode: "0265",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Trosterudveien",
    streetNumber: "53B",
    postalCode: "0778",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skogbrynet",
    streetNumber: "39C",
    postalCode: "0283",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lutvannsveien",
    streetNumber: "34",
    postalCode: "0676",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Garver Ytteborgs vei",
    streetNumber: "93B",
    postalCode: "0977",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thaulows vei",
    streetNumber: "10B",
    postalCode: "0369",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skigardveien",
    streetNumber: "53",
    postalCode: "0681",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Storåsveien",
    streetNumber: "5A",
    postalCode: "1169",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Herregårdsveien",
    streetNumber: "37",
    postalCode: "1168",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Professor Birkelands vei",
    streetNumber: "3",
    postalCode: "1081",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gaustadalléen",
    streetNumber: "23B",
    postalCode: "0373",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Professor Dahls gate",
    streetNumber: "21",
    postalCode: "0353",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Martin Borrebekkens vei",
    streetNumber: "33",
    postalCode: "0584",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bernhard Getz gate",
    streetNumber: "3",
    postalCode: "0165",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Silurveien",
    streetNumber: "28",
    postalCode: "0380",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sandakerveien",
    streetNumber: "12",
    postalCode: "0473",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thomas Heftyes gate",
    streetNumber: "64A",
    postalCode: "0267",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lilleakerveien",
    streetNumber: "16",
    postalCode: "0283",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kirkeveien",
    streetNumber: "104A",
    postalCode: "0361",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vallefaret",
    streetNumber: "2",
    postalCode: "0663",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vidars gate",
    streetNumber: "18A",
    postalCode: "0452",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Voksenkollveien",
    streetNumber: "80A",
    postalCode: "0790",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ulsholtveien",
    streetNumber: "10D",
    postalCode: "1053",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tvetenveien",
    streetNumber: "4",
    postalCode: "0661",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gamle Bygdevei",
    streetNumber: "201",
    postalCode: "1284",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Glads vei",
    streetNumber: "49",
    postalCode: "0489",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fyrstikkbakken",
    streetNumber: "9",
    postalCode: "0667",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Østensjøveien",
    streetNumber: "128B",
    postalCode: "0682",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skogryggveien",
    streetNumber: "35",
    postalCode: "0781",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jarlsborgveien",
    streetNumber: "3",
    postalCode: "0379",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stølsvegen",
    streetNumber: "6B",
    postalCode: "0590",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Akersgata",
    streetNumber: "55",
    postalCode: "0180",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Drammensveien",
    streetNumber: "119B",
    postalCode: "0273",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Måltrostveien",
    streetNumber: "3B",
    postalCode: "0786",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stordamveien",
    streetNumber: "44A",
    postalCode: "0671",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dronningens gate",
    streetNumber: "21",
    postalCode: "0154",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bygdøyveien",
    streetNumber: "1",
    postalCode: "0287",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Silurveien",
    streetNumber: "17",
    postalCode: "0380",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Odins gate",
    streetNumber: "17",
    postalCode: "0266",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frits Kiærs vei",
    streetNumber: "12",
    postalCode: "0383",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Marta Steinsviksvei",
    streetNumber: "55",
    postalCode: "1283",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vålveien",
    streetNumber: "4",
    postalCode: "0852",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Urtestien",
    streetNumber: "25",
    postalCode: "1164",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Oppsalveien 2",
    streetNumber: "4",
    postalCode: "0686",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Suhms gate",
    streetNumber: "6",
    postalCode: "0362",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Innspurten",
    streetNumber: "6",
    postalCode: "0663",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Risalléen",
    streetNumber: "40",
    postalCode: "0776",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Melkeveien",
    streetNumber: "33",
    postalCode: "0779",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Schiøtts vei",
    streetNumber: "8",
    postalCode: "0286",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Cappelens vei",
    streetNumber: "11",
    postalCode: "1162",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "avringen brygge",
    streetNumber: "5",
    postalCode: "0252",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: " Langbølgen",
    streetNumber: "2",
    postalCode: "1150",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Niels Juels gate",
    streetNumber: "20",
    postalCode: "0272",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dagaliveien",
    streetNumber: "19B",
    postalCode: "0776",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kristian Auberts vei",
    streetNumber: "18",
    postalCode: "0760",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sørkedalsveien",
    streetNumber: "10",
    postalCode: "0369",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skogfaret",
    streetNumber: "7",
    postalCode: "0382",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Olaus Fjørtofts vei",
    streetNumber: "135",
    postalCode: "0982",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holmenkollveien",
    streetNumber: "70C",
    postalCode: "0784",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Otto Sogns vei",
    streetNumber: "21",
    postalCode: "0681",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Treskeveien",
    streetNumber: "52",
    postalCode: "0681",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ravnkollbakken",
    streetNumber: "37",
    postalCode: "0971",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jutulveien",
    streetNumber: "9",
    postalCode: "0852",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karihaugveien",
    streetNumber: "100",
    postalCode: "1086",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Klingenberggata",
    streetNumber: "4",
    postalCode: "0161",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Keysers gate",
    streetNumber: "3",
    postalCode: "0180",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Solveien",
    streetNumber: "58B",
    postalCode: "1170",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Drammensveien",
    streetNumber: "157",
    postalCode: "0277",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Mellombølgen",
    streetNumber: "10",
    postalCode: "1157",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tokerudberget",
    streetNumber: "2",
    postalCode: "0986",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Griniveien",
    streetNumber: "163",
    postalCode: "1359",
    city: "Eiksmarka",
    country: "Norge",
  },
  {
    streetName: "Ankerveien",
    streetNumber: "232",
    postalCode: "1359",
    city: "Eiksmarka",
    country: "Norge",
  },
  {
    streetName: "Rosenbergveien",
    streetNumber: "9",
    postalCode: "0963",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skogfaret",
    streetNumber: "6A",
    postalCode: "0382",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grensen",
    streetNumber: "1",
    postalCode: "0159",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Maridalsveien",
    streetNumber: "17",
    postalCode: "0175",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Museumsveien",
    streetNumber: "15",
    postalCode: "0287",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Landingsveien",
    streetNumber: "122",
    postalCode: "0767",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Odvar Solbergs vei",
    streetNumber: "208",
    postalCode: "0973",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Svoldergata",
    streetNumber: "14",
    postalCode: "5518",
    city: "Haugesund",
    country: "Norge",
  },
  {
    streetName: "Gydas vei",
    streetNumber: "10",
    postalCode: "0363",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Schweigaards gate",
    streetNumber: "94B",
    postalCode: "0656",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jeppes vei 1",
    streetNumber: "3",
    postalCode: "0768",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dorthes vei",
    streetNumber: "4E",
    postalCode: "0287",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brannfjellveien",
    streetNumber: "82",
    postalCode: "1181",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Middelthuns",
    streetNumber: "gate",
    postalCode: "0369",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ymers vei 3",
    streetNumber: "5",
    postalCode: "0588",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sofies gate",
    streetNumber: "41",
    postalCode: "0168",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frederik Stangs gate",
    streetNumber: "46",
    postalCode: "0264",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ellen Gleditsch vei",
    streetNumber: "12",
    postalCode: "0987",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tallbergveien",
    streetNumber: "6A",
    postalCode: "1166",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "St. Halvards gate",
    streetNumber: "25F",
    postalCode: "0192",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Orreskogen",
    streetNumber: "5b",
    postalCode: "0788",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gregers Grams vei",
    streetNumber: "27",
    postalCode: "0382",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Keysers gate",
    streetNumber: "1",
    postalCode: "0180",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Munkebekken",
    streetNumber: "81",
    postalCode: "1061",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ullernchausseen",
    streetNumber: "111",
    postalCode: "0284",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grensen",
    streetNumber: "9B",
    postalCode: "0159",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Slyngveien",
    streetNumber: "19A",
    postalCode: "0376",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tingstuveien",
    streetNumber: "7D",
    postalCode: "0281",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ljabrukollen Terrasse",
    streetNumber: "43",
    postalCode: "1165",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Majorstuveien",
    streetNumber: "33B",
    postalCode: "0367",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Volvat Terrasse",
    streetNumber: "13",
    postalCode: "0369",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Postdamveien",
    streetNumber: "18",
    postalCode: "1164",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grefsenveien",
    streetNumber: "65",
    postalCode: "0487",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nedre Kalbakkvei",
    streetNumber: "80",
    postalCode: "1081",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Abildsøveien",
    streetNumber: "60",
    postalCode: "1187",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brattoddveien",
    streetNumber: "3",
    postalCode: "0198",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Griniveien",
    streetNumber: "163B",
    postalCode: "1359",
    city: "Eiksmarka",
    country: "Norge",
  },
  {
    streetName: "Høyenhallveien",
    streetNumber: "25",
    postalCode: "0678",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nedre Skøyen vei",
    streetNumber: "24",
    postalCode: "0276",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lindemans gate",
    streetNumber: "3C",
    postalCode: "0267",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Morells vei",
    streetNumber: "1C",
    postalCode: "0487",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fossumveien",
    streetNumber: "91B",
    postalCode: "1359",
    city: "Eiksmarka",
    country: "Norge",
  },
  {
    streetName: "Munkerudvollen",
    streetNumber: "16B",
    postalCode: "1165",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Drammensveien",
    streetNumber: "153",
    postalCode: "0277",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Østli",
    streetNumber: "122",
    postalCode: "2054",
    city: "Mogreina",
    country: "Norge",
  },
  {
    streetName: "Vestengsvingen",
    streetNumber: "58",
    postalCode: "1182",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kvernkallveien",
    streetNumber: "10B",
    postalCode: "0382",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stortorvet",
    streetNumber: "1",
    postalCode: "0155",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Enebakkveien",
    streetNumber: "304",
    postalCode: "1188",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "C. A. Torstensens vei",
    streetNumber: "59",
    postalCode: "0377",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brynsveien",
    streetNumber: "16",
    postalCode: "0667",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gildevangen",
    streetNumber: "8",
    postalCode: "0585",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sandakerveien",
    streetNumber: "60",
    postalCode: "0477",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ulsrudveien",
    streetNumber: "10",
    postalCode: "0690",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rundtjernveien",
    streetNumber: "34",
    postalCode: "0672",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hoffsjef Løvenskiolds vei",
    streetNumber: "20",
    postalCode: "0382",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sandakerveien",
    streetNumber: "24C",
    postalCode: "0473",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Alnafetgata",
    streetNumber: "1",
    postalCode: "0192",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rolf E. Stenersens allé",
    streetNumber: "23",
    postalCode: "0858",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Herregårdsveien 17",
    streetNumber: "21",
    postalCode: "1168",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lysehagan",
    streetNumber: "1F",
    postalCode: "0383",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tyristubbveien",
    streetNumber: "26",
    postalCode: "0687",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Øvre Slottsgate",
    streetNumber: "18",
    postalCode: "0157",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lettvintveien",
    streetNumber: "43",
    postalCode: "0487",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rolf E. Stenersens allé",
    streetNumber: "26",
    postalCode: "0858",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nordkappgata",
    streetNumber: "24",
    postalCode: "0479",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Saubakken",
    streetNumber: "4",
    postalCode: "1349",
    city: "Rykkinn",
    country: "Norge",
  },
  {
    streetName: "Valhallveien",
    streetNumber: "107",
    postalCode: "0193",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Mosseveien",
    streetNumber: "245",
    postalCode: "1169",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Krokkleiva",
    streetNumber: "5",
    postalCode: "1170",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nydalen allé",
    streetNumber: "23",
    postalCode: "0484",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Trosterudveien",
    streetNumber: "9D",
    postalCode: "0778",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "John Colletts allé",
    streetNumber: "100",
    postalCode: "0870",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kurveien",
    streetNumber: "11",
    postalCode: "0495",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nydalen allé",
    streetNumber: "23",
    postalCode: "0484",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bestumveien",
    streetNumber: "63A",
    postalCode: "0283",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Eirik Raudes vei",
    streetNumber: "25",
    postalCode: "0196",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holmlia Senter vei",
    streetNumber: "1",
    postalCode: "1255",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Langerudsvingen",
    streetNumber: "31D",
    postalCode: "1187",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bølerskrenten",
    streetNumber: "14",
    postalCode: "0691",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ammerudgrenda",
    streetNumber: "176",
    postalCode: "0960",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Professor Dahls gate",
    streetNumber: "18",
    postalCode: "0353",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Akebakkeskogen",
    streetNumber: "3",
    postalCode: "0490",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Maridalsveien",
    streetNumber: "372",
    postalCode: "0881",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dr. Dedichens vei",
    streetNumber: "42",
    postalCode: "0675",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lille Grensen",
    streetNumber: "5",
    postalCode: "0159",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Darres gate",
    streetNumber: "30",
    postalCode: "0175",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skiveien",
    streetNumber: "40B",
    postalCode: "1410",
    city: "Kolbotn",
    country: "Norge",
  },
  {
    streetName: "Chr. Krohgs gate",
    streetNumber: "16",
    postalCode: "0186",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hagelundveien",
    streetNumber: "34",
    postalCode: "0963",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Smalvollveien",
    streetNumber: "36",
    postalCode: "0667",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Østre Aker vei",
    streetNumber: "33",
    postalCode: "0581",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hoffsjef Løvenskiolds vei",
    streetNumber: "60",
    postalCode: "0382",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Filips gate",
    streetNumber: "2",
    postalCode: "0655",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dalsbergstien",
    streetNumber: "1",
    postalCode: "0170",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Manglerudveien",
    streetNumber: "8",
    postalCode: "0678",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Smalvollveien",
    streetNumber: "22",
    postalCode: "0667",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Birgitte Hammers vei",
    streetNumber: "15A",
    postalCode: "1167",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Wilses gate",
    streetNumber: "4B",
    postalCode: "0178",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rosenkrantz gate",
    streetNumber: "10",
    postalCode: "0162",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Langkaia",
    streetNumber: "1A",
    postalCode: "0150",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Solkroken",
    streetNumber: "5",
    postalCode: "0664",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nedre Skøyen vei",
    streetNumber: "26",
    postalCode: "0276",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Maria Dehlis vei",
    streetNumber: "29B",
    postalCode: "1084",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sognsveien 68",
    streetNumber: "70",
    postalCode: "0855",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Munkerudveien",
    streetNumber: "26B",
    postalCode: "1163",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gabels gate",
    streetNumber: "9A",
    postalCode: "0272",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Haugerudveien",
    streetNumber: "72",
    postalCode: "0674",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Middelthuns gate",
    streetNumber: "23",
    postalCode: "0368",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hovseterveien",
    streetNumber: "98",
    postalCode: "0768",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Økernveien",
    streetNumber: "99",
    postalCode: "0579",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dalsbergstien",
    streetNumber: "2",
    postalCode: "0170",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Vækerøveien",
    streetNumber: "140",
    postalCode: "0383",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Snipemyrveien",
    streetNumber: "2",
    postalCode: "1273",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Geitmyrsveien",
    streetNumber: "7E",
    postalCode: "0171",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bergtunveien",
    streetNumber: "3",
    postalCode: "1087",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rødbergveien",
    streetNumber: "82A",
    postalCode: "0593",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Huk Aveny",
    streetNumber: "12",
    postalCode: "0287",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Myrhaugen",
    streetNumber: "25D",
    postalCode: "0752",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Harald Sohlbergs Vei",
    streetNumber: "19",
    postalCode: "1064",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tordenskiolds gate",
    streetNumber: "3",
    postalCode: "0160",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Niels Juels gate",
    streetNumber: "12",
    postalCode: "0272",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fossumveien",
    streetNumber: "93",
    postalCode: "1359",
    city: "Eiksmarka",
    country: "Norge",
  },
  {
    streetName: "Vækerø Terrasse",
    streetNumber: "2",
    postalCode: "0282",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Apalveien",
    streetNumber: "12",
    postalCode: "0371",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Huk Aveny",
    streetNumber: "58G",
    postalCode: "0287",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Niels Juels gate",
    streetNumber: "15",
    postalCode: "0272",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Enebakkveien",
    streetNumber: "342",
    postalCode: "1164",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brattvollveien",
    streetNumber: "187",
    postalCode: "1164",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Breiens vei",
    streetNumber: "1J",
    postalCode: "1165",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nedre Ullevål",
    streetNumber: "2A",
    postalCode: "0850",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "St. Halvards gate",
    streetNumber: "65",
    postalCode: "0657",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lysehagan",
    streetNumber: "73",
    postalCode: "0383",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Selvbyggerveien",
    streetNumber: "46",
    postalCode: "0591",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Holmenkollveien",
    streetNumber: "12",
    postalCode: "0376",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ljansbrukveien",
    streetNumber: "26",
    postalCode: "1250",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hegdehaugsveien",
    streetNumber: "27A",
    postalCode: "0352",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Mosseveien",
    streetNumber: "213",
    postalCode: "1169",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bråtenalléen",
    streetNumber: "16",
    postalCode: "0487",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Blindernveien",
    streetNumber: "43",
    postalCode: "0371",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tingstuveien",
    streetNumber: "8E",
    postalCode: "0281",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Majorstuveien",
    streetNumber: "2",
    postalCode: "0353",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ullevålsveien",
    streetNumber: "109B",
    postalCode: "0359",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Monrads gate",
    streetNumber: "3D",
    postalCode: "0577",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Madserud allé",
    streetNumber: "68",
    postalCode: "0274",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ullevålsveien",
    streetNumber: "82",
    postalCode: "0454",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gabels gate",
    streetNumber: "8A",
    postalCode: "0272",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Martin Linges vei",
    streetNumber: "12",
    postalCode: "0692",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brugata",
    streetNumber: "9",
    postalCode: "0186",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rosenholmveien",
    streetNumber: "40",
    postalCode: "1414",
    city: "Trollåsen",
    country: "Norge",
  },
  {
    streetName: "Waldemar Thranes gate",
    streetNumber: "42B",
    postalCode: "0171",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Solliveien",
    streetNumber: "21",
    postalCode: "1366",
    city: "Lysaker",
    country: "Norge",
  },
  {
    streetName: "Lakkegata",
    streetNumber: "15B",
    postalCode: "0187",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Åsdalsveien",
    streetNumber: "13B",
    postalCode: "1166",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Østre Aker vei",
    streetNumber: "35",
    postalCode: "0581",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Trosterudveien",
    streetNumber: "52",
    postalCode: "0778",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Haakon VIIs gate",
    streetNumber: "2",
    postalCode: "0161",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bygdøy allé",
    streetNumber: "21",
    postalCode: "0262",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Apalveien",
    streetNumber: "21",
    postalCode: "0371",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hoffsveien",
    streetNumber: "50",
    postalCode: "0377",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Zetlitz gate",
    streetNumber: "4",
    postalCode: "0165",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Olaf Helsets vei",
    streetNumber: "2",
    postalCode: "0694",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "John Colletts allé",
    streetNumber: "74",
    postalCode: "0854",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sjøgata",
    streetNumber: "2",
    postalCode: "0250",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Østbyfaret",
    streetNumber: "22B",
    postalCode: "0687",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sorgenfrigata",
    streetNumber: "2",
    postalCode: "0367",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Monrads gate",
    streetNumber: "11",
    postalCode: "0564",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Maridalsveien",
    streetNumber: "217",
    postalCode: "0467",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Westye Egebergs gate",
    streetNumber: "8B",
    postalCode: "0172",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Welding Olsens vei",
    streetNumber: "19",
    postalCode: "0694",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hartmanns vei",
    streetNumber: "25A",
    postalCode: "0284",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vollsveien",
    streetNumber: "43",
    postalCode: "1358",
    city: "Jar",
    country: "Norge",
  },
  {
    streetName: "Tore Hunds vei",
    streetNumber: "23",
    postalCode: "0576",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Husebybakken",
    streetNumber: "28B",
    postalCode: "0379",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Øvre Prinsdals vei",
    streetNumber: "12",
    postalCode: "1266",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Peter Møllers vei",
    streetNumber: "5B",
    postalCode: "0585",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stovnerlia",
    streetNumber: "69",
    postalCode: "0983",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Enebakkveien",
    streetNumber: "249B",
    postalCode: "1187",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hospitsveien",
    streetNumber: "2A",
    postalCode: "0789",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Trostefaret",
    streetNumber: "4",
    postalCode: "0786",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Harald Løvenskiolds vei",
    streetNumber: "6",
    postalCode: "0760",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Observatoriegata",
    streetNumber: "7B",
    postalCode: "0254",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Keysers gate",
    streetNumber: "3",
    postalCode: "0165",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sollerudveien",
    streetNumber: "27",
    postalCode: "0283",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karlstadgata",
    streetNumber: "4B",
    postalCode: "0553",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Malmøyveien",
    streetNumber: "31B",
    postalCode: "0198",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skådalsveien",
    streetNumber: "28",
    postalCode: "0781",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ammerudgrenda",
    streetNumber: "189",
    postalCode: "0960",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ulsrudveien",
    streetNumber: "26A",
    postalCode: "0690",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vækerøveien",
    streetNumber: "25",
    postalCode: "0282",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lunden",
    streetNumber: "30",
    postalCode: "0598",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dalskroken",
    streetNumber: "3B",
    postalCode: "0376",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karenslyst Allé",
    streetNumber: "53",
    postalCode: "0279",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Postdamveien",
    streetNumber: "2E",
    postalCode: "1164",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bogstadveien",
    streetNumber: "30",
    postalCode: "0355",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Slimeveien",
    streetNumber: "37",
    postalCode: "1275",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kongshavnveien",
    streetNumber: "2",
    postalCode: "0193",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rønningveien",
    streetNumber: "3",
    postalCode: "0494",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bekkeliveien",
    streetNumber: "13B",
    postalCode: "0375",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Professor Kohts Vei",
    streetNumber: "41",
    postalCode: "1368",
    city: "Stabekk",
    country: "Norge",
  },
  {
    streetName: "Ragnhild Schibbyes vei",
    streetNumber: "40",
    postalCode: "0968",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gråkamveien",
    streetNumber: "4",
    postalCode: "0777",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Strømsveien",
    streetNumber: "245",
    postalCode: "0668",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sognsvannsveien",
    streetNumber: "59",
    postalCode: "0372",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sportsveien",
    streetNumber: "8",
    postalCode: "0382",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Enebakkveien",
    streetNumber: "302",
    postalCode: "1188",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Fyrstikkbakken",
    streetNumber: "7C",
    postalCode: "0667",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grensen",
    streetNumber: "9B",
    postalCode: "0159",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Olaf Bulls vei",
    streetNumber: "10A",
    postalCode: "0765",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Peder Holters vei",
    streetNumber: "39C",
    postalCode: "1168",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nordraaks vei",
    streetNumber: "2",
    postalCode: "1369",
    city: "Stabekk",
    country: "Norge",
  },
  {
    streetName: "Nordbergveien",
    streetNumber: "78",
    postalCode: "0875",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Midtstugrenda",
    streetNumber: "60",
    postalCode: "0787",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Professor Birkelands vei",
    streetNumber: "33",
    postalCode: "1081",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Armauer Hansens gate",
    streetNumber: "14",
    postalCode: "0455",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skogfaret",
    streetNumber: "6A",
    postalCode: "0382",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Selvbyggerveien",
    streetNumber: "46",
    postalCode: "0591",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vækerøveien",
    streetNumber: "18",
    postalCode: "0282",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Storgata",
    streetNumber: "55",
    postalCode: "0182",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Smalvollveien",
    streetNumber: "48",
    postalCode: "0667",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rådyrveien",
    streetNumber: "5",
    postalCode: "0595",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hovfaret",
    streetNumber: "17B",
    postalCode: "0275",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bukken Bruses vei",
    streetNumber: "20",
    postalCode: "0851",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Havnabakken",
    streetNumber: "48",
    postalCode: "0874",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kvartsveien",
    streetNumber: "11",
    postalCode: "1158",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Solfjellshøgda",
    streetNumber: "44",
    postalCode: "0677",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Maridalsveien",
    streetNumber: "33M",
    postalCode: "0175",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ullern Gårds vei",
    streetNumber: "27",
    postalCode: "0382",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Snarøyveien",
    streetNumber: "55",
    postalCode: "1364",
    city: "Snarøya",
    country: "Norge",
  },
  {
    streetName: "Vestvangvegen",
    streetNumber: "144",
    postalCode: "2080",
    city: "Eidsvoll",
    country: "Norge",
  },
  {
    streetName: "Solligrenda 37",
    streetNumber: "40",
    postalCode: "0491",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bredo Stabells vei",
    streetNumber: "5",
    postalCode: "0853",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bjørn Bondes vei",
    streetNumber: "2",
    postalCode: "1253",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skogroveien",
    streetNumber: "4",
    postalCode: "1178",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stockfleths gate",
    streetNumber: "45",
    postalCode: "0461",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ravnåsveien",
    streetNumber: "62C",
    postalCode: "1254",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lille Borgen vei",
    streetNumber: "10",
    postalCode: "0370",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Drammensveien",
    streetNumber: "250",
    postalCode: "0277",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bjerregaards gate",
    streetNumber: "2",
    postalCode: "0172",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ingar Nilsens vei",
    streetNumber: "9",
    postalCode: "0268",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Storgata",
    streetNumber: "47",
    postalCode: "0182",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sorgenfrigata",
    streetNumber: "19B",
    postalCode: "0365",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lørenveien",
    streetNumber: "11",
    postalCode: "0585",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holmenveien",
    streetNumber: "10",
    postalCode: "0374",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Haugerudhagan",
    streetNumber: "70",
    postalCode: "0673",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karenslyst Allé",
    streetNumber: "12",
    postalCode: "0278",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Akershusstranda",
    streetNumber: "15",
    postalCode: "0150",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Margrethe Parms vei",
    streetNumber: "18A",
    postalCode: "0988",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Økern Torgvei",
    streetNumber: "51",
    postalCode: "0580",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jacob Aalls gate",
    streetNumber: "15",
    postalCode: "0366",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Snarøyveien",
    streetNumber: "69",
    postalCode: "1364",
    city: "Fornebu",
    country: "Norge",
  },
  {
    streetName: "Gamleveien",
    streetNumber: "1",
    postalCode: "1473",
    city: "Lørenskog",
    country: "Norge",
  },
  {
    streetName: "Ekebergveien",
    streetNumber: "256",
    postalCode: "1166",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lørenvangen",
    streetNumber: "34",
    postalCode: "0585",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Veitvetveien",
    streetNumber: "21",
    postalCode: "0596",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grønlibakken",
    streetNumber: "11",
    postalCode: "0682",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sognsvannsveien",
    streetNumber: "21",
    postalCode: "0372",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nedre Silkestrå",
    streetNumber: "17",
    postalCode: "0375",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dalssvingen",
    streetNumber: "22D",
    postalCode: "1285",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Postdamveien",
    streetNumber: "12",
    postalCode: "1164",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Oppsaltoppen",
    streetNumber: "9",
    postalCode: "0687",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Møllefaret",
    streetNumber: "44A",
    postalCode: "0750",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Røaveien",
    streetNumber: "27",
    postalCode: "0752",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bjørnveien",
    streetNumber: "20F",
    postalCode: "0774",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skøyen allé",
    streetNumber: "8",
    postalCode: "0667",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holmenkollveien",
    streetNumber: "2",
    postalCode: "0376",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ammerudgrenda",
    streetNumber: "172",
    postalCode: "0960",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Postdamveien",
    streetNumber: "6B",
    postalCode: "1164",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Arilds vei",
    streetNumber: "3D",
    postalCode: "0491",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gydas vei",
    streetNumber: "8",
    postalCode: "0363",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skigardveien",
    streetNumber: "23",
    postalCode: "0681",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Alfaset 3. Industrivei",
    streetNumber: "27",
    postalCode: "0668",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Torggata",
    streetNumber: "4",
    postalCode: "0181",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Akersgata",
    streetNumber: "17",
    postalCode: "0158",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grorudveien",
    streetNumber: "99",
    postalCode: "1053",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dælenenggata",
    streetNumber: "1",
    postalCode: "0567",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ragnhild Schibbyes vei",
    streetNumber: "35",
    postalCode: "0968",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Alundamveien",
    streetNumber: "61A",
    postalCode: "0957",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Olaf Schous vei",
    streetNumber: "11",
    postalCode: "0572",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Alf Bjerckes vei",
    streetNumber: "10",
    postalCode: "0596",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ole Fladagers gate",
    streetNumber: "10B",
    postalCode: "0353",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Titutveien",
    streetNumber: "18",
    postalCode: "0668",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Munkedamsveien",
    streetNumber: "55",
    postalCode: "0270",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bygdøy allé",
    streetNumber: "17",
    postalCode: "0262",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Maria Dehlis vei",
    streetNumber: "40",
    postalCode: "1084",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frognerstranda",
    streetNumber: "2",
    postalCode: "0250",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nordåsveien",
    streetNumber: "1",
    postalCode: "1251",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "St. Halvards gate",
    streetNumber: "33",
    postalCode: "0192",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "President Harbitz gate",
    streetNumber: "a1",
    postalCode: "0259",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Biskop Heuchs vei",
    streetNumber: "1",
    postalCode: "0871",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dronningens gate",
    streetNumber: "15",
    postalCode: "0152",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kapellveien",
    streetNumber: "110D",
    postalCode: "0493",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brattvollveien",
    streetNumber: "35",
    postalCode: "1164",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rubina Ranas gate",
    streetNumber: "7",
    postalCode: "0190",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Smedveien",
    streetNumber: "7",
    postalCode: "1164",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "St. Olavs Gate",
    streetNumber: "11C",
    postalCode: "0165",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Traverveien",
    streetNumber: "44",
    postalCode: "0588",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sinsenveien",
    streetNumber: "48",
    postalCode: "0585",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bjerkeliveien",
    streetNumber: "2B",
    postalCode: "1087",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dagaliveien",
    streetNumber: "18C",
    postalCode: "0776",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Deichmans gate",
    streetNumber: "4B",
    postalCode: "0178",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gøteborggata",
    streetNumber: "35B",
    postalCode: "0566",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Innspurten",
    streetNumber: "9",
    postalCode: "0663",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Valhallaveien",
    streetNumber: "62B",
    postalCode: "1413",
    city: "Tårnåsen",
    country: "Norge",
  },
  {
    streetName: "Folke Bernadottes vei",
    streetNumber: "37C",
    postalCode: "0862",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hovfaret 6",
    streetNumber: "8",
    postalCode: "0275",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hovfaret",
    streetNumber: "17",
    postalCode: "0275",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Vækerøveien",
    streetNumber: "129C",
    postalCode: "0383",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ullevålsveien",
    streetNumber: "45",
    postalCode: "0171",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Øvre Skogvei",
    streetNumber: "1C",
    postalCode: "0281",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vøyensvingen",
    streetNumber: "2",
    postalCode: "0458",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sognsveien",
    streetNumber: "220",
    postalCode: "0863",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karl Johans gate",
    streetNumber: "5",
    postalCode: "0154",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ekebergveien",
    streetNumber: "187F",
    postalCode: "1177",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skansen Terrasse",
    streetNumber: "26",
    postalCode: "1062",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bjerkebakken",
    streetNumber: "18",
    postalCode: "0757",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ankerveien",
    streetNumber: "51D",
    postalCode: "0785",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Strømsborgveien",
    streetNumber: "47B",
    postalCode: "0287",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tingstuveien",
    streetNumber: "7B",
    postalCode: "0281",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Herregårdsveien",
    streetNumber: "32",
    postalCode: "1168",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Cecilie Thoresens vei",
    streetNumber: "17",
    postalCode: "1153",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rolfsbuktveien",
    streetNumber: "17",
    postalCode: "1364",
    city: "Fornebu",
    country: "Norge",
  },
  {
    streetName: "Slottsplassen",
    streetNumber: "1",
    postalCode: "0010",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hagegata",
    streetNumber: "28",
    postalCode: "0653",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vitaminveien",
    streetNumber: "7",
    postalCode: "0485",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bygdøy allé",
    streetNumber: "123A",
    postalCode: "0273",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Havreveien",
    streetNumber: "11",
    postalCode: "0680",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gamle Leirdalsvei",
    streetNumber: "20",
    postalCode: "1081",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dronning Blancas vei",
    streetNumber: "1",
    postalCode: "0287",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Smestadveien",
    streetNumber: "8J",
    postalCode: "0376",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dalsbergstien",
    streetNumber: "18",
    postalCode: "0170",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Idas vei",
    streetNumber: "139",
    postalCode: "0981",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Persveien",
    streetNumber: "20",
    postalCode: "0581",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holmenveien",
    streetNumber: "27",
    postalCode: "0374",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Løchenveien",
    streetNumber: "3A",
    postalCode: "0286",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vestlisvingen",
    streetNumber: "110",
    postalCode: "0969",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vennersborgveien",
    streetNumber: "8A",
    postalCode: "0281",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brinken",
    streetNumber: "20",
    postalCode: "0654",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Akersgata",
    streetNumber: "28",
    postalCode: "0158",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skådalsveien",
    streetNumber: "8",
    postalCode: "0781",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gamlelinja",
    streetNumber: "52B",
    postalCode: "1254",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Malmøyveien",
    streetNumber: "15A",
    postalCode: "0198",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gladengveien",
    streetNumber: "15",
    postalCode: "0661",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Mosseveien",
    streetNumber: "256",
    postalCode: "1169",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Økernveien",
    streetNumber: "4",
    postalCode: "0653",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Trondheimsveien",
    streetNumber: "618",
    postalCode: "0964",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lillevannsveien",
    streetNumber: "61",
    postalCode: "0788",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Trollåsveien",
    streetNumber: "47",
    postalCode: "0672",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Arnulf Øverlands vei",
    streetNumber: "216",
    postalCode: "0763",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gulleråsveien",
    streetNumber: "20",
    postalCode: "0779",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dr. Holms vei",
    streetNumber: "21D",
    postalCode: "0787",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nydalsveien",
    streetNumber: "15",
    postalCode: "0484",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Trosterudveien",
    streetNumber: "17",
    postalCode: "0778",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Melumveien",
    streetNumber: "54",
    postalCode: "0760",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Smalvollveien",
    streetNumber: "65",
    postalCode: "0667",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Voksenkollveien",
    streetNumber: "35",
    postalCode: "0790",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Langerudsvingen",
    streetNumber: "8E",
    postalCode: "1187",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bjerregaards gate",
    streetNumber: "68A",
    postalCode: "0174",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kalbakkveien",
    streetNumber: "1B",
    postalCode: "0953",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Midtåsen",
    streetNumber: "48",
    postalCode: "1166",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Drammensveien",
    streetNumber: "133",
    postalCode: "0277",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ankerveien",
    streetNumber: "51D",
    postalCode: "0785",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Jordal Terrasse",
    streetNumber: "10",
    postalCode: "0658",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hasleveien",
    streetNumber: "15B",
    postalCode: "0571",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Økern Torgvei",
    streetNumber: "9C",
    postalCode: "0580",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Østre Aker vei",
    streetNumber: "50",
    postalCode: "0581",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rasmusbakken",
    streetNumber: "17",
    postalCode: "0962",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kronprinsesse Märthas plass",
    streetNumber: "1",
    postalCode: "0160",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vækerøveien",
    streetNumber: "10",
    postalCode: "0282",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Svenskerudveien",
    streetNumber: "20",
    postalCode: "0680",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Erling Michelsens vei",
    streetNumber: "2A",
    postalCode: "1061",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karenslyst Allé",
    streetNumber: "49",
    postalCode: "0279",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Granveien",
    streetNumber: "15C",
    postalCode: "1178",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Huitfeldts gate",
    streetNumber: "13",
    postalCode: "0253",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Plogveien",
    streetNumber: "100",
    postalCode: "0681",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Krokliveien",
    streetNumber: "52",
    postalCode: "0584",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vitaminveien",
    streetNumber: "7",
    postalCode: "0485",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tordenskiolds gate",
    streetNumber: "3",
    postalCode: "0160",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Smestadveien",
    streetNumber: "1G",
    postalCode: "0376",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Montebello Terrasse",
    streetNumber: "9",
    postalCode: "0379",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hauges vei",
    streetNumber: "12B",
    postalCode: "0871",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Adolf Hedins",
    streetNumber: "vei",
    postalCode: "0680",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "John Colletts allé",
    streetNumber: "82",
    postalCode: "0854",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Trondheimsveien",
    streetNumber: "14",
    postalCode: "0560",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Strømsborgveien",
    streetNumber: "27B",
    postalCode: "0287",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dronning Eufemias gate",
    streetNumber: "4",
    postalCode: "0154",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Enebakkveien",
    streetNumber: "322",
    postalCode: "1188",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ekebergveien",
    streetNumber: "253B",
    postalCode: "1166",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Malmøyveien",
    streetNumber: "31A",
    postalCode: "0198",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kirkeveien",
    streetNumber: "45",
    postalCode: "0368",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kransen",
    streetNumber: "2B",
    postalCode: "1166",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gladengveien",
    streetNumber: "2",
    postalCode: "0661",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fagerheimgata",
    streetNumber: "20C",
    postalCode: "0475",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Storgata",
    streetNumber: "32",
    postalCode: "0184",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ljabrukollen Terrasse",
    streetNumber: "41",
    postalCode: "1165",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stranden",
    streetNumber: "1",
    postalCode: "0250",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tjernveien",
    streetNumber: "5B",
    postalCode: "0957",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gråkamveien",
    streetNumber: "10C",
    postalCode: "0779",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Godals vei",
    streetNumber: "29",
    postalCode: "0871",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bergljots vei",
    streetNumber: "13A",
    postalCode: "0575",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Østre Aker",
    streetNumber: "vei",
    postalCode: "0581",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Colbjørnsens gate",
    streetNumber: "12",
    postalCode: "0256",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Langerudsvingen",
    streetNumber: "16G",
    postalCode: "1187",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Mosseveien",
    streetNumber: "243",
    postalCode: "1169",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Samvirkeveien",
    streetNumber: "26B",
    postalCode: "1178",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Granveien",
    streetNumber: "16",
    postalCode: "1178",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Forskningsveien",
    streetNumber: "2B",
    postalCode: "0373",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Agathe Grøndahls gate",
    streetNumber: "43",
    postalCode: "0475",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Agathe Grøndahls gate",
    streetNumber: "26",
    postalCode: "0478",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Agathe Grøndahls gate",
    streetNumber: "24",
    postalCode: "0478",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Agmund Bolts vei",
    streetNumber: "49",
    postalCode: "0664",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Agmund Bolts vei",
    streetNumber: "46",
    postalCode: "0664",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Agmund Bolts vei",
    streetNumber: "43",
    postalCode: "0664",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Agmund Bolts vei",
    streetNumber: "31",
    postalCode: "0664",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Akerlia",
    streetNumber: "11",
    postalCode: "0951",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Akersbakken",
    streetNumber: "35",
    postalCode: "0172",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Akersbakken",
    streetNumber: "10",
    postalCode: "0172",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Akersgata",
    streetNumber: "73",
    postalCode: "0180",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Akersgata",
    streetNumber: "34",
    postalCode: "0180",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Akersgata",
    streetNumber: "30",
    postalCode: "0158",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Akersgata",
    streetNumber: "28",
    postalCode: "0158",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Akersgata",
    streetNumber: "8",
    postalCode: "0158",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Akersgata",
    streetNumber: "2",
    postalCode: "0158",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Alf Bjerckes vei",
    streetNumber: "10",
    postalCode: "0596",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Alfaset Industrivei",
    streetNumber: "25",
    postalCode: "0668",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Alfasetveien",
    streetNumber: "24",
    postalCode: "0668",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Alnabruveien",
    streetNumber: "10",
    postalCode: "0668",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Alnafetgata",
    streetNumber: "8B",
    postalCode: "0192",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Alnagata",
    streetNumber: "16",
    postalCode: "0192",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Alnagata",
    streetNumber: "14",
    postalCode: "0192",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Alnagata",
    streetNumber: "11",
    postalCode: "0192",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ammerudgrenda",
    streetNumber: "166",
    postalCode: "0960",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ammerudgrenda",
    streetNumber: "38",
    postalCode: "0960",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ammerudhellinga",
    streetNumber: "62",
    postalCode: "0959",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ammerudhellinga",
    streetNumber: "39",
    postalCode: "0959",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ammerudhellinga",
    streetNumber: "33",
    postalCode: "0959",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ammerudhellinga",
    streetNumber: "28",
    postalCode: "0959",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Amtmann Meinichs gate",
    streetNumber: "14B",
    postalCode: "0482",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ankerveien",
    streetNumber: "79B",
    postalCode: "0766",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ankerveien",
    streetNumber: "51D",
    postalCode: "0785",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ankerveien",
    streetNumber: "40A",
    postalCode: "0785",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ankerveien",
    streetNumber: "31M",
    postalCode: "0785",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ankerveien",
    streetNumber: "26B",
    postalCode: "0785",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ankerveien",
    streetNumber: "31",
    postalCode: "0785",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ankerveien",
    streetNumber: "14",
    postalCode: "0782",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Anna Rogstads vei",
    streetNumber: "45B",
    postalCode: "0592",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Anna Rogstads vei",
    streetNumber: "45",
    postalCode: "0592",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ansgar Sørlies vei",
    streetNumber: "5",
    postalCode: "0576",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ansgar Sørlies vei",
    streetNumber: "1",
    postalCode: "0576",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Anton Schjøths gate",
    streetNumber: "2B",
    postalCode: "0454",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Anton Tschudis vei",
    streetNumber: "19",
    postalCode: "0583",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Apalveien",
    streetNumber: "40",
    postalCode: "0371",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Apalveien",
    streetNumber: "18",
    postalCode: "0371",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Arbos gate",
    streetNumber: "2A",
    postalCode: "0368",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Arbos gate",
    streetNumber: "1A",
    postalCode: "0368",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Arendalsgata",
    streetNumber: "15",
    postalCode: "0463",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Arendalsgata",
    streetNumber: "15",
    postalCode: "0463",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Arendalsgata",
    streetNumber: "6",
    postalCode: "0463",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Arne Garborgs vei",
    streetNumber: "32",
    postalCode: "0671",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Arneborgveien",
    streetNumber: "14",
    postalCode: "1430",
    city: "Ås",
    country: "Norge",
  },
  {
    streetName: "Arnebråtveien",
    streetNumber: "32C",
    postalCode: "0771",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Arnebråtveien",
    streetNumber: "51",
    postalCode: "0771",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Arnebråtveien",
    streetNumber: "18",
    postalCode: "0376",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Arnebråtveien",
    streetNumber: "3",
    postalCode: "0376",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Arnljot Gellines vei",
    streetNumber: "5A",
    postalCode: "0657",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Arnstein Arnebergs vei",
    streetNumber: "4",
    postalCode: "1366",
    city: "Lysaker",
    country: "Norge",
  },
  {
    streetName: "Arnulf Øverlands vei",
    streetNumber: "241A",
    postalCode: "0763",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Arnulf Øverlands vei",
    streetNumber: "249",
    postalCode: "0763",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Arnulf Øverlands vei",
    streetNumber: "209",
    postalCode: "0763",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Arnulf Øverlands vei",
    streetNumber: "206",
    postalCode: "0763",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Arnulf Øverlands vei",
    streetNumber: "133",
    postalCode: "0763",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Arnulf Øverlands vei",
    streetNumber: "131",
    postalCode: "0763",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Arnulf Øverlands vei",
    streetNumber: "5",
    postalCode: "0763",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Arups gate",
    streetNumber: "18A",
    postalCode: "0192",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Arups gate",
    streetNumber: "20",
    postalCode: "0192",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Aschehougs vei",
    streetNumber: "6",
    postalCode: "0587",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Askergata",
    streetNumber: "2",
    postalCode: "0475",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Aslakveien",
    streetNumber: "27",
    postalCode: "0753",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Aslakveien",
    streetNumber: "19",
    postalCode: "0753",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Asperudtoppen",
    streetNumber: "64",
    postalCode: "1258",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Asperudtoppen",
    streetNumber: "38",
    postalCode: "1258",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Asperudveien",
    streetNumber: "4A",
    postalCode: "1258",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Asperudveien",
    streetNumber: "4A",
    postalCode: "1258",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Aspeveien",
    streetNumber: "5",
    postalCode: "1359",
    city: "Eiksmarka",
    country: "Norge",
  },
  {
    streetName: "Astrids vei",
    streetNumber: "3",
    postalCode: "0276",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Aurskoggata",
    streetNumber: "7H",
    postalCode: "0655",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Avstikkeren",
    streetNumber: "5",
    postalCode: "1156",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Badebakken",
    streetNumber: "22",
    postalCode: "0467",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Badebakken",
    streetNumber: "20",
    postalCode: "0467",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Badebakken",
    streetNumber: "13",
    postalCode: "0467",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Badstugata",
    streetNumber: "1",
    postalCode: "0179",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bakkeveien",
    streetNumber: "7C",
    postalCode: "1415",
    city: "Oppegård",
    country: "Norge",
  },
  {
    streetName: "Balders gate",
    streetNumber: "13",
    postalCode: "0263",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bamsefaret",
    streetNumber: "6A",
    postalCode: "1362",
    city: "Hosle",
    country: "Norge",
  },
  {
    streetName: "Bankplassen",
    streetNumber: "1A",
    postalCode: "0151",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bankplassen",
    streetNumber: "4",
    postalCode: "0151",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bekkefaret",
    streetNumber: "9A",
    postalCode: "0280",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bekkefaret",
    streetNumber: "8C",
    postalCode: "0280",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bekkefaret",
    streetNumber: "4C",
    postalCode: "0280",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bekkefaret",
    streetNumber: "16",
    postalCode: "0280",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bekkefaret",
    streetNumber: "13",
    postalCode: "0280",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bekkelagsveien",
    streetNumber: "20",
    postalCode: "1177",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bekkeliveien",
    streetNumber: "4B",
    postalCode: "0375",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bekkestuveien",
    streetNumber: "3",
    postalCode: "1357",
    city: "Bekkestua",
    country: "Norge",
  },
  {
    streetName: "Bekkeveien",
    streetNumber: "22",
    postalCode: "1472",
    city: "Fjellhamar",
    country: "Norge",
  },
  {
    streetName: "Bentsebrugata",
    streetNumber: "25B",
    postalCode: "0469",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bentsebrugata",
    streetNumber: "16H",
    postalCode: "0476",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bentsebrugata",
    streetNumber: "16",
    postalCode: "0476",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bentsebrugata",
    streetNumber: "5",
    postalCode: "0476",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bentsegata",
    streetNumber: "2B",
    postalCode: "0465",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bentsegata",
    streetNumber: "17",
    postalCode: "0465",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Benveien",
    streetNumber: "6",
    postalCode: "1182",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bergensgata",
    streetNumber: "6",
    postalCode: "0468",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bergensveien",
    streetNumber: "4A",
    postalCode: "0963",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Berghoffveien",
    streetNumber: "59E",
    postalCode: "1340",
    city: "Skui",
    country: "Norge",
  },
  {
    streetName: "Bergkrystallen",
    streetNumber: "3",
    postalCode: "1155",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bergljots vei",
    streetNumber: "3C",
    postalCode: "0575",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Berglyveien",
    streetNumber: "22C",
    postalCode: "1262",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Berglyveien",
    streetNumber: "20C",
    postalCode: "1262",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bergtunveien",
    streetNumber: "6",
    postalCode: "1087",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bernhard Herres vei",
    streetNumber: "19",
    postalCode: "0376",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bernt Knudsens vei",
    streetNumber: "52B",
    postalCode: "1152",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bernt Knudsens vei",
    streetNumber: "51B",
    postalCode: "1152",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bernt Knudsens vei",
    streetNumber: "43E",
    postalCode: "1152",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bernt Knudsens vei",
    streetNumber: "25C",
    postalCode: "1176",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bertramjordet",
    streetNumber: "19A",
    postalCode: "1251",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bertrand Narvesens vei",
    streetNumber: "4c",
    postalCode: "0661",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bervens Løkke",
    streetNumber: "4",
    postalCode: "0254",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bestumveien",
    streetNumber: "76A",
    postalCode: "0283",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bestumveien",
    streetNumber: "6H",
    postalCode: "0281",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bestumveien",
    streetNumber: "6A",
    postalCode: "0281",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bestumveien",
    streetNumber: "99",
    postalCode: "0283",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Betzy Kjelsbergs vei",
    streetNumber: "7",
    postalCode: "0486",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Beverkollen",
    streetNumber: "22",
    postalCode: "1275",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Beverveien",
    streetNumber: "13",
    postalCode: "0596",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Beverveien",
    streetNumber: "10",
    postalCode: "0596",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bidenkaps gate",
    streetNumber: "1B",
    postalCode: "0165",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Billingstadlia",
    streetNumber: "1",
    postalCode: "1396",
    city: "Billingstad",
    country: "Norge",
  },
  {
    streetName: "Birch-reichenwalds gate",
    streetNumber: "30",
    postalCode: "0483",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Birch-reichenwalds gate",
    streetNumber: "28",
    postalCode: "0483",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Birger Olivers vei",
    streetNumber: "28A",
    postalCode: "1176",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Birger Olivers vei",
    streetNumber: "9",
    postalCode: "1176",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Birgitte Hammers vei",
    streetNumber: "20",
    postalCode: "1167",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Biskop Grimelunds vei",
    streetNumber: "7B",
    postalCode: "0374",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Biskop Grimelunds vei",
    streetNumber: "11B",
    postalCode: "0374",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bispeluelia",
    streetNumber: "9",
    postalCode: "1286",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bispeveien",
    streetNumber: "52B",
    postalCode: "1362",
    city: "Hosle",
    country: "Norge",
  },
  {
    streetName: "Bispeveien",
    streetNumber: "52",
    postalCode: "1362",
    city: "Hosle",
    country: "Norge",
  },
  {
    streetName: "Bjarne Skaus vei",
    streetNumber: "5",
    postalCode: "1362",
    city: "Hosle",
    country: "Norge",
  },
  {
    streetName: "Bjartveien",
    streetNumber: "1C",
    postalCode: "0687",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bjartveien",
    streetNumber: "11B",
    postalCode: "0687",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bjerkebakken",
    streetNumber: "29",
    postalCode: "0757",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bjerkelundgata",
    streetNumber: "1",
    postalCode: "0553",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bjerkesvingen",
    streetNumber: "8B",
    postalCode: "1367",
    city: "Snarøya",
    country: "Norge",
  },
  {
    streetName: "Bjerkeveien",
    streetNumber: "91",
    postalCode: "1350",
    city: "Lommedalen",
    country: "Norge",
  },
  {
    streetName: "Bjerregaards gate",
    streetNumber: "64B",
    postalCode: "0174",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bjerregaards gate",
    streetNumber: "58B",
    postalCode: "0174",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bjerregaards gate",
    streetNumber: "31B",
    postalCode: "0174",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bjerregaards gate",
    streetNumber: "2F",
    postalCode: "0172",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bjerregaards gate",
    streetNumber: "2a",
    postalCode: "0172",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bjerregaards gate",
    streetNumber: "51",
    postalCode: "0174",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bjerregaards gate",
    streetNumber: "29",
    postalCode: "0172",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bjerregaards gate",
    streetNumber: "19",
    postalCode: "0172",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bjerregaards gate",
    streetNumber: "2",
    postalCode: "0172",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bjølsengata",
    streetNumber: "15",
    postalCode: "0468",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bjølsengata",
    streetNumber: "1",
    postalCode: "0468",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bjørn Farmanns gate",
    streetNumber: "14B",
    postalCode: "0271",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bjørn Farmanns gate",
    streetNumber: "16",
    postalCode: "0271",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bjørnefaret",
    streetNumber: "20",
    postalCode: "1270",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bjørnekroken",
    streetNumber: "19",
    postalCode: "1366",
    city: "Lysaker",
    country: "Norge",
  },
  {
    streetName: "Bjørnheimveien",
    streetNumber: "4B",
    postalCode: "1086",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bjørnslettstubben",
    streetNumber: "10",
    postalCode: "0382",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bjørnstjerne Bjørnsons plass",
    streetNumber: "1",
    postalCode: "0340",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Blakkens vei",
    streetNumber: "85",
    postalCode: "1281",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Blomstervegen",
    streetNumber: "33D",
    postalCode: "2005",
    city: "Rælingen",
    country: "Norge",
  },
  {
    streetName: "Blåbærsvingen",
    streetNumber: "9",
    postalCode: "0789",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bogstadveien",
    streetNumber: "7B",
    postalCode: "0355",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bogstadveien",
    streetNumber: "48A",
    postalCode: "0366",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bogstadveien",
    streetNumber: "39B",
    postalCode: "0366",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bogstadveien",
    streetNumber: "74",
    postalCode: "0366",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bogstadveien",
    streetNumber: "58",
    postalCode: "0366",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bogstadveien",
    streetNumber: "46",
    postalCode: "0366",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bogstadveien",
    streetNumber: "26",
    postalCode: "0355",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bogstadveien",
    streetNumber: "23",
    postalCode: "0355",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bogstadveien",
    streetNumber: "1",
    postalCode: "0355",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bolette brygge",
    streetNumber: "1",
    postalCode: "0252",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Borgendammen",
    streetNumber: "8",
    postalCode: "2040",
    city: "Kløfta",
    country: "Norge",
  },
  {
    streetName: "Borgenveien",
    streetNumber: "42",
    postalCode: "0370",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Borggata",
    streetNumber: "12D",
    postalCode: "0650",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Borggata",
    streetNumber: "2",
    postalCode: "0650",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brages vei",
    streetNumber: "8",
    postalCode: "1387",
    city: "Asker",
    country: "Norge",
  },
  {
    streetName: "Brannfjellveien",
    streetNumber: "94B",
    postalCode: "1181",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brannfjellveien",
    streetNumber: "2B",
    postalCode: "1181",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brannfjellveien",
    streetNumber: "10D",
    postalCode: "1181",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brannfjellveien",
    streetNumber: "102D",
    postalCode: "1182",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brannfjellveien",
    streetNumber: "10",
    postalCode: "1181",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brattelia",
    streetNumber: "6A",
    postalCode: "0875",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brattvollveien",
    streetNumber: "36",
    postalCode: "1164",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brattvollveien",
    streetNumber: "25",
    postalCode: "1164",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brattvollveien",
    streetNumber: "20",
    postalCode: "1164",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bredo Stabells vei",
    streetNumber: "13",
    postalCode: "0853",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bredo Stabells vei",
    streetNumber: "9",
    postalCode: "0853",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brenneveien",
    streetNumber: "22A",
    postalCode: "1339",
    city: "Vøyenenga",
    country: "Norge",
  },
  {
    streetName: "Brinken",
    streetNumber: "2D",
    postalCode: "0654",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brinken",
    streetNumber: "18",
    postalCode: "0654",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Briskebyveien",
    streetNumber: "74",
    postalCode: "0259",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brochmanns gate",
    streetNumber: "4A",
    postalCode: "0470",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brochmanns gate",
    streetNumber: "5",
    postalCode: "0470",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brodtkorbsgate",
    streetNumber: "7",
    postalCode: "1338",
    city: "Sandvika",
    country: "Norge",
  },
  {
    streetName: "Brolandsveien",
    streetNumber: "2b",
    postalCode: "0980",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bruerveien",
    streetNumber: "25",
    postalCode: "1550",
    city: "Hølen",
    country: "Norge",
  },
  {
    streetName: "Bruksveien",
    streetNumber: "52",
    postalCode: "1367",
    city: "Snarøya",
    country: "Norge",
  },
  {
    streetName: "Brusetveien",
    streetNumber: "34",
    postalCode: "1395",
    city: "Hvalstad",
    country: "Norge",
  },
  {
    streetName: "Bryggegata",
    streetNumber: "22",
    postalCode: "0250",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bryggegata",
    streetNumber: "7",
    postalCode: "0250",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bryggegata",
    streetNumber: "6",
    postalCode: "0250",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bryggetorget",
    streetNumber: "4",
    postalCode: "0250",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brynjeveien",
    streetNumber: "2",
    postalCode: "0881",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brønnveien",
    streetNumber: "9B",
    postalCode: "0283",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bygdøy allé",
    streetNumber: "81F",
    postalCode: "0268",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bygdøy allé",
    streetNumber: "63C",
    postalCode: "0265",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bygdøy allé",
    streetNumber: "60A",
    postalCode: "0265",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bygdøy allé",
    streetNumber: "27B",
    postalCode: "0262",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bygdøy allé",
    streetNumber: "25A",
    postalCode: "0262",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bygdøy allé",
    streetNumber: "69",
    postalCode: "0268",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bygdøy allé",
    streetNumber: "60",
    postalCode: "0265",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bygdøy allé",
    streetNumber: "53",
    postalCode: "0265",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bygdøy allé",
    streetNumber: "16",
    postalCode: "0262",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bygdøy allé",
    streetNumber: "12",
    postalCode: "0262",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bygdøy allé",
    streetNumber: "2",
    postalCode: "0257",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bygdøynesveien",
    streetNumber: "30C",
    postalCode: "0286",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bygdøynesveien",
    streetNumber: "33",
    postalCode: "0286",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bygdøynesveien",
    streetNumber: "9",
    postalCode: "0286",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bygdøynesveien",
    streetNumber: "7",
    postalCode: "0286",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bærumsveien",
    streetNumber: "18",
    postalCode: "0284",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bøkkerveien",
    streetNumber: "32",
    postalCode: "0579",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bøkkerveien",
    streetNumber: "30",
    postalCode: "0575",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bøkkerveien",
    streetNumber: "26",
    postalCode: "0579",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bølerbakken",
    streetNumber: "4",
    postalCode: "0690",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bølerlia",
    streetNumber: "44",
    postalCode: "0689",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bølerlia",
    streetNumber: "36",
    postalCode: "0689",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bølerlia",
    streetNumber: "24",
    postalCode: "0691",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bølerlia",
    streetNumber: "23",
    postalCode: "0691",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bølerlia",
    streetNumber: "12",
    postalCode: "0691",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bølerlia",
    streetNumber: "12",
    postalCode: "0691",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bølerskogen",
    streetNumber: "10",
    postalCode: "0691",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bølerskogen",
    streetNumber: "9",
    postalCode: "0691",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bølerskogen",
    streetNumber: "5",
    postalCode: "0691",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bølerskogen",
    streetNumber: "1",
    postalCode: "0691",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bølersvingen",
    streetNumber: "18C",
    postalCode: "0690",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bølerveien",
    streetNumber: "24",
    postalCode: "0690",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Børsteveien",
    streetNumber: "6A",
    postalCode: "0585",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Calmeyers gate",
    streetNumber: "15B",
    postalCode: "0183",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Camilla Colletts vei",
    streetNumber: "15",
    postalCode: "0258",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Cappelens vei",
    streetNumber: "2B",
    postalCode: "1162",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Carl Jeppesens gate",
    streetNumber: "24",
    postalCode: "0481",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Carl Kjelsens Vei",
    streetNumber: "43",
    postalCode: "0874",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Caroline Øverlands vei",
    streetNumber: "32B",
    postalCode: "1356",
    city: "Bekkestua",
    country: "Norge",
  },
  {
    streetName: "Cecilie Thoresens vei",
    streetNumber: "17",
    postalCode: "1153",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Chr. Benneches vei",
    streetNumber: "4H",
    postalCode: "0286",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Chr. Benneches vei",
    streetNumber: "4E",
    postalCode: "0286",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Chr. Krohgs gate",
    streetNumber: "30",
    postalCode: "0186",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Chr. Michelsens gate",
    streetNumber: "61B",
    postalCode: "0474",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Chr. Michelsens gate",
    streetNumber: "17B",
    postalCode: "0568",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Chr. Michelsens gate",
    streetNumber: "49",
    postalCode: "0568",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Chr. Michelsens gate",
    streetNumber: "25",
    postalCode: "0568",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Christies gate",
    streetNumber: "34B",
    postalCode: "0557",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Christoffer Hellums vei",
    streetNumber: "23",
    postalCode: "0951",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Christoffer Hellums vei",
    streetNumber: "9",
    postalCode: "0951",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Claus Borchs vei",
    streetNumber: "19B",
    postalCode: "0853",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Colbjørnsens gate",
    streetNumber: "16",
    postalCode: "0256",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Colbjørnsens gate",
    streetNumber: "13",
    postalCode: "0256",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Colbjørnsens gate",
    streetNumber: "1",
    postalCode: "0256",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Colletts gate",
    streetNumber: "60B",
    postalCode: "0456",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Colletts gate",
    streetNumber: "58B",
    postalCode: "0456",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Colletts gate",
    streetNumber: "37",
    postalCode: "0456",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Daas gate",
    streetNumber: "14",
    postalCode: "0259",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dagalikroken",
    streetNumber: "2",
    postalCode: "0783",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dagalikroken",
    streetNumber: "2",
    postalCode: "0783",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dagaliveien",
    streetNumber: "27A",
    postalCode: "0783",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dagaliveien",
    streetNumber: "27A",
    postalCode: "0783",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dalbakkveien",
    streetNumber: "15A",
    postalCode: "0682",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dalbakkveien",
    streetNumber: "36",
    postalCode: "0682",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dalehaugen",
    streetNumber: "4",
    postalCode: "0657",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dalehaugen",
    streetNumber: "2",
    postalCode: "0657",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dalsbergstien",
    streetNumber: "21",
    postalCode: "0170",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dalsbergstien",
    streetNumber: "18",
    postalCode: "0170",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dalsbergstien",
    streetNumber: "18",
    postalCode: "0170",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dalsbergstien",
    streetNumber: "6",
    postalCode: "0171",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dalssvingen",
    streetNumber: "23B",
    postalCode: "1285",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dalsveien",
    streetNumber: "35C",
    postalCode: "0775",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dalsveien",
    streetNumber: "28B",
    postalCode: "0376",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dalsveien",
    streetNumber: "7",
    postalCode: "0376",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dampsagveien",
    streetNumber: "34",
    postalCode: "1408",
    city: "Kråkstad",
    country: "Norge",
  },
  {
    streetName: "Danmarks gate",
    streetNumber: "37",
    postalCode: "0658",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dannevigsveien",
    streetNumber: "7L",
    postalCode: "0463",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Deichmans gate",
    streetNumber: "27A",
    postalCode: "0178",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Diakonveien",
    streetNumber: "14",
    postalCode: "0370",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Diakonveien",
    streetNumber: "12",
    postalCode: "0370",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Disenveien",
    streetNumber: "13",
    postalCode: "0587",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Disenveien",
    streetNumber: "9",
    postalCode: "0587",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Disenveien",
    streetNumber: "7",
    postalCode: "0587",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dorthes vei",
    streetNumber: "4D",
    postalCode: "0287",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dorthes vei",
    streetNumber: "4",
    postalCode: "0287",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dovresvingen",
    streetNumber: "11",
    postalCode: "1184",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dr. Dedichens vei",
    streetNumber: "132",
    postalCode: "0675",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dr. Dedichens vei",
    streetNumber: "48",
    postalCode: "0675",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dr. Holms vei",
    streetNumber: "17D",
    postalCode: "0787",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dragonstien",
    streetNumber: "87A",
    postalCode: "1062",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Drammensveien",
    streetNumber: "56A",
    postalCode: "0271",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Drammensveien",
    streetNumber: "120",
    postalCode: "0277",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Drammensveien",
    streetNumber: "100",
    postalCode: "0273",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Drammensveien",
    streetNumber: "72",
    postalCode: "0271",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Drammensveien",
    streetNumber: "45",
    postalCode: "0271",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Drammensveien",
    streetNumber: "12",
    postalCode: "0271",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Drammensveien",
    streetNumber: "1",
    postalCode: "0271",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dronning Eufemias gate",
    streetNumber: "47",
    postalCode: "0191",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dronning Eufemias gate",
    streetNumber: "45",
    postalCode: "0191",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dronning Eufemias gate",
    streetNumber: "30",
    postalCode: "0191",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dronning Eufemias gate",
    streetNumber: "14",
    postalCode: "0191",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dronning Eufemias gate",
    streetNumber: "8",
    postalCode: "0191",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dronning Eufemias gate",
    streetNumber: "4",
    postalCode: "0154",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dronning Mauds gate",
    streetNumber: "15",
    postalCode: "0250",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dronning Mauds gate",
    streetNumber: "15",
    postalCode: "0250",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dronning Mauds gate",
    streetNumber: "3",
    postalCode: "0250",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Drottveien",
    streetNumber: "13B",
    postalCode: "1430",
    city: "Ås",
    country: "Norge",
  },
  {
    streetName: "Drøbakgata",
    streetNumber: "1",
    postalCode: "0463",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Duehaugveien",
    streetNumber: "1",
    postalCode: "0851",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Duggveien",
    streetNumber: "5B",
    postalCode: "0664",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dynekilgata",
    streetNumber: "16",
    postalCode: "0569",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dynekilgata",
    streetNumber: "15",
    postalCode: "0569",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dyrefaret",
    streetNumber: "18",
    postalCode: "1362",
    city: "Hosle",
    country: "Norge",
  },
  {
    streetName: "Dyretråkket",
    streetNumber: "12",
    postalCode: "1251",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dyretråkket",
    streetNumber: "11",
    postalCode: "1251",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dyrskuevegen",
    streetNumber: "21",
    postalCode: "2040",
    city: "Kløfta",
    country: "Norge",
  },
  {
    streetName: "Dælenenggata",
    streetNumber: "21C",
    postalCode: "0567",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dælenenggata",
    streetNumber: "12B",
    postalCode: "0567",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dælenenggata",
    streetNumber: "25",
    postalCode: "0567",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dælitoppen",
    streetNumber: "5",
    postalCode: "1383",
    city: "Asker",
    country: "Norge",
  },
  {
    streetName: "Dønskitoppen",
    streetNumber: "12I",
    postalCode: "1346",
    city: "Gjettum",
    country: "Norge",
  },
  {
    streetName: "Eckersbergs gate",
    streetNumber: "15B",
    postalCode: "0266",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Eckersbergs gate",
    streetNumber: "51",
    postalCode: "0260",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Eckersbergs gate",
    streetNumber: "30",
    postalCode: "0260",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Eckersbergs gate",
    streetNumber: "10",
    postalCode: "0266",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Eddaveien",
    streetNumber: "27",
    postalCode: "0772",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Eddaveien",
    streetNumber: "23",
    postalCode: "0772",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Edelgranveien",
    streetNumber: "82",
    postalCode: "1356",
    city: "Bekkestua",
    country: "Norge",
  },
  {
    streetName: "Edelgranveien",
    streetNumber: "28",
    postalCode: "1356",
    city: "Bekkestua",
    country: "Norge",
  },
  {
    streetName: "Edvard Munchs vei",
    streetNumber: "95",
    postalCode: "1063",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Edvard Munchs vei",
    streetNumber: "83",
    postalCode: "1063",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Eftasåsen",
    streetNumber: "24",
    postalCode: "0687",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Eikelibuen",
    streetNumber: "9",
    postalCode: "1407",
    city: "Vinterbro",
    country: "Norge",
  },
  {
    streetName: "Eikelundveien",
    streetNumber: "10",
    postalCode: "0588",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Eiksveien",
    streetNumber: "117",
    postalCode: "1361",
    city: "Østerås",
    country: "Norge",
  },
  {
    streetName: "Eiksveien",
    streetNumber: "78",
    postalCode: "1361",
    city: "Østerås",
    country: "Norge",
  },
  {
    streetName: "Eiksveien",
    streetNumber: "76",
    postalCode: "1361",
    city: "Østerås",
    country: "Norge",
  },
  {
    streetName: "Eiksveien",
    streetNumber: "69",
    postalCode: "1361",
    city: "Østerås",
    country: "Norge",
  },
  {
    streetName: "Eiksveien",
    streetNumber: "65",
    postalCode: "1361",
    city: "Østerås",
    country: "Norge",
  },
  {
    streetName: "Eilert Sundts gate",
    streetNumber: "14B",
    postalCode: "0259",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Eilert Sundts gate",
    streetNumber: "21",
    postalCode: "0259",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Eiriks gate",
    streetNumber: "14",
    postalCode: "0650",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Eiriks gate",
    streetNumber: "2",
    postalCode: "0650",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ekeberglia",
    streetNumber: "1B",
    postalCode: "1356",
    city: "Bekkestua",
    country: "Norge",
  },
  {
    streetName: "Ekebergveien",
    streetNumber: "3C",
    postalCode: "0192",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ekebergveien",
    streetNumber: "19M",
    postalCode: "0196",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ekebergveien",
    streetNumber: "19A",
    postalCode: "0196",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ekebergveien",
    streetNumber: "11D",
    postalCode: "0192",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ekebergveien",
    streetNumber: "317",
    postalCode: "1166",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ekebergveien",
    streetNumber: "242",
    postalCode: "1166",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ekebergveien",
    streetNumber: "233",
    postalCode: "1162",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ekebergveien",
    streetNumber: "226",
    postalCode: "1162",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ekebergveien",
    streetNumber: "208",
    postalCode: "1162",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ekebergveien",
    streetNumber: "196",
    postalCode: "1162",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ekebergveien",
    streetNumber: "187",
    postalCode: "1177",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ekebergveien",
    streetNumber: "173",
    postalCode: "1177",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ekebergveien",
    streetNumber: "45",
    postalCode: "0196",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ekelyveien",
    streetNumber: "10A",
    postalCode: "0374",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ekraveien",
    streetNumber: "68C",
    postalCode: "0757",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ekraveien",
    streetNumber: "68",
    postalCode: "0757",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Elgefaret",
    streetNumber: "31",
    postalCode: "1362",
    city: "Hosle",
    country: "Norge",
  },
  {
    streetName: "Elgveien",
    streetNumber: "12",
    postalCode: "0852",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Elisenbergveien",
    streetNumber: "24A",
    postalCode: "0265",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Elisenbergveien",
    streetNumber: "16",
    postalCode: "0265",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Elisenbergveien",
    streetNumber: "15",
    postalCode: "0265",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Elisenbergveien",
    streetNumber: "3",
    postalCode: "0265",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Elleveien",
    streetNumber: "3A",
    postalCode: "1440",
    city: "Drøbak",
    country: "Norge",
  },
  {
    streetName: "Elmholt allé",
    streetNumber: "14",
    postalCode: "0275",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Elmholt allé",
    streetNumber: "13",
    postalCode: "0275",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Elmholt allé",
    streetNumber: "5",
    postalCode: "0275",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Elmholtveien",
    streetNumber: "3",
    postalCode: "0281",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Eltonveien",
    streetNumber: "49",
    postalCode: "0586",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Eltonveien",
    streetNumber: "37",
    postalCode: "0586",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Enebakkveien",
    streetNumber: "25D",
    postalCode: "0657",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Enebakkveien",
    streetNumber: "248F",
    postalCode: "1187",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Enebakkveien",
    streetNumber: "231C",
    postalCode: "1187",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Enebakkveien",
    streetNumber: "218A",
    postalCode: "0680",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Enebakkveien",
    streetNumber: "196B",
    postalCode: "0680",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Enebakkveien",
    streetNumber: "150",
    postalCode: "0680",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Enebakkveien",
    streetNumber: "135",
    postalCode: "0680",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Energivegen",
    streetNumber: "7",
    postalCode: "2050",
    city: "Jessheim",
    country: "Norge",
  },
  {
    streetName: "Enerhauggata",
    streetNumber: "7",
    postalCode: "0651",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Engebråtveien",
    streetNumber: "12B",
    postalCode: "0493",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Engebråtveien",
    streetNumber: "8",
    postalCode: "0493",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Enoks vei",
    streetNumber: "21",
    postalCode: "1181",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Enoks vei",
    streetNumber: "5",
    postalCode: "1181",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ensjøveien",
    streetNumber: "18",
    postalCode: "0661",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ensjøveien",
    streetNumber: "16",
    postalCode: "0655",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ensjøveien",
    streetNumber: "14",
    postalCode: "0655",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ensjøveien",
    streetNumber: "10",
    postalCode: "0655",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Erich Mogensøns vei",
    streetNumber: "38",
    postalCode: "0594",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Erich Mogensøns vei",
    streetNumber: "16",
    postalCode: "0594",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Erika Nissens gate",
    streetNumber: "2",
    postalCode: "0480",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Erlandstuveien",
    streetNumber: "23",
    postalCode: "1178",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Erlandstuveien",
    streetNumber: "9",
    postalCode: "1178",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Erling Michelsens vei",
    streetNumber: "34E",
    postalCode: "1061",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Erling Michelsens vei",
    streetNumber: "26",
    postalCode: "1061",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Erling Skjalgssons gate",
    streetNumber: "23E",
    postalCode: "0267",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Erling Skjalgssons gate",
    streetNumber: "24",
    postalCode: "0266",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Essendrops gate",
    streetNumber: "3",
    postalCode: "0368",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Eterveien",
    streetNumber: "49",
    postalCode: "0690",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Etterstadkroken",
    streetNumber: "7F",
    postalCode: "0659",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Etterstadsletta",
    streetNumber: "44B",
    postalCode: "0660",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Etterstadsletta",
    streetNumber: "25A",
    postalCode: "0660",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Etterstadsletta",
    streetNumber: "44",
    postalCode: "0660",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Eugenies gate",
    streetNumber: "21",
    postalCode: "0168",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Eystein Torkildsens vei",
    streetNumber: "16",
    postalCode: "1165",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fagerborggata",
    streetNumber: "34",
    postalCode: "0360",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fagerheimgata",
    streetNumber: "8",
    postalCode: "0567",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fagerliveien",
    streetNumber: "21A",
    postalCode: "0587",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fagerstrandveien",
    streetNumber: "38",
    postalCode: "1368",
    city: "Stabekk",
    country: "Norge",
  },
  {
    streetName: "Fagertunveien",
    streetNumber: "135",
    postalCode: "1358",
    city: "Jar",
    country: "Norge",
  },
  {
    streetName: "Falsens gate",
    streetNumber: "27",
    postalCode: "0555",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fearnleys gate",
    streetNumber: "5A",
    postalCode: "0353",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fearnleys gate",
    streetNumber: "8",
    postalCode: "0353",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Feddersens gate",
    streetNumber: "7",
    postalCode: "0473",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Feltspatveien",
    streetNumber: "31",
    postalCode: "1155",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fernanda Nissens Gate",
    streetNumber: "10b",
    postalCode: "0484",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fernanda Nissens Gate",
    streetNumber: "4",
    postalCode: "0484",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fernanda Nissens Gate",
    streetNumber: "3",
    postalCode: "0484",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fernanda Nissens Gate",
    streetNumber: "1",
    postalCode: "0484",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Filerveien",
    streetNumber: "10",
    postalCode: "0684",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Filipstad Brygge",
    streetNumber: "1",
    postalCode: "0252",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Filipstadveien",
    streetNumber: "15",
    postalCode: "0250",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Filipstadveien",
    streetNumber: "10",
    postalCode: "0250",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Filipstadveien",
    streetNumber: "7",
    postalCode: "0250",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Finstadåsen",
    streetNumber: "11",
    postalCode: "1475",
    city: "Finstadjordet",
    country: "Norge",
  },
  {
    streetName: "Firkløverveien",
    streetNumber: "7",
    postalCode: "0379",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fjellgata",
    streetNumber: "32",
    postalCode: "0567",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fjellstuveien",
    streetNumber: "17",
    postalCode: "0982",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fjellsvingen",
    streetNumber: "21",
    postalCode: "1472",
    city: "Fjellhamar",
    country: "Norge",
  },
  {
    streetName: "Fjordveien",
    streetNumber: "61B",
    postalCode: "1363",
    city: "Høvik",
    country: "Norge",
  },
  {
    streetName: "Florabakken",
    streetNumber: "2",
    postalCode: "1162",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Folke Bernadottes vei",
    streetNumber: "35B",
    postalCode: "0862",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Folke Bernadottes vei",
    streetNumber: "15B",
    postalCode: "0862",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Folke Bernadottes vei",
    streetNumber: "14A",
    postalCode: "0862",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Folke Bernadottes vei",
    streetNumber: "43",
    postalCode: "0862",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fornebuveien",
    streetNumber: "38",
    postalCode: "1366",
    city: "Lysaker",
    country: "Norge",
  },
  {
    streetName: "Forskningsveien",
    streetNumber: "2",
    postalCode: "0373",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fossefaret",
    streetNumber: "6",
    postalCode: "0373",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fossumberget",
    streetNumber: "44",
    postalCode: "0983",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fossumkroken",
    streetNumber: "19",
    postalCode: "0982",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fossveien",
    streetNumber: "13",
    postalCode: "0551",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fougstads gate",
    streetNumber: "25A",
    postalCode: "0173",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fougstads gate",
    streetNumber: "2",
    postalCode: "0173",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fred Olsens gate",
    streetNumber: "3",
    postalCode: "0152",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fredensborgveien",
    streetNumber: "9A",
    postalCode: "0177",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fredensborgveien",
    streetNumber: "44",
    postalCode: "0177",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fredensborgveien",
    streetNumber: "42",
    postalCode: "0177",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fredensborgveien",
    streetNumber: "22",
    postalCode: "0177",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fredensborgveien",
    streetNumber: "11",
    postalCode: "0177",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fredensborgveien",
    streetNumber: "2",
    postalCode: "0177",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frederik Glads gate",
    streetNumber: "22C",
    postalCode: "0482",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frederik Glads gate",
    streetNumber: "19",
    postalCode: "0482",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frederik Glads gate",
    streetNumber: "1",
    postalCode: "0482",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frederik Stangs gate",
    streetNumber: "41B",
    postalCode: "0264",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frederik Stangs gate",
    streetNumber: "37",
    postalCode: "0264",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fredriksborgveien",
    streetNumber: "44A",
    postalCode: "0286",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fredriksborgveien",
    streetNumber: "15",
    postalCode: "0286",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frennings vei",
    streetNumber: "7",
    postalCode: "0588",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Freserveien",
    streetNumber: "37",
    postalCode: "0196",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Freserveien",
    streetNumber: "33",
    postalCode: "0196",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Freserveien",
    streetNumber: "27",
    postalCode: "0196",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Freserveien",
    streetNumber: "17",
    postalCode: "0196",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Freserveien",
    streetNumber: "12",
    postalCode: "0195",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Freserveien",
    streetNumber: "9",
    postalCode: "0196",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fridtjof Nansens Plass",
    streetNumber: "8",
    postalCode: "0160",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fridtjof Nansens Plass",
    streetNumber: "7",
    postalCode: "0160",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fridtjof Nansens Plass",
    streetNumber: "5",
    postalCode: "0160",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fridtjof Nansens vei",
    streetNumber: "19",
    postalCode: "0369",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fridtjof Nansens vei",
    streetNumber: "14",
    postalCode: "0369",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fridtjof Nansens vei",
    streetNumber: "5",
    postalCode: "0369",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Friggs vei",
    streetNumber: "10",
    postalCode: "0588",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frits Kiærs vei",
    streetNumber: "13",
    postalCode: "0383",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frogner Terrasse",
    streetNumber: "3",
    postalCode: "0268",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frognerseterveien",
    streetNumber: "46F",
    postalCode: "0776",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frognerseterveien",
    streetNumber: "40D",
    postalCode: "0776",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frognerseterveien",
    streetNumber: "39D",
    postalCode: "0776",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frognerseterveien",
    streetNumber: "30D",
    postalCode: "0775",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frognerseterveien",
    streetNumber: "15E",
    postalCode: "0775",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frognerveien",
    streetNumber: "44G",
    postalCode: "0266",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frognerveien",
    streetNumber: "30D",
    postalCode: "0263",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frognerveien",
    streetNumber: "67",
    postalCode: "0266",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frognerveien",
    streetNumber: "31",
    postalCode: "0263",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frognerveien",
    streetNumber: "30",
    postalCode: "0263",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frognerveien",
    streetNumber: "5",
    postalCode: "0257",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frognerveien",
    streetNumber: "2",
    postalCode: "0257",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frognerveien",
    streetNumber: "1",
    postalCode: "0257",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fru Kroghs brygge",
    streetNumber: "2",
    postalCode: "0252",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frydenbergveien",
    streetNumber: "58",
    postalCode: "0575",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frydenbergveien",
    streetNumber: "48",
    postalCode: "0575",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frydenlundgata",
    streetNumber: "8A",
    postalCode: "0169",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frydenlundgata",
    streetNumber: "1B",
    postalCode: "0169",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frydens gate",
    streetNumber: "5D",
    postalCode: "0564",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frydens gate",
    streetNumber: "4C",
    postalCode: "0564",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frysjaveien",
    streetNumber: "40",
    postalCode: "0884",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frøyas gate",
    streetNumber: "10B",
    postalCode: "0273",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frøyas gate",
    streetNumber: "10B",
    postalCode: "0273",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frøyas gate",
    streetNumber: "12",
    postalCode: "0273",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frøytunveien",
    streetNumber: "3",
    postalCode: "1357",
    city: "Bekkestua",
    country: "Norge",
  },
  {
    streetName: "Fuglehauggata",
    streetNumber: "13A",
    postalCode: "0260",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fugleliveien",
    streetNumber: "14C",
    postalCode: "0667",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Furulundsveien",
    streetNumber: "7C",
    postalCode: "0282",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fv168",
    streetNumber: "58",
    postalCode: "0366",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fyrstikkalléen",
    streetNumber: "12A",
    postalCode: "0661",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fyrstikkbakken",
    streetNumber: "9",
    postalCode: "0667",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fyrstikkbakken",
    streetNumber: "7",
    postalCode: "0667",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gabbi Lunds vei",
    streetNumber: "9",
    postalCode: "0677",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gabels gate",
    streetNumber: "4B",
    postalCode: "0272",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gabels gate",
    streetNumber: "4A",
    postalCode: "0272",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gabels gate",
    streetNumber: "48",
    postalCode: "0262",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gamle Bygdevei",
    streetNumber: "180B",
    postalCode: "1284",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gamle Bygdevei",
    streetNumber: "172E",
    postalCode: "1284",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gamle Drammensvei",
    streetNumber: "396A",
    postalCode: "1383",
    city: "Asker",
    country: "Norge",
  },
  {
    streetName: "Gamle Drammensvei",
    streetNumber: "172H",
    postalCode: "1365",
    city: "Blommenholm",
    country: "Norge",
  },
  {
    streetName: "Gamle Drammensvei",
    streetNumber: "269",
    postalCode: "1383",
    city: "Asker",
    country: "Norge",
  },
  {
    streetName: "Gamle Drammensvei",
    streetNumber: "127",
    postalCode: "1363",
    city: "Høvik",
    country: "Norge",
  },
  {
    streetName: "Gamle Drammensvei",
    streetNumber: "122",
    postalCode: "1363",
    city: "Høvik",
    country: "Norge",
  },
  {
    streetName: "Gamle Drammensvei",
    streetNumber: "38",
    postalCode: "1369",
    city: "Stabekk",
    country: "Norge",
  },
  {
    streetName: "Gamle Enebakkvei",
    streetNumber: "42",
    postalCode: "1188",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gamle Ringeriksvei",
    streetNumber: "36",
    postalCode: "1357",
    city: "Bekkestua",
    country: "Norge",
  },
  {
    streetName: "Gamle Ringeriksvei",
    streetNumber: "9",
    postalCode: "1368",
    city: "Stabekk",
    country: "Norge",
  },
  {
    streetName: "Gamlehagen",
    streetNumber: "9",
    postalCode: "1151",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gamlelinja",
    streetNumber: "7A",
    postalCode: "1254",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gamlelinja",
    streetNumber: "42C",
    postalCode: "1254",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gamleveien",
    streetNumber: "9",
    postalCode: "1053",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gartnerveien",
    streetNumber: "4",
    postalCode: "0678",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Garver Ytteborgs vei",
    streetNumber: "103",
    postalCode: "0977",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gaupefaret",
    streetNumber: "18",
    postalCode: "0773",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gaustadalléen",
    streetNumber: "34",
    postalCode: "0372",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gaustadalléen",
    streetNumber: "21",
    postalCode: "0349",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gaustadveien",
    streetNumber: "8D",
    postalCode: "0372",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gaustadveien",
    streetNumber: "23b",
    postalCode: "0372",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gaustadveien",
    streetNumber: "10D",
    postalCode: "0372",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gaustadveien",
    streetNumber: "243",
    postalCode: "0372",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Geitmyrsveien",
    streetNumber: "7E",
    postalCode: "0171",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Geitmyrsveien",
    streetNumber: "35B",
    postalCode: "0171",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Geitmyrsveien",
    streetNumber: "25B",
    postalCode: "0171",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Geitmyrsveien",
    streetNumber: "33",
    postalCode: "0171",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Geitramsvegen",
    streetNumber: "15B",
    postalCode: "2052",
    city: "Jessheim",
    country: "Norge",
  },
  {
    streetName: "General Fleischers vei",
    streetNumber: "5D",
    postalCode: "1359",
    city: "Eiksmarka",
    country: "Norge",
  },
  {
    streetName: "General Krohgs vei",
    streetNumber: "13",
    postalCode: "0379",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "General Ruges vei",
    streetNumber: "57C",
    postalCode: "0691",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gimleveien",
    streetNumber: "21A",
    postalCode: "0266",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gimleveien",
    streetNumber: "17C",
    postalCode: "1358",
    city: "Jar",
    country: "Norge",
  },
  {
    streetName: "Gimleveien",
    streetNumber: "27",
    postalCode: "1358",
    city: "Jar",
    country: "Norge",
  },
  {
    streetName: "Gina Krogs vei",
    streetNumber: "20",
    postalCode: "1153",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gisles vei",
    streetNumber: "7",
    postalCode: "1185",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gjallarveien",
    streetNumber: "16E",
    postalCode: "1413",
    city: "Tårnåsen",
    country: "Norge",
  },
  {
    streetName: "Gjerdrums vei",
    streetNumber: "11",
    postalCode: "0484",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gjønnesjordet",
    streetNumber: "10",
    postalCode: "1357",
    city: "Bekkestua",
    country: "Norge",
  },
  {
    streetName: "Gjørstads gate",
    streetNumber: "3",
    postalCode: "0367",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gjøvikgata",
    streetNumber: "4B",
    postalCode: "0470",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gjøvikgata",
    streetNumber: "3D",
    postalCode: "0470",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gladengveien",
    streetNumber: "3B",
    postalCode: "0661",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gladengveien",
    streetNumber: "17",
    postalCode: "0661",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gladengveien",
    streetNumber: "2",
    postalCode: "0661",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Glads vei",
    streetNumber: "1",
    postalCode: "0489",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Glitreveien",
    streetNumber: "14",
    postalCode: "0686",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Godlia Parksti",
    streetNumber: "1B",
    postalCode: "0671",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Godliaveien",
    streetNumber: "1A",
    postalCode: "0682",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gommerudveien",
    streetNumber: "5",
    postalCode: "1348",
    city: "Rykkinn",
    country: "Norge",
  },
  {
    streetName: "Granbergstubben",
    streetNumber: "190",
    postalCode: "1275",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Granbergstubben",
    streetNumber: "142",
    postalCode: "1275",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Granebakken",
    streetNumber: "48",
    postalCode: "1284",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gransdalen",
    streetNumber: "11",
    postalCode: "1054",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Granstangen",
    streetNumber: "32A",
    postalCode: "1051",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Granstangen",
    streetNumber: "30A",
    postalCode: "1051",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Granstangen",
    streetNumber: "28A",
    postalCode: "1051",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Granveien",
    streetNumber: "15B",
    postalCode: "1178",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Granåsen",
    streetNumber: "66A",
    postalCode: "1362",
    city: "Hosle",
    country: "Norge",
  },
  {
    streetName: "Granåsen",
    streetNumber: "63D",
    postalCode: "1362",
    city: "Hosle",
    country: "Norge",
  },
  {
    streetName: "Gravdalsveien",
    streetNumber: "2I",
    postalCode: "0756",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grefsenkollveien",
    streetNumber: "12",
    postalCode: "0490",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grefsenveien",
    streetNumber: "47D",
    postalCode: "0485",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grefsenveien",
    streetNumber: "105C",
    postalCode: "0492",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grefsenveien",
    streetNumber: "108",
    postalCode: "0492",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grefsenveien",
    streetNumber: "67",
    postalCode: "0487",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grefsenveien",
    streetNumber: "47",
    postalCode: "0485",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grefsenveien",
    streetNumber: "39",
    postalCode: "0485",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grefsenveien",
    streetNumber: "28",
    postalCode: "0482",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grefsenveien",
    streetNumber: "10",
    postalCode: "0482",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gregers Grams vei",
    streetNumber: "25",
    postalCode: "0382",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gregers Grams vei",
    streetNumber: "19",
    postalCode: "0382",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gregers Grams vei",
    streetNumber: "8",
    postalCode: "0382",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gregers Grams vei",
    streetNumber: "6",
    postalCode: "0382",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gregers Grams vei",
    streetNumber: "6",
    postalCode: "0382",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grensen",
    streetNumber: "9B",
    postalCode: "0159",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grensen",
    streetNumber: "18",
    postalCode: "0159",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grensen",
    streetNumber: "9",
    postalCode: "0159",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grensen",
    streetNumber: "3",
    postalCode: "0159",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grensen G",
    streetNumber: "92",
    postalCode: "0180",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grenseveien",
    streetNumber: "30A",
    postalCode: "0571",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grenseveien",
    streetNumber: "17A",
    postalCode: "0571",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grenseveien",
    streetNumber: "107",
    postalCode: "0663",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grenseveien",
    streetNumber: "82",
    postalCode: "0663",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grenseveien",
    streetNumber: "55",
    postalCode: "0663",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grenseveien",
    streetNumber: "50",
    postalCode: "0579",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grenseveien",
    streetNumber: "29",
    postalCode: "0575",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grenseveien",
    streetNumber: "18",
    postalCode: "0571",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grenseveien",
    streetNumber: "16",
    postalCode: "0571",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grevlinglia",
    streetNumber: "14",
    postalCode: "1270",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grevlingåsen",
    streetNumber: "13A",
    postalCode: "1362",
    city: "Hosle",
    country: "Norge",
  },
  {
    streetName: "Grevlingåsen",
    streetNumber: "13",
    postalCode: "1362",
    city: "Hosle",
    country: "Norge",
  },
  {
    streetName: "Grimelundshaugen",
    streetNumber: "16B",
    postalCode: "0374",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grindbakken",
    streetNumber: "36",
    postalCode: "0764",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grindbakken",
    streetNumber: "16",
    postalCode: "0764",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grindbakken",
    streetNumber: "10",
    postalCode: "0764",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Griniveien",
    streetNumber: "34A",
    postalCode: "0756",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grottenveien",
    streetNumber: "7",
    postalCode: "1177",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grusveien",
    streetNumber: "44",
    postalCode: "1158",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gruvemyra",
    streetNumber: "21",
    postalCode: "1354",
    city: "Bærums Verk",
    country: "Norge",
  },
  {
    streetName: "Grüners gate",
    streetNumber: "15",
    postalCode: "0552",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grønland",
    streetNumber: "10",
    postalCode: "0188",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grønlandsleiret",
    streetNumber: "61a",
    postalCode: "0190",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grønlandsleiret",
    streetNumber: "61",
    postalCode: "0190",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grønlandsleiret",
    streetNumber: "39",
    postalCode: "0190",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grønnegata",
    streetNumber: "16",
    postalCode: "0350",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grønvoll allé",
    streetNumber: "38",
    postalCode: "0663",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gudbrands vei",
    streetNumber: "8",
    postalCode: "2015",
    city: "Leirsund",
    country: "Norge",
  },
  {
    streetName: "Guldbergs vei",
    streetNumber: "28C",
    postalCode: "0375",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gullhaugveien",
    streetNumber: "167",
    postalCode: "1354",
    city: "Bærums Verk",
    country: "Norge",
  },
  {
    streetName: "Gullkroken",
    streetNumber: "10B",
    postalCode: "0377",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gunnar Schjelderups vei",
    streetNumber: "13E",
    postalCode: "0485",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gunnar Schjelderups vei",
    streetNumber: "13C",
    postalCode: "0485",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gunnar Schjelderups vei",
    streetNumber: "11N",
    postalCode: "0485",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gunnar Schjelderups vei",
    streetNumber: "11J",
    postalCode: "0485",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gunnar Schjelderups vei",
    streetNumber: "11",
    postalCode: "0485",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gurinestubben",
    streetNumber: "27",
    postalCode: "0584",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gustav Vigelands vei",
    streetNumber: "5A",
    postalCode: "0274",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gustav Vigelands vei",
    streetNumber: "15b",
    postalCode: "0274",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gustav Vigelands vei",
    streetNumber: "54",
    postalCode: "0274",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gustav Vigelands vei",
    streetNumber: "48",
    postalCode: "0274",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gustav Vigelands vei",
    streetNumber: "46",
    postalCode: "0274",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gustav Vigelands vei",
    streetNumber: "40",
    postalCode: "0274",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gydas vei",
    streetNumber: "20",
    postalCode: "0363",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gyldenløves gate",
    streetNumber: "1A",
    postalCode: "0260",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gyldenløves gate",
    streetNumber: "24",
    postalCode: "0260",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gyldenløves gate",
    streetNumber: "10",
    postalCode: "0260",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gyldenløves gate",
    streetNumber: "1",
    postalCode: "0257",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gørbitz gate",
    streetNumber: "10",
    postalCode: "0360",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gørbitz gate",
    streetNumber: "4",
    postalCode: "0360",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gøteborggata",
    streetNumber: "4A",
    postalCode: "0566",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gøteborggata",
    streetNumber: "12d",
    postalCode: "0566",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gøteborggata",
    streetNumber: "26",
    postalCode: "0566",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gøteborggata",
    streetNumber: "1",
    postalCode: "0566",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Haakon Tveters vei",
    streetNumber: "51",
    postalCode: "0686",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Haakon Tveters vei",
    streetNumber: "12",
    postalCode: "0682",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Haakon VIIs gate",
    streetNumber: "2",
    postalCode: "0161",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hafslundveien",
    streetNumber: "2",
    postalCode: "0373",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hagaliveien",
    streetNumber: "31B",
    postalCode: "1358",
    city: "Jar",
    country: "Norge",
  },
  {
    streetName: "Hagaveien",
    streetNumber: "14A",
    postalCode: "0980",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hagaveien",
    streetNumber: "15",
    postalCode: "0980",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hagegata",
    streetNumber: "36A",
    postalCode: "0653",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hagegata",
    streetNumber: "41",
    postalCode: "0653",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hagegata",
    streetNumber: "28",
    postalCode: "0653",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hagelundveien",
    streetNumber: "2",
    postalCode: "0963",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Haldenvegen",
    streetNumber: "391",
    postalCode: "1923",
    city: "Sørum",
    country: "Norge",
  },
  {
    streetName: "Hallvard Bergves vei",
    streetNumber: "3A",
    postalCode: "0976",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hammarsvegen",
    streetNumber: "53A",
    postalCode: "2008",
    city: "Fjerdingby",
    country: "Norge",
  },
  {
    streetName: "Hammergata",
    streetNumber: "8",
    postalCode: "0465",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hammerstads gate",
    streetNumber: "38",
    postalCode: "0363",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hammerstads gate",
    streetNumber: "23",
    postalCode: "0363",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hammerstads gate",
    streetNumber: "20",
    postalCode: "0363",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hammerstads gate",
    streetNumber: "14",
    postalCode: "0364",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hammerstads gate",
    streetNumber: "11",
    postalCode: "0363",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hans Barliens gate",
    streetNumber: "4A",
    postalCode: "0568",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hans Barliens gate",
    streetNumber: "4",
    postalCode: "0568",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hans Nordahls Gate",
    streetNumber: "84-86",
    postalCode: "0485",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hans Nordahls Gate",
    streetNumber: "90",
    postalCode: "0485",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hans Nordahls Gate",
    streetNumber: "66",
    postalCode: "0485",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hans Nordahls Gate",
    streetNumber: "6",
    postalCode: "0481",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hansemyrveien",
    streetNumber: "19B",
    postalCode: "0880",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hansteens gate",
    streetNumber: "7A",
    postalCode: "0253",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hansteens gate",
    streetNumber: "12B",
    postalCode: "0253",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hansteens gate",
    streetNumber: "18",
    postalCode: "0253",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Harald Hårfagres gate",
    streetNumber: "10A",
    postalCode: "0363",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Harald Hårfagres gate",
    streetNumber: "9",
    postalCode: "0363",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Harald Hårfagres gate",
    streetNumber: "2",
    postalCode: "0363",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Harald Sohlbergs Vei",
    streetNumber: "1",
    postalCode: "1064",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Harbitzalléen",
    streetNumber: "14F",
    postalCode: "0275",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Harbitzalléen",
    streetNumber: "24",
    postalCode: "0275",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hareveien",
    streetNumber: "13",
    postalCode: "0491",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hareveien",
    streetNumber: "2",
    postalCode: "0491",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hasleveien",
    streetNumber: "56C",
    postalCode: "0575",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hasleveien",
    streetNumber: "28A",
    postalCode: "0571",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hasleveien",
    streetNumber: "28",
    postalCode: "0571",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hasleveien",
    streetNumber: "10",
    postalCode: "0571",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hasleveien",
    streetNumber: "9",
    postalCode: "0571",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hauchs gate",
    streetNumber: "2A",
    postalCode: "0175",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hauchs gate",
    streetNumber: "1",
    postalCode: "0175",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Haugenstuveien",
    streetNumber: "43",
    postalCode: "1084",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Haugerudhagan",
    streetNumber: "40C",
    postalCode: "0673",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Haugerudhagan",
    streetNumber: "82",
    postalCode: "0673",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Haugerudhagan",
    streetNumber: "80",
    postalCode: "0673",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Haugerudhagan",
    streetNumber: "3",
    postalCode: "0673",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Haugerudveien",
    streetNumber: "88",
    postalCode: "0674",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Haugerudveien",
    streetNumber: "88",
    postalCode: "0674",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Haugerudveien",
    streetNumber: "76",
    postalCode: "0674",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Haugerudveien",
    streetNumber: "55",
    postalCode: "0674",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Haugerudveien",
    streetNumber: "2",
    postalCode: "0674",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hauges vei",
    streetNumber: "5A",
    postalCode: "0871",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Haugjordet",
    streetNumber: "15",
    postalCode: "1336",
    city: "Sandvika",
    country: "Norge",
  },
  {
    streetName: "Hauketoveien",
    streetNumber: "5C",
    postalCode: "1266",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Havnabakken",
    streetNumber: "29B",
    postalCode: "0874",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Havreveien",
    streetNumber: "50",
    postalCode: "0680",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Havreveien",
    streetNumber: "26",
    postalCode: "0680",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Havreveien",
    streetNumber: "11",
    postalCode: "0680",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hegdehaugsveien",
    streetNumber: "36C",
    postalCode: "0352",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hegdehaugsveien",
    streetNumber: "27B",
    postalCode: "0352",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hegdehaugsveien",
    streetNumber: "34",
    postalCode: "0352",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hegdehaugsveien",
    streetNumber: "15",
    postalCode: "0352",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hegdehaugsveien",
    streetNumber: "9",
    postalCode: "0352",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Heggedalsveien",
    streetNumber: "102B",
    postalCode: "1385",
    city: "Asker",
    country: "Norge",
  },
  {
    streetName: "Heggelibakken",
    streetNumber: "23",
    postalCode: "0375",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Heiasvingen",
    streetNumber: "25",
    postalCode: "1177",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Heimdalsgata",
    streetNumber: "35",
    postalCode: "0578",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Heimdalsgata",
    streetNumber: "8",
    postalCode: "0561",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Heimdalsveien",
    streetNumber: "22",
    postalCode: "1472",
    city: "Fjellhamar",
    country: "Norge",
  },
  {
    streetName: "Hekkveien",
    streetNumber: "10B",
    postalCode: "0571",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hekkveien",
    streetNumber: "7",
    postalCode: "0571",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hekkveien",
    streetNumber: "1",
    postalCode: "0571",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Helgesens gate",
    streetNumber: "80F",
    postalCode: "0563",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Helgesens gate",
    streetNumber: "42f",
    postalCode: "0553",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Helgesens gate",
    streetNumber: "72",
    postalCode: "0558",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Helgesens gate",
    streetNumber: "42",
    postalCode: "0553",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Helgesens gate",
    streetNumber: "17",
    postalCode: "0553",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hellerudfaret",
    streetNumber: "12",
    postalCode: "0672",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hellerudjordet",
    streetNumber: "8",
    postalCode: "1350",
    city: "Lommedalen",
    country: "Norge",
  },
  {
    streetName: "Hellerudsvingen",
    streetNumber: "12a",
    postalCode: "0684",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hellerudveien",
    streetNumber: "90",
    postalCode: "0687",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hellerudveien",
    streetNumber: "25",
    postalCode: "0672",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hellinga",
    streetNumber: "4C",
    postalCode: "1151",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hellinga",
    streetNumber: "2A",
    postalCode: "1151",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hemingveien",
    streetNumber: "7F",
    postalCode: "0772",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hemingveien",
    streetNumber: "10E",
    postalCode: "0772",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Henrik Ibsens gate",
    streetNumber: "100",
    postalCode: "0255",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Henrik Sørensens vei",
    streetNumber: "30",
    postalCode: "1065",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Henrik Sørensens vei",
    streetNumber: "27",
    postalCode: "1065",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Herman Foss' gate",
    streetNumber: "4D",
    postalCode: "0171",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Herregårdsveien",
    streetNumber: "53A",
    postalCode: "1168",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Herregårdsveien",
    streetNumber: "2A",
    postalCode: "1168",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Herregårdsveien",
    streetNumber: "10B",
    postalCode: "1168",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Herregårdsveien",
    streetNumber: "2",
    postalCode: "1168",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Herslebs gate",
    streetNumber: "32A",
    postalCode: "0578",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Herslebs gate",
    streetNumber: "23",
    postalCode: "0561",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Herslebs gate",
    streetNumber: "19",
    postalCode: "0561",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hesteskoen",
    streetNumber: "9",
    postalCode: "0493",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Heyerdahls vei",
    streetNumber: "5B",
    postalCode: "0777",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Heyerdahls vei",
    streetNumber: "16A",
    postalCode: "0777",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hieronymus Heyerdahls gate",
    streetNumber: "1",
    postalCode: "0160",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hjorteveien",
    streetNumber: "8",
    postalCode: "0852",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hjorteveien",
    streetNumber: "1",
    postalCode: "0852",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hoffsjef Løvenskiolds vei",
    streetNumber: "63A",
    postalCode: "0382",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hoffsjef Løvenskiolds vei",
    streetNumber: "48D",
    postalCode: "0382",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hoffsjef Løvenskiolds vei",
    streetNumber: "60",
    postalCode: "0382",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hoffsveien",
    streetNumber: "8A",
    postalCode: "0275",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hoffsveien",
    streetNumber: "75D",
    postalCode: "0377",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hoffsveien",
    streetNumber: "70C",
    postalCode: "0377",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hoffsveien",
    streetNumber: "64B",
    postalCode: "0377",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hoffsveien",
    streetNumber: "60C",
    postalCode: "0377",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hoffsveien",
    streetNumber: "49A",
    postalCode: "0377",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hoffsveien",
    streetNumber: "1B",
    postalCode: "0275",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hoffsveien",
    streetNumber: "1A",
    postalCode: "0275",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hoffsveien",
    streetNumber: "17",
    postalCode: "0275",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hoffsveien",
    streetNumber: "14",
    postalCode: "0275",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hoffsveien",
    streetNumber: "10",
    postalCode: "0275",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hoffsveien",
    streetNumber: "4",
    postalCode: "0275",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hofftunet",
    streetNumber: "19",
    postalCode: "0379",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hofftunet",
    streetNumber: "17",
    postalCode: "0379",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hogstveien",
    streetNumber: "22A",
    postalCode: "2006",
    city: "Løvenstad",
    country: "Norge",
  },
  {
    streetName: "Holbergs gate",
    streetNumber: "11B",
    postalCode: "0166",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holbergs gate",
    streetNumber: "21",
    postalCode: "0166",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holbergs Plass",
    streetNumber: "3",
    postalCode: "0166",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hollendergata",
    streetNumber: "6B",
    postalCode: "0190",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hollendergata",
    streetNumber: "2B",
    postalCode: "0190",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holmenkollveien",
    streetNumber: "2B",
    postalCode: "0376",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holmenkollveien",
    streetNumber: "127B",
    postalCode: "0787",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holmenkollveien",
    streetNumber: "200",
    postalCode: "0791",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holmenkollveien",
    streetNumber: "65",
    postalCode: "0784",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holmenkollveien",
    streetNumber: "58",
    postalCode: "0784",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holmenkollveien",
    streetNumber: "53",
    postalCode: "0773",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holmenveien",
    streetNumber: "6a",
    postalCode: "0374",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holmenveien",
    streetNumber: "5C",
    postalCode: "0374",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holmenveien",
    streetNumber: "49e",
    postalCode: "0376",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holmenveien",
    streetNumber: "45B",
    postalCode: "0376",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holmenveien",
    streetNumber: "41B",
    postalCode: "0374",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holmenveien",
    streetNumber: "34E",
    postalCode: "0374",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holmenveien",
    streetNumber: "34B",
    postalCode: "0374",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holmenveien",
    streetNumber: "20B",
    postalCode: "0374",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holmenveien",
    streetNumber: "16B",
    postalCode: "0374",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holmenveien",
    streetNumber: "51",
    postalCode: "0376",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holmenveien",
    streetNumber: "2",
    postalCode: "0374",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holmlia Senter vei",
    streetNumber: "22",
    postalCode: "1255",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holmlia Senter vei",
    streetNumber: "19",
    postalCode: "1255",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holmlia Senter vei",
    streetNumber: "15",
    postalCode: "1255",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holmliveien",
    streetNumber: "44",
    postalCode: "1252",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holmliveien",
    streetNumber: "4",
    postalCode: "1252",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holsteinveien",
    streetNumber: "15",
    postalCode: "0860",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holsteinveien",
    streetNumber: "12",
    postalCode: "0874",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holtegata",
    streetNumber: "10",
    postalCode: "0259",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holteveien",
    streetNumber: "44C",
    postalCode: "1410",
    city: "Kolbotn",
    country: "Norge",
  },
  {
    streetName: "Holtveien",
    streetNumber: "50",
    postalCode: "1177",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hornfjellveien",
    streetNumber: "2D",
    postalCode: "1086",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hoslegata",
    streetNumber: "37",
    postalCode: "1356",
    city: "Bekkestua",
    country: "Norge",
  },
  {
    streetName: "Hosleveien",
    streetNumber: "110",
    postalCode: "1357",
    city: "Bekkestua",
    country: "Norge",
  },
  {
    streetName: "Hosleveien",
    streetNumber: "29",
    postalCode: "1357",
    city: "Bekkestua",
    country: "Norge",
  },
  {
    streetName: "Hospitsveien",
    streetNumber: "1E",
    postalCode: "0789",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hospitsveien",
    streetNumber: "11A",
    postalCode: "0789",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hovfaret",
    streetNumber: "4b",
    postalCode: "0275",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hovfaret",
    streetNumber: "17b",
    postalCode: "0275",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hovfaret",
    streetNumber: "17",
    postalCode: "0275",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hovfaret",
    streetNumber: "8",
    postalCode: "0275",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hovfaret",
    streetNumber: "1",
    postalCode: "0276",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hovinveien",
    streetNumber: "9B",
    postalCode: "0576",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hovinveien",
    streetNumber: "43",
    postalCode: "0576",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hovinveien",
    streetNumber: "37",
    postalCode: "0576",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hovseterveien",
    streetNumber: "68B",
    postalCode: "0768",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hovseterveien",
    streetNumber: "40A",
    postalCode: "0768",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hovseterveien",
    streetNumber: "32B",
    postalCode: "0768",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hovseterveien",
    streetNumber: "96",
    postalCode: "0767",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hubrosvingen",
    streetNumber: "4",
    postalCode: "1555",
    city: "Son",
    country: "Norge",
  },
  {
    streetName: "Huitfeldts gate",
    streetNumber: "35C",
    postalCode: "0253",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Huitfeldts gate",
    streetNumber: "31",
    postalCode: "0253",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Huitfeldts gate",
    streetNumber: "18",
    postalCode: "0253",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Huitfeldts gate",
    streetNumber: "13",
    postalCode: "0253",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Huk Aveny",
    streetNumber: "14A",
    postalCode: "0287",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Huk Aveny",
    streetNumber: "25",
    postalCode: "0287",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Huldreveien",
    streetNumber: "3",
    postalCode: "0781",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Humleveien",
    streetNumber: "8A",
    postalCode: "0870",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Humleveien",
    streetNumber: "12",
    postalCode: "0870",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hundsundveien",
    streetNumber: "26",
    postalCode: "1367",
    city: "Snarøya",
    country: "Norge",
  },
  {
    streetName: "Hundsundveien",
    streetNumber: "17",
    postalCode: "1367",
    city: "Snarøya",
    country: "Norge",
  },
  {
    streetName: "Husebybakken",
    streetNumber: "30B",
    postalCode: "0379",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Husebybakken",
    streetNumber: "42",
    postalCode: "0379",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Husebybakken",
    streetNumber: "7",
    postalCode: "0379",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Husebyveien",
    streetNumber: "7B",
    postalCode: "0379",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hyllveien",
    streetNumber: "9",
    postalCode: "0274",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Høgåsveien",
    streetNumber: "68",
    postalCode: "1259",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Høybråtenstien",
    streetNumber: "2A",
    postalCode: "1088",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Høybråtenveien",
    streetNumber: "25D",
    postalCode: "1055",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Høybråtenveien",
    streetNumber: "23K",
    postalCode: "1055",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Høybråtenveien",
    streetNumber: "17A",
    postalCode: "1055",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Høybråtenveien",
    streetNumber: "75",
    postalCode: "1086",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Høybråtenveien",
    streetNumber: "23",
    postalCode: "1055",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Høyenhallveien",
    streetNumber: "110",
    postalCode: "0678",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Høyenhallveien",
    streetNumber: "35",
    postalCode: "0678",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Høyenhallveien",
    streetNumber: "25",
    postalCode: "0678",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Høyrabben",
    streetNumber: "44",
    postalCode: "1336",
    city: "Sandvika",
    country: "Norge",
  },
  {
    streetName: "Håkons gate",
    streetNumber: "22",
    postalCode: "0652",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Håkons vei",
    streetNumber: "92",
    postalCode: "1470",
    city: "Lørenskog",
    country: "Norge",
  },
  {
    streetName: "Håvalds vei",
    streetNumber: "5",
    postalCode: "1185",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Idas vei",
    streetNumber: "17",
    postalCode: "0981",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Idrettsveien",
    streetNumber: "17B",
    postalCode: "1087",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Iduns gate",
    streetNumber: "2A",
    postalCode: "0178",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Iduns gate",
    streetNumber: "1A",
    postalCode: "0178",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Industrigata",
    streetNumber: "31B",
    postalCode: "0357",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Industrigata",
    streetNumber: "67",
    postalCode: "0357",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Industrigata",
    streetNumber: "40",
    postalCode: "0357",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Inges gate",
    streetNumber: "4M",
    postalCode: "0196",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Inges gate",
    streetNumber: "2",
    postalCode: "0196",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ingolf Ruuds vei",
    streetNumber: "6",
    postalCode: "1169",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Inkognito Terrasse",
    streetNumber: "1",
    postalCode: "0256",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Innspurten",
    streetNumber: "15",
    postalCode: "0663",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Innspurten",
    streetNumber: "8",
    postalCode: "0663",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Innspurten",
    streetNumber: "6",
    postalCode: "0663",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Irisveien",
    streetNumber: "26",
    postalCode: "0870",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Islands gate",
    streetNumber: "14",
    postalCode: "0658",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ivan Bjørndals gate",
    streetNumber: "23B",
    postalCode: "0472",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ivan Bjørndals gate",
    streetNumber: "24",
    postalCode: "0472",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ivar Welles vei",
    streetNumber: "13",
    postalCode: "0672",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jacob Aalls gate",
    streetNumber: "8B",
    postalCode: "0368",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jacob Aalls gate",
    streetNumber: "44B",
    postalCode: "0364",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jacob Aalls gate",
    streetNumber: "40B",
    postalCode: "0364",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jacob Aalls gate",
    streetNumber: "27B",
    postalCode: "0364",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jacob Aalls gate",
    streetNumber: "23A",
    postalCode: "0364",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jacob Aalls gate",
    streetNumber: "17B",
    postalCode: "0364",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jacob Aalls gate",
    streetNumber: "46",
    postalCode: "0364",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jacob Aalls gate",
    streetNumber: "34",
    postalCode: "0364",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jacob Aalls gate",
    streetNumber: "26",
    postalCode: "0368",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jacob Aalls gate",
    streetNumber: "21",
    postalCode: "0364",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jacob Aalls gate",
    streetNumber: "15",
    postalCode: "0366",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jacob Aalls gate",
    streetNumber: "11",
    postalCode: "0368",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jacob Hansens vei",
    streetNumber: "15",
    postalCode: "0370",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jacob Hansens vei",
    streetNumber: "12",
    postalCode: "0370",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jansbergveien",
    streetNumber: "13",
    postalCode: "0861",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jegerveien",
    streetNumber: "21B",
    postalCode: "0777",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jegerveien",
    streetNumber: "13",
    postalCode: "0777",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jens Bjelkes gate",
    streetNumber: "10A",
    postalCode: "0562",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jernbanetorget",
    streetNumber: "1",
    postalCode: "0154",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jerpefaret",
    streetNumber: "13H",
    postalCode: "0788",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jess Carlsens gate",
    streetNumber: "6",
    postalCode: "0183",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Johan Hirsch' vei",
    streetNumber: "13",
    postalCode: "0678",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Johan Scharffenbergs vei",
    streetNumber: "106",
    postalCode: "0694",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Johan Scharffenbergs vei",
    streetNumber: "99",
    postalCode: "0694",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Johan Scharffenbergs vei",
    streetNumber: "14",
    postalCode: "0694",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Johan Svendsens gate",
    streetNumber: "29",
    postalCode: "0475",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "John Brandts vei",
    streetNumber: "51",
    postalCode: "0860",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "John Østensens vei",
    streetNumber: "6",
    postalCode: "1394",
    city: "Nesbru",
    country: "Norge",
  },
  {
    streetName: "Jomfrubråtveien",
    streetNumber: "57B",
    postalCode: "1179",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jomfrubråtveien",
    streetNumber: "38B",
    postalCode: "1179",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jomfrubråtveien",
    streetNumber: "79",
    postalCode: "1179",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jon Smestads vei",
    streetNumber: "4",
    postalCode: "0377",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jordal Terrasse",
    streetNumber: "8",
    postalCode: "0658",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jordalgata",
    streetNumber: "2",
    postalCode: "0657",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jordstjerneveien",
    streetNumber: "77",
    postalCode: "1283",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jordstjerneveien",
    streetNumber: "3",
    postalCode: "1283",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Josefines Gate",
    streetNumber: "41",
    postalCode: "0351",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Juterudåsen",
    streetNumber: "75B",
    postalCode: "1341",
    city: "Slependen",
    country: "Norge",
  },
  {
    streetName: "Jutulveien",
    streetNumber: "12",
    postalCode: "0852",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jørgen Løvlands gate",
    streetNumber: "3",
    postalCode: "0570",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jørgen Løvlands gate",
    streetNumber: "1",
    postalCode: "0570",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jørnstadveien",
    streetNumber: "15",
    postalCode: "1394",
    city: "Nesbru",
    country: "Norge",
  },
  {
    streetName: "Kaare Røddes Plass",
    streetNumber: "15",
    postalCode: "0576",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kabelgaten",
    streetNumber: "39c",
    postalCode: "0580",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kabelgaten",
    streetNumber: "33",
    postalCode: "0580",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kabelgaten",
    streetNumber: "1",
    postalCode: "0580",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kaiekroken",
    streetNumber: "16",
    postalCode: "1056",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kalbakkslyngen",
    streetNumber: "28",
    postalCode: "0951",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kalbakkslyngen",
    streetNumber: "24",
    postalCode: "0951",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kalbakkstubben",
    streetNumber: "10",
    postalCode: "0951",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kalderaveien",
    streetNumber: "9",
    postalCode: "1359",
    city: "Eiksmarka",
    country: "Norge",
  },
  {
    streetName: "Kampengata",
    streetNumber: "16",
    postalCode: "0654",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kampheimveien",
    streetNumber: "12A",
    postalCode: "0685",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kanonhallveien",
    streetNumber: "58B",
    postalCode: "0585",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kanonhallveien",
    streetNumber: "56G",
    postalCode: "0585",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kanonhallveien",
    streetNumber: "48C",
    postalCode: "0585",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kanonhallveien",
    streetNumber: "14F",
    postalCode: "0585",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kapellveien",
    streetNumber: "158D",
    postalCode: "0493",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kapellveien",
    streetNumber: "31",
    postalCode: "0487",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kaptein Oppegaards vei",
    streetNumber: "2A",
    postalCode: "1164",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kaptein Oppegaards vei",
    streetNumber: "15D",
    postalCode: "1164",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karenslyst Allé",
    streetNumber: "16D",
    postalCode: "0278",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karenslyst Allé",
    streetNumber: "56",
    postalCode: "0277",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karenslyst Allé",
    streetNumber: "54",
    postalCode: "0277",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karenslyst Allé",
    streetNumber: "49",
    postalCode: "0279",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karenslyst Allé",
    streetNumber: "16",
    postalCode: "0278",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karenslyst Allé",
    streetNumber: "12",
    postalCode: "0278",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karenslyst Allé",
    streetNumber: "10",
    postalCode: "0278",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karenslyst Allé",
    streetNumber: "9",
    postalCode: "0278",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karenslyst Allé",
    streetNumber: "7",
    postalCode: "0278",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karenslyst Allé",
    streetNumber: "1",
    postalCode: "0278",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karl Andersens vei",
    streetNumber: "83",
    postalCode: "1086",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karl Andersens vei",
    streetNumber: "78",
    postalCode: "1086",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karl Andersens vei",
    streetNumber: "61",
    postalCode: "1086",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karl Johans gate",
    streetNumber: "23B",
    postalCode: "0159",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karl Johans gate",
    streetNumber: "37",
    postalCode: "0162",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karl Johans gate",
    streetNumber: "23",
    postalCode: "0159",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karl Johans gate",
    streetNumber: "12",
    postalCode: "0154",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karl Johans gate",
    streetNumber: "1",
    postalCode: "0154",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karl Staaffs vei",
    streetNumber: "80",
    postalCode: "0665",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karl Staaffs vei",
    streetNumber: "56",
    postalCode: "0665",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karl Staaffs vei",
    streetNumber: "23",
    postalCode: "0665",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karl Staaffs vei",
    streetNumber: "19",
    postalCode: "0665",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karlstadgata",
    streetNumber: "29",
    postalCode: "0553",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karoline Kristiansens vei",
    streetNumber: "2",
    postalCode: "0661",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karvesvingen",
    streetNumber: "1",
    postalCode: "0579",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Keysers gate",
    streetNumber: "3",
    postalCode: "0180",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kildals vei",
    streetNumber: "13",
    postalCode: "0678",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kildals vei",
    streetNumber: "9",
    postalCode: "0678",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kilenveien",
    streetNumber: "7",
    postalCode: "1366",
    city: "Lysaker",
    country: "Norge",
  },
  {
    streetName: "Kingos gate",
    streetNumber: "8",
    postalCode: "0457",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kirkegata",
    streetNumber: "6B",
    postalCode: "2000",
    city: "Lillestrøm",
    country: "Norge",
  },
  {
    streetName: "Kirkegata",
    streetNumber: "34",
    postalCode: "0153",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kirkeveien",
    streetNumber: "90B",
    postalCode: "0364",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kirkeveien",
    streetNumber: "77A",
    postalCode: "0364",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kirkeveien",
    streetNumber: "49B",
    postalCode: "0368",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kirkeveien",
    streetNumber: "112A",
    postalCode: "0361",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kirkeveien",
    streetNumber: "179",
    postalCode: "0455",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kirkeveien",
    streetNumber: "166",
    postalCode: "0450",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kirkeveien",
    streetNumber: "165",
    postalCode: "0450",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kirkeveien",
    streetNumber: "163",
    postalCode: "0450",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kirkeveien",
    streetNumber: "104",
    postalCode: "0361",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kirkeveien",
    streetNumber: "102",
    postalCode: "0361",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kirkeveien",
    streetNumber: "87",
    postalCode: "0361",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kirkeveien",
    streetNumber: "70",
    postalCode: "0364",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kirkeveien",
    streetNumber: "60",
    postalCode: "0368",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kirkeveien",
    streetNumber: "50",
    postalCode: "0368",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kirkeveien",
    streetNumber: "25",
    postalCode: "0260",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kirkeveien",
    streetNumber: "4",
    postalCode: "1363",
    city: "Høvik",
    country: "Norge",
  },
  {
    streetName: "Kittel-nielsens vei",
    streetNumber: "79K",
    postalCode: "1163",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kjelsåsveien",
    streetNumber: "74A",
    postalCode: "0491",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kjelsåsveien",
    streetNumber: "135C",
    postalCode: "0491",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kjelsåsveien",
    streetNumber: "143",
    postalCode: "0491",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kjelsåsveien",
    streetNumber: "101",
    postalCode: "0491",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kjølberggata",
    streetNumber: "23",
    postalCode: "0653",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kleivveien",
    streetNumber: "10B",
    postalCode: "1356",
    city: "Bekkestua",
    country: "Norge",
  },
  {
    streetName: "Klinestadmoen",
    streetNumber: "1",
    postalCode: "3241",
    city: "Sandefjord",
    country: "Norge",
  },
  {
    streetName: "Klingenberggata",
    streetNumber: "7A",
    postalCode: "0161",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kløfterhagen",
    streetNumber: "13F",
    postalCode: "1067",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Knut Valstads vei",
    streetNumber: "18",
    postalCode: "0690",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kolderups vei",
    streetNumber: "21A",
    postalCode: "0587",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kongens gate",
    streetNumber: "23",
    postalCode: "0153",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kongens gate",
    streetNumber: "22",
    postalCode: "0153",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Konglefaret",
    streetNumber: "39A",
    postalCode: "1359",
    city: "Eiksmarka",
    country: "Norge",
  },
  {
    streetName: "Kongsberggata",
    streetNumber: "3",
    postalCode: "0468",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kongshavnveien",
    streetNumber: "9",
    postalCode: "0193",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kongsveien",
    streetNumber: "82",
    postalCode: "1177",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kongsvingergata",
    streetNumber: "1A",
    postalCode: "0464",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Konows gate",
    streetNumber: "83C",
    postalCode: "0196",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Konows gate",
    streetNumber: "30K",
    postalCode: "0196",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Konows gate",
    streetNumber: "19C",
    postalCode: "0196",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Konows gate",
    streetNumber: "83",
    postalCode: "0196",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Konows gate",
    streetNumber: "8",
    postalCode: "0192",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Konsul Schjelderups vei",
    streetNumber: "8",
    postalCode: "0286",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Konvallveien",
    streetNumber: "4",
    postalCode: "0855",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Korsgata",
    streetNumber: "12A",
    postalCode: "0551",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Korsgata",
    streetNumber: "25",
    postalCode: "0551",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Korsvoll Terrasse",
    streetNumber: "21",
    postalCode: "0881",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Korsvoll Terrasse",
    streetNumber: "12",
    postalCode: "0881",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kranveien",
    streetNumber: "12",
    postalCode: "0684",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kringsjåveien",
    streetNumber: "11A",
    postalCode: "1358",
    city: "Jar",
    country: "Norge",
  },
  {
    streetName: "Kristian Auberts vei",
    streetNumber: "37",
    postalCode: "0760",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kristian Kølles vei",
    streetNumber: "1",
    postalCode: "1367",
    city: "Snarøya",
    country: "Norge",
  },
  {
    streetName: "Kristiansands gate",
    streetNumber: "12C",
    postalCode: "0463",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kristiansands gate",
    streetNumber: "5",
    postalCode: "0463",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kristine Bonnevies vei",
    streetNumber: "20",
    postalCode: "0592",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kristine Bonnevies vei",
    streetNumber: "10",
    postalCode: "0592",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kristins vei",
    streetNumber: "40",
    postalCode: "0669",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kristoffer Robins vei",
    streetNumber: "46",
    postalCode: "0978",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kristoffer Robins vei",
    streetNumber: "18",
    postalCode: "0978",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kristoffer Robins vei",
    streetNumber: "13",
    postalCode: "0978",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kristoffer Robins vei",
    streetNumber: "2",
    postalCode: "0978",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Krokliveien",
    streetNumber: "67B",
    postalCode: "0584",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kronprinsens gate",
    streetNumber: "14",
    postalCode: "0251",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kronprinsesse Märthas plass",
    streetNumber: "1",
    postalCode: "0160",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Krumgata",
    streetNumber: "4",
    postalCode: "0170",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kruttveien",
    streetNumber: "2A",
    postalCode: "0585",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kurlandstien",
    streetNumber: "42",
    postalCode: "1052",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kveldsro Terrasse",
    streetNumber: "1",
    postalCode: "1368",
    city: "Stabekk",
    country: "Norge",
  },
  {
    streetName: "Kyhns vei",
    streetNumber: "10B",
    postalCode: "1166",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kyrres vei",
    streetNumber: "26B",
    postalCode: "1369",
    city: "Stabekk",
    country: "Norge",
  },
  {
    streetName: "Kyrres vei",
    streetNumber: "17a",
    postalCode: "1369",
    city: "Stabekk",
    country: "Norge",
  },
  {
    streetName: "Københavngata",
    streetNumber: "10",
    postalCode: "0566",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Labben",
    streetNumber: "7",
    postalCode: "1359",
    city: "Eiksmarka",
    country: "Norge",
  },
  {
    streetName: "Lachmanns vei",
    streetNumber: "4",
    postalCode: "0495",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lakkegata",
    streetNumber: "71A",
    postalCode: "0562",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lakkegata",
    streetNumber: "75",
    postalCode: "0562",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lakkegata",
    streetNumber: "66",
    postalCode: "0562",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lambertseterveien",
    streetNumber: "3A",
    postalCode: "1160",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lambertseterveien",
    streetNumber: "57",
    postalCode: "1154",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lambertseterveien",
    streetNumber: "49",
    postalCode: "1154",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lambertseterveien",
    streetNumber: "8",
    postalCode: "1160",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lambertseterveien",
    streetNumber: "4",
    postalCode: "1160",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Landingsveien",
    streetNumber: "132",
    postalCode: "0767",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Landingsveien",
    streetNumber: "122",
    postalCode: "0767",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Landingsveien",
    streetNumber: "72",
    postalCode: "0767",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Landingsveien",
    streetNumber: "66",
    postalCode: "0767",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Landingsveien",
    streetNumber: "46",
    postalCode: "0767",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Landingsveien",
    streetNumber: "27",
    postalCode: "0770",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Landingsveien",
    streetNumber: "15",
    postalCode: "0770",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Landingsveien",
    streetNumber: "3",
    postalCode: "0770",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Landingsveien",
    streetNumber: "1",
    postalCode: "0770",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Langbølgen",
    streetNumber: "53",
    postalCode: "1150",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Langbølgen",
    streetNumber: "39",
    postalCode: "1150",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Langbølgen",
    streetNumber: "27",
    postalCode: "1150",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Langbølgen",
    streetNumber: "24",
    postalCode: "1155",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Langerudhaugen",
    streetNumber: "4E",
    postalCode: "1187",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Langerudsvingen",
    streetNumber: "8A",
    postalCode: "1187",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Langerudsvingen",
    streetNumber: "31C",
    postalCode: "1187",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Langkaia",
    streetNumber: "1",
    postalCode: "0150",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Langmyrgrenda",
    streetNumber: "61B",
    postalCode: "0861",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Langmyrgrenda",
    streetNumber: "49D",
    postalCode: "0861",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Langoddveien",
    streetNumber: "21",
    postalCode: "1367",
    city: "Fornebu",
    country: "Norge",
  },
  {
    streetName: "Langsetveien",
    streetNumber: "24",
    postalCode: "0495",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Langviksveien",
    streetNumber: "11A",
    postalCode: "0286",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Langviksveien",
    streetNumber: "10",
    postalCode: "0286",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Langåsveien",
    streetNumber: "17a",
    postalCode: "0880",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Larsbråtveien",
    streetNumber: "137",
    postalCode: "0674",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Larsbråtveien",
    streetNumber: "133",
    postalCode: "0674",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lassons gate",
    streetNumber: "8",
    postalCode: "0270",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Laura Gundersens gate",
    streetNumber: "2",
    postalCode: "0168",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lavrans vei",
    streetNumber: "7",
    postalCode: "0670",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Leirfallsgata",
    streetNumber: "8B",
    postalCode: "0550",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lensmann Hiorths allé",
    streetNumber: "3",
    postalCode: "0661",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lerkeveien",
    streetNumber: "15B",
    postalCode: "1053",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Liakollveien",
    streetNumber: "22",
    postalCode: "1259",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Libakkfaret",
    streetNumber: "10B",
    postalCode: "1184",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Libakkveien",
    streetNumber: "6B",
    postalCode: "1184",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lille Bislett",
    streetNumber: "24",
    postalCode: "0170",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lille Bislett",
    streetNumber: "6",
    postalCode: "0170",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lille Bislett",
    streetNumber: "4",
    postalCode: "0170",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lille Borgen vei",
    streetNumber: "14B",
    postalCode: "0370",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lille Stranden",
    streetNumber: "8",
    postalCode: "0252",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lille Stranden",
    streetNumber: "4",
    postalCode: "0252",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lilleakerveien",
    streetNumber: "6B",
    postalCode: "0283",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lilleakerveien",
    streetNumber: "31",
    postalCode: "0283",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lilleakerveien",
    streetNumber: "20",
    postalCode: "0283",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lilleakerveien",
    streetNumber: "16",
    postalCode: "0283",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lillerutsvei",
    streetNumber: "95",
    postalCode: "1367",
    city: "Snarøya",
    country: "Norge",
  },
  {
    streetName: "Lillerutsvei",
    streetNumber: "5",
    postalCode: "1364",
    city: "Fornebu",
    country: "Norge",
  },
  {
    streetName: "Lillerutsvei",
    streetNumber: "2",
    postalCode: "1364",
    city: "Fornebu",
    country: "Norge",
  },
  {
    streetName: "Lillevannsveien",
    streetNumber: "41B",
    postalCode: "0788",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lillevannsveien",
    streetNumber: "12D",
    postalCode: "0788",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lillevannsveien",
    streetNumber: "12",
    postalCode: "0788",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lillevannsveien",
    streetNumber: "4",
    postalCode: "0788",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lilloe-olsens vei",
    streetNumber: "9",
    postalCode: "0489",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lilloe-olsens vei",
    streetNumber: "4",
    postalCode: "0489",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lillogata",
    streetNumber: "5H",
    postalCode: "0484",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lillogata",
    streetNumber: "3C",
    postalCode: "0484",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lindbäckveien",
    streetNumber: "31",
    postalCode: "1163",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lindealléen",
    streetNumber: "16",
    postalCode: "0487",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lindebergveien",
    streetNumber: "7C",
    postalCode: "1358",
    city: "Jar",
    country: "Norge",
  },
  {
    streetName: "Lindebergveien",
    streetNumber: "7A",
    postalCode: "1069",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lindebergveien",
    streetNumber: "43B",
    postalCode: "1069",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lindebergveien",
    streetNumber: "37",
    postalCode: "1358",
    city: "Jar",
    country: "Norge",
  },
  {
    streetName: "Lindebergåsen",
    streetNumber: "21B",
    postalCode: "1071",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lindebergåsen",
    streetNumber: "14A",
    postalCode: "1068",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lindebergåsen",
    streetNumber: "12B",
    postalCode: "1068",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Linneaveien",
    streetNumber: "17A",
    postalCode: "1182",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lofsrudveien",
    streetNumber: "6",
    postalCode: "1281",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lofthusveien",
    streetNumber: "17A",
    postalCode: "0587",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lomviveien",
    streetNumber: "4A",
    postalCode: "1364",
    city: "Fornebu",
    country: "Norge",
  },
  {
    streetName: "Louises gate",
    streetNumber: "17A",
    postalCode: "0169",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lovisenberggata",
    streetNumber: "7B",
    postalCode: "0456",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lovisenberggata",
    streetNumber: "4F",
    postalCode: "0456",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lovisenberggata",
    streetNumber: "4C",
    postalCode: "0456",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lovisenberggata",
    streetNumber: "21E",
    postalCode: "0456",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lovisenberggata",
    streetNumber: "5",
    postalCode: "0456",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ludvig Karstens vei",
    streetNumber: "4",
    postalCode: "1064",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Luftfartsveien",
    streetNumber: "4",
    postalCode: "0770",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lunden",
    streetNumber: "34",
    postalCode: "0598",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lundliveien",
    streetNumber: "30C",
    postalCode: "0584",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lundliveien",
    streetNumber: "2",
    postalCode: "0580",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lutvannsveien",
    streetNumber: "52",
    postalCode: "0676",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lutvannsveien",
    streetNumber: "8",
    postalCode: "0676",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lutvannsveien",
    streetNumber: "1",
    postalCode: "0676",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lybekkveien",
    streetNumber: "18",
    postalCode: "0772",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lyngåsveien",
    streetNumber: "10B",
    postalCode: "0491",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lysaker Brygge",
    streetNumber: "23",
    postalCode: "1366",
    city: "Lysaker",
    country: "Norge",
  },
  {
    streetName: "Lysaker Brygge",
    streetNumber: "9",
    postalCode: "1366",
    city: "Lysaker",
    country: "Norge",
  },
  {
    streetName: "Lysehagan",
    streetNumber: "46B",
    postalCode: "0383",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lysehagan",
    streetNumber: "1F",
    postalCode: "0383",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lysehagan",
    streetNumber: "20",
    postalCode: "0383",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lyseskrenten",
    streetNumber: "23",
    postalCode: "0383",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lyseskrenten",
    streetNumber: "9",
    postalCode: "0383",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lærer Holes vei",
    streetNumber: "5",
    postalCode: "0378",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Løchenveien",
    streetNumber: "20D",
    postalCode: "0286",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Løchenveien",
    streetNumber: "8",
    postalCode: "0286",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Løkkaskogen",
    streetNumber: "18C",
    postalCode: "0773",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Løkkaskogen",
    streetNumber: "16B",
    postalCode: "0773",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Løkkegangen",
    streetNumber: "1",
    postalCode: "0270",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lønneveien",
    streetNumber: "5B",
    postalCode: "1053",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lønnåsen",
    streetNumber: "37A",
    postalCode: "1362",
    city: "Hosle",
    country: "Norge",
  },
  {
    streetName: "Lønnåsen",
    streetNumber: "1",
    postalCode: "1362",
    city: "Hosle",
    country: "Norge",
  },
  {
    streetName: "Lørenvangen",
    streetNumber: "50",
    postalCode: "0580",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lørenvangen",
    streetNumber: "40",
    postalCode: "0580",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lørenvangen",
    streetNumber: "34",
    postalCode: "0585",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lørenvangen",
    streetNumber: "28",
    postalCode: "0580",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lørenvangen",
    streetNumber: "5",
    postalCode: "0580",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lørenveien",
    streetNumber: "43a",
    postalCode: "0585",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lørenveien",
    streetNumber: "41e",
    postalCode: "0585",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lørenveien",
    streetNumber: "36A",
    postalCode: "0585",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lørenveien",
    streetNumber: "35",
    postalCode: "0585",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Løvenskiolds gate",
    streetNumber: "2A",
    postalCode: "0263",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Løvenskiolds gate",
    streetNumber: "19A",
    postalCode: "0260",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Løvenskiolds gate",
    streetNumber: "12A",
    postalCode: "0260",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Løvenskiolds vei",
    streetNumber: "14D",
    postalCode: "1358",
    city: "Jar",
    country: "Norge",
  },
  {
    streetName: "Løvsetfaret",
    streetNumber: "64C",
    postalCode: "1188",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Løypeveien",
    streetNumber: "1",
    postalCode: "0686",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Låveveien",
    streetNumber: "50",
    postalCode: "0682",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Låveveien",
    streetNumber: "48",
    postalCode: "0682",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Madserud allé",
    streetNumber: "34",
    postalCode: "0274",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Magnefaret",
    streetNumber: "5",
    postalCode: "0678",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Magnus Bergs gate",
    streetNumber: "6A",
    postalCode: "0266",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Majorstuveien",
    streetNumber: "32B",
    postalCode: "0367",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Malerhaugveien",
    streetNumber: "26C",
    postalCode: "0661",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Malerhaugveien",
    streetNumber: "34",
    postalCode: "0661",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Malmøgata",
    streetNumber: "9",
    postalCode: "0556",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Mandalls gate",
    streetNumber: "16",
    postalCode: "0190",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Manglerudveien",
    streetNumber: "76",
    postalCode: "0678",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Manglerudveien",
    streetNumber: "71",
    postalCode: "0678",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Marcus Thranes Gate",
    streetNumber: "2",
    postalCode: "0473",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Maria Dehlis vei",
    streetNumber: "40",
    postalCode: "1083",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Mariboes gate",
    streetNumber: "13",
    postalCode: "0183",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Maridalsveien",
    streetNumber: "6B",
    postalCode: "0178",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Maridalsveien",
    streetNumber: "39C",
    postalCode: "0175",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Maridalsveien",
    streetNumber: "180B",
    postalCode: "0469",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Maridalsveien",
    streetNumber: "310",
    postalCode: "0881",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Maridalsveien",
    streetNumber: "221",
    postalCode: "0467",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Maridalsveien",
    streetNumber: "153",
    postalCode: "0461",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Maridalsveien",
    streetNumber: "149",
    postalCode: "0461",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Maridalsveien",
    streetNumber: "146",
    postalCode: "0461",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Maridalsveien",
    streetNumber: "17",
    postalCode: "0175",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Maries gate",
    streetNumber: "9A",
    postalCode: "0368",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Maries gate",
    streetNumber: "12C",
    postalCode: "0368",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Markalleen",
    streetNumber: "50",
    postalCode: "1368",
    city: "Stabekk",
    country: "Norge",
  },
  {
    streetName: "Markveien",
    streetNumber: "56H",
    postalCode: "0550",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Markveien",
    streetNumber: "35B",
    postalCode: "0554",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Markveien",
    streetNumber: "25A",
    postalCode: "0554",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Markveien",
    streetNumber: "16B",
    postalCode: "0554",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Markveien",
    streetNumber: "56",
    postalCode: "0550",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Markveien",
    streetNumber: "22",
    postalCode: "0554",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Marmorveien",
    streetNumber: "18C",
    postalCode: "1154",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Marselis' gate",
    streetNumber: "24",
    postalCode: "0551",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Marstranderveien",
    streetNumber: "33",
    postalCode: "1369",
    city: "Stabekk",
    country: "Norge",
  },
  {
    streetName: "Marta Steinsviksvei",
    streetNumber: "77D",
    postalCode: "1283",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Marta Steinsviksvei",
    streetNumber: "75J",
    postalCode: "1283",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Marta Steinsviksvei",
    streetNumber: "49",
    postalCode: "1283",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Marta Steinsviksvei",
    streetNumber: "14",
    postalCode: "1283",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Martin Borrebekkens vei",
    streetNumber: "36",
    postalCode: "0584",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Martin Borrebekkens vei",
    streetNumber: "27",
    postalCode: "0584",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Martin Skatvedts vei",
    streetNumber: "12",
    postalCode: "0950",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Mauritz Hansens gate",
    streetNumber: "4A",
    postalCode: "0350",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Mekanikerveien",
    streetNumber: "19",
    postalCode: "0683",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Mekanikerveien",
    streetNumber: "7",
    postalCode: "0683",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Melkeveien",
    streetNumber: "27",
    postalCode: "0779",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Mellombølgen",
    streetNumber: "16",
    postalCode: "1157",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Meltzers gate",
    streetNumber: "16B",
    postalCode: "0257",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Melumveien",
    streetNumber: "60",
    postalCode: "0760",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Melumveien",
    streetNumber: "4",
    postalCode: "0751",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Messepromenaden",
    streetNumber: "10",
    postalCode: "0279",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Michelets vei",
    streetNumber: "19A",
    postalCode: "1366",
    city: "Lysaker",
    country: "Norge",
  },
  {
    streetName: "Michelets vei",
    streetNumber: "17A",
    postalCode: "1366",
    city: "Lysaker",
    country: "Norge",
  },
  {
    streetName: "Michelets vei",
    streetNumber: "11A",
    postalCode: "1366",
    city: "Lysaker",
    country: "Norge",
  },
  {
    streetName: "Micheletveien",
    streetNumber: "38G",
    postalCode: "1053",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Micheletveien",
    streetNumber: "38",
    postalCode: "1053",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Micheletveien",
    streetNumber: "34",
    postalCode: "1053",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Micheletveien",
    streetNumber: "25",
    postalCode: "1053",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Middelthuns gate",
    streetNumber: "25C",
    postalCode: "0368",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Midttunveien",
    streetNumber: "9",
    postalCode: "1177",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Midtåsen",
    streetNumber: "50B",
    postalCode: "1166",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Midtåsen",
    streetNumber: "11",
    postalCode: "1166",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Mikjelsbakken",
    streetNumber: "1A",
    postalCode: "0378",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Mina Beiteplukksvei",
    streetNumber: "149C",
    postalCode: "1272",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Minister Ditleffs vei",
    streetNumber: "5C",
    postalCode: "0862",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Minister Ditleffs vei",
    streetNumber: "21A",
    postalCode: "0862",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Mogata",
    streetNumber: "2B",
    postalCode: "0464",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Mogens Thorsens gate",
    streetNumber: "5",
    postalCode: "0264",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Moldegata",
    streetNumber: "43",
    postalCode: "0468",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Moltke Moes vei",
    streetNumber: "35",
    postalCode: "0851",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Montebellobakken",
    streetNumber: "3",
    postalCode: "0379",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Montebelloveien",
    streetNumber: "14B",
    postalCode: "0379",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Morells vei",
    streetNumber: "19",
    postalCode: "0487",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Morells vei",
    streetNumber: "5",
    postalCode: "0487",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Morgedalsvegen",
    streetNumber: "13c",
    postalCode: "0378",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Morgedalsvegen",
    streetNumber: "43",
    postalCode: "0378",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Mortensrudveien",
    streetNumber: "98",
    postalCode: "1283",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Mortensvingen",
    streetNumber: "12",
    postalCode: "0284",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Mortensvingen",
    streetNumber: "10",
    postalCode: "0284",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Mosseveien",
    streetNumber: "12A",
    postalCode: "0193",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Motzfeldts gate",
    streetNumber: "32A",
    postalCode: "0561",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Munkebekken",
    streetNumber: "133",
    postalCode: "1061",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Munkedamsveien",
    streetNumber: "53B",
    postalCode: "0250",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Munkedamsveien",
    streetNumber: "45A",
    postalCode: "0250",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Munkedamsveien",
    streetNumber: "74",
    postalCode: "0270",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Munkedamsveien",
    streetNumber: "53",
    postalCode: "0270",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Munkelia",
    streetNumber: "6",
    postalCode: "1163",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Munkengveien",
    streetNumber: "1",
    postalCode: "0376",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Munkerudkleiva",
    streetNumber: "6B",
    postalCode: "1164",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Munkerudkleiva",
    streetNumber: "22B",
    postalCode: "1164",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Munkerudveien",
    streetNumber: "75B",
    postalCode: "1165",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Munkerudveien",
    streetNumber: "69B",
    postalCode: "1165",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Munkerudveien",
    streetNumber: "18B",
    postalCode: "1163",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Munkerudveien",
    streetNumber: "66",
    postalCode: "1165",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Munkerudvollen",
    streetNumber: "9",
    postalCode: "1165",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Myrerskogveien",
    streetNumber: "43",
    postalCode: "0495",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Myrerskogveien",
    streetNumber: "36",
    postalCode: "0495",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Møllefaret",
    streetNumber: "44A",
    postalCode: "0750",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Møllefaret",
    streetNumber: "26D",
    postalCode: "0750",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Møllefaret",
    streetNumber: "44",
    postalCode: "0750",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Møllers vei",
    streetNumber: "23",
    postalCode: "1363",
    city: "Høvik",
    country: "Norge",
  },
  {
    streetName: "Møllerveien",
    streetNumber: "4",
    postalCode: "0182",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Måltrostveien",
    streetNumber: "2B",
    postalCode: "0786",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nadderudlia",
    streetNumber: "38",
    postalCode: "1357",
    city: "Bekkestua",
    country: "Norge",
  },
  {
    streetName: "Nadderudveien",
    streetNumber: "88A",
    postalCode: "1362",
    city: "Hosle",
    country: "Norge",
  },
  {
    streetName: "Nadderudveien",
    streetNumber: "66",
    postalCode: "1362",
    city: "Hosle",
    country: "Norge",
  },
  {
    streetName: "Nadderudåsen",
    streetNumber: "29",
    postalCode: "1357",
    city: "Bekkestua",
    country: "Norge",
  },
  {
    streetName: "Nannestadgata",
    streetNumber: "2",
    postalCode: "2000",
    city: "Lillestrøm",
    country: "Norge",
  },
  {
    streetName: "Nebbejordet",
    streetNumber: "57",
    postalCode: "1266",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nebbejordet",
    streetNumber: "13",
    postalCode: "1266",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nedre Frydendal",
    streetNumber: "68",
    postalCode: "1384",
    city: "Asker",
    country: "Norge",
  },
  {
    streetName: "Nedre Gullhaug",
    streetNumber: "17",
    postalCode: "1354",
    city: "Bærums Verk",
    country: "Norge",
  },
  {
    streetName: "Nedre Kalbakkvei",
    streetNumber: "12",
    postalCode: "0950",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nedre Silkestrå",
    streetNumber: "5",
    postalCode: "0375",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nedre Skogvei",
    streetNumber: "4N",
    postalCode: "0281",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nedre Skogvei",
    streetNumber: "4",
    postalCode: "0281",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nedre Skogvei",
    streetNumber: "3",
    postalCode: "0281",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nedre Skøyen vei",
    streetNumber: "27",
    postalCode: "0276",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nedre Skøyen vei",
    streetNumber: "6",
    postalCode: "0276",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nedre Slottsgate",
    streetNumber: "13",
    postalCode: "0157",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nedre Slottsgate",
    streetNumber: "5",
    postalCode: "0157",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nedre Ullevål",
    streetNumber: "2A",
    postalCode: "0850",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nedre Ullevål",
    streetNumber: "1A",
    postalCode: "0850",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nedre Ullevål",
    streetNumber: "1",
    postalCode: "0850",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nesbruveien",
    streetNumber: "40",
    postalCode: "1396",
    city: "Billingstad",
    country: "Norge",
  },
  {
    streetName: "Neuberggata",
    streetNumber: "10A",
    postalCode: "0367",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Neuberggata",
    streetNumber: "3",
    postalCode: "0367",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nico Hambros vei",
    streetNumber: "73",
    postalCode: "0969",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Niels Juels gate",
    streetNumber: "9B",
    postalCode: "0272",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Niels Juels gate",
    streetNumber: "66",
    postalCode: "0259",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Niels Juels gate",
    streetNumber: "49",
    postalCode: "0259",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Niels Juels gate",
    streetNumber: "44",
    postalCode: "0257",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Niels Juels gate",
    streetNumber: "20",
    postalCode: "0272",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Niels Juels gate",
    streetNumber: "12",
    postalCode: "0272",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nils Bays vei",
    streetNumber: "106",
    postalCode: "0855",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nils Lauritssøns vei",
    streetNumber: "39",
    postalCode: "0870",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nilserudkleiva",
    streetNumber: "30",
    postalCode: "0861",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nobels gate",
    streetNumber: "12A",
    postalCode: "0273",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nobels gate",
    streetNumber: "21",
    postalCode: "0268",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nobels gate",
    streetNumber: "21",
    postalCode: "0268",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nobels gate",
    streetNumber: "1",
    postalCode: "0273",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nordahl Bruns gate",
    streetNumber: "9A",
    postalCode: "0165",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nordahl Bruns gate",
    streetNumber: "17",
    postalCode: "0165",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nordahl Bruns gate",
    streetNumber: "8",
    postalCode: "0165",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nordalveien",
    streetNumber: "8",
    postalCode: "0580",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nordbergveien",
    streetNumber: "54",
    postalCode: "0875",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nordengveien",
    streetNumber: "55",
    postalCode: "0755",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Norderhovgata",
    streetNumber: "34B",
    postalCode: "0654",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Norderhovgata",
    streetNumber: "34",
    postalCode: "0654",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nordhagaveien",
    streetNumber: "1K",
    postalCode: "0491",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nordhagaveien",
    streetNumber: "2",
    postalCode: "0491",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nordlisletta",
    streetNumber: "13",
    postalCode: "0952",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nordliveien",
    streetNumber: "41",
    postalCode: "0571",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nordliveien",
    streetNumber: "12",
    postalCode: "0571",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nordliveien",
    streetNumber: "4",
    postalCode: "0571",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nordraaks gate",
    streetNumber: "3",
    postalCode: "0266",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nordseter Terrasse",
    streetNumber: "37",
    postalCode: "1163",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nordsetergrenda",
    streetNumber: "9B",
    postalCode: "1161",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nordstjerneveien",
    streetNumber: "15",
    postalCode: "0494",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nordstrandveien",
    streetNumber: "89A",
    postalCode: "1164",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nordstrandveien",
    streetNumber: "76C",
    postalCode: "1164",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nordstrandveien",
    streetNumber: "15B",
    postalCode: "1170",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nordstrandveien",
    streetNumber: "115B",
    postalCode: "1164",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nordstrandveien",
    streetNumber: "12",
    postalCode: "1170",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nordtvetveien",
    streetNumber: "42",
    postalCode: "0952",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Noreveien",
    streetNumber: "12",
    postalCode: "0379",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Norittveien",
    streetNumber: "28",
    postalCode: "1555",
    city: "Son",
    country: "Norge",
  },
  {
    streetName: "Normannsgata",
    streetNumber: "54",
    postalCode: "0655",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nybyggerveien",
    streetNumber: "28A",
    postalCode: "1084",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nydalen allé",
    streetNumber: "29",
    postalCode: "0484",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nydalen allé",
    streetNumber: "23",
    postalCode: "0484",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nydalsveien",
    streetNumber: "20a",
    postalCode: "0484",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nydalsveien",
    streetNumber: "14a",
    postalCode: "0484",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nydalsveien",
    streetNumber: "28",
    postalCode: "0484",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nydalsveien",
    streetNumber: "22",
    postalCode: "0484",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nydalsveien",
    streetNumber: "16",
    postalCode: "0484",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nydalsveien",
    streetNumber: "15",
    postalCode: "0484",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nyjordeveien",
    streetNumber: "8",
    postalCode: "1275",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nyjordstubben",
    streetNumber: "117",
    postalCode: "1275",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nyjordstubben",
    streetNumber: "73",
    postalCode: "1275",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nyjordstubben",
    streetNumber: "50",
    postalCode: "1275",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nyveien",
    streetNumber: "21A",
    postalCode: "1369",
    city: "Stabekk",
    country: "Norge",
  },
  {
    streetName: "Nåkkves vei",
    streetNumber: "5",
    postalCode: "0670",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nåkkves vei",
    streetNumber: "1",
    postalCode: "0670",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Oberst Angells vei",
    streetNumber: "12",
    postalCode: "0787",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Oberst Rodes vei",
    streetNumber: "66B",
    postalCode: "1165",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Oberst Rodes vei",
    streetNumber: "33B",
    postalCode: "1152",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Oberst Rodes vei",
    streetNumber: "19A",
    postalCode: "1152",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Oberst Rodes vei",
    streetNumber: "48",
    postalCode: "1152",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Oberst Rodes vei",
    streetNumber: "35",
    postalCode: "1152",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Observatoriegata",
    streetNumber: "17A",
    postalCode: "0254",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Odins gate",
    streetNumber: "17A",
    postalCode: "0266",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Odins gate",
    streetNumber: "14A",
    postalCode: "0266",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Odins gate",
    streetNumber: "11A",
    postalCode: "0266",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Odins gate",
    streetNumber: "34",
    postalCode: "0260",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Oksenøyveien",
    streetNumber: "41",
    postalCode: "1366",
    city: "Lysaker",
    country: "Norge",
  },
  {
    streetName: "Oksenøyveien",
    streetNumber: "10",
    postalCode: "1366",
    city: "Lysaker",
    country: "Norge",
  },
  {
    streetName: "Ola Narr",
    streetNumber: "20",
    postalCode: "0564",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ola Narr",
    streetNumber: "7",
    postalCode: "0563",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Olaf Bulls vei",
    streetNumber: "6D",
    postalCode: "0765",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Olaf Bulls vei",
    streetNumber: "44",
    postalCode: "0765",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Olaf Helsets vei",
    streetNumber: "5",
    postalCode: "0694",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Olaf Schous vei",
    streetNumber: "16",
    postalCode: "0572",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Olaf Schous vei",
    streetNumber: "3",
    postalCode: "0572",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Olasrudveien",
    streetNumber: "170",
    postalCode: "1284",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Olasrudveien",
    streetNumber: "41",
    postalCode: "1284",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Olav Aukrusts vei",
    streetNumber: "44D",
    postalCode: "0785",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Olav Aukrusts vei",
    streetNumber: "44",
    postalCode: "0785",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Olav Hegnas vei",
    streetNumber: "5A",
    postalCode: "0585",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Olav Kyrres gate",
    streetNumber: "13",
    postalCode: "0273",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Olav Kyrres gate",
    streetNumber: "8",
    postalCode: "0273",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Olav Nygards veg",
    streetNumber: "214",
    postalCode: "0688",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Olav Nygards veg",
    streetNumber: "116",
    postalCode: "0688",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ole Brumms vei",
    streetNumber: "28",
    postalCode: "0979",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ole Deviks vei",
    streetNumber: "16",
    postalCode: "0666",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ole Messelts vei",
    streetNumber: "5",
    postalCode: "0676",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ole Reistads vei",
    streetNumber: "39C",
    postalCode: "1068",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ole Vigs gate",
    streetNumber: "32A",
    postalCode: "0366",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Olleveien",
    streetNumber: "2C",
    postalCode: "1182",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Oppsaltoppen",
    streetNumber: "9",
    postalCode: "0687",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Oreliveien",
    streetNumber: "17A",
    postalCode: "0583",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Orreskogen",
    streetNumber: "38",
    postalCode: "0788",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Orreskogen",
    streetNumber: "20",
    postalCode: "0788",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Orreskogen",
    streetNumber: "14",
    postalCode: "0788",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Oscar Dahls vei",
    streetNumber: "18",
    postalCode: "1285",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Oscars gate",
    streetNumber: "8A",
    postalCode: "0352",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Oscars gate",
    streetNumber: "76B",
    postalCode: "0256",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Oscars gate",
    streetNumber: "6A",
    postalCode: "0352",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Oscars gate",
    streetNumber: "37A",
    postalCode: "0258",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Oscars gate",
    streetNumber: "1C",
    postalCode: "0352",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Oscars gate",
    streetNumber: "60",
    postalCode: "0256",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Oscars gate",
    streetNumber: "55",
    postalCode: "0258",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Oscars gate",
    streetNumber: "37",
    postalCode: "0258",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Oscars gate",
    streetNumber: "27",
    postalCode: "0352",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Oslo gate",
    streetNumber: "12",
    postalCode: "0192",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Oslo gate",
    streetNumber: "2",
    postalCode: "0192",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Oslo gate",
    streetNumber: "1",
    postalCode: "0192",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ostadalsveien",
    streetNumber: "24B",
    postalCode: "0753",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ostadalsveien",
    streetNumber: "75",
    postalCode: "0753",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ostadalsveien",
    streetNumber: "62",
    postalCode: "0753",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ostadalsveien",
    streetNumber: "54",
    postalCode: "0753",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ostadalsveien",
    streetNumber: "50",
    postalCode: "0753",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Osterhaus' gate",
    streetNumber: "8B",
    postalCode: "0183",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Osterhaus' gate",
    streetNumber: "6A",
    postalCode: "0183",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Osterhaus' gate",
    streetNumber: "16E",
    postalCode: "0183",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Otto Ruges vei",
    streetNumber: "23A",
    postalCode: "1361",
    city: "Østerås",
    country: "Norge",
  },
  {
    streetName: "Otto Ruges vei",
    streetNumber: "21B",
    postalCode: "1361",
    city: "Østerås",
    country: "Norge",
  },
  {
    streetName: "Otto Sogns vei",
    streetNumber: "27",
    postalCode: "0681",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Otto Sogns vei",
    streetNumber: "12",
    postalCode: "0681",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Otto Sogns vei",
    streetNumber: "2",
    postalCode: "0681",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ovenbakken",
    streetNumber: "16B",
    postalCode: "1361",
    city: "Østerås",
    country: "Norge",
  },
  {
    streetName: "P. T. Mallings vei",
    streetNumber: "26C",
    postalCode: "0286",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Paal Bergs vei",
    streetNumber: "43",
    postalCode: "0692",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Pareliusveien",
    streetNumber: "13C",
    postalCode: "1177",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Parkveien",
    streetNumber: "53B",
    postalCode: "0256",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Parkveien",
    streetNumber: "53A",
    postalCode: "0256",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Parkveien",
    streetNumber: "73",
    postalCode: "0254",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Parkveien",
    streetNumber: "64",
    postalCode: "0254",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Parkveien",
    streetNumber: "28",
    postalCode: "0350",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Parkveien",
    streetNumber: "25",
    postalCode: "0350",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Parkveien",
    streetNumber: "24",
    postalCode: "0350",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Pasoplia",
    streetNumber: "24",
    postalCode: "1279",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Pasopveien",
    streetNumber: "39",
    postalCode: "1279",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Pastor Fangens vei",
    streetNumber: "16",
    postalCode: "0854",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Pavels' gate",
    streetNumber: "1",
    postalCode: "0454",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Peder Ankers vei",
    streetNumber: "17",
    postalCode: "0861",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Peder Holters vei",
    streetNumber: "39C",
    postalCode: "1168",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Pelvikveien",
    streetNumber: "1D",
    postalCode: "1367",
    city: "Snarøya",
    country: "Norge",
  },
  {
    streetName: "Persbråtan",
    streetNumber: "13",
    postalCode: "0770",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Peter Møllers vei",
    streetNumber: "5A",
    postalCode: "0585",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Peter Møllers vei",
    streetNumber: "29",
    postalCode: "0585",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Peterhofveien",
    streetNumber: "31D",
    postalCode: "1369",
    city: "Stabekk",
    country: "Norge",
  },
  {
    streetName: "Peterhofveien",
    streetNumber: "31",
    postalCode: "1369",
    city: "Stabekk",
    country: "Norge",
  },
  {
    streetName: "Pilestredet",
    streetNumber: "65B",
    postalCode: "0350",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Pilestredet",
    streetNumber: "47A",
    postalCode: "0350",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Pilestredet",
    streetNumber: "29A",
    postalCode: "0166",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Pilestredet",
    streetNumber: "100B",
    postalCode: "0358",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Pilestredet",
    streetNumber: "57",
    postalCode: "0350",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Pilestredet",
    streetNumber: "56",
    postalCode: "0167",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Pilestredet",
    streetNumber: "35",
    postalCode: "0166",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Pilestredet Park",
    streetNumber: "39",
    postalCode: "0176",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Pilestredet Park",
    streetNumber: "38",
    postalCode: "0176",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Pilestredet Park",
    streetNumber: "27",
    postalCode: "0176",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Pilestredet Park",
    streetNumber: "19",
    postalCode: "0176",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Pilestredet Park",
    streetNumber: "18",
    postalCode: "0176",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Pilestredet Park",
    streetNumber: "14",
    postalCode: "0176",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Pilestredet Park",
    streetNumber: "5",
    postalCode: "0176",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Pilestredet Park",
    streetNumber: "2",
    postalCode: "0176",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Pionerstien",
    streetNumber: "10",
    postalCode: "1062",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Platous gate",
    streetNumber: "33",
    postalCode: "0190",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Platous gate",
    streetNumber: "33",
    postalCode: "0190",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Platous gate",
    streetNumber: "16",
    postalCode: "0190",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Platous gate",
    streetNumber: "9",
    postalCode: "0190",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Platous gate",
    streetNumber: "4",
    postalCode: "0190",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Platåveien",
    streetNumber: "11",
    postalCode: "1369",
    city: "Stabekk",
    country: "Norge",
  },
  {
    streetName: "Platåveien",
    streetNumber: "5",
    postalCode: "0489",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Platåveien",
    streetNumber: "1",
    postalCode: "0489",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Plogveien",
    streetNumber: "44B",
    postalCode: "0681",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Plogveien",
    streetNumber: "15",
    postalCode: "0679",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Plogveien",
    streetNumber: "6",
    postalCode: "0679",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Postdamveien",
    streetNumber: "11B",
    postalCode: "1164",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Postdamveien",
    streetNumber: "7",
    postalCode: "1164",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Presidentgata",
    streetNumber: "2",
    postalCode: "0474",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Prestegårdsveien",
    streetNumber: "5A",
    postalCode: "0851",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Prinsdalsfaret",
    streetNumber: "24",
    postalCode: "1262",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Prinsens gate",
    streetNumber: "10B",
    postalCode: "0152",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Prinsens gate",
    streetNumber: "22",
    postalCode: "0157",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Prinsens gate",
    streetNumber: "18",
    postalCode: "0152",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Prinsessealléen",
    streetNumber: "15",
    postalCode: "0276",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Priorveien",
    streetNumber: "4B",
    postalCode: "0377",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Priorveien",
    streetNumber: "10G",
    postalCode: "0377",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Professor Dahls gate",
    streetNumber: "5B",
    postalCode: "0355",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Professor Dahls gate",
    streetNumber: "21B",
    postalCode: "0353",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Professor Kohts Vei",
    streetNumber: "41",
    postalCode: "1368",
    city: "Lysaker",
    country: "Norge",
  },
  {
    streetName: "Professor Kohts Vei",
    streetNumber: "9",
    postalCode: "1366",
    city: "Lysaker",
    country: "Norge",
  },
  {
    streetName: "Professor Kohts Vei",
    streetNumber: "2",
    postalCode: "1366",
    city: "Lysaker",
    country: "Norge",
  },
  {
    streetName: "Protonveien",
    streetNumber: "4",
    postalCode: "0690",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rabbeveien",
    streetNumber: "21",
    postalCode: "0580",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Radarveien",
    streetNumber: "31",
    postalCode: "1152",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ragna Nielsens vei",
    streetNumber: "30A",
    postalCode: "0592",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ragna Nielsens vei",
    streetNumber: "5",
    postalCode: "0592",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ragnhild Schibbyes vei",
    streetNumber: "19",
    postalCode: "0968",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Raschs vei",
    streetNumber: "64B",
    postalCode: "1153",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Raschs vei",
    streetNumber: "2C",
    postalCode: "1178",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Raschs vei",
    streetNumber: "36",
    postalCode: "1178",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ravnkollbakken",
    streetNumber: "87",
    postalCode: "0971",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ravnkollbakken",
    streetNumber: "58",
    postalCode: "0971",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ravnkollbakken",
    streetNumber: "42",
    postalCode: "0971",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ravnkollbakken",
    streetNumber: "31",
    postalCode: "0971",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ravnkollbakken",
    streetNumber: "24",
    postalCode: "0971",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ravnkroken",
    streetNumber: "64C",
    postalCode: "1254",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ravnkroken",
    streetNumber: "18C",
    postalCode: "1254",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ravnåsveien",
    streetNumber: "17H",
    postalCode: "1254",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Refstad allé",
    streetNumber: "14",
    postalCode: "0586",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Refstadsvingen",
    streetNumber: "3",
    postalCode: "0589",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Refstadsvingen",
    streetNumber: "1",
    postalCode: "0589",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Refstadveien",
    streetNumber: "60",
    postalCode: "0586",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Refstadveien",
    streetNumber: "60",
    postalCode: "0589",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Refstadveien",
    streetNumber: "1",
    postalCode: "0589",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Regattaveien",
    streetNumber: "105",
    postalCode: "1364",
    city: "Fornebu",
    country: "Norge",
  },
  {
    streetName: "Regattaveien",
    streetNumber: "4",
    postalCode: "1364",
    city: "Fornebu",
    country: "Norge",
  },
  {
    streetName: "Revefaret",
    streetNumber: "3",
    postalCode: "0491",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ridderkleiva",
    streetNumber: "112",
    postalCode: "1386",
    city: "Asker",
    country: "Norge",
  },
  {
    streetName: "Riddervolds gate",
    streetNumber: "8",
    postalCode: "0258",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ringgata",
    streetNumber: "2E",
    postalCode: "0577",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ringnesveien",
    streetNumber: "23",
    postalCode: "0978",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ringshusstubben",
    streetNumber: "4B",
    postalCode: "1176",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ringshusveien",
    streetNumber: "24D",
    postalCode: "1176",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ringshusveien",
    streetNumber: "17",
    postalCode: "1176",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ringstabekkveien",
    streetNumber: "64A",
    postalCode: "1358",
    city: "Jar",
    country: "Norge",
  },
  {
    streetName: "Ringsveien",
    streetNumber: "6B",
    postalCode: "1368",
    city: "Stabekk",
    country: "Norge",
  },
  {
    streetName: "Risalléen",
    streetNumber: "27",
    postalCode: "0374",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Roald Amundsens gate",
    streetNumber: "43",
    postalCode: "2004",
    city: "Lillestrøm",
    country: "Norge",
  },
  {
    streetName: "Rolf Hofmos gate",
    streetNumber: "19",
    postalCode: "0655",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rolf Hofmos gate",
    streetNumber: "11",
    postalCode: "0655",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rolfsbuktalleen",
    streetNumber: "16",
    postalCode: "1364",
    city: "Fornebu",
    country: "Norge",
  },
  {
    streetName: "Rolfsbuktveien",
    streetNumber: "20",
    postalCode: "1364",
    city: "Fornebu",
    country: "Norge",
  },
  {
    streetName: "Romsås Senter",
    streetNumber: "3",
    postalCode: "0970",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rosenborggata",
    streetNumber: "19C",
    postalCode: "0356",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rosenborggata",
    streetNumber: "13A",
    postalCode: "0356",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rosendalsveien",
    streetNumber: "20",
    postalCode: "1166",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rosenhoffgata",
    streetNumber: "1B",
    postalCode: "0569",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rosenkrantz' gate",
    streetNumber: "2",
    postalCode: "0164",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rosenlundgata",
    streetNumber: "15",
    postalCode: "0474",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rostockgata",
    streetNumber: "24",
    postalCode: "0191",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Roveruds gate",
    streetNumber: "13",
    postalCode: "0481",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Roveruds gate",
    streetNumber: "12",
    postalCode: "0481",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Roveruds gate",
    streetNumber: "10",
    postalCode: "0481",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rubina Ranas gate",
    streetNumber: "7",
    postalCode: "0190",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rugdeberget",
    streetNumber: "65",
    postalCode: "1266",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rundtjernveien",
    streetNumber: "2C",
    postalCode: "0672",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rustadgrenda",
    streetNumber: "33",
    postalCode: "0693",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rustadgrenda",
    streetNumber: "13",
    postalCode: "0693",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rv162",
    streetNumber: "53",
    postalCode: "0270",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ryenbergveien",
    streetNumber: "58D",
    postalCode: "0196",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ryenstubben",
    streetNumber: "8A",
    postalCode: "0679",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ryensvingen",
    streetNumber: "3",
    postalCode: "0680",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Røahagan",
    streetNumber: "55A",
    postalCode: "0754",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Røahagan",
    streetNumber: "1C",
    postalCode: "0754",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Røahagan",
    streetNumber: "41",
    postalCode: "0754",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Røaveien",
    streetNumber: "3C",
    postalCode: "0752",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rødbergveien",
    streetNumber: "4B",
    postalCode: "0591",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rødbergveien",
    streetNumber: "3B",
    postalCode: "0591",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rødkleivfaret",
    streetNumber: "22",
    postalCode: "0788",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rødstuveien",
    streetNumber: "10",
    postalCode: "0572",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rødtvetveien",
    streetNumber: "69",
    postalCode: "0955",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rødtvetveien",
    streetNumber: "59",
    postalCode: "0955",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rødtvetveien",
    streetNumber: "33",
    postalCode: "0955",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rådhusgata",
    streetNumber: "32",
    postalCode: "0151",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rådhusgata",
    streetNumber: "27",
    postalCode: "0158",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rådhusveien",
    streetNumber: "4",
    postalCode: "1940",
    city: "Bjørkelangen",
    country: "Norge",
  },
  {
    streetName: "Rådyrfaret",
    streetNumber: "3",
    postalCode: "1362",
    city: "Hosle",
    country: "Norge",
  },
  {
    streetName: "Rådyrveien",
    streetNumber: "26B",
    postalCode: "0595",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sagadammen",
    streetNumber: "26",
    postalCode: "0884",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sagadammen",
    streetNumber: "20",
    postalCode: "0884",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sagadammen",
    streetNumber: "12",
    postalCode: "0884",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sagbruksveien",
    streetNumber: "23",
    postalCode: "1555",
    city: "Son",
    country: "Norge",
  },
  {
    streetName: "Sagveien",
    streetNumber: "16",
    postalCode: "0459",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sagveien",
    streetNumber: "11",
    postalCode: "0459",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sanatoriebakken",
    streetNumber: "16",
    postalCode: "1170",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sandakerveien",
    streetNumber: "74A",
    postalCode: "0484",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sandakerveien",
    streetNumber: "48A",
    postalCode: "0477",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sandakerveien",
    streetNumber: "38A",
    postalCode: "0473",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sandakerveien",
    streetNumber: "24C",
    postalCode: "0473",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sandakerveien",
    streetNumber: "22c",
    postalCode: "0473",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sandakerveien",
    streetNumber: "10N",
    postalCode: "0473",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sandakerveien",
    streetNumber: "10J",
    postalCode: "0473",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sandakerveien",
    streetNumber: "133",
    postalCode: "0484",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sandakerveien",
    streetNumber: "121",
    postalCode: "0484",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sandakerveien",
    streetNumber: "74",
    postalCode: "0483",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sandefjordgata",
    streetNumber: "4H",
    postalCode: "0464",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sandefjordgata",
    streetNumber: "3F",
    postalCode: "0464",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sandefjordgata",
    streetNumber: "4",
    postalCode: "0464",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sandstuveien",
    streetNumber: "48B",
    postalCode: "1184",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sandstuveien",
    streetNumber: "29",
    postalCode: "1184",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sankt Olavs Plass",
    streetNumber: "2",
    postalCode: "0165",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sannergata",
    streetNumber: "6E",
    postalCode: "0557",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sannergata",
    streetNumber: "6B",
    postalCode: "0557",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sannergata",
    streetNumber: "5A",
    postalCode: "0557",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sannergata",
    streetNumber: "32B",
    postalCode: "0557",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sannergata",
    streetNumber: "21A",
    postalCode: "0557",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sannergata",
    streetNumber: "15B",
    postalCode: "0557",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sarpsborggata",
    streetNumber: "3",
    postalCode: "0468",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sarpsborggata",
    streetNumber: "1",
    postalCode: "0468",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sars' gate",
    streetNumber: "2A",
    postalCode: "0562",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sars' gate",
    streetNumber: "60",
    postalCode: "0564",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Schleppegrells gate",
    streetNumber: "15A",
    postalCode: "0556",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Schouterrassen",
    streetNumber: "7",
    postalCode: "0573",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Schouterrassen",
    streetNumber: "3",
    postalCode: "0573",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Schultz' gate",
    streetNumber: "12C",
    postalCode: "0365",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Schultz' gate",
    streetNumber: "31",
    postalCode: "0365",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Schweigaards gate",
    streetNumber: "77A",
    postalCode: "0656",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Schweigaards gate",
    streetNumber: "61C",
    postalCode: "0656",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Schweigaards gate",
    streetNumber: "61A",
    postalCode: "0656",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Schweigaards gate",
    streetNumber: "53A",
    postalCode: "0191",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Schweigaards gate",
    streetNumber: "74",
    postalCode: "0656",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Schønings gate",
    streetNumber: "12",
    postalCode: "0362",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Seljeveien",
    streetNumber: "33",
    postalCode: "0575",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Seljeveien",
    streetNumber: "3",
    postalCode: "0575",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Selma Ellefsens Vei",
    streetNumber: "10",
    postalCode: "0581",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Selma Ellefsens Vei",
    streetNumber: "8",
    postalCode: "0581",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Selvbyggerveien",
    streetNumber: "139",
    postalCode: "0591",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Selvbyggerveien",
    streetNumber: "114",
    postalCode: "0591",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Selvbyggerveien",
    streetNumber: "60",
    postalCode: "0591",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Seterbråtveien",
    streetNumber: "137",
    postalCode: "1271",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Seterbråtveien",
    streetNumber: "97",
    postalCode: "1273",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Seterbråtveien",
    streetNumber: "13",
    postalCode: "1273",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Seterhøyveien",
    streetNumber: "25",
    postalCode: "1176",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sidsel Sidsærks vei",
    streetNumber: "5",
    postalCode: "0956",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sigurd Hoels vei",
    streetNumber: "108",
    postalCode: "0655",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sigurd Hoels vei",
    streetNumber: "100",
    postalCode: "0655",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sigurd Hoels vei",
    streetNumber: "63",
    postalCode: "0655",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sigurd Hoels vei",
    streetNumber: "17",
    postalCode: "0655",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sigurd Lies gate",
    streetNumber: "28",
    postalCode: "0479",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sigurd Lies gate",
    streetNumber: "24",
    postalCode: "0479",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sigyns gate",
    streetNumber: "12",
    postalCode: "0260",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sigyns gate",
    streetNumber: "2",
    postalCode: "0260",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sildreveien",
    streetNumber: "16",
    postalCode: "2007",
    city: "Kjeller",
    country: "Norge",
  },
  {
    streetName: "Silurveien",
    streetNumber: "44",
    postalCode: "0380",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Simensbråtveien",
    streetNumber: "27",
    postalCode: "1182",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sinsenterrassen",
    streetNumber: "16",
    postalCode: "0574",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sinsenterrassen",
    streetNumber: "9",
    postalCode: "0574",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sinsenveien",
    streetNumber: "84A",
    postalCode: "0586",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sinsenveien",
    streetNumber: "84A",
    postalCode: "0586",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sinsenveien",
    streetNumber: "5B",
    postalCode: "0572",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sinsenveien",
    streetNumber: "82",
    postalCode: "0586",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sinsenveien",
    streetNumber: "2",
    postalCode: "0572",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sjøgangen",
    streetNumber: "2",
    postalCode: "0252",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sjølyst plass",
    streetNumber: "3",
    postalCode: "0278",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sjølyst plass",
    streetNumber: "2",
    postalCode: "0278",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skabos vei",
    streetNumber: "4",
    postalCode: "0278",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skansen Terrasse",
    streetNumber: "17",
    postalCode: "1062",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skansen Terrasse",
    streetNumber: "15",
    postalCode: "1062",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skilleveien",
    streetNumber: "4C",
    postalCode: "2010",
    city: "Strømmen",
    country: "Norge",
  },
  {
    streetName: "Skippergata",
    streetNumber: "32",
    postalCode: "0154",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skippergata",
    streetNumber: "5",
    postalCode: "0151",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skjelderups gate",
    streetNumber: "16",
    postalCode: "0559",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skjellerudveien",
    streetNumber: "20",
    postalCode: "1440",
    city: "Drøbak",
    country: "Norge",
  },
  {
    streetName: "Skjellestadåsen",
    streetNumber: "24",
    postalCode: "1389",
    city: "Heggedal",
    country: "Norge",
  },
  {
    streetName: "Skjoldveien",
    streetNumber: "9B",
    postalCode: "0881",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skogbakken",
    streetNumber: "1",
    postalCode: "1177",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skogbrynet",
    streetNumber: "2B",
    postalCode: "0283",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skogbrynet",
    streetNumber: "2A",
    postalCode: "0283",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skogfaret",
    streetNumber: "25D",
    postalCode: "0382",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skogfaret",
    streetNumber: "3",
    postalCode: "0382",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skogholtveien",
    streetNumber: "14",
    postalCode: "1178",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skogryggveien",
    streetNumber: "17D",
    postalCode: "0781",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skogryggveien",
    streetNumber: "16",
    postalCode: "0781",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skogveien",
    streetNumber: "103",
    postalCode: "1369",
    city: "Stabekk",
    country: "Norge",
  },
  {
    streetName: "Skoleveien",
    streetNumber: "3",
    postalCode: "1178",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skovveien",
    streetNumber: "18B",
    postalCode: "0257",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skovveien",
    streetNumber: "49",
    postalCode: "0258",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skovveien",
    streetNumber: "27",
    postalCode: "0257",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skovveien",
    streetNumber: "26",
    postalCode: "0257",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skovveien",
    streetNumber: "7",
    postalCode: "0257",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skråninga",
    streetNumber: "1",
    postalCode: "0181",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skullerudbakken",
    streetNumber: "70",
    postalCode: "1189",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skullerudbakken",
    streetNumber: "68",
    postalCode: "1189",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skullerudbakken",
    streetNumber: "52",
    postalCode: "1189",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skullerudskogen",
    streetNumber: "13D",
    postalCode: "1188",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skullerudskogen",
    streetNumber: "9",
    postalCode: "1188",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skytten",
    streetNumber: "6",
    postalCode: "1348",
    city: "Rykkinn",
    country: "Norge",
  },
  {
    streetName: "Skytterveien",
    streetNumber: "16",
    postalCode: "1392",
    city: "Vettre",
    country: "Norge",
  },
  {
    streetName: "Skøyen Terrasse",
    streetNumber: "36",
    postalCode: "0276",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skøyen Terrasse",
    streetNumber: "2",
    postalCode: "0276",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skøyen Terrasse",
    streetNumber: "1",
    postalCode: "0276",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skøyenbakken",
    streetNumber: "14",
    postalCode: "0667",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skøyenbrynet",
    streetNumber: "1A",
    postalCode: "0671",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skøyensvingen",
    streetNumber: "2",
    postalCode: "0375",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skøyenveien",
    streetNumber: "73",
    postalCode: "0375",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skøyenveien",
    streetNumber: "31",
    postalCode: "0375",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skøyenåsveien",
    streetNumber: "31",
    postalCode: "0686",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skådalsveien",
    streetNumber: "5D",
    postalCode: "0781",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skådalsveien",
    streetNumber: "10C",
    postalCode: "0781",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skårer Terrasse",
    streetNumber: "18",
    postalCode: "1473",
    city: "Lørenskog",
    country: "Norge",
  },
  {
    streetName: "Slalåmveien",
    streetNumber: "7",
    postalCode: "0380",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sleiverudåsen",
    streetNumber: "22",
    postalCode: "1354",
    city: "Bærums Verk",
    country: "Norge",
  },
  {
    streetName: "Slemdalsveien",
    streetNumber: "72",
    postalCode: "0373",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Slemdalsveien",
    streetNumber: "65",
    postalCode: "0373",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Slemdalsvingen",
    streetNumber: "31B",
    postalCode: "0776",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sloreåsen",
    streetNumber: "34E",
    postalCode: "1257",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sloreåsen",
    streetNumber: "16B",
    postalCode: "1257",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sloreåsen",
    streetNumber: "14",
    postalCode: "1257",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Slottsplassen",
    streetNumber: "1",
    postalCode: "0010",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Smalgangen",
    streetNumber: "42B",
    postalCode: "0188",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Smalgangen",
    streetNumber: "42",
    postalCode: "0188",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Smalgangen",
    streetNumber: "28",
    postalCode: "0188",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Smalgangen",
    streetNumber: "15",
    postalCode: "0188",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Smalvollveien",
    streetNumber: "65",
    postalCode: "0667",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Smalvollveien",
    streetNumber: "56",
    postalCode: "0667",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Smedgata",
    streetNumber: "33A",
    postalCode: "0651",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Smedgata",
    streetNumber: "34",
    postalCode: "0651",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Smeltedigelen",
    streetNumber: "6",
    postalCode: "0195",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Smeltedigelen",
    streetNumber: "1",
    postalCode: "0195",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Smestadhagan",
    streetNumber: "5",
    postalCode: "0376",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Smestadhøgda",
    streetNumber: "25",
    postalCode: "2008",
    city: "Fjerdingby",
    country: "Norge",
  },
  {
    streetName: "Smestadveien",
    streetNumber: "3C",
    postalCode: "0376",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Smiestykket",
    streetNumber: "40",
    postalCode: "1344",
    city: "Haslum",
    country: "Norge",
  },
  {
    streetName: "Snarøyveien",
    streetNumber: "30L",
    postalCode: "1360",
    city: "Fornebu",
    country: "Norge",
  },
  {
    streetName: "Snarøyveien",
    streetNumber: "55",
    postalCode: "1364",
    city: "Snarøya",
    country: "Norge",
  },
  {
    streetName: "Snarøyveien",
    streetNumber: "42",
    postalCode: "1364",
    city: "Fornebu",
    country: "Norge",
  },
  {
    streetName: "Snarøyveien",
    streetNumber: "30",
    postalCode: "1364",
    city: "Fornebu",
    country: "Norge",
  },
  {
    streetName: "Snipemyrlia",
    streetNumber: "21",
    postalCode: "1273",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Snippen",
    streetNumber: "19F",
    postalCode: "0566",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Snippen",
    streetNumber: "8",
    postalCode: "0566",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sofienberggata",
    streetNumber: "7A",
    postalCode: "0551",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sofienberggata",
    streetNumber: "59D",
    postalCode: "0563",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sofienberggata",
    streetNumber: "3A",
    postalCode: "0551",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sofienberggata",
    streetNumber: "58",
    postalCode: "0563",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sofienberggata",
    streetNumber: "5",
    postalCode: "0551",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sofies gate",
    streetNumber: "6D",
    postalCode: "0170",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sofies gate",
    streetNumber: "6C",
    postalCode: "0170",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sofies gate",
    streetNumber: "6B",
    postalCode: "0170",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sofies gate",
    streetNumber: "84",
    postalCode: "0454",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sofies gate",
    streetNumber: "41",
    postalCode: "0168",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sofies gate",
    streetNumber: "35",
    postalCode: "0168",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sognsvannsveien",
    streetNumber: "49A",
    postalCode: "0372",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sognsvannsveien",
    streetNumber: "40B",
    postalCode: "0372",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sognsvannsveien",
    streetNumber: "20",
    postalCode: "0372",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sognsveien",
    streetNumber: "70A",
    postalCode: "0855",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sognsveien",
    streetNumber: "6B",
    postalCode: "0451",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sognsveien",
    streetNumber: "127A",
    postalCode: "0855",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sognsveien",
    streetNumber: "114D",
    postalCode: "0860",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sognsveien",
    streetNumber: "102L",
    postalCode: "0857",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sognsveien",
    streetNumber: "127",
    postalCode: "0855",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sognsveien",
    streetNumber: "84",
    postalCode: "0855",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sognsveien",
    streetNumber: "32",
    postalCode: "0851",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sognsveien",
    streetNumber: "20",
    postalCode: "0851",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sognsveien",
    streetNumber: "18",
    postalCode: "0851",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sogstiveien",
    streetNumber: "68",
    postalCode: "1440",
    city: "Drøbak",
    country: "Norge",
  },
  {
    streetName: "Solbakken allé",
    streetNumber: "7",
    postalCode: "1086",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Solbakkeveien",
    streetNumber: "13",
    postalCode: "0678",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Solbergliveien",
    streetNumber: "106B",
    postalCode: "0683",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Solbergliveien",
    streetNumber: "95",
    postalCode: "0683",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Solbergliveien",
    streetNumber: "78",
    postalCode: "0683",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Solbergveien",
    streetNumber: "33",
    postalCode: "1337",
    city: "Sandvika",
    country: "Norge",
  },
  {
    streetName: "Solgangsbrisen",
    streetNumber: "28",
    postalCode: "1364",
    city: "Fornebu",
    country: "Norge",
  },
  {
    streetName: "Solgangsbrisen",
    streetNumber: "3",
    postalCode: "1364",
    city: "Fornebu",
    country: "Norge",
  },
  {
    streetName: "Solgangsbrisen",
    streetNumber: "1",
    postalCode: "1360",
    city: "Fornebu",
    country: "Norge",
  },
  {
    streetName: "Solhaugveien",
    streetNumber: "71",
    postalCode: "1337",
    city: "Sandvika",
    country: "Norge",
  },
  {
    streetName: "Solhaugveien",
    streetNumber: "20",
    postalCode: "1337",
    city: "Sandvika",
    country: "Norge",
  },
  {
    streetName: "Solheimgata",
    streetNumber: "3",
    postalCode: "0267",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Solheimveien",
    streetNumber: "4",
    postalCode: "1473",
    city: "Lørenskog",
    country: "Norge",
  },
  {
    streetName: "Solliveien",
    streetNumber: "50",
    postalCode: "1366",
    city: "Lysaker",
    country: "Norge",
  },
  {
    streetName: "Solskinnskroken",
    streetNumber: "4",
    postalCode: "0375",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Solskinnsveien",
    streetNumber: "18",
    postalCode: "0376",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Solskinnsveien",
    streetNumber: "4",
    postalCode: "0376",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Solveien",
    streetNumber: "24B",
    postalCode: "1177",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Solveien",
    streetNumber: "124B",
    postalCode: "1170",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Solveien",
    streetNumber: "124A",
    postalCode: "1170",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Solveien",
    streetNumber: "109",
    postalCode: "1162",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sonja Henies plass",
    streetNumber: "3",
    postalCode: "0185",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sons gate",
    streetNumber: "3E",
    postalCode: "0654",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sophus Lies gate",
    streetNumber: "6B",
    postalCode: "0264",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sorgenfrigata",
    streetNumber: "39B",
    postalCode: "0365",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sorgenfrigata",
    streetNumber: "3",
    postalCode: "0367",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Spireaveien",
    streetNumber: "14c",
    postalCode: "0580",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Spireaveien",
    streetNumber: "12a",
    postalCode: "0580",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Spireaveien",
    streetNumber: "16",
    postalCode: "0580",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Spireaveien",
    streetNumber: "6",
    postalCode: "0580",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sponhoggveien",
    streetNumber: "39B",
    postalCode: "0284",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sporveisgata",
    streetNumber: "29",
    postalCode: "0354",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "St. Edmunds vei",
    streetNumber: "49",
    postalCode: "0280",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "St. Halvards gate",
    streetNumber: "57A",
    postalCode: "0657",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "St. Halvards gate",
    streetNumber: "31B",
    postalCode: "0192",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "St. Halvards gate",
    streetNumber: "24",
    postalCode: "0192",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "St. Halvards vei",
    streetNumber: "17B",
    postalCode: "1358",
    city: "Jar",
    country: "Norge",
  },
  {
    streetName: "St. Jørgens vei",
    streetNumber: "63",
    postalCode: "0662",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "St. Jørgens vei",
    streetNumber: "55",
    postalCode: "0662",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "St. Jørgens vei",
    streetNumber: "32",
    postalCode: "0662",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "St. Jørgens vei",
    streetNumber: "24",
    postalCode: "0662",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "St. Jørgens vei",
    streetNumber: "19",
    postalCode: "0662",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "St. Jørgens vei",
    streetNumber: "16",
    postalCode: "0662",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "St. Jørgens vei",
    streetNumber: "4",
    postalCode: "0662",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stalsberg Terrasse",
    streetNumber: "34",
    postalCode: "2010",
    city: "Strømmen",
    country: "Norge",
  },
  {
    streetName: "Stangåsveien",
    streetNumber: "22",
    postalCode: "1415",
    city: "Oppegård",
    country: "Norge",
  },
  {
    streetName: "Stanseveien",
    streetNumber: "2",
    postalCode: "0975",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Starveien",
    streetNumber: "26",
    postalCode: "1088",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stasjonsveien",
    streetNumber: "27B",
    postalCode: "0773",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stasjonsveien",
    streetNumber: "55",
    postalCode: "0771",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stasjonsveien",
    streetNumber: "54",
    postalCode: "0771",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stasjonsveien",
    streetNumber: "30",
    postalCode: "0772",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stasjonsveien",
    streetNumber: "11",
    postalCode: "0774",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Statsråd Mathiesens vei",
    streetNumber: "12",
    postalCode: "0598",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Statsråd Mathiesens vei",
    streetNumber: "5",
    postalCode: "0594",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stavangergata",
    streetNumber: "46B",
    postalCode: "0467",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stavangergata",
    streetNumber: "36",
    postalCode: "0467",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stavangergata",
    streetNumber: "25",
    postalCode: "0467",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Steenstrups gate",
    streetNumber: "9",
    postalCode: "0554",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Steffensens vei",
    streetNumber: "18",
    postalCode: "2020",
    city: "Skedsmokorset",
    country: "Norge",
  },
  {
    streetName: "Steinborgveien",
    streetNumber: "28",
    postalCode: "0678",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Steinhammerveien",
    streetNumber: "8",
    postalCode: "1177",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Steinliveien",
    streetNumber: "8",
    postalCode: "1185",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Steinliveien",
    streetNumber: "6",
    postalCode: "1185",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Steinspranget",
    streetNumber: "10",
    postalCode: "1156",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Steinspranget",
    streetNumber: "9",
    postalCode: "1156",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stenersgata",
    streetNumber: "20",
    postalCode: "0184",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stenersgata",
    streetNumber: "1",
    postalCode: "0050",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stensberggata",
    streetNumber: "21",
    postalCode: "0170",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stensgata",
    streetNumber: "4B",
    postalCode: "0358",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stensgata",
    streetNumber: "33A",
    postalCode: "0358",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stensgata",
    streetNumber: "40",
    postalCode: "0451",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stensgata",
    streetNumber: "24",
    postalCode: "0358",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stensgata",
    streetNumber: "18",
    postalCode: "0358",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stiftamtmann Kaas' vei",
    streetNumber: "3",
    postalCode: "0852",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stigenga",
    streetNumber: "70",
    postalCode: "0979",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stjerneblokkveien",
    streetNumber: "4",
    postalCode: "1083",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stjernemyrveien",
    streetNumber: "34",
    postalCode: "0673",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stjerneveien",
    streetNumber: "12B",
    postalCode: "0779",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stjerneveien",
    streetNumber: "23",
    postalCode: "0779",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stockholmgata",
    streetNumber: "14",
    postalCode: "0566",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Storgata",
    streetNumber: "87",
    postalCode: "0182",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Storgata",
    streetNumber: "65",
    postalCode: "0182",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Storgata",
    streetNumber: "1",
    postalCode: "0155",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stortingsgata",
    streetNumber: "14",
    postalCode: "0161",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Storåsveien",
    streetNumber: "4",
    postalCode: "1169",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stovner Senter",
    streetNumber: "3",
    postalCode: "0985",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stovner Senter",
    streetNumber: "1",
    postalCode: "0985",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stovnerlia",
    streetNumber: "25",
    postalCode: "0983",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stovnerlia",
    streetNumber: "3",
    postalCode: "0983",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stovnerveien",
    streetNumber: "2",
    postalCode: "0980",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stranden",
    streetNumber: "73",
    postalCode: "0250",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stranden",
    streetNumber: "3",
    postalCode: "0250",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Strandveien",
    streetNumber: "20",
    postalCode: "1366",
    city: "Lysaker",
    country: "Norge",
  },
  {
    streetName: "Strandveien",
    streetNumber: "18",
    postalCode: "1366",
    city: "Lysaker",
    country: "Norge",
  },
  {
    streetName: "Strandveien",
    streetNumber: "13",
    postalCode: "1366",
    city: "Lysaker",
    country: "Norge",
  },
  {
    streetName: "Strandveien",
    streetNumber: "4",
    postalCode: "1366",
    city: "Lysaker",
    country: "Norge",
  },
  {
    streetName: "Strømbråtenveien",
    streetNumber: "2A",
    postalCode: "1555",
    city: "Son",
    country: "Norge",
  },
  {
    streetName: "Strømsborgveien",
    streetNumber: "53",
    postalCode: "0287",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Strømstangveien",
    streetNumber: "10E",
    postalCode: "1367",
    city: "Snarøya",
    country: "Norge",
  },
  {
    streetName: "Strømsveien",
    streetNumber: "23F",
    postalCode: "0657",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Strømsveien",
    streetNumber: "266",
    postalCode: "0668",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Strømsveien",
    streetNumber: "245",
    postalCode: "0668",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Strømsveien",
    streetNumber: "51",
    postalCode: "0658",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Strømsveien",
    streetNumber: "17",
    postalCode: "0657",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sturlas vei",
    streetNumber: "12",
    postalCode: "0772",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stuttvegen",
    streetNumber: "4B",
    postalCode: "1166",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Støttumveien",
    streetNumber: "1B",
    postalCode: "1170",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stålfjæra",
    streetNumber: "18",
    postalCode: "0975",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stålverkskroken",
    streetNumber: "4",
    postalCode: "0661",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stålverkskroken",
    streetNumber: "3",
    postalCode: "0661",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Suhms gate",
    streetNumber: "3B",
    postalCode: "0362",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Suhms gate",
    streetNumber: "24",
    postalCode: "0362",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Suhms gate",
    streetNumber: "18",
    postalCode: "0362",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Svartdalsveien",
    streetNumber: "33D",
    postalCode: "0678",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Svartdalsveien",
    streetNumber: "52",
    postalCode: "0678",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Svarttrostveien",
    streetNumber: "23A",
    postalCode: "0788",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Svarttrostveien",
    streetNumber: "25",
    postalCode: "0788",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sveiva",
    streetNumber: "28",
    postalCode: "0988",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sverdrups gate",
    streetNumber: "23",
    postalCode: "0559",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sverres vei",
    streetNumber: "33",
    postalCode: "1473",
    city: "Lørenskog",
    country: "Norge",
  },
  {
    streetName: "Svingen",
    streetNumber: "3",
    postalCode: "0196",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Svingen",
    streetNumber: "3",
    postalCode: "0196",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Svingen Terrasse",
    streetNumber: "3",
    postalCode: "0756",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Svingen Terrasse",
    streetNumber: "2",
    postalCode: "0756",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Svoldergata",
    streetNumber: "8A",
    postalCode: "0271",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Svoldergata",
    streetNumber: "9",
    postalCode: "0271",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Symreveien",
    streetNumber: "7",
    postalCode: "1182",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sømveien",
    streetNumber: "16",
    postalCode: "0493",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sørbyhaugen",
    streetNumber: "9",
    postalCode: "0377",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Søren Jaabæks gate",
    streetNumber: "8F",
    postalCode: "0460",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sørengkaia",
    streetNumber: "169",
    postalCode: "0194",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sørengkaia",
    streetNumber: "153",
    postalCode: "0194",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sørengkaia",
    streetNumber: "137",
    postalCode: "0194",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sørengkaia",
    streetNumber: "131",
    postalCode: "0194",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sørengkaia",
    streetNumber: "129",
    postalCode: "0194",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sørengkaia",
    streetNumber: "118",
    postalCode: "0194",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sørengkaia",
    streetNumber: "39",
    postalCode: "0194",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sørengkaia",
    streetNumber: "21",
    postalCode: "0194",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sørkedalsveien",
    streetNumber: "9A",
    postalCode: "0369",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sørkedalsveien",
    streetNumber: "266F",
    postalCode: "0754",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sørkedalsveien",
    streetNumber: "11G",
    postalCode: "0369",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sørkedalsveien",
    streetNumber: "283",
    postalCode: "0754",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sørkedalsveien",
    streetNumber: "256",
    postalCode: "0754",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sørkedalsveien",
    streetNumber: "226",
    postalCode: "0754",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sørkedalsveien",
    streetNumber: "203",
    postalCode: "0754",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sørkedalsveien",
    streetNumber: "135",
    postalCode: "0378",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sørkedalsveien",
    streetNumber: "10",
    postalCode: "0369",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Søråsen",
    streetNumber: "3",
    postalCode: "1356",
    city: "Bekkestua",
    country: "Norge",
  },
  {
    streetName: "Tamburveien",
    streetNumber: "13F",
    postalCode: "0485",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tamburveien",
    streetNumber: "13",
    postalCode: "0485",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tangerudbakken",
    streetNumber: "4B",
    postalCode: "0982",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tangerudveien",
    streetNumber: "43A",
    postalCode: "0982",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tante Ulrikkes vei",
    streetNumber: "46A",
    postalCode: "0984",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tante Ulrikkes vei",
    streetNumber: "44B",
    postalCode: "0984",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tante Ulrikkes vei",
    streetNumber: "26B",
    postalCode: "0984",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tante Ulrikkes vei",
    streetNumber: "12A",
    postalCode: "0984",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Teglverksgata",
    streetNumber: "7",
    postalCode: "0553",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Telesvingen",
    streetNumber: "12",
    postalCode: "0378",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tennisveien",
    streetNumber: "10C",
    postalCode: "0777",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tennisveien",
    streetNumber: "69",
    postalCode: "0777",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Terrasseveien",
    streetNumber: "114",
    postalCode: "0682",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Terrasseveien",
    streetNumber: "17",
    postalCode: "1369",
    city: "Stabekk",
    country: "Norge",
  },
  {
    streetName: "Thereses gate",
    streetNumber: "51B",
    postalCode: "0354",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thereses gate",
    streetNumber: "47A",
    postalCode: "0354",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thereses gate",
    streetNumber: "26C",
    postalCode: "0168",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thereses gate",
    streetNumber: "16H",
    postalCode: "0452",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thereses gate",
    streetNumber: "42",
    postalCode: "0168",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thereses gate",
    streetNumber: "14",
    postalCode: "0452",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thomas Heftyes gate",
    streetNumber: "54B",
    postalCode: "0267",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thomas Heftyes gate",
    streetNumber: "42E",
    postalCode: "0264",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thomas Heftyes gate",
    streetNumber: "42D",
    postalCode: "0264",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thomas Heftyes gate",
    streetNumber: "37A",
    postalCode: "0264",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thomas Heftyes gate",
    streetNumber: "30",
    postalCode: "0264",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thomles gate",
    streetNumber: "4",
    postalCode: "0270",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thor Olsens gate",
    streetNumber: "10A",
    postalCode: "0177",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thor Olsens gate",
    streetNumber: "6",
    postalCode: "0180",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thorleif Haugs vei",
    streetNumber: "87",
    postalCode: "0791",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thorleif Haugs vei",
    streetNumber: "9",
    postalCode: "0791",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thorleif Haugs vei",
    streetNumber: "4",
    postalCode: "0791",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thorleifs allé",
    streetNumber: "8",
    postalCode: "0489",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thorn Dønhaugs vei",
    streetNumber: "70",
    postalCode: "1271",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thorvald Meyers gate",
    streetNumber: "79A",
    postalCode: "0552",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thorvald Meyers gate",
    streetNumber: "74B",
    postalCode: "0552",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thorvald Meyers gate",
    streetNumber: "30B",
    postalCode: "0555",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thorvald Meyers gate",
    streetNumber: "16A",
    postalCode: "0555",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thorvald Meyers gate",
    streetNumber: "12F",
    postalCode: "0555",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thorvald Meyers gate",
    streetNumber: "78",
    postalCode: "0550",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thorvald Meyers gate",
    streetNumber: "26",
    postalCode: "0555",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thorvald Meyers gate",
    streetNumber: "9",
    postalCode: "0555",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thorvald Meyers gate",
    streetNumber: "5",
    postalCode: "0555",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thurmanns gate",
    streetNumber: "12C",
    postalCode: "0461",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thygesons vei",
    streetNumber: "15",
    postalCode: "0667",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tidemands gate",
    streetNumber: "21",
    postalCode: "0260",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tidemands gate",
    streetNumber: "4",
    postalCode: "0266",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tingstuveien",
    streetNumber: "7D",
    postalCode: "0281",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tingstuveien",
    streetNumber: "7D",
    postalCode: "0281",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tingstuveien",
    streetNumber: "7B",
    postalCode: "0281",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tingstuveien",
    streetNumber: "8",
    postalCode: "0281",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tiriltunga",
    streetNumber: "134",
    postalCode: "1259",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tiurveien",
    streetNumber: "5",
    postalCode: "0852",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tjernfaret",
    streetNumber: "29",
    postalCode: "0956",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tjerngløtt",
    streetNumber: "8",
    postalCode: "2050",
    city: "Jessheim",
    country: "Norge",
  },
  {
    streetName: "Tjuvholmen allé",
    streetNumber: "3",
    postalCode: "0252",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Toftes Gate",
    streetNumber: "39A",
    postalCode: "0552",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Toftes Gate",
    streetNumber: "17",
    postalCode: "0556",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Toftes Gate",
    streetNumber: "2",
    postalCode: "0556",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tokerudberget",
    streetNumber: "25",
    postalCode: "0986",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tonsenveien",
    streetNumber: "33",
    postalCode: "0587",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Toppåsveien",
    streetNumber: "45A",
    postalCode: "1262",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Toppåsveien",
    streetNumber: "22A",
    postalCode: "1262",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Toppåsveien",
    streetNumber: "42",
    postalCode: "1262",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tor Jonssons veg",
    streetNumber: "5",
    postalCode: "0688",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tordenskiolds gate",
    streetNumber: "80",
    postalCode: "0160",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tordenskiolds gate",
    streetNumber: "2",
    postalCode: "0160",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tores vei",
    streetNumber: "7B",
    postalCode: "0687",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tores vei",
    streetNumber: "1B",
    postalCode: "0687",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Torggata",
    streetNumber: "11",
    postalCode: "0181",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Torggata",
    streetNumber: "6",
    postalCode: "0181",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Torggata",
    streetNumber: "5",
    postalCode: "0181",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Torggata",
    streetNumber: "4",
    postalCode: "0181",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Torggata",
    streetNumber: "2",
    postalCode: "0181",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tornsangerveien",
    streetNumber: "21",
    postalCode: "1360",
    city: "Fornebu",
    country: "Norge",
  },
  {
    streetName: "Torshovgata",
    streetNumber: "15B",
    postalCode: "0476",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Torshovgata",
    streetNumber: "15A",
    postalCode: "0476",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Torshovgata",
    streetNumber: "15",
    postalCode: "0476",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Torvbakkgata",
    streetNumber: "12",
    postalCode: "0550",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Traverveien",
    streetNumber: "38",
    postalCode: "0588",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Traverveien",
    streetNumber: "33",
    postalCode: "0588",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Trelastgata",
    streetNumber: "27",
    postalCode: "0191",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Trelastgata",
    streetNumber: "21",
    postalCode: "0191",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Treskeveien",
    streetNumber: "63",
    postalCode: "0681",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tromsøgata",
    streetNumber: "5B",
    postalCode: "0565",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tromsøgata",
    streetNumber: "3B",
    postalCode: "0565",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tromsøgata",
    streetNumber: "29",
    postalCode: "0565",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Trondheimsveien",
    streetNumber: "5D",
    postalCode: "0560",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Trondheimsveien",
    streetNumber: "2F",
    postalCode: "0560",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Trondheimsveien",
    streetNumber: "20A",
    postalCode: "0560",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Trondheimsveien",
    streetNumber: "235",
    postalCode: "0586",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Trondheimsveien",
    streetNumber: "189",
    postalCode: "0570",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Trondheimsveien",
    streetNumber: "155",
    postalCode: "0570",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Trondheimsveien",
    streetNumber: "135",
    postalCode: "0570",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Trondheimsveien",
    streetNumber: "79",
    postalCode: "0565",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Trondheimsveien",
    streetNumber: "47",
    postalCode: "0560",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Trondheimsveien",
    streetNumber: "27",
    postalCode: "0560",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Trondheimsveien",
    streetNumber: "16",
    postalCode: "0560",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Trosterudveien",
    streetNumber: "33G",
    postalCode: "0778",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Trosterudveien",
    streetNumber: "25C",
    postalCode: "0778",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Trosterudveien",
    streetNumber: "20B",
    postalCode: "0778",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Trosterudveien",
    streetNumber: "22",
    postalCode: "0778",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Trudvangveien",
    streetNumber: "1B",
    postalCode: "0363",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tråkka",
    streetNumber: "13",
    postalCode: "0774",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tråkka",
    streetNumber: "11",
    postalCode: "0774",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tråkka",
    streetNumber: "10",
    postalCode: "0774",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tulipanveien",
    streetNumber: "31",
    postalCode: "1475",
    city: "Finstadjordet",
    country: "Norge",
  },
  {
    streetName: "Tullatoppen",
    streetNumber: "56",
    postalCode: "2016",
    city: "Frogner",
    country: "Norge",
  },
  {
    streetName: "Tullins gate",
    streetNumber: "2",
    postalCode: "0166",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tunfaret",
    streetNumber: "6",
    postalCode: "0373",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Turbinveien",
    streetNumber: "24",
    postalCode: "0195",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Turbinveien",
    streetNumber: "16",
    postalCode: "0192",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Turbinveien",
    streetNumber: "8",
    postalCode: "0195",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Turbinveien",
    streetNumber: "1",
    postalCode: "0195",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tvetenveien",
    streetNumber: "267",
    postalCode: "0675",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tvetenveien",
    streetNumber: "253",
    postalCode: "0675",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tvetenveien",
    streetNumber: "150",
    postalCode: "0671",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tvetenveien",
    streetNumber: "19",
    postalCode: "0666",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tyslevveien",
    streetNumber: "52B",
    postalCode: "1163",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tønsberggata",
    streetNumber: "3",
    postalCode: "0464",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tøyengata",
    streetNumber: "40C",
    postalCode: "0578",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tøyengata",
    streetNumber: "35",
    postalCode: "0578",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tåsen allé",
    streetNumber: "13",
    postalCode: "0853",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tåsenveien",
    streetNumber: "65",
    postalCode: "0870",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tåsenveien",
    streetNumber: "43",
    postalCode: "0870",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tåsenveien",
    streetNumber: "12",
    postalCode: "0853",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tåsenveien",
    streetNumber: "2",
    postalCode: "0462",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Uelands gate",
    streetNumber: "61C",
    postalCode: "0460",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Uelands gate",
    streetNumber: "59F",
    postalCode: "0460",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Uelands gate",
    streetNumber: "77",
    postalCode: "0462",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Uelands gate",
    streetNumber: "73",
    postalCode: "0462",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Uelands gate",
    streetNumber: "50",
    postalCode: "0457",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ullern allé",
    streetNumber: "73",
    postalCode: "0381",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ullern allé",
    streetNumber: "40",
    postalCode: "0381",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ullern allé",
    streetNumber: "2",
    postalCode: "0381",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ullernchausseen",
    streetNumber: "13",
    postalCode: "0377",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ullernfaret",
    streetNumber: "7",
    postalCode: "0281",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ullernveien",
    streetNumber: "2E",
    postalCode: "0280",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ullernveien",
    streetNumber: "20",
    postalCode: "0280",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ullernveien",
    streetNumber: "15",
    postalCode: "0280",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ullerudskogen",
    streetNumber: "12",
    postalCode: "1440",
    city: "Drøbak",
    country: "Norge",
  },
  {
    streetName: "Ullevålsveien",
    streetNumber: "95B",
    postalCode: "0359",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ullevålsveien",
    streetNumber: "95A",
    postalCode: "0359",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ullevålsveien",
    streetNumber: "82D",
    postalCode: "0454",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ullevålsveien",
    streetNumber: "59A",
    postalCode: "0171",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ullevålsveien",
    streetNumber: "41",
    postalCode: "0171",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ulsrudfaret",
    streetNumber: "7",
    postalCode: "0687",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ulsrudveien",
    streetNumber: "10D",
    postalCode: "0690",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ulsrudveien",
    streetNumber: "37",
    postalCode: "0687",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ulsrudveien",
    streetNumber: "11",
    postalCode: "0690",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ulsrudveien",
    streetNumber: "10",
    postalCode: "0690",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Underhaugsveien",
    streetNumber: "9B",
    postalCode: "0354",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Underhaugsveien",
    streetNumber: "3B",
    postalCode: "0354",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Underhaugsveien",
    streetNumber: "29B",
    postalCode: "1358",
    city: "Jar",
    country: "Norge",
  },
  {
    streetName: "Uranienborg Terrasse",
    streetNumber: "9",
    postalCode: "0351",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Uranienborgveien",
    streetNumber: "12",
    postalCode: "0258",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Urtegata",
    streetNumber: "24b",
    postalCode: "0187",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Urtegata",
    streetNumber: "24",
    postalCode: "0187",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Utmarkveien",
    streetNumber: "1",
    postalCode: "0689",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Utsynsveien",
    streetNumber: "18",
    postalCode: "1176",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Valdresgata",
    streetNumber: "7",
    postalCode: "0557",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Valkyriegata",
    streetNumber: "19",
    postalCode: "0366",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Valkyriegata",
    streetNumber: "5",
    postalCode: "0366",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vallefaret",
    streetNumber: "16",
    postalCode: "0663",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vallefaret",
    streetNumber: "2",
    postalCode: "0663",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vallerveien",
    streetNumber: "57E",
    postalCode: "1344",
    city: "Haslum",
    country: "Norge",
  },
  {
    streetName: "Vardeheimveien",
    streetNumber: "4",
    postalCode: "1088",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vardeveien",
    streetNumber: "13J",
    postalCode: "1182",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vardøgata",
    streetNumber: "5B",
    postalCode: "0565",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Veitvetstubben",
    streetNumber: "6B",
    postalCode: "0596",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Veitvetsvingen",
    streetNumber: "3",
    postalCode: "0595",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Veitvetveien",
    streetNumber: "6D",
    postalCode: "0596",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Veitvetveien",
    streetNumber: "6B",
    postalCode: "0596",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Veitvetveien",
    streetNumber: "5A",
    postalCode: "0596",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Veitvetveien",
    streetNumber: "26",
    postalCode: "0596",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vekslerveien",
    streetNumber: "7",
    postalCode: "0585",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vekslerveien",
    streetNumber: "5",
    postalCode: "0580",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Veksthusfløtten",
    streetNumber: "26",
    postalCode: "0594",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Veksthusfløtten",
    streetNumber: "5",
    postalCode: "0594",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vendla",
    streetNumber: "46B",
    postalCode: "1397",
    city: "Nesøya",
    country: "Norge",
  },
  {
    streetName: "Verkseier Furulunds vei",
    streetNumber: "22",
    postalCode: "0668",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Verkstedveien",
    streetNumber: "3",
    postalCode: "0277",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Verkstedveien",
    streetNumber: "1",
    postalCode: "0277",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vestbrynet",
    streetNumber: "9",
    postalCode: "1176",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vestbysvingen",
    streetNumber: "7A",
    postalCode: "0976",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vestengsvingen",
    streetNumber: "14",
    postalCode: "1182",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vestengveien",
    streetNumber: "1B",
    postalCode: "1182",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vestengveien",
    streetNumber: "4",
    postalCode: "1182",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vestlisvingen",
    streetNumber: "19",
    postalCode: "0986",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vestlisvingen",
    streetNumber: "3",
    postalCode: "0986",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vestre Elvebakke",
    streetNumber: "3",
    postalCode: "0182",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vestveien",
    streetNumber: "48",
    postalCode: "0284",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vetlandsveien",
    streetNumber: "115",
    postalCode: "0686",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vettaliveien",
    streetNumber: "8",
    postalCode: "0781",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vevelstadåsen",
    streetNumber: "55",
    postalCode: "1405",
    city: "Langhus",
    country: "Norge",
  },
  {
    streetName: "Vibes gate",
    streetNumber: "31B",
    postalCode: "0356",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vibes gate",
    streetNumber: "12",
    postalCode: "0356",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Victoria Terrasse",
    streetNumber: "1",
    postalCode: "0251",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vidars gate",
    streetNumber: "7",
    postalCode: "0452",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Villaveien",
    streetNumber: "16",
    postalCode: "0371",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vingolfveien",
    streetNumber: "25",
    postalCode: "1170",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vitaminveien",
    streetNumber: "7",
    postalCode: "0485",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vogts gate",
    streetNumber: "32B",
    postalCode: "0474",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vogts gate",
    streetNumber: "54",
    postalCode: "0477",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Voksenkollveien",
    streetNumber: "11B",
    postalCode: "0791",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vollebekkveien",
    streetNumber: "1",
    postalCode: "0598",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vollsveien",
    streetNumber: "13H",
    postalCode: "1366",
    city: "Lysaker",
    country: "Norge",
  },
  {
    streetName: "Vollsveien",
    streetNumber: "116",
    postalCode: "1358",
    city: "Jar",
    country: "Norge",
  },
  {
    streetName: "Volvat Terrasse",
    streetNumber: "6",
    postalCode: "0369",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vossegata",
    streetNumber: "18B",
    postalCode: "0475",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vulkan",
    streetNumber: "10",
    postalCode: "0182",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vækerø Terrasse",
    streetNumber: "14B",
    postalCode: "0282",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vækerøveien",
    streetNumber: "90B",
    postalCode: "0383",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vækerøveien",
    streetNumber: "216C",
    postalCode: "0751",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vækerøveien",
    streetNumber: "162B",
    postalCode: "0751",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vækerøveien",
    streetNumber: "144C",
    postalCode: "0383",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vækerøveien",
    streetNumber: "126B",
    postalCode: "0383",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vækerøveien",
    streetNumber: "118D",
    postalCode: "0383",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vækerøveien",
    streetNumber: "121",
    postalCode: "0383",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vækerøveien",
    streetNumber: "120",
    postalCode: "0383",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vækerøåsen",
    streetNumber: "14A",
    postalCode: "0282",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vækerøåsen",
    streetNumber: "5",
    postalCode: "0282",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vøyensvingen",
    streetNumber: "21A",
    postalCode: "0458",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vøyensvingen",
    streetNumber: "14",
    postalCode: "0458",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vågebyveien",
    streetNumber: "17",
    postalCode: "0569",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Våronnveien",
    streetNumber: "10",
    postalCode: "0679",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Waldemar Thranes gate",
    streetNumber: "62D",
    postalCode: "0173",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Waldemar Thranes gate",
    streetNumber: "61",
    postalCode: "0173",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Waldemar Thranes gate",
    streetNumber: "17",
    postalCode: "0171",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Waldemar Thranes gate",
    streetNumber: "2",
    postalCode: "0171",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Waldemars Hage",
    streetNumber: "01-Jun",
    postalCode: "0175",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Waldemars Hage",
    streetNumber: "3",
    postalCode: "0175",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Waldemars Hage",
    streetNumber: "1",
    postalCode: "0175",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Welding Olsens vei",
    streetNumber: "17",
    postalCode: "0694",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Welding Olsens vei",
    streetNumber: "6",
    postalCode: "0694",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Welhavens gate",
    streetNumber: "12",
    postalCode: "0350",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Welhavens gate",
    streetNumber: "1",
    postalCode: "0166",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Wessels gate",
    streetNumber: "3B",
    postalCode: "0165",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Wessels gate",
    streetNumber: "3A",
    postalCode: "0165",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Wessels gate",
    streetNumber: "16",
    postalCode: "0165",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Westye Egebergs gate",
    streetNumber: "7C",
    postalCode: "0172",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Wetlesens vei",
    streetNumber: "45",
    postalCode: "0681",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Wilh. Wilhelmsens Vei",
    streetNumber: "89",
    postalCode: "1362",
    city: "Hosle",
    country: "Norge",
  },
  {
    streetName: "Wilh. Wilhelmsens Vei",
    streetNumber: "10",
    postalCode: "1362",
    city: "Hosle",
    country: "Norge",
  },
  {
    streetName: "Wilhelm Frøshaugs vei",
    streetNumber: "3",
    postalCode: "1410",
    city: "Kolbotn",
    country: "Norge",
  },
  {
    streetName: "Wilhelms gate",
    streetNumber: "5B",
    postalCode: "0168",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Wilhelms gate",
    streetNumber: "1",
    postalCode: "0168",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Wilses gate",
    streetNumber: "8A",
    postalCode: "0178",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Wilses vei",
    streetNumber: "1",
    postalCode: "1358",
    city: "Jar",
    country: "Norge",
  },
  {
    streetName: "Ymers vei",
    streetNumber: "23",
    postalCode: "0588",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Youngstorget",
    streetNumber: "2A",
    postalCode: "0181",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Youngstorget",
    streetNumber: "2",
    postalCode: "0181",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Zetlitz' gate",
    streetNumber: "1A",
    postalCode: "0165",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Økernveien",
    streetNumber: "121",
    postalCode: "0579",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Økernveien",
    streetNumber: "78",
    postalCode: "0575",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ørneveien",
    streetNumber: "3B",
    postalCode: "1357",
    city: "Bekkestua",
    country: "Norge",
  },
  {
    streetName: "Ørneveien",
    streetNumber: "1",
    postalCode: "1357",
    city: "Bekkestua",
    country: "Norge",
  },
  {
    streetName: "Østbyfaret",
    streetNumber: "19",
    postalCode: "0687",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Østensjøveien",
    streetNumber: "118",
    postalCode: "0682",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Østensjøveien",
    streetNumber: "79",
    postalCode: "0667",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Østensjøveien",
    streetNumber: "2",
    postalCode: "0661",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Østerli Terrasse",
    streetNumber: "21B",
    postalCode: "1185",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Østerli Terrasse",
    streetNumber: "15B",
    postalCode: "1185",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Østerlisvingen",
    streetNumber: "3",
    postalCode: "1153",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Østerlisvingen",
    streetNumber: "3",
    postalCode: "1153",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Østerliveien",
    streetNumber: "33D",
    postalCode: "1185",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Østerliveien",
    streetNumber: "32B",
    postalCode: "1185",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Østerliveien",
    streetNumber: "32A",
    postalCode: "1185",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Østerliveien",
    streetNumber: "33",
    postalCode: "1185",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Østerliveien",
    streetNumber: "29",
    postalCode: "1185",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Østhellinga",
    streetNumber: "48",
    postalCode: "1388",
    city: "Borgen",
    country: "Norge",
  },
  {
    streetName: "Østmarkveien",
    streetNumber: "27",
    postalCode: "0687",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Østre Aker vei",
    streetNumber: "264",
    postalCode: "0977",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Østre Aker vei",
    streetNumber: "203",
    postalCode: "0975",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Østre Kullerød",
    streetNumber: "5",
    postalCode: "3241",
    city: "Sandefjord",
    country: "Norge",
  },
  {
    streetName: "Øvre Gullhaug",
    streetNumber: "17",
    postalCode: "1354",
    city: "Bærums Verk",
    country: "Norge",
  },
  {
    streetName: "Øvre Langås vei",
    streetNumber: "5",
    postalCode: "0880",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Øvre Ljanskoll vei",
    streetNumber: "28B",
    postalCode: "1169",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Øvre Slottsgate",
    streetNumber: "2B",
    postalCode: "0157",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Øvre Slottsgate",
    streetNumber: "29",
    postalCode: "0157",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Øvre Slottsgate",
    streetNumber: "20",
    postalCode: "0157",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Øvre Slottsgate",
    streetNumber: "3",
    postalCode: "0157",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Øvre Smestadvei",
    streetNumber: "2",
    postalCode: "0378",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Øvre Ullern Terrasse",
    streetNumber: "5",
    postalCode: "0380",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Øygardveien",
    streetNumber: "75",
    postalCode: "1357",
    city: "Bekkestua",
    country: "Norge",
  },
  {
    streetName: "Åkebergveien",
    streetNumber: "28",
    postalCode: "0650",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ålesundgata",
    streetNumber: "3D",
    postalCode: "0470",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ålesundgata",
    streetNumber: "3B",
    postalCode: "0470",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Åmotveien",
    streetNumber: "55",
    postalCode: "0881",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ånnerudhagen",
    streetNumber: "12B",
    postalCode: "1383",
    city: "Asker",
    country: "Norge",
  },
  {
    streetName: "Ånnerudtoppen",
    streetNumber: "36A",
    postalCode: "1383",
    city: "Asker",
    country: "Norge",
  },
  {
    streetName: "Åsaveien",
    streetNumber: "3",
    postalCode: "0365",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Åsbråtstien",
    streetNumber: "35",
    postalCode: "1251",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Åsdalsveien",
    streetNumber: "22B",
    postalCode: "1166",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Åsdalsveien",
    streetNumber: "41",
    postalCode: "1166",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Åsdalsveien",
    streetNumber: "37",
    postalCode: "1166",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Åsengata",
    streetNumber: "6B",
    postalCode: "0480",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Åsengata",
    streetNumber: "4B",
    postalCode: "0480",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Åsenhagen",
    streetNumber: "27C",
    postalCode: "2020",
    city: "Skedsmokorset",
    country: "Norge",
  },
  {
    streetName: "Åsensvingen",
    streetNumber: "6C",
    postalCode: "0488",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Åsjordet",
    streetNumber: "1B",
    postalCode: "0382",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Åslandhellinga",
    streetNumber: "291",
    postalCode: "1274",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Åslandhellinga",
    streetNumber: "51",
    postalCode: "1274",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Åslandhellinga",
    streetNumber: "3",
    postalCode: "1274",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Åslandkroken",
    streetNumber: "22",
    postalCode: "1274",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Åslandkroken",
    streetNumber: "17",
    postalCode: "1274",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Åsliveien",
    streetNumber: "1F",
    postalCode: "1178",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Åsliveien",
    streetNumber: "7",
    postalCode: "1178",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Åsryggen",
    streetNumber: "16A",
    postalCode: "1166",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Åssiden Terrasse",
    streetNumber: "30A",
    postalCode: "1160",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Enerveien",
    streetNumber: "2d",
    postalCode: "1450",
    city: "Nesoddtangen",
    country: "Norway",
  },
  {
    streetName: "Vetlandsveien",
    streetNumber: " 82B",
    postalCode: "0685",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Dronningveien",
    streetNumber: "66",
    postalCode: "1550",
    city: "Hølen",
    country: "Norge",
  },
  {
    streetName: "Skøyenveien",
    streetNumber: "77",
    postalCode: "0375",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Soltoppveien",
    streetNumber: "1",
    postalCode: "1555",
    city: "Son",
    country: "Norge",
  },
  {
    streetName: "Vøyensvingen",
    streetNumber: "1A",
    postalCode: "0458",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Trygves vei",
    streetNumber: " 9b",
    postalCode: "1473",
    city: "Lørenskog",
    country: "Norway",
  },
  {
    streetName: "Fyrstikkbakken",
    streetNumber: " 7C",
    postalCode: "0667",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Tåsenveien",
    streetNumber: "77",
    postalCode: "0873",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Pettersløkka",
    streetNumber: "26e",
    postalCode: "3040",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Elgefaret",
    streetNumber: "61",
    postalCode: "1362",
    city: "Hosle",
    country: "Norway",
  },
  {
    streetName: "Skøyen Terrasse",
    streetNumber: " 23",
    postalCode: "0276",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Vennersborgveien",
    streetNumber: " 19",
    postalCode: "0281",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Måneveien",
    streetNumber: "44",
    postalCode: "1337",
    city: "Sandvika",
    country: "Norway",
  },
  {
    streetName: "Bydøy Allé",
    streetNumber: "127A (Leilighet H802)",
    postalCode: "0273",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Heggeliveien",
    streetNumber: "30 B",
    postalCode: "0375",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Gartnerveien",
    streetNumber: "3B",
    postalCode: "1356",
    city: "Bekkestua",
    country: "Norway",
  },
  {
    streetName: "Sogsti Terrasse",
    streetNumber: "3B",
    postalCode: "1446",
    city: "Drøbak",
    country: "Norge",
  },
  {
    streetName: "Tante Ulrikkes vei",
    streetNumber: " 31",
    postalCode: "0984",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Myraparkveien",
    streetNumber: "13 D",
    postalCode: "1415",
    city: "Oppegård",
    country: "Norway",
  },
  {
    streetName: "Fagerborggata",
    streetNumber: "6b",
    postalCode: "0360",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Akersbakken",
    streetNumber: "10",
    postalCode: "0172",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bråtasvingen",
    streetNumber: "58I",
    postalCode: "3425",
    city: "Reistad",
    country: "Norway",
  },
  {
    streetName: "Brenneveien",
    streetNumber: "28A",
    postalCode: "1339",
    city: "Vøyenenga",
    country: "Norway",
  },
  {
    streetName: "Doktor Narverudsvei",
    streetNumber: "33",
    postalCode: "3024",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Sørengkaia",
    streetNumber: "98",
    postalCode: "0194",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Storgata",
    streetNumber: "29",
    postalCode: "1440",
    city: "Drøbak",
    country: "Norway",
  },
  {
    streetName: "Lundliveien",
    streetNumber: "12",
    postalCode: "0584",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hans Nordahls gate",
    streetNumber: " 90",
    postalCode: "0485",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Henrik Ibsens gate",
    streetNumber: " 12",
    postalCode: "3022",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Innspurten",
    streetNumber: " 10A",
    postalCode: "0663",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Vålerenggata",
    streetNumber: "48B",
    postalCode: "0658",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Haakon Tveters vei",
    streetNumber: "43",
    postalCode: "0686",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Haakon Tveters vei",
    streetNumber: "43",
    postalCode: "0686",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Seterhøyveien",
    streetNumber: "7B",
    postalCode: "1176",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stenfeltskogen",
    streetNumber: "37",
    postalCode: "1405",
    city: "Langhus",
    country: "Norway",
  },
  {
    streetName: "Ostadalsveien",
    streetNumber: "68",
    postalCode: "0753",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Kalbakkveien",
    streetNumber: "11B",
    postalCode: "0953",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skogveien",
    streetNumber: "72G",
    postalCode: "1369",
    city: "Stabekk",
    country: "Norway",
  },
  {
    streetName: "Ekebergveien",
    streetNumber: "225",
    postalCode: "1162",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lørenveien",
    streetNumber: "57",
    postalCode: "0580",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bringebærhagen",
    streetNumber: "10",
    postalCode: "1440",
    city: "Drøbak",
    country: "Norway",
  },
  {
    streetName: "Christian Braunmansvei",
    streetNumber: " 11a",
    postalCode: "3043",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Hilton 152",
    streetNumber: " g",
    postalCode: "2040",
    city: "Kløfta",
    country: "Norway",
  },
  {
    streetName: "Margarethas vei",
    streetNumber: " 1",
    postalCode: "1473",
    city: "Lørenskog",
    country: "Norway",
  },
  {
    streetName: "Krokfaret",
    streetNumber: "10",
    postalCode: "1356",
    city: "Bekkestua",
    country: "Norway",
  },
  {
    streetName: "Bekkestuveien",
    streetNumber: "11B",
    postalCode: "1357",
    city: "Bekkestua",
    country: "Norway",
  },
  {
    streetName: "Korsgata",
    streetNumber: "30",
    postalCode: "0550",
    city: "Oslo",
    country: "NO",
  },
  {
    streetName: "Lyngveien",
    streetNumber: "20A",
    postalCode: "1430",
    city: "Ås",
    country: "Norge",
  },
  {
    streetName: "Oskar Braatens gate",
    streetNumber: " 31",
    postalCode: "0474",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Grønmoveien",
    streetNumber: " 1",
    postalCode: "1290",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sverdrupsgate",
    streetNumber: "9a",
    postalCode: "0559",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Austadveien 16",
    streetNumber: " A",
    postalCode: "3043",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Eikeleina",
    streetNumber: "36",
    postalCode: "3472",
    city: "Bødalen",
    country: "Norway",
  },
  {
    streetName: "Tjernsrudveien",
    streetNumber: " 20",
    postalCode: "1358",
    city: "Jar",
    country: "Norway",
  },
  {
    streetName: "Jordal Terrasse",
    streetNumber: "14",
    postalCode: "0658",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Badebakken",
    streetNumber: " 10",
    postalCode: "0467",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ullernveien",
    streetNumber: "22",
    postalCode: "0280",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Westye Egebergs gate",
    streetNumber: "4C",
    postalCode: "0177",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Regattaveien",
    streetNumber: "103",
    postalCode: "1364",
    city: "Fornebu",
    country: "Norway",
  },
  {
    streetName: "Vetlandsveien",
    streetNumber: "93B",
    postalCode: "0685",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Lensmann Jens Sørums vei ,",
    streetNumber: "16",
    postalCode: "2019",
    city: "Skedsmokorset",
    country: "Norway",
  },
  {
    streetName: "Lensmann Jens Sørums vei ,",
    streetNumber: "16",
    postalCode: "2019",
    city: "Skedsmokorset",
    country: "Norway",
  },
  {
    streetName: "Vardelia",
    streetNumber: " 20",
    postalCode: "2020",
    city: "Skedsmokorset",
    country: "Norway",
  },
  {
    streetName: "Lørenveien",
    streetNumber: "69",
    postalCode: "0585",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dyrlandsveien",
    streetNumber: "20",
    postalCode: "0875",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Freserveien",
    streetNumber: "21",
    postalCode: "0195",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Glostrupveien",
    streetNumber: "23",
    postalCode: "2013",
    city: "Skjetten",
    country: "Norway",
  },
  {
    streetName: "Vardelia",
    streetNumber: " 13",
    postalCode: "2020",
    city: "Skedsmokorset",
    country: "Norway",
  },
  {
    streetName: "Bekkelistubben",
    streetNumber: "13",
    postalCode: "0375",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Oppsalstubben",
    streetNumber: "7A",
    postalCode: "0685",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Almeveien",
    streetNumber: "17",
    postalCode: "1470",
    city: "Lørenskog",
    country: "Norway",
  },
  {
    streetName: "Marta Steinsviks vei 75",
    streetNumber: " H",
    postalCode: "1283",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hauketosvingen",
    streetNumber: "25",
    postalCode: "1266",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Lindemans gate",
    streetNumber: " 3a",
    postalCode: "0267",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Treschows gate",
    streetNumber: "23C",
    postalCode: "0470",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Furulundsveien",
    streetNumber: "7c",
    postalCode: "0282",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Enebakkveien",
    streetNumber: "196",
    postalCode: "0680",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Larsbråtveien",
    streetNumber: " 217",
    postalCode: "0674",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bernt Knudsens vei",
    streetNumber: "41G",
    postalCode: "1152",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brusagaveien",
    streetNumber: "53",
    postalCode: "1404",
    city: "Siggerud",
    country: "Norge",
  },
  {
    streetName: "Rydningen",
    streetNumber: "10",
    postalCode: "1435",
    city: "Ås",
    country: "Norway",
  },
  {
    streetName: "Bredsrudtoppen",
    streetNumber: " 108",
    postalCode: "1385",
    city: "Asker",
    country: "Norway",
  },
  {
    streetName: "Årvollveien",
    streetNumber: "58K",
    postalCode: "0590",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Vevelstadåsen",
    streetNumber: "1",
    postalCode: "1405",
    city: "Langhus",
    country: "Norway",
  },
  {
    streetName: "Haneborgenga",
    streetNumber: "9E",
    postalCode: "1440",
    city: "Drøbak",
    country: "Norge",
  },
  {
    streetName: "Midtoddveien",
    streetNumber: " 26b",
    postalCode: "0494",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Grønliveien",
    streetNumber: "1C",
    postalCode: "1413",
    city: "Tårnåsen",
    country: "Norge",
  },
  {
    streetName: "Teglverksgata",
    streetNumber: "9A, H0507",
    postalCode: "0553",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Maridalsveien",
    streetNumber: " 211A",
    postalCode: "0469",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Svendsemarka",
    streetNumber: " 4B",
    postalCode: "3409",
    city: "Tranby",
    country: "Norway",
  },
  {
    streetName: "Bjørkesvingen",
    streetNumber: " 6H",
    postalCode: "3408",
    city: "Tranby",
    country: "Norway",
  },
  {
    streetName: "Krags Terasse",
    streetNumber: "5",
    postalCode: "0783",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kirkeveien",
    streetNumber: " 2",
    postalCode: "1449",
    city: "Drøbak",
    country: "Norway",
  },
  {
    streetName: "Fernanda Nissens Gate",
    streetNumber: "2A",
    postalCode: "0484",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Oscars gate",
    streetNumber: "6A",
    postalCode: "0352",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bjølsengata",
    streetNumber: "17A",
    postalCode: "0468",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "karl Andersensvei",
    streetNumber: "99",
    postalCode: "1086",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bankveien",
    streetNumber: "19",
    postalCode: "1362",
    city: "Hosle",
    country: "Norway",
  },
  {
    streetName: "Lybekkveien",
    streetNumber: "48B",
    postalCode: "0770",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Olaf Bulls Vei",
    streetNumber: "36",
    postalCode: "0765",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Rådyrveien",
    streetNumber: "3A",
    postalCode: "0595",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sørbråtenveien",
    streetNumber: " 15",
    postalCode: "1449",
    city: "Drøbak",
    country: "Norway",
  },
  {
    streetName: "Risalléen",
    streetNumber: "23",
    postalCode: "0374",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vardeveien",
    streetNumber: "19",
    postalCode: "2020",
    city: "Skedsmokorset",
    country: "Norge",
  },
  {
    streetName: "Ugleveien",
    streetNumber: "9b",
    postalCode: "0853",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Grefsenveien",
    streetNumber: "44",
    postalCode: "0485",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sloreåsen 33",
    streetNumber: " C",
    postalCode: "1257",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Risalleen",
    streetNumber: "6",
    postalCode: "0374",
    city: "Oslo",
    country: "NO",
  },
  {
    streetName: "Schives gate",
    streetNumber: " 12a",
    postalCode: "0259",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Haskollveien 24",
    streetNumber: " F",
    postalCode: "3403",
    city: "Lier",
    country: "Norway",
  },
  {
    streetName: "Nøkleveien",
    streetNumber: "12",
    postalCode: "0689",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Munkerudvn.",
    streetNumber: "79c",
    postalCode: "1165",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bærumsveien",
    streetNumber: "156",
    postalCode: "1358",
    city: "Jar",
    country: "Norway",
  },
  {
    streetName: "Welhavens gate",
    streetNumber: " 12",
    postalCode: "0350",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Asakkollen",
    streetNumber: " 12",
    postalCode: "2015",
    city: "Leirsund",
    country: "Norway",
  },
  {
    streetName: "Mørtelverksbakken 13",
    streetNumber: "c/o 29",
    postalCode: "0580",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Theodor Hansens vei",
    streetNumber: " 18A",
    postalCode: "1410",
    city: "Kolbotn",
    country: "Norway",
  },
  {
    streetName: "Bjørnebærstien",
    streetNumber: " 18",
    postalCode: "1348",
    city: "Rykkinn",
    country: "Norway",
  },
  {
    streetName: "Welhavens gate",
    streetNumber: " 12",
    postalCode: "0350",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Martin Linges vei",
    streetNumber: "6",
    postalCode: "0692",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Fernanda Nissens Gate",
    streetNumber: "3A",
    postalCode: "0484",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Carl Kjelsens vei",
    streetNumber: "12 A",
    postalCode: "0860",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sørkedalsveien",
    streetNumber: "11F",
    postalCode: "0369",
    city: "Oslo",
    country: "NO",
  },
  {
    streetName: "Rolf Hofmos Gate 4,",
    streetNumber: "403",
    postalCode: "0655",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ekrekroken",
    streetNumber: "43",
    postalCode: "1356",
    city: "Bekkestua",
    country: "Norge",
  },
  {
    streetName: "Busoppveien",
    streetNumber: "40",
    postalCode: "1349",
    city: "Rykkinn",
    country: "Norway",
  },
  {
    streetName: "Robert Millares vei",
    streetNumber: "16E",
    postalCode: "1185",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Glenneveien",
    streetNumber: "38B",
    postalCode: "1481",
    city: "Hagan",
    country: "Norge",
  },
  {
    streetName: "Oscars gate",
    streetNumber: "14",
    postalCode: "0352",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vollebekkveien",
    streetNumber: " 4A",
    postalCode: "0598",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Amtmann Meinichs gate",
    streetNumber: "14A",
    postalCode: "0482",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jerikoveien 67",
    streetNumber: "c/o Oslo",
    postalCode: "1067",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Laueveien",
    streetNumber: "21",
    postalCode: "1481",
    city: "Hagan",
    country: "Norway",
  },
  {
    streetName: "Hans Tordsens gate",
    streetNumber: " 20",
    postalCode: "3040",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Frøyas gate",
    streetNumber: "1",
    postalCode: "0273",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sinsenveien",
    streetNumber: "3C",
    postalCode: "0572",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hovinveien",
    streetNumber: "37F",
    postalCode: "0576",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Lillogata",
    streetNumber: "5F",
    postalCode: "0484",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dalsbergstien",
    streetNumber: "22 E",
    postalCode: "0170",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Strandlia",
    streetNumber: "2",
    postalCode: "1454",
    city: "Fagerstrand",
    country: "Norge",
  },
  {
    streetName: "Enerhauggata",
    streetNumber: " 5",
    postalCode: "0651",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Fayes gate",
    streetNumber: "18",
    postalCode: "0455",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Etterstadkroken",
    streetNumber: "5E",
    postalCode: "0659",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Helga Vaneks vei",
    streetNumber: "5",
    postalCode: "1281",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fagerveien",
    streetNumber: "33",
    postalCode: "1454",
    city: "Fagerstrand",
    country: "Norge",
  },
  {
    streetName: "Kirkeveien",
    streetNumber: "48",
    postalCode: "0368",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Nordbyhagen",
    streetNumber: "8",
    postalCode: "1540",
    city: "Vestby",
    country: "Norge",
  },
  {
    streetName: "Måneveien",
    streetNumber: "28",
    postalCode: "1337",
    city: "Sandvika",
    country: "Norge",
  },
  {
    streetName: "Platous gate",
    streetNumber: " 31",
    postalCode: "0190",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Birch-Reichenwalds gate",
    streetNumber: "28",
    postalCode: "0483",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Søndre Rød",
    streetNumber: "6B",
    postalCode: "0752",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Kristins vei",
    streetNumber: " 32",
    postalCode: "0669",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Silurveien",
    streetNumber: "8A",
    postalCode: "0380",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lifaret",
    streetNumber: "16",
    postalCode: "1414",
    city: "Trollåsen",
    country: "Norway",
  },
  {
    streetName: "Gunnar Schjelderups vei",
    streetNumber: "33A",
    postalCode: "0485",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Solerunden",
    streetNumber: "30",
    postalCode: "1540",
    city: "Vestby",
    country: "Norway",
  },
  {
    streetName: "Grønland",
    streetNumber: " 50",
    postalCode: "3045",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Gabels gate",
    streetNumber: "41",
    postalCode: "0262",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Colletts Gate",
    streetNumber: "64",
    postalCode: "0460",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Søster Mathildes gate",
    streetNumber: " 42",
    postalCode: "1473",
    city: "Lørenskog",
    country: "Norway",
  },
  {
    streetName: "Løkendalen",
    streetNumber: "34",
    postalCode: "2019",
    city: "Skedsmokorset",
    country: "Norge",
  },
  {
    streetName: "Bernt Knudsens vei",
    streetNumber: "44E",
    postalCode: "1152",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sorgenfrigata",
    streetNumber: "19A",
    postalCode: "0365",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Woldbakken",
    streetNumber: "26B",
    postalCode: "1481",
    city: "Hagan",
    country: "Norway",
  },
  {
    streetName: "Colletts Gate",
    streetNumber: "63",
    postalCode: "0456",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Maridalsveien",
    streetNumber: "205",
    postalCode: "0469",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Storengveien",
    streetNumber: "92",
    postalCode: "1368",
    city: "Stabekk",
    country: "Norway",
  },
  {
    streetName: "Hellerudveien",
    streetNumber: "28B",
    postalCode: "0672",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sofie Roes Veg",
    streetNumber: "4",
    postalCode: "2040",
    city: "Kløfta",
    country: "Norge",
  },
  {
    streetName: "Fagerborggata",
    streetNumber: "6b",
    postalCode: "0360",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bølerlia",
    streetNumber: "61",
    postalCode: "0691",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Enebakkveien",
    streetNumber: "231C",
    postalCode: "1187",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "General Ruges vei",
    streetNumber: " 115",
    postalCode: "0694",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Myrveien",
    streetNumber: "39B",
    postalCode: "1406",
    city: "Ski",
    country: "Norge",
  },
  {
    streetName: "Åstadryggen",
    streetNumber: "10",
    postalCode: "1396",
    city: "Billingstad",
    country: "Norway",
  },
  {
    streetName: "Adolph Tidemands gate",
    streetNumber: " 49",
    postalCode: "2000",
    city: "Lillestrøm",
    country: "Norway",
  },
  {
    streetName: "Snarefjellet",
    streetNumber: "106",
    postalCode: "1481",
    city: "Hagan",
    country: "Norway",
  },
  {
    streetName: "Ekebergveien",
    streetNumber: "5C",
    postalCode: "0192",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Langretta",
    streetNumber: "12",
    postalCode: "1279",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Støperiveien",
    streetNumber: "2B",
    postalCode: "2010",
    city: "Strømmen",
    country: "Norway",
  },
  {
    streetName: "Hilton",
    streetNumber: "24A",
    postalCode: "2040",
    city: "Kløfta",
    country: "Norge",
  },
  {
    streetName: "Undelstadveien",
    streetNumber: "63",
    postalCode: "1387",
    city: "Asker",
    country: "Norway",
  },
  {
    streetName: "Kreklingfaret",
    streetNumber: "24",
    postalCode: "1555",
    city: "Son",
    country: "Norge",
  },
  {
    streetName: "Eugeniesgate",
    streetNumber: "23j",
    postalCode: "0168",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Undelstadveien",
    streetNumber: "88C",
    postalCode: "1387",
    city: "Asker",
    country: "Norway",
  },
  {
    streetName: "Fururabben",
    streetNumber: " 5",
    postalCode: "1412",
    city: "Sofiemyr",
    country: "Norway",
  },
  {
    streetName: "Professor Dahls gate",
    streetNumber: "16",
    postalCode: "0355",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Alundamveien",
    streetNumber: "42C",
    postalCode: "0957",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Etterstadsletta",
    streetNumber: "72",
    postalCode: "0659",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Skrysetveien",
    streetNumber: " 3",
    postalCode: "3440",
    city: "Røyken",
    country: "Norway",
  },
  {
    streetName: "Olaus Hansens vei",
    streetNumber: "56",
    postalCode: "1415",
    city: "Oppegård",
    country: "Norway",
  },
  {
    streetName: "Ole Brumms vei",
    streetNumber: "4",
    postalCode: "0979",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Baglerfaret",
    streetNumber: "8 b",
    postalCode: "0677",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Høgdaveien",
    streetNumber: "28A",
    postalCode: "0680",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Gjerdrumsgata",
    streetNumber: " 27",
    postalCode: "2000",
    city: "Lillestrøm",
    country: "Norway",
  },
  {
    streetName: "Konglestien",
    streetNumber: "46",
    postalCode: "1412",
    city: "Sofiemyr",
    country: "Norge",
  },
  {
    streetName: "Hesteløkka",
    streetNumber: "12",
    postalCode: "2069",
    city: "Jessheim",
    country: "Norge",
  },
  {
    streetName: "Lindemannvei",
    streetNumber: " 57",
    postalCode: "1453",
    city: "Bjørnemyr",
    country: "Norway",
  },
  {
    streetName: "Kobbervikhagen",
    streetNumber: " 2",
    postalCode: "3040",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Hagapynten",
    streetNumber: " 17",
    postalCode: "0673",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hukenbekken",
    streetNumber: "34 A",
    postalCode: "1383",
    city: "Asker",
    country: "Norway",
  },
  {
    streetName: "Ekebergveien",
    streetNumber: "225",
    postalCode: "1162",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dalsveien",
    streetNumber: "6B",
    postalCode: "1405",
    city: "Langhus",
    country: "Norge",
  },
  {
    streetName: "Hasleveien",
    streetNumber: " 45b",
    postalCode: "0575",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Frydenbergveien",
    streetNumber: "66B",
    postalCode: "1415",
    city: "Oppegård",
    country: "Norge",
  },
  {
    streetName: "Finn Thorsagers vei",
    streetNumber: " 11",
    postalCode: "1360",
    city: "Fornebu",
    country: "Norway",
  },
  {
    streetName: "Gamle Kjelsåsvei",
    streetNumber: "39",
    postalCode: "0492",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Finstad Hageby",
    streetNumber: "69",
    postalCode: "1423",
    city: "Ski",
    country: "Norge",
  },
  {
    streetName: "Magasinparken",
    streetNumber: "26",
    postalCode: "1423",
    city: "Ski",
    country: "Norge",
  },
  {
    streetName: "Kjølberggata",
    streetNumber: " 1D",
    postalCode: "0653",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Oskar Braatens gate",
    streetNumber: " 31",
    postalCode: "0474",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Finstad Hageby",
    streetNumber: "10",
    postalCode: "1423",
    city: "Ski",
    country: "Norge",
  },
  {
    streetName: "Christian Michelsens Gate H0402",
    streetNumber: "15A ",
    postalCode: "0568",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Grenseveien",
    streetNumber: "9B",
    postalCode: "0571",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Skiveien",
    streetNumber: "130A",
    postalCode: "1415",
    city: "Oppegård",
    country: "Norge",
  },
  {
    streetName: "Brantenborgveien",
    streetNumber: "16",
    postalCode: "0778",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Odalsveien",
    streetNumber: "18",
    postalCode: "3470",
    city: "Slemmestad",
    country: "Norway",
  },
  {
    streetName: "Gamle Strømsvei",
    streetNumber: "102 D",
    postalCode: "1061",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Svingen",
    streetNumber: "15",
    postalCode: "0196",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hekkveien",
    streetNumber: "11",
    postalCode: "0571",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Kanalveien 19",
    streetNumber: "c/o Kurt Lind",
    postalCode: "2004",
    city: "Lillestrøm",
    country: "Norge",
  },
  {
    streetName: "Sophus Aars Vei",
    streetNumber: "19 B",
    postalCode: "0588",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bølerskogen",
    streetNumber: "17",
    postalCode: "0691",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Nedre Skøyen vei 16, 0276 Oslo, no",
    streetNumber: "16",
    postalCode: "0276",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Tønsberggata",
    streetNumber: "4",
    postalCode: "0464",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jens Bjelkes gate",
    streetNumber: "61",
    postalCode: "0650",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jørnsebakken",
    streetNumber: "31",
    postalCode: "1440",
    city: "Drøbak",
    country: "Norge",
  },
  {
    streetName: "Nedre Berglia",
    streetNumber: " 39",
    postalCode: "1353",
    city: "Bærums verk",
    country: "Norway",
  },
  {
    streetName: "Filerveien",
    streetNumber: "2",
    postalCode: "0684",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Åsbråtstien",
    streetNumber: "26",
    postalCode: "1251",
    city: "Oslo",
    country: "NORWAY",
  },
  {
    streetName: "Tråkka",
    streetNumber: "22",
    postalCode: "0774",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grimelundsveien",
    streetNumber: "7G",
    postalCode: "0775",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Marstranderveien",
    streetNumber: "21",
    postalCode: "1369",
    city: "Stabekk",
    country: "Norway",
  },
  {
    streetName: "Heslebergtoppen",
    streetNumber: " 6a",
    postalCode: "1390",
    city: "Vollen",
    country: "Norway",
  },
  {
    streetName: "Liaveien",
    streetNumber: " 33",
    postalCode: "1435",
    city: "Ås",
    country: "Norway",
  },
  {
    streetName: "Kanonhallveien",
    streetNumber: " 36b",
    postalCode: "0585",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Eckersbergs Gate",
    streetNumber: "37",
    postalCode: "0266",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Stasjonsveien",
    streetNumber: " 23b",
    postalCode: "2010",
    city: "Strømmen",
    country: "Norway",
  },
  {
    streetName: "Sars' gate",
    streetNumber: "56",
    postalCode: "0564",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ormerudveien",
    streetNumber: " 57B",
    postalCode: "1410",
    city: "Kolbotn",
    country: "Norway",
  },
  {
    streetName: "Riisalleen",
    streetNumber: "41",
    postalCode: "2007",
    city: "Kjeller",
    country: "Norway",
  },
  {
    streetName: "Thornegata",
    streetNumber: " 59",
    postalCode: "3015",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Fritznersgate",
    streetNumber: "19",
    postalCode: "0264",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Åsaveien",
    streetNumber: "10",
    postalCode: "0362",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Munkerudveien",
    streetNumber: "61c",
    postalCode: "1165",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vevelstadåsen",
    streetNumber: "13",
    postalCode: "1405",
    city: "Langhus",
    country: "Norge",
  },
  {
    streetName: "Agmund Bolts vei",
    streetNumber: "44",
    postalCode: "0664",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Grinibråten",
    streetNumber: "50",
    postalCode: "1339",
    city: "Vøyenenga",
    country: "Norway",
  },
  {
    streetName: "Traverveien",
    streetNumber: " 27",
    postalCode: "0588",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ivan Bjørndals gate",
    streetNumber: "11 A",
    postalCode: "0472",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stenstrupsgate",
    streetNumber: "5",
    postalCode: "0554",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Elgtråkket 1",
    streetNumber: " A",
    postalCode: "2014",
    city: "Blystadlia",
    country: "Norway",
  },
  {
    streetName: "Micheletsvei",
    streetNumber: "18",
    postalCode: "1366",
    city: "Lysaker",
    country: "Norge",
  },
  {
    streetName: "Tollbugata 13",
    streetNumber: "c/o Postkasse 20",
    postalCode: "0152",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Suhmsgate",
    streetNumber: "18c",
    postalCode: "0362",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Eugeniesgate",
    streetNumber: "23j",
    postalCode: "0168",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Birger Olivers vei",
    streetNumber: "16A",
    postalCode: "1176",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Linstows gate",
    streetNumber: " 5",
    postalCode: "0166",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Jupiterveien",
    streetNumber: "19",
    postalCode: "0489",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Dronning Eufemias gate 65",
    streetNumber: "c/o Leilighet 706",
    postalCode: "0194",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Svoldergata",
    streetNumber: "8A",
    postalCode: "0271",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Enerhauggata",
    streetNumber: "5",
    postalCode: "0651",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Durudveien",
    streetNumber: "20A",
    postalCode: "1344",
    city: "Haslum",
    country: "Norway",
  },
  {
    streetName: "kampheimveien",
    streetNumber: "8",
    postalCode: "0685",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Havreveien",
    streetNumber: "33",
    postalCode: "0680",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Dybwads gate",
    streetNumber: "6",
    postalCode: "0367",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hemmestveitbakken",
    streetNumber: "8b",
    postalCode: "0378",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hamang Terrasse",
    streetNumber: " 41",
    postalCode: "1336",
    city: "Sandvika",
    country: "Norway",
  },
  {
    streetName: "Granfaret",
    streetNumber: " 27",
    postalCode: "1405",
    city: "Langhus",
    country: "Norway",
  },
  {
    streetName: "Ullern Gårds Vei",
    streetNumber: "5D",
    postalCode: "0382",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Kunnskapsveien",
    streetNumber: " 42T",
    postalCode: "3430",
    city: "Spikkestad",
    country: "Norway",
  },
  {
    streetName: "Nico Hambros vei",
    streetNumber: " 2",
    postalCode: "0969",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Stensgata",
    streetNumber: "36A",
    postalCode: "0358",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Risløkkveien",
    streetNumber: "35B",
    postalCode: "0580",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Lillogata",
    streetNumber: "5L",
    postalCode: "0484",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Gamle Drammensvei",
    streetNumber: "16A",
    postalCode: "1369",
    city: "Stabekk",
    country: "Norway",
  },
  {
    streetName: "Knud Schartums gate",
    streetNumber: "5A",
    postalCode: "3045",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Ulvenveien",
    streetNumber: " 123A",
    postalCode: "0665",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Løvenskiolds vei",
    streetNumber: "12 C",
    postalCode: "1358",
    city: "Jar",
    country: "Norge",
  },
  {
    streetName: "Øvre Ekeberglia",
    streetNumber: "40",
    postalCode: "3420",
    city: "Lierskogen",
    country: "Norge",
  },
  {
    streetName: "Refstadveien",
    streetNumber: " 18",
    postalCode: "0589",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bernhard Herres Vei",
    streetNumber: "22A",
    postalCode: "0376",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Øvre Myrhaugen",
    streetNumber: "9",
    postalCode: "0752",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fiolvegen",
    streetNumber: " 4A",
    postalCode: "2052",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Lerkeveien",
    streetNumber: " 30",
    postalCode: "2016",
    city: "Frogner",
    country: "Norway",
  },
  {
    streetName: "Christies gate 22 Oppg.",
    streetNumber: " B.",
    postalCode: "0557",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Buskerudveien",
    streetNumber: " 156",
    postalCode: "3027",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Lunneveien",
    streetNumber: "3",
    postalCode: "1479",
    city: "Kurland",
    country: "Norge",
  },
  {
    streetName: "Fernanda Nissens Gate",
    streetNumber: "3B",
    postalCode: "0484",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Langretta",
    streetNumber: "12",
    postalCode: "1279",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Blystadringen",
    streetNumber: " 6b",
    postalCode: "2014",
    city: "Blystadlia",
    country: "Norway",
  },
  {
    streetName: "Brodtkorbsgate",
    streetNumber: "7",
    postalCode: "1338",
    city: "Sandvika",
    country: "Norge",
  },
  {
    streetName: "Sorgenfrigata 20 A",
    streetNumber: "c/o Finn Ravndal",
    postalCode: "0365",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bakke Søndre",
    streetNumber: " 49",
    postalCode: "2040",
    city: "Kløfta",
    country: "Norway",
  },
  {
    streetName: "Sørengkaia",
    streetNumber: " 88",
    postalCode: "0194",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Aslaug Vaas veg 5e",
    streetNumber: "c/o Breakeven Holding AS",
    postalCode: "0766",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Østensjø Terrasse",
    streetNumber: "12",
    postalCode: "0686",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gildevangen",
    streetNumber: "2",
    postalCode: "0585",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hallermoveien",
    streetNumber: " 24",
    postalCode: "3030",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Åbyveien",
    streetNumber: " 14",
    postalCode: "3402",
    city: "Lier",
    country: "Norway",
  },
  {
    streetName: "Nitteberglia",
    streetNumber: " 1",
    postalCode: "2007",
    city: "Kjeller",
    country: "Norway",
  },
  {
    streetName: "Myrvegen",
    streetNumber: "66B",
    postalCode: "2052",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Spurvefaret",
    streetNumber: "11",
    postalCode: "1448",
    city: "Drøbak",
    country: "Norway",
  },
  {
    streetName: "Bjørliveien",
    streetNumber: "24",
    postalCode: "1542",
    city: "Vestby",
    country: "Norway",
  },
  {
    streetName: "Fredrikke Qvams gate 19",
    streetNumber: "c/o Aursand og Spangen",
    postalCode: "0172",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Åråsveien",
    streetNumber: " 54",
    postalCode: "2007",
    city: "Kjeller",
    country: "Norway",
  },
  {
    streetName: "Fürstlia",
    streetNumber: "17",
    postalCode: "1367",
    city: "Snarøya",
    country: "Norway",
  },
  {
    streetName: "Tokerudberget",
    streetNumber: "35",
    postalCode: "0986",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bjerkehageveien",
    streetNumber: "37",
    postalCode: "1405",
    city: "Langhus",
    country: "Norway",
  },
  {
    streetName: "Veslefrikkveien",
    streetNumber: "3",
    postalCode: "0851",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Rødskrubbgrenda",
    streetNumber: "27",
    postalCode: "1352",
    city: "Kolsås",
    country: "Norway",
  },
  {
    streetName: "Nygårdskogen",
    streetNumber: " 69",
    postalCode: "3409",
    city: "Tranby",
    country: "Norway",
  },
  {
    streetName: "Løvenskiolds Gate",
    streetNumber: "21",
    postalCode: "0260",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skippergata",
    streetNumber: " 44",
    postalCode: "3040",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Lensmann Solbergs vei",
    streetNumber: "1",
    postalCode: "3046",
    city: "Drammen",
    country: "Norge",
  },
  {
    streetName: "Gamle Åsvei",
    streetNumber: " 29",
    postalCode: "1424",
    city: "Ski",
    country: "Norway",
  },
  {
    streetName: "Hellerudsvingen",
    streetNumber: "18D",
    postalCode: "0684",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Berberissveien",
    streetNumber: "33C",
    postalCode: "3408",
    city: "Tranby",
    country: "Norge",
  },
  {
    streetName: "Hesteløkka",
    streetNumber: " 17",
    postalCode: "2069",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Jacob Aalls gate",
    streetNumber: "42A",
    postalCode: "0364",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Haugerudveien",
    streetNumber: " 74",
    postalCode: "0674",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Gesellsvingen",
    streetNumber: "25",
    postalCode: "1348",
    city: "Rykkinn",
    country: "Norway",
  },
  {
    streetName: "Strømsbergveien",
    streetNumber: " 10",
    postalCode: "1467",
    city: "Strømmen",
    country: "Norway",
  },
  {
    streetName: "Anchersens vei",
    streetNumber: " 70",
    postalCode: "3043",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Reichweins gate",
    streetNumber: "6A",
    postalCode: "0254",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sagdalsveien",
    streetNumber: "9C",
    postalCode: "2010",
    city: "Strømmen",
    country: "Norway",
  },
  {
    streetName: "Bertrand Narvesens vei",
    streetNumber: "4C",
    postalCode: "0661",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kristian Auberts vei",
    streetNumber: "32",
    postalCode: "0760",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Olaf Bulls vei",
    streetNumber: "82",
    postalCode: "0765",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hellinga,",
    streetNumber: "4e",
    postalCode: "1151",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Strømstangveien",
    streetNumber: "12B",
    postalCode: "1367",
    city: "Snarøya",
    country: "Norway",
  },
  {
    streetName: "Piggsoppveien,",
    streetNumber: "11i",
    postalCode: "2016",
    city: "Frogner",
    country: "Norway",
  },
  {
    streetName: "Grefsenkollveien",
    streetNumber: "12 A",
    postalCode: "0490",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Niels Juels gate",
    streetNumber: " 11A",
    postalCode: "0272",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Gyldenløvensgate",
    streetNumber: "13",
    postalCode: "0260",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Herman Wildenveys gate",
    streetNumber: " 51",
    postalCode: "3022",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Dalsbergstien",
    streetNumber: "10b",
    postalCode: "0170",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Fredrikke Qvams gate 19",
    streetNumber: "c/o Aursand og Spangen",
    postalCode: "0172",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Mortensvingen",
    streetNumber: " 12",
    postalCode: "0284",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hegdehaugsveien",
    streetNumber: "14C",
    postalCode: "0167",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Furuveien",
    streetNumber: " 17",
    postalCode: "3408",
    city: "Tranby",
    country: "Norway",
  },
  {
    streetName: "Rolf Hofmos gate",
    streetNumber: "2",
    postalCode: "0655",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Aslakveien",
    streetNumber: "28C",
    postalCode: "0753",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Peer Gynts vei",
    streetNumber: "37",
    postalCode: "1482",
    city: "Nittedal",
    country: "Norge",
  },
  {
    streetName: "Ljabruveien",
    streetNumber: "35",
    postalCode: "1169",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tokerudberget",
    streetNumber: "33",
    postalCode: "0986",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grønnegata",
    streetNumber: "10",
    postalCode: "0350",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Slemdalsvingen",
    streetNumber: "31C",
    postalCode: "0776",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Odvar Solbergs vei",
    streetNumber: "144",
    postalCode: "0973",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Høgåsveien",
    streetNumber: "15 B",
    postalCode: "1352",
    city: "Kolsås",
    country: "Norway",
  },
  {
    streetName: "K M Nordangersvei",
    streetNumber: "30",
    postalCode: "2003",
    city: "Lillestrøm",
    country: "NO",
  },
  {
    streetName: "Skogveien",
    streetNumber: "159B",
    postalCode: "1356",
    city: "Bekkestua",
    country: "Norway",
  },
  {
    streetName: "Lofsrudhøgda",
    streetNumber: "19E",
    postalCode: "1281",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Rosenkrantz vei",
    streetNumber: "3B",
    postalCode: "1397",
    city: "Nesøya",
    country: "Norge",
  },
  {
    streetName: "Marikloppa",
    streetNumber: "21",
    postalCode: "1458",
    city: "Fjellstrand",
    country: "Norway",
  },
  {
    streetName: "Skolebråtan",
    streetNumber: " 6",
    postalCode: "3031",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Kunnskapsveien",
    streetNumber: " 98b",
    postalCode: "3430",
    city: "Spikkestad",
    country: "Norway",
  },
  {
    streetName: "Mortensrudveien 40",
    streetNumber: "c/o Mubarika Tauqeer",
    postalCode: "1283",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rådhusgata",
    streetNumber: " 21",
    postalCode: "0158",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Kruttveien",
    streetNumber: "1B",
    postalCode: "0585",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Eikeleina",
    streetNumber: "27",
    postalCode: "3472",
    city: "Bødalen",
    country: "NO",
  },
  {
    streetName: "Ødegårdsveien",
    streetNumber: "4D",
    postalCode: "1545",
    city: "Hvitsten",
    country: "Norway",
  },
  {
    streetName: "Paal Bergsvei",
    streetNumber: "60",
    postalCode: "1348",
    city: "Rykkinn",
    country: "Norge",
  },
  {
    streetName: "Eilert Sundts gate",
    streetNumber: "50",
    postalCode: "0355",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "0305, Jerikoveien 1",
    streetNumber: " H",
    postalCode: "1067",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Lerdalsgata",
    streetNumber: "24A",
    postalCode: "1467",
    city: "Strømmen",
    country: "Norway",
  },
  {
    streetName: "Bergsliens gate",
    streetNumber: " 1C",
    postalCode: "0354",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Harald Sohlbergs Vei",
    streetNumber: "16",
    postalCode: "1064",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Refstadsvingen",
    streetNumber: " 2",
    postalCode: "0589",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Inkognitogata",
    streetNumber: "2B",
    postalCode: "0258",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bergensveien",
    streetNumber: "21c",
    postalCode: "0963",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Fougstads gate",
    streetNumber: "25B",
    postalCode: "0173",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Georg Frølichs vei",
    streetNumber: "36",
    postalCode: "1482",
    city: "Nittedal",
    country: "Norway",
  },
  {
    streetName: "Østerudvegen",
    streetNumber: " 40",
    postalCode: "2040",
    city: "Kløfta",
    country: "Norway",
  },
  {
    streetName: "Preståsen",
    streetNumber: "13",
    postalCode: "1365",
    city: "Blommenholm",
    country: "Norway",
  },
  {
    streetName: "Myrveien",
    streetNumber: "13B",
    postalCode: "1397",
    city: "Nesøya",
    country: "Norge",
  },
  {
    streetName: "Nedre Skøyen vei",
    streetNumber: "8",
    postalCode: "0276",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Økern torgvei",
    streetNumber: "9B",
    postalCode: "0580",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dalehaugen",
    streetNumber: " 4",
    postalCode: "0657",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Rognebærlia",
    streetNumber: " 36",
    postalCode: "1412",
    city: "Sofiemyr",
    country: "Norway",
  },
  {
    streetName: "Romeriksgata 56",
    streetNumber: " a",
    postalCode: "2003",
    city: "Lillestrøm",
    country: "Norway",
  },
  {
    streetName: "Rødgata",
    streetNumber: " 40",
    postalCode: "3048",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Vangkroken",
    streetNumber: "6b ",
    postalCode: "1351",
    city: "Rud",
    country: "Norway",
  },
  {
    streetName: "Sognsveien",
    streetNumber: " 102S",
    postalCode: "0857",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Stovnet senter",
    streetNumber: "24",
    postalCode: "0985",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kringkollen",
    streetNumber: "17C",
    postalCode: "0690",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kirkehaugsveien",
    streetNumber: "5G",
    postalCode: "0283",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Underhaugsveien",
    streetNumber: " 7B",
    postalCode: "0354",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ammerudhellinga",
    streetNumber: "72",
    postalCode: "0959",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Rosenhoffgata",
    streetNumber: "7 F",
    postalCode: "0569",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skullerudskogen",
    streetNumber: " 9",
    postalCode: "1188",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hansteens gate 20",
    streetNumber: "c/o U104 ap 57",
    postalCode: "0253",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Haarklous Plass 15",
    streetNumber: " B",
    postalCode: "0479",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Micheletveien",
    streetNumber: "38N",
    postalCode: "1053",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Guristuveien",
    streetNumber: "12",
    postalCode: "0690",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ekornkroken",
    streetNumber: "13",
    postalCode: "2052",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Trondheimsveien",
    streetNumber: " 143",
    postalCode: "0570",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hans Barliens gate",
    streetNumber: "1C",
    postalCode: "0568",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Prost Holms vei",
    streetNumber: " 125",
    postalCode: "2019",
    city: "Skedsmokorset",
    country: "Norway",
  },
  {
    streetName: "Schouterrassen",
    streetNumber: "18",
    postalCode: "0573",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Colletts gate",
    streetNumber: "63",
    postalCode: "0456",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ekebergveien",
    streetNumber: "292 E",
    postalCode: "1166",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Erlends vei",
    streetNumber: " 46",
    postalCode: "0669",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Schæffers gate",
    streetNumber: "4B-403",
    postalCode: "0558",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bjerkealleen",
    streetNumber: "35",
    postalCode: "1385",
    city: "Asker",
    country: "Norway",
  },
  {
    streetName: "Eiksveien",
    streetNumber: " 88A",
    postalCode: "1361",
    city: "Østerås",
    country: "Norway",
  },
  {
    streetName: "Vollenlia",
    streetNumber: "53",
    postalCode: "1390",
    city: "Vollen",
    country: "Norway",
  },
  {
    streetName: "Brokkenhusgrenda",
    streetNumber: " 6",
    postalCode: "1404",
    city: "Siggerud",
    country: "Norway",
  },
  {
    streetName: "Furukollen Terrasse",
    streetNumber: "8A",
    postalCode: "1450",
    city: "Nesoddtangen",
    country: "Norway",
  },
  {
    streetName: "Gustav Vigelands vei",
    streetNumber: "17",
    postalCode: "0274",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Fürstlia",
    streetNumber: " 9",
    postalCode: "1367",
    city: "Snarøya",
    country: "Norway",
  },
  {
    streetName: "Myrvollveien",
    streetNumber: " 9",
    postalCode: "1415",
    city: "Oppegård",
    country: "Norway",
  },
  {
    streetName: "Lievegen",
    streetNumber: " 7",
    postalCode: "2067",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Guldbrandslia",
    streetNumber: "17",
    postalCode: "1353",
    city: "Bærums verk",
    country: "Norway",
  },
  {
    streetName: "Nydalen allé",
    streetNumber: " 73",
    postalCode: "0485",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Frognerveien",
    streetNumber: " 9",
    postalCode: "0257",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Fredlihavna",
    streetNumber: " 21",
    postalCode: "3031",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Bygdøy Alle",
    streetNumber: "33",
    postalCode: "0262",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Kopperuds vei",
    streetNumber: "5d",
    postalCode: "0377",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Thereses gate",
    streetNumber: "35b",
    postalCode: "0354",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Larsbråtveien",
    streetNumber: " 217",
    postalCode: "0674",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Arnebråtveien",
    streetNumber: "23a",
    postalCode: "0376",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jomfrubråtveien",
    streetNumber: "79A",
    postalCode: "1179",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bertand Narvesenes vei",
    streetNumber: "13",
    postalCode: "0661",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rådmann Paulsens gate",
    streetNumber: "16",
    postalCode: "1476",
    city: "Rasta",
    country: "NO",
  },
  {
    streetName: "Olaf Bulls vei",
    streetNumber: " 1c",
    postalCode: "0765",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ullevålsveien 88",
    streetNumber: " c",
    postalCode: "0451",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Øvre Prinsdals vei",
    streetNumber: " 31",
    postalCode: "1266",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Blindernveien",
    streetNumber: "6b",
    postalCode: "0361",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fjellsveien",
    streetNumber: "145",
    postalCode: "3035",
    city: "Drammen",
    country: "Norge",
  },
  {
    streetName: "Tunfaret",
    streetNumber: "12",
    postalCode: "0373",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Thorvald Meyers gate",
    streetNumber: " 5",
    postalCode: "0555",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ringeriksveien",
    streetNumber: "113",
    postalCode: "3402",
    city: "Lier",
    country: "Norge",
  },
  {
    streetName: "Kurveien",
    streetNumber: "46/leil.231",
    postalCode: "0495",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nedre Berglia",
    streetNumber: "17",
    postalCode: "1353",
    city: "Bærums verk",
    country: "Norge",
  },
  {
    streetName: "Liljeveien",
    streetNumber: "11 H",
    postalCode: "0586",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Fartein Valens vei",
    streetNumber: " 37",
    postalCode: "1410",
    city: "Kolbotn",
    country: "Norway",
  },
  {
    streetName: "Stigenga",
    streetNumber: " 284",
    postalCode: "0979",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Selvbyggerveien",
    streetNumber: "59",
    postalCode: "0591",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Dr. Høst vei",
    streetNumber: "37",
    postalCode: "1346",
    city: "Gjettum",
    country: "Norway",
  },
  {
    streetName: "Jegerveien",
    streetNumber: "40",
    postalCode: "0777",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Årvollveien",
    streetNumber: " 60f",
    postalCode: "0590",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Finn Thorsagers Vei",
    streetNumber: "9",
    postalCode: "1366",
    city: "Lysaker",
    country: "Norway",
  },
  {
    streetName: "Tøyenbekken",
    streetNumber: " 6",
    postalCode: "0188",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Rathkes gate",
    streetNumber: " 17a",
    postalCode: "0558",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Engelia",
    streetNumber: "52",
    postalCode: "2019",
    city: "Skedsmokorset",
    country: "Norway",
  },
  {
    streetName: "Underhaugsveien",
    streetNumber: " 2",
    postalCode: "0354",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Lyseskrenten",
    streetNumber: " 1",
    postalCode: "0383",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Brånenveien",
    streetNumber: " 16",
    postalCode: "1454",
    city: "Fagerstrand",
    country: "Norway",
  },
  {
    streetName: "Mørtelverksbakken",
    streetNumber: " 9",
    postalCode: "0580",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Kongsskogen",
    streetNumber: "102",
    postalCode: "1385",
    city: "Asker",
    country: "Norway",
  },
  {
    streetName: "Dønskiveien",
    streetNumber: " 6b",
    postalCode: "1346",
    city: "Gjettum",
    country: "Norway",
  },
  {
    streetName: "Undelstadlia",
    streetNumber: " 8c",
    postalCode: "1387",
    city: "Asker",
    country: "Norway",
  },
  {
    streetName: "Villaveien",
    streetNumber: " 25",
    postalCode: "1385",
    city: "Asker",
    country: "Norway",
  },
  {
    streetName: "Nøsteveien",
    streetNumber: "18",
    postalCode: "3413",
    city: "Lier",
    country: "Norway",
  },
  {
    streetName: "Holmenveien,",
    streetNumber: "30",
    postalCode: "1555",
    city: "Son",
    country: "Norway",
  },
  {
    streetName: "Steinspranget",
    streetNumber: " 22",
    postalCode: "1156",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bredo Stabellsvei",
    streetNumber: "8 A",
    postalCode: "0853",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Odins vei",
    streetNumber: " 4d",
    postalCode: "1413",
    city: "Tårnåsen",
    country: "Norway",
  },
  {
    streetName: "Sloratoppen",
    streetNumber: "155",
    postalCode: "1405",
    city: "Langhus",
    country: "Norway",
  },
  {
    streetName: "Nyhuslia",
    streetNumber: " 7",
    postalCode: "1389",
    city: "Heggedal",
    country: "Norway",
  },
  {
    streetName: "Elgfaret",
    streetNumber: "41",
    postalCode: "2016",
    city: "Frogner",
    country: "Norway",
  },
  {
    streetName: "Bitehagen",
    streetNumber: " 12B",
    postalCode: "3440",
    city: "Røyken",
    country: "Norway",
  },
  {
    streetName: "Tangerudveien",
    streetNumber: "60G",
    postalCode: "0982",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lørenvangen",
    streetNumber: "42",
    postalCode: "0585",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Måkeveien",
    streetNumber: "8",
    postalCode: "0139",
    city: "Oslo",
    country: "NO",
  },
  {
    streetName: "Sagaveien",
    streetNumber: "2D",
    postalCode: "1430",
    city: "Ås",
    country: "Norge",
  },
  {
    streetName: "Niels Leuchs vei",
    streetNumber: "73",
    postalCode: "1359",
    city: "Eiksmarka",
    country: "Norway",
  },
  {
    streetName: "Størsrudveien",
    streetNumber: " 26",
    postalCode: "2016",
    city: "Frogner",
    country: "Norway",
  },
  {
    streetName: "Solsvingen",
    streetNumber: "28",
    postalCode: "3034",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Hafrsfjordgata",
    streetNumber: " 39A",
    postalCode: "0268",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Solsvingen",
    streetNumber: "28",
    postalCode: "3034",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Ammerudveien",
    streetNumber: "59D",
    postalCode: "0958",
    city: "Oslo",
    country: "NO",
  },
  {
    streetName: "Såvegen",
    streetNumber: " 11",
    postalCode: "2050",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Edvard Munchs vei",
    streetNumber: " 47",
    postalCode: "1063",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Solgangsbrisen",
    streetNumber: " 5",
    postalCode: "1364",
    city: "Fornebu",
    country: "Norway",
  },
  {
    streetName: "Bogstadveien",
    streetNumber: " 46B",
    postalCode: "0366",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Eikelivegen",
    streetNumber: " 11",
    postalCode: "2005",
    city: "Rælingen",
    country: "Norway",
  },
  {
    streetName: "Hans Nordahls gate",
    streetNumber: " 92",
    postalCode: "0485",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bjerkenesveien",
    streetNumber: " 14",
    postalCode: "1407",
    city: "Vinterbro",
    country: "Norway",
  },
  {
    streetName: "Nordre Skrenten",
    streetNumber: "1E",
    postalCode: "1413",
    city: "Tårnåsen",
    country: "NO",
  },
  {
    streetName: "Løkkeveien",
    streetNumber: "21A",
    postalCode: "1368",
    city: "Stabekk",
    country: "Norge",
  },
  {
    streetName: "Stensgata",
    streetNumber: "36A",
    postalCode: "0358",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Fornebuveien",
    streetNumber: " 10e",
    postalCode: "1366",
    city: "Lysaker",
    country: "Norway",
  },
  {
    streetName: "Slåbråtveien",
    streetNumber: "3",
    postalCode: "1415",
    city: "Oppegård",
    country: "Norge",
  },
  {
    streetName: "Holteløkka",
    streetNumber: "4C",
    postalCode: "2050",
    city: "Jessheim",
    country: "Norge",
  },
  {
    streetName: "Seljeveien",
    streetNumber: "11",
    postalCode: "0575",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Darres gate",
    streetNumber: " 10",
    postalCode: "0175",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Frysjaveien",
    streetNumber: " 17B",
    postalCode: "0883",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Strømstangveien",
    streetNumber: "9",
    postalCode: "1367",
    city: "Snarøya",
    country: "Norway",
  },
  {
    streetName: "Høgåsvien",
    streetNumber: "138",
    postalCode: "1259",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brettevilles gate",
    streetNumber: "24",
    postalCode: "0481",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kroerveien 82",
    streetNumber: " D",
    postalCode: "1435",
    city: "Ås",
    country: "Norway",
  },
  {
    streetName: "Drømkollveien",
    streetNumber: " 23",
    postalCode: "1550",
    city: "Hølen",
    country: "Norway",
  },
  {
    streetName: "gamle drammensvei",
    streetNumber: "40",
    postalCode: "1369",
    city: "Stabekk",
    country: "Norway",
  },
  {
    streetName: "Odins vei",
    streetNumber: "7e",
    postalCode: "1413",
    city: "Tårnåsen",
    country: "Norway",
  },
  {
    streetName: "Fernanda Nissens Gate",
    streetNumber: "10b",
    postalCode: "0484",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hauges vei",
    streetNumber: "32",
    postalCode: "0871",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nedre Solhellinga",
    streetNumber: "13B",
    postalCode: "2067",
    city: "Jessheim",
    country: "Norge",
  },
  {
    streetName: "Ribstonveien",
    streetNumber: "30",
    postalCode: "0586",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Oppsal Terrasse 2",
    streetNumber: " 4016",
    postalCode: "0686",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ivar Aasens Vei",
    streetNumber: "2A",
    postalCode: "0373",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Mauritz Hansens vei",
    streetNumber: " 58",
    postalCode: "2013",
    city: "Skjetten",
    country: "Norway",
  },
  {
    streetName: "Odins vei",
    streetNumber: "7B",
    postalCode: "1413",
    city: "Tårnåsen",
    country: "Norge",
  },
  {
    streetName: "Grågåsveien",
    streetNumber: " 9D",
    postalCode: "1187",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Munkerudveien",
    streetNumber: "18",
    postalCode: "1163",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Riddervoldsgate",
    streetNumber: "3",
    postalCode: "0258",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hesselbergs Gate",
    streetNumber: "15A",
    postalCode: "0555",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Flesåsveien",
    streetNumber: " 3A",
    postalCode: "0860",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Jordstjerneveien",
    streetNumber: "54",
    postalCode: "1283",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Høvikkollen",
    streetNumber: "10A",
    postalCode: "1363",
    city: "Høvik",
    country: "Norway",
  },
  {
    streetName: "Huitfeldts gate",
    streetNumber: " 3",
    postalCode: "0253",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Jerikoveien",
    streetNumber: "93a",
    postalCode: "1052",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Dr. Dedichens vei 26",
    streetNumber: " C",
    postalCode: "0675",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "maria dehlis vei",
    streetNumber: "23b",
    postalCode: "1084",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Benedikts vei",
    streetNumber: " 3B",
    postalCode: "1362",
    city: "Hosle",
    country: "Norway",
  },
  {
    streetName: "Huk Aveny",
    streetNumber: "13",
    postalCode: "0287",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Fredrikke Qvams Gate",
    streetNumber: "3D",
    postalCode: "0172",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hans Øverlands vei",
    streetNumber: "43B",
    postalCode: "1363",
    city: "Høvik",
    country: "NO",
  },
  {
    streetName: "Nordahl Bruns gate",
    streetNumber: "10B",
    postalCode: "0165",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kampebråten",
    streetNumber: "5a",
    postalCode: "1338",
    city: "Sandvika",
    country: "Norge",
  },
  {
    streetName: "Olav M. Troviks vei",
    streetNumber: " 34",
    postalCode: "0864",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sørkedalsveien",
    streetNumber: "148",
    postalCode: "0754",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hovseterveien",
    streetNumber: "42A",
    postalCode: "0768",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Trondheimsveien",
    streetNumber: " 18C",
    postalCode: "0560",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sjøskogenfaret",
    streetNumber: " 3B",
    postalCode: "1407",
    city: "Vinterbro",
    country: "Norway",
  },
  {
    streetName: "Rudbakken",
    streetNumber: "19",
    postalCode: "1480",
    city: "Slattum",
    country: "Norge",
  },
  {
    streetName: "Hemmestveitbakken",
    streetNumber: "11",
    postalCode: "0378",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Nedre Movei",
    streetNumber: " 10",
    postalCode: "1450",
    city: "Nesoddtangen",
    country: "Norway",
  },
  {
    streetName: "Einars Gate",
    streetNumber: "8",
    postalCode: "2004",
    city: "Lillestrøm",
    country: "Norway",
  },
  {
    streetName: "Trestakkveien",
    streetNumber: "8A",
    postalCode: "2016",
    city: "Frogner",
    country: "Norway",
  },
  {
    streetName: "Knut Alvssons vei",
    streetNumber: "21",
    postalCode: "0574",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Husebyveien",
    streetNumber: "7B",
    postalCode: "0379",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hovinveien",
    streetNumber: "39",
    postalCode: "0661",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ingebret Andersens vei",
    streetNumber: "3a",
    postalCode: "0955",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sveriges Gate",
    streetNumber: "17C",
    postalCode: "0658",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hans Nordahls gate",
    streetNumber: "68",
    postalCode: "0485",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Grimelundsveien",
    streetNumber: "4",
    postalCode: "0775",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bygdøy allé",
    streetNumber: "33",
    postalCode: "0262",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brusagaveien",
    streetNumber: "29",
    postalCode: "1404",
    city: "Siggerud",
    country: "Norway",
  },
  {
    streetName: "Kolderups vei 8",
    streetNumber: " A",
    postalCode: "0587",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Holumbakken",
    streetNumber: " 30",
    postalCode: "1481",
    city: "Hagan",
    country: "Norway",
  },
  {
    streetName: "Blåbærstien",
    streetNumber: "10e",
    postalCode: "1456",
    city: "Nesoddtangen",
    country: "Norge",
  },
  {
    streetName: "Rasmus Engers vei",
    streetNumber: "46A",
    postalCode: "0956",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "St. jørgens vei",
    streetNumber: "10",
    postalCode: "0662",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ottesvei",
    streetNumber: "14",
    postalCode: "1397",
    city: "Nesøya",
    country: "Norge",
  },
  {
    streetName: "Sponstubben",
    streetNumber: "22",
    postalCode: "1414",
    city: "Trollåsen",
    country: "Norway",
  },
  {
    streetName: "Karisveien",
    streetNumber: " 318",
    postalCode: "2013",
    city: "Skjetten",
    country: "Norway",
  },
  {
    streetName: "Nedre Skøyen Vei",
    streetNumber: "18a",
    postalCode: "0276",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Majorstuveien",
    streetNumber: " 36",
    postalCode: "0367",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Møllerstuveien",
    streetNumber: "10",
    postalCode: "1415",
    city: "Oppegård",
    country: "Norway",
  },
  {
    streetName: "Prestegårdsjordet",
    streetNumber: " 18",
    postalCode: "3440",
    city: "Røyken",
    country: "Norway",
  },
  {
    streetName: "Nordraaks vei",
    streetNumber: " 52B",
    postalCode: "1369",
    city: "Stabekk",
    country: "Norway",
  },
  {
    streetName: "Utsiktsvegen",
    streetNumber: "1",
    postalCode: "2040",
    city: "Kløfta",
    country: "Norge",
  },
  {
    streetName: "Prost Hallings vei 35",
    streetNumber: " A",
    postalCode: "0666",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sjøgangen",
    streetNumber: " 2",
    postalCode: "0252",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Holmlia senter vei",
    streetNumber: "2",
    postalCode: "1255",
    city: "Oslo",
    country: "NO",
  },
  {
    streetName: "Birgitte Hammers Vei",
    streetNumber: "28",
    postalCode: "1167",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Holumbakken",
    streetNumber: " 30",
    postalCode: "1481",
    city: "Hagan",
    country: "Norway",
  },
  {
    streetName: "Jacob Aalls gate",
    streetNumber: " 40A",
    postalCode: "0364",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sportsveien",
    streetNumber: " 8",
    postalCode: "0382",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Etterstadsletta",
    streetNumber: "110",
    postalCode: "0659",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Damliveien",
    streetNumber: " 6",
    postalCode: "0275",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Orrevegen",
    streetNumber: "10",
    postalCode: "2006",
    city: "Løvenstad",
    country: "Norge",
  },
  {
    streetName: "Grevlingåsen",
    streetNumber: "38",
    postalCode: "1386",
    city: "Asker",
    country: "Norge",
  },
  {
    streetName: "Biskop Jens Nilssoensgate",
    streetNumber: "19b",
    postalCode: "0659",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Eikeleina , Eikeleina",
    streetNumber: "36",
    postalCode: "3472",
    city: "Bødalen",
    country: "Norway",
  },
  {
    streetName: "Slyngveien",
    streetNumber: " 19b",
    postalCode: "0376",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Holmenveien,",
    streetNumber: "30",
    postalCode: "1555",
    city: "Son",
    country: "Norway",
  },
  {
    streetName: "Jordstjerneveien",
    streetNumber: "78",
    postalCode: "1283",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lavrans vei",
    streetNumber: "18",
    postalCode: "0670",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Elgtråkket",
    streetNumber: "78",
    postalCode: "1270",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Rolfstangveien",
    streetNumber: "36",
    postalCode: "1367",
    city: "Snarøya",
    country: "Norway",
  },
  {
    streetName: "Haukeveien",
    streetNumber: " 7a",
    postalCode: "1476",
    city: "Rasta",
    country: "Norway",
  },
  {
    streetName: "Odden",
    streetNumber: "12 B",
    postalCode: "1397",
    city: "Nesøya",
    country: "Norway",
  },
  {
    streetName: "Lørenveien",
    streetNumber: "59A",
    postalCode: "0585",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sandveien",
    streetNumber: " 5b",
    postalCode: "1389",
    city: "Heggedal",
    country: "Norway",
  },
  {
    streetName: "Glostrupveien",
    streetNumber: "29",
    postalCode: "2013",
    city: "Skjetten",
    country: "Norge",
  },
  {
    streetName: "Fiskekroken 12",
    streetNumber: " C",
    postalCode: "0139",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Røykås Terreasse",
    streetNumber: "74",
    postalCode: "1476",
    city: "Rasta",
    country: "Norway",
  },
  {
    streetName: "Åstadryggen",
    streetNumber: "10",
    postalCode: "1396",
    city: "Billingstad",
    country: "Norway",
  },
  {
    streetName: "Vogts gate",
    streetNumber: " 31a",
    postalCode: "0474",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Gamle Algarheimsveg",
    streetNumber: "83",
    postalCode: "2052",
    city: "Jessheim",
    country: "Norge",
  },
  {
    streetName: "Sørengkaia",
    streetNumber: " 66",
    postalCode: "0194",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Herman Foss Gate",
    streetNumber: "25",
    postalCode: "0171",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hoslebakken",
    streetNumber: "37",
    postalCode: "1362",
    city: "Hosle",
    country: "Norway",
  },
  {
    streetName: "Granatveien",
    streetNumber: " 12",
    postalCode: "1454",
    city: "Fagerstrand",
    country: "Norway",
  },
  {
    streetName: "Leangveien",
    streetNumber: "40B",
    postalCode: "1387",
    city: "Asker",
    country: "Norge",
  },
  {
    streetName: "Helga Vaneks vei",
    streetNumber: " 1C",
    postalCode: "1281",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bjerkebakken",
    streetNumber: "11",
    postalCode: "0757",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hellerudgrenda",
    streetNumber: "107 c",
    postalCode: "0672",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Schouterrassen",
    streetNumber: "19",
    postalCode: "0573",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Birkelundvegen",
    streetNumber: "3",
    postalCode: "2050",
    city: "Jessheim",
    country: "Norge",
  },
  {
    streetName: "Drengsrudhagen",
    streetNumber: "17",
    postalCode: "1385",
    city: "Asker",
    country: "Norge",
  },
  {
    streetName: "Raschs vei 45",
    streetNumber: " c",
    postalCode: "1153",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Parkveien",
    streetNumber: "64",
    postalCode: "0254",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kirkerudveien",
    streetNumber: "6",
    postalCode: "3470",
    city: "Slemmestad",
    country: "Norge",
  },
  {
    streetName: "Nils Collett Vogts vei",
    streetNumber: "66B",
    postalCode: "0766",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rolf Hofmos gate",
    streetNumber: "15",
    postalCode: "0655",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sørengkaia",
    streetNumber: "51",
    postalCode: "0194",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Smestadveien",
    streetNumber: "8F",
    postalCode: "0376",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kurveien",
    streetNumber: "15",
    postalCode: "0495",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Trondheimsveien",
    streetNumber: "13",
    postalCode: "0560",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Plutostien",
    streetNumber: "55",
    postalCode: "3033",
    city: "Drammen",
    country: "Norge",
  },
  {
    streetName: "Høybråtenveien",
    streetNumber: " 1B",
    postalCode: "1055",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Trondheimsveien",
    streetNumber: " 215A",
    postalCode: "2016",
    city: "Frogner",
    country: "Norway",
  },
  {
    streetName: "Eikeleina",
    streetNumber: "12",
    postalCode: "3472",
    city: "Bødalen",
    country: "Norway",
  },
  {
    streetName: "Sandbekkfaret",
    streetNumber: "54",
    postalCode: "1479",
    city: "Kurland",
    country: "Norway",
  },
  {
    streetName: "Olav Kyrres Gate",
    streetNumber: "6E",
    postalCode: "0273",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Korsnebbveien",
    streetNumber: "17",
    postalCode: "1440",
    city: "Drøbak",
    country: "Norge",
  },
  {
    streetName: "Øygardveien",
    streetNumber: "34",
    postalCode: "1357",
    city: "Bekkestua",
    country: "Norge",
  },
  {
    streetName: "Sportsveien",
    streetNumber: " 8",
    postalCode: "0382",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Freserveien",
    streetNumber: "43",
    postalCode: "0195",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Syd-fossum",
    streetNumber: "53",
    postalCode: "1359",
    city: "Eiksmarka",
    country: "Norge",
  },
  {
    streetName: "Morells vei",
    streetNumber: "16",
    postalCode: "0487",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Leirfivelveien",
    streetNumber: "57",
    postalCode: "1346",
    city: "Gjettum",
    country: "Norway",
  },
  {
    streetName: "Harald Halvorsens vei",
    streetNumber: " 26a",
    postalCode: "0666",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Dalslia",
    streetNumber: "26",
    postalCode: "1394",
    city: "Nesbru",
    country: "Norway",
  },
  {
    streetName: "Slyngveien",
    streetNumber: "26",
    postalCode: "0376",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rolfsbuktalleen",
    streetNumber: "12",
    postalCode: "1364",
    city: "Fornebu",
    country: "Norge",
  },
  {
    streetName: "Hagelundveien",
    streetNumber: "5",
    postalCode: "0963",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Mørtelverksbakken",
    streetNumber: "17",
    postalCode: "0580",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kristoffer Robins Vei",
    streetNumber: "48",
    postalCode: "0978",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Blindernveien 6b",
    streetNumber: "c/o Leilighet 801",
    postalCode: "0361",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Heggeliveien",
    streetNumber: "37A",
    postalCode: "0375",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Øierudbakken",
    streetNumber: "3",
    postalCode: "1440",
    city: "Drøbak",
    country: "Norway",
  },
  {
    streetName: "Nadderudveien",
    streetNumber: "113",
    postalCode: "1359",
    city: "Eiksmarka",
    country: "Norge",
  },
  {
    streetName: "Odden",
    streetNumber: "12 B",
    postalCode: "1397",
    city: "Nesøya",
    country: "Norway",
  },
  {
    streetName: "Dronningveien",
    streetNumber: "66",
    postalCode: "1550",
    city: "Hølen",
    country: "Norge",
  },
  {
    streetName: "Maarveien",
    streetNumber: " 11",
    postalCode: "1346",
    city: "Gjettum",
    country: "Norway",
  },
  {
    streetName: "Suhmsgate",
    streetNumber: "3b",
    postalCode: "0362",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Iver Olsens vei",
    streetNumber: " 4",
    postalCode: "0488",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Måltrostveien",
    streetNumber: " 5b",
    postalCode: "0786",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Kringsjåveien",
    streetNumber: "8",
    postalCode: "1470",
    city: "Lørenskog",
    country: "Norge",
  },
  {
    streetName: "Rugdeberget",
    streetNumber: "12",
    postalCode: "1266",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Strandvegen",
    streetNumber: "47",
    postalCode: "2005",
    city: "Rælingen",
    country: "Norway",
  },
  {
    streetName: "Josefines gate",
    streetNumber: "3a",
    postalCode: "0351",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sidesporet",
    streetNumber: "6",
    postalCode: "2010",
    city: "Strømmen",
    country: "Norge",
  },
  {
    streetName: "Seterhøyveien",
    streetNumber: "18B",
    postalCode: "1176",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sognsvannsveien",
    streetNumber: "26E",
    postalCode: "0372",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Linnesstranda",
    streetNumber: "58",
    postalCode: "3426",
    city: "Gullaug",
    country: "Norway",
  },
  {
    streetName: "Skuiveien",
    streetNumber: "135",
    postalCode: "1339",
    city: "Vøyenenga",
    country: "Norge",
  },
  {
    streetName: "Furukollen Terrasse",
    streetNumber: "8A",
    postalCode: "1450",
    city: "Nesoddtangen",
    country: "Norway",
  },
  {
    streetName: "Høyboveien",
    streetNumber: "12B",
    postalCode: "0198",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Parkveien",
    streetNumber: "71",
    postalCode: "0254",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fernanda Nissens gate",
    streetNumber: " 3E",
    postalCode: "0484",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Myrveien",
    streetNumber: "39B",
    postalCode: "1406",
    city: "Ski",
    country: "Norge",
  },
  {
    streetName: "Sørengkaia",
    streetNumber: "51",
    postalCode: "0194",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sognsvannsveien",
    streetNumber: "26E",
    postalCode: "0372",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Amtmann Meinichs gate",
    streetNumber: " 20c",
    postalCode: "0482",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Svalestien",
    streetNumber: " 49",
    postalCode: "1407",
    city: "Vinterbro",
    country: "Norway",
  },
  {
    streetName: "Tåsen Terrasse",
    streetNumber: "13a",
    postalCode: "0873",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Eikholtveien",
    streetNumber: " 6",
    postalCode: "3031",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Johan Svendsens gate",
    streetNumber: " 11",
    postalCode: "0475",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Grønland",
    streetNumber: "54",
    postalCode: "3045",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Åsveien",
    streetNumber: "30",
    postalCode: "1369",
    city: "Stabekk",
    country: "Norge",
  },
  {
    streetName: "Håkons gate 18A",
    streetNumber: "c/o 3. ETASJE, DØR NR 2 FRA VENSTRE",
    postalCode: "0652",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ekebergveien",
    streetNumber: "290B",
    postalCode: "1166",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hartmanns vei 6",
    streetNumber: " B",
    postalCode: "0284",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bekkeliveien",
    streetNumber: "15A",
    postalCode: "0375",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Uelands gate",
    streetNumber: " 63C",
    postalCode: "0462",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Vardeheimveien 3",
    streetNumber: " B",
    postalCode: "1088",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Oddenveien",
    streetNumber: "7D",
    postalCode: "1363",
    city: "Høvik",
    country: "Norge",
  },
  {
    streetName: "",
    streetNumber: "24",
    postalCode: "0192",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Guvernørens Vei",
    streetNumber: "9E",
    postalCode: "0284",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Slattum Terrasse",
    streetNumber: "17C",
    postalCode: "1480",
    city: "Slattum",
    country: "Norway",
  },
  {
    streetName: "Fürstveien",
    streetNumber: "17",
    postalCode: "1367",
    city: "Snarøya",
    country: "Norway",
  },
  {
    streetName: "Brøholtskogen",
    streetNumber: "33",
    postalCode: "3430",
    city: "Spikkestad",
    country: "Norway",
  },
  {
    streetName: "Amtmann Meinichs gate 14",
    streetNumber: " d",
    postalCode: "0482",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Landskronaveien",
    streetNumber: "181",
    postalCode: "2013",
    city: "Skjetten",
    country: "Norway",
  },
  {
    streetName: "Vikingveien",
    streetNumber: "6",
    postalCode: "1170",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Pasopsvingen",
    streetNumber: "13",
    postalCode: "1279",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sørkedalsveien",
    streetNumber: " 76A",
    postalCode: "0377",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Granstangen 36",
    streetNumber: " B",
    postalCode: "1051",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Romsaasbakken",
    streetNumber: " 1",
    postalCode: "2063",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Vabråten",
    streetNumber: "144",
    postalCode: "1392",
    city: "Vettre",
    country: "Norway",
  },
  {
    streetName: "Lerdalsgata",
    streetNumber: "46A",
    postalCode: "1467",
    city: "Strømmen",
    country: "Norway",
  },
  {
    streetName: "Bragernes Torg",
    streetNumber: " 12",
    postalCode: "3017",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Bygdøy Allé",
    streetNumber: "115A",
    postalCode: "0273",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Eiksveien",
    streetNumber: "57",
    postalCode: "1359",
    city: "Eiksmarka",
    country: "Norway",
  },
  {
    streetName: "Holmestrandgata 9",
    streetNumber: "c/o Leilighet H0202",
    postalCode: "0468",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Schweigaards gate 94",
    streetNumber: " c",
    postalCode: "0656",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Brunskrubbveien",
    streetNumber: "22A",
    postalCode: "1476",
    city: "Rasta",
    country: "Norway",
  },
  {
    streetName: "Holmenveien,",
    streetNumber: "30",
    postalCode: "1555",
    city: "Son",
    country: "Norway",
  },
  {
    streetName: "Bjørnstadsvingen",
    streetNumber: "16",
    postalCode: "3440",
    city: "Røyken",
    country: "Norge",
  },
  {
    streetName: "Bringebærstien",
    streetNumber: " 30",
    postalCode: "1476",
    city: "Rasta",
    country: "Norway",
  },
  {
    streetName: "Gullaugkleiva",
    streetNumber: " 3",
    postalCode: "3427",
    city: "Gullaug",
    country: "Norway",
  },
  {
    streetName: "Vollenlia 109",
    streetNumber: " d",
    postalCode: "1390",
    city: "Vollen",
    country: "Norway",
  },
  {
    streetName: "Olav Aukrustvei",
    streetNumber: "35 a",
    postalCode: "0785",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ensjøveien 6",
    streetNumber: " A",
    postalCode: "0655",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Øvre Ekeberglia",
    streetNumber: "29",
    postalCode: "3420",
    city: "Lierskogen",
    country: "Norway",
  },
  {
    streetName: "Colletts Gate",
    streetNumber: "86",
    postalCode: "0460",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Brøttet",
    streetNumber: " 5",
    postalCode: "3029",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Kvernstien",
    streetNumber: " 4",
    postalCode: "0373",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sigurd Hoels vei",
    streetNumber: "68",
    postalCode: "0655",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Elgtråkket",
    streetNumber: " 126B",
    postalCode: "1270",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Prost Stabels vei",
    streetNumber: " 277",
    postalCode: "2019",
    city: "Skedsmokorset",
    country: "Norway",
  },
  {
    streetName: "Munkerudvollen",
    streetNumber: "22",
    postalCode: "1165",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tokerudberget",
    streetNumber: "35",
    postalCode: "0986",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Støperiveien",
    streetNumber: "2B",
    postalCode: "2010",
    city: "Strømmen",
    country: "Norway",
  },
  {
    streetName: "Erlends vei 17",
    streetNumber: " 0202",
    postalCode: "0669",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Svaleveien",
    streetNumber: " 5",
    postalCode: "3030",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Fagerborggata",
    streetNumber: " 50b",
    postalCode: "0360",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Lundveien",
    streetNumber: " 11b",
    postalCode: "1482",
    city: "Nittedal",
    country: "Norway",
  },
  {
    streetName: "Lysåskroken",
    streetNumber: " 7",
    postalCode: "2052",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Høybråtenveien 23",
    streetNumber: " e",
    postalCode: "1055",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bergerveien",
    streetNumber: " 1",
    postalCode: "1475",
    city: "Finstadjordet",
    country: "Norway",
  },
  {
    streetName: "A.C. Svarstads gate",
    streetNumber: " 2A",
    postalCode: "2003",
    city: "Lillestrøm",
    country: "Norway",
  },
  {
    streetName: "Høymyrfjellet",
    streetNumber: " 17",
    postalCode: "1389",
    city: "Heggedal",
    country: "Norway",
  },
  {
    streetName: "løkebergveien",
    streetNumber: "129",
    postalCode: "1344",
    city: "Haslum",
    country: "Norge",
  },
  {
    streetName: "Gabelsgate",
    streetNumber: "21B",
    postalCode: "0272",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Transetveien",
    streetNumber: " 6",
    postalCode: "3440",
    city: "Røyken",
    country: "Norway",
  },
  {
    streetName: "Prinsessetunet",
    streetNumber: " 4",
    postalCode: "2016",
    city: "Frogner",
    country: "Norway",
  },
  {
    streetName: "Bikuben",
    streetNumber: "13",
    postalCode: "1086",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Brekkeveien",
    streetNumber: " 5C",
    postalCode: "1430",
    city: "Ås",
    country: "Norway",
  },
  {
    streetName: "Danskerudveien",
    streetNumber: "12 B",
    postalCode: "1435",
    city: "Ås",
    country: "Norway",
  },
  {
    streetName: "Odins vei",
    streetNumber: " 20C",
    postalCode: "1413",
    city: "Tårnåsen",
    country: "Norway",
  },
  {
    streetName: "Dølstuveien",
    streetNumber: "11",
    postalCode: "3470",
    city: "Slemmestad",
    country: "Norway",
  },
  {
    streetName: "Odvar Solbergs vei",
    streetNumber: "76",
    postalCode: "0970",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Grøndahls vei 19",
    streetNumber: " a",
    postalCode: "1456",
    city: "Nesoddtangen",
    country: "Norway",
  },
  {
    streetName: "Tullatoppen",
    streetNumber: "16",
    postalCode: "2016",
    city: "Frogner",
    country: "Norway",
  },
  {
    streetName: "Solsvingen",
    streetNumber: " 16",
    postalCode: "3034",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Almenningsveien",
    streetNumber: "32 c",
    postalCode: "1425",
    city: "Ski",
    country: "Norway",
  },
  {
    streetName: "bassengvegen",
    streetNumber: "29",
    postalCode: "2006",
    city: "Løvenstad",
    country: "Norge",
  },
  {
    streetName: "Stjerneveien",
    streetNumber: " 30",
    postalCode: "1482",
    city: "Nittedal",
    country: "Norway",
  },
  {
    streetName: "Hans Nielsen Hauges gate",
    streetNumber: "39A",
    postalCode: "0481",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ole Reistads vei",
    streetNumber: " 23G",
    postalCode: "1068",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Skåvålenga",
    streetNumber: "13",
    postalCode: "2000",
    city: "Lillestrøm",
    country: "Norway",
  },
  {
    streetName: "Lerkeveien",
    streetNumber: " 10B",
    postalCode: "1053",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Enebakkveien",
    streetNumber: " 623F",
    postalCode: "1404",
    city: "Siggerud",
    country: "Norway",
  },
  {
    streetName: "Vøyensvingen",
    streetNumber: "5c",
    postalCode: "0458",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hellerudveien",
    streetNumber: " 75",
    postalCode: "0684",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ulsholtveien",
    streetNumber: "56A",
    postalCode: "1053",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Cappelens gate",
    streetNumber: " 28",
    postalCode: "3015",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Skogveien",
    streetNumber: "4",
    postalCode: "1540",
    city: "Vestby",
    country: "Norge",
  },
  {
    streetName: "Nordkappgata",
    streetNumber: "17",
    postalCode: "0479",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nordkappgata",
    streetNumber: "17",
    postalCode: "0479",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lindebergveien",
    streetNumber: "53C",
    postalCode: "1069",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hegermanns gate",
    streetNumber: "4",
    postalCode: "0478",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Åsstubben",
    streetNumber: "23",
    postalCode: "0381",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Lerdalsfaret",
    streetNumber: " 32",
    postalCode: "1263",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Vårveien",
    streetNumber: "49",
    postalCode: "3024",
    city: "Drammen",
    country: "Norge",
  },
  {
    streetName: "Tyslevveien",
    streetNumber: "34a",
    postalCode: "1163",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Østerlisvingen",
    streetNumber: "4",
    postalCode: "1153",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Tordenskiolds Gate",
    streetNumber: "83C",
    postalCode: "3044",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Ørakerfaret",
    streetNumber: " 1",
    postalCode: "0284",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "SKOGSTUVEIEN",
    streetNumber: "23",
    postalCode: "1363",
    city: "Høvik",
    country: "Norway",
  },
  {
    streetName: "Rødtvetkroken",
    streetNumber: " 10A",
    postalCode: "0956",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sletteløkka",
    streetNumber: "24A",
    postalCode: "0597",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Fururabben",
    streetNumber: " 5",
    postalCode: "1412",
    city: "Sofiemyr",
    country: "Norway",
  },
  {
    streetName: "Herslebs gate",
    streetNumber: "15A",
    postalCode: "0561",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Michelets vei",
    streetNumber: " 19b",
    postalCode: "1366",
    city: "Lysaker",
    country: "Norway",
  },
  {
    streetName: "Østerliveien",
    streetNumber: "43a",
    postalCode: "1153",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "St. Jørgens vei",
    streetNumber: " 2",
    postalCode: "0662",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Trondheimsveien",
    streetNumber: "174",
    postalCode: "0570",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Høymyrmarka",
    streetNumber: "237",
    postalCode: "1391",
    city: "Vollen",
    country: "Norway",
  },
  {
    streetName: "Tåsenveien",
    streetNumber: "119",
    postalCode: "0880",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Pilestredet Park",
    streetNumber: "27",
    postalCode: "0176",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hanevoldveien",
    streetNumber: " 36",
    postalCode: "1384",
    city: "Asker",
    country: "Norway",
  },
  {
    streetName: "Ravnkollbakken",
    streetNumber: " 58",
    postalCode: "0971",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ivar Welles vei",
    streetNumber: "5",
    postalCode: "0672",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Rugdeberget",
    streetNumber: "12",
    postalCode: "1266",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Piggsoppveien",
    streetNumber: "4G",
    postalCode: "2016",
    city: "Frogner",
    country: "Norge",
  },
  {
    streetName: "Oksvalveien",
    streetNumber: "25",
    postalCode: "1456",
    city: "Nesoddtangen",
    country: "Norway",
  },
  {
    streetName: "Helsethellinga",
    streetNumber: "21",
    postalCode: "1353",
    city: "Bærums verk",
    country: "Norway",
  },
  {
    streetName: "Drammensveien",
    streetNumber: "91D",
    postalCode: "0271",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Askervangen",
    streetNumber: " 19",
    postalCode: "1383",
    city: "Asker",
    country: "Norway",
  },
  {
    streetName: "St. Halvards gate 25",
    streetNumber: " B",
    postalCode: "0192",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Tunnelveien",
    streetNumber: " 6",
    postalCode: "3426",
    city: "Gullaug",
    country: "Norway",
  },
  {
    streetName: "Arbins gate",
    streetNumber: " 3",
    postalCode: "0253",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Professor Dahls gate",
    streetNumber: " 35b",
    postalCode: "0353",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Kalderaveien",
    streetNumber: "17",
    postalCode: "1359",
    city: "Eiksmarka",
    country: "Norway",
  },
  {
    streetName: "Sankt Halvards gate",
    streetNumber: "22",
    postalCode: "0192",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Balders gate",
    streetNumber: "7b",
    postalCode: "0263",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Nordbyvegen",
    streetNumber: " 16B",
    postalCode: "2069",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Hamang terrasse",
    streetNumber: "79 ",
    postalCode: "1336",
    city: "Sandvika",
    country: "Norway",
  },
  {
    streetName: "Jacob Neumanns Vei",
    streetNumber: "33",
    postalCode: "1384",
    city: "Asker",
    country: "Norway",
  },
  {
    streetName: "Inga Falsen Gjerdrums vei",
    streetNumber: "43",
    postalCode: "1396",
    city: "Billingstad",
    country: "Norge",
  },
  {
    streetName: "Professor Daaes gate",
    streetNumber: "1",
    postalCode: "3043",
    city: "Drammen",
    country: "Norge",
  },
  {
    streetName: "Ekebergveien",
    streetNumber: "7A",
    postalCode: "0192",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Blindernveien 6B",
    streetNumber: "c/o H0902",
    postalCode: "0361",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Veslekroken",
    streetNumber: "28",
    postalCode: "0377",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Idretten",
    streetNumber: " 11",
    postalCode: "2068",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Johans vei",
    streetNumber: "2",
    postalCode: "1542",
    city: "Vestby",
    country: "Norge",
  },
  {
    streetName: "Nordlysveien",
    streetNumber: "21",
    postalCode: "0489",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Lyngveien",
    streetNumber: "7B",
    postalCode: "3470",
    city: "Slemmestad",
    country: "Norge",
  },
  {
    streetName: "Jaktlia",
    streetNumber: "3c",
    postalCode: "1361",
    city: "Østerås",
    country: "Norway",
  },
  {
    streetName: "Ensjøveien",
    streetNumber: "21",
    postalCode: "0661",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nedre Pentagonvei",
    streetNumber: "28",
    postalCode: "1430",
    city: "Ås",
    country: "Norge",
  },
  {
    streetName: "Steinspranget",
    streetNumber: "12",
    postalCode: "1156",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Fearnleys gate 10B",
    streetNumber: "c/o Magnus Aaning Buntemeyer",
    postalCode: "0353",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holmsens vei 48",
    streetNumber: " B",
    postalCode: "1467",
    city: "Strømmen",
    country: "Norway",
  },
  {
    streetName: "Welhavens gate",
    streetNumber: " 18",
    postalCode: "0350",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Røyeveien",
    streetNumber: "22",
    postalCode: "1481",
    city: "Hagan",
    country: "Norway",
  },
  {
    streetName: "Hovseterveien",
    streetNumber: "66c",
    postalCode: "0768",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Parkveien",
    streetNumber: " 17A",
    postalCode: "3413",
    city: "Lier",
    country: "Norway",
  },
  {
    streetName: "Dølstuveien",
    streetNumber: "11",
    postalCode: "3470",
    city: "Slemmestad",
    country: "Norway",
  },
  {
    streetName: "Sarabråtveien",
    streetNumber: "11B",
    postalCode: "0687",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Aukeveien",
    streetNumber: "81b",
    postalCode: "3472",
    city: "Bødalen",
    country: "Norway",
  },
  {
    streetName: "Hamang terrasse",
    streetNumber: "79 ",
    postalCode: "1336",
    city: "Sandvika",
    country: "Norway",
  },
  {
    streetName: "Høyboveien",
    streetNumber: "8",
    postalCode: "0198",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Isdamveien",
    streetNumber: " 2",
    postalCode: "1555",
    city: "Son",
    country: "Norway",
  },
  {
    streetName: "Godals vei",
    streetNumber: "26",
    postalCode: "0871",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Engelsrudstranda,",
    streetNumber: "41",
    postalCode: "1385",
    city: "Asker",
    country: "Norway",
  },
  {
    streetName: "Hellaveien",
    streetNumber: "186",
    postalCode: "1458",
    city: "Fjellstrand",
    country: "Norway",
  },
  {
    streetName: "Bekkehøy",
    streetNumber: " 9",
    postalCode: "2052",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Store Landfall Nedre",
    streetNumber: " 8",
    postalCode: "3024",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Aslakveien",
    streetNumber: "28C",
    postalCode: "0753",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bråtasvingen",
    streetNumber: "10A",
    postalCode: "3425",
    city: "Reistad",
    country: "Norway",
  },
  {
    streetName: "Fossilveien",
    streetNumber: "4a",
    postalCode: "1346",
    city: "Gjettum",
    country: "Norway",
  },
  {
    streetName: "Øvre Lunden",
    streetNumber: " 15",
    postalCode: "0598",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Storgata",
    streetNumber: " 36",
    postalCode: "2000",
    city: "Lillestrøm",
    country: "Norway",
  },
  {
    streetName: "Nonnegata 15",
    streetNumber: "c/o Nonnegata 15",
    postalCode: "0656",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gamle Drammensvei",
    streetNumber: "171 C",
    postalCode: "1365",
    city: "Blommenholm",
    country: "Norway",
  },
  {
    streetName: "Lensmann Hiorths allé",
    streetNumber: " 5",
    postalCode: "0661",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Telesvingen",
    streetNumber: "5",
    postalCode: "0378",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Landerokollen",
    streetNumber: " 40",
    postalCode: "0672",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hammerstads gate",
    streetNumber: " 23B",
    postalCode: "0363",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Pilotveien",
    streetNumber: "10",
    postalCode: "0770",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Smiuvegen",
    streetNumber: "19",
    postalCode: "0981",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Haugerudhagan",
    streetNumber: "3A",
    postalCode: "0673",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Biskop Jens Nilssøns gate",
    streetNumber: "15B",
    postalCode: "0659",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dorthesvei",
    streetNumber: "4f",
    postalCode: "0287",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Birch-Reichenwalds gate",
    streetNumber: " 10",
    postalCode: "0483",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Laurits Grønlands vei",
    streetNumber: "15",
    postalCode: "3035",
    city: "Drammen",
    country: "Norge",
  },
  {
    streetName: "Jenshaugveien",
    streetNumber: " 28",
    postalCode: "1363",
    city: "Høvik",
    country: "Norway",
  },
  {
    streetName: "Sandakerveien",
    streetNumber: "81",
    postalCode: "0483",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sandakerveien 99",
    streetNumber: " H0521",
    postalCode: "0483",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Tømteveien",
    streetNumber: "146",
    postalCode: "2013",
    city: "Skjetten",
    country: "Norway",
  },
  {
    streetName: "Kranveien",
    streetNumber: " 2",
    postalCode: "0684",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Grindbakken",
    streetNumber: "1",
    postalCode: "0764",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ruglandveien 156A",
    streetNumber: "c/o Peder Thomsen Nes",
    postalCode: "1359",
    city: "Eiksmarka",
    country: "Norge",
  },
  {
    streetName: "Torpstubben",
    streetNumber: "65",
    postalCode: "1389",
    city: "Heggedal",
    country: "Norway",
  },
  {
    streetName: "Kastanjeveien",
    streetNumber: "26E",
    postalCode: "3026",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Christoffer Hellums vei",
    streetNumber: " 4",
    postalCode: "0951",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Rosenkrantzgata",
    streetNumber: " 177",
    postalCode: "3023",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Øvre eikervei",
    streetNumber: "50 B",
    postalCode: "3048",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Hans Ross' gate",
    streetNumber: "3",
    postalCode: "0172",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "I. Opsahls gate",
    streetNumber: " 25",
    postalCode: "2004",
    city: "Lillestrøm",
    country: "Norway",
  },
  {
    streetName: "Grønland",
    streetNumber: "54",
    postalCode: "3045",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Mor go Hjertas vei",
    streetNumber: "13",
    postalCode: "0469",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Magnus Bergs gate",
    streetNumber: " 9B",
    postalCode: "0266",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Neslia",
    streetNumber: " 1C",
    postalCode: "1344",
    city: "Haslum",
    country: "Norway",
  },
  {
    streetName: "Ødegårds vei",
    streetNumber: " 3",
    postalCode: "1470",
    city: "Lørenskog",
    country: "Norway",
  },
  {
    streetName: "Eikholtveien",
    streetNumber: " 6",
    postalCode: "3031",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Ståvihagan 12",
    streetNumber: " B",
    postalCode: "1339",
    city: "Vøyenenga",
    country: "Norway",
  },
  {
    streetName: "Theodor Hansens vei",
    streetNumber: "19A",
    postalCode: "1410",
    city: "Kolbotn",
    country: "Norway",
  },
  {
    streetName: "Smalgangen",
    streetNumber: "26",
    postalCode: "0188",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Einerveien",
    streetNumber: " 18",
    postalCode: "1405",
    city: "Langhus",
    country: "Norway",
  },
  {
    streetName: "Vestlia",
    streetNumber: " 114",
    postalCode: "1453",
    city: "Bjørnemyr",
    country: "Norway",
  },
  {
    streetName: "Tømmerbakke vei",
    streetNumber: "64",
    postalCode: "1453",
    city: "Bjørnemyr",
    country: "Norway",
  },
  {
    streetName: "Rauerskauveien",
    streetNumber: " 638",
    postalCode: "1482",
    city: "Nittedal",
    country: "Norway",
  },
  {
    streetName: "Austadveien",
    streetNumber: " 24a",
    postalCode: "3043",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Jørgen Ringstads vei",
    streetNumber: " 12",
    postalCode: "3040",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Margarethas veg",
    streetNumber: "4 ",
    postalCode: "1473",
    city: "Lørenskog",
    country: "Norway",
  },
  {
    streetName: "Enebakkveien",
    streetNumber: "240C",
    postalCode: "1187",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Odvar Solbergs vei",
    streetNumber: "188",
    postalCode: "0973",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nils Kristensens gate",
    streetNumber: "12",
    postalCode: "2003",
    city: "Lillestrøm",
    country: "Norway",
  },
  {
    streetName: "Elgtråkket",
    streetNumber: " 126B",
    postalCode: "1270",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Stavangergata",
    streetNumber: " 23",
    postalCode: "0467",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Edelgranveien",
    streetNumber: "74",
    postalCode: "1356",
    city: "Bekkestua",
    country: "Norway",
  },
  {
    streetName: "kvartsveien",
    streetNumber: "9",
    postalCode: "1158",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jordbærstien",
    streetNumber: " 43",
    postalCode: "1405",
    city: "Langhus",
    country: "Norway",
  },
  {
    streetName: "Bergveien",
    streetNumber: " 4B",
    postalCode: "1406",
    city: "Ski",
    country: "Norway",
  },
  {
    streetName: "Huitfeldts gate",
    streetNumber: " 15",
    postalCode: "0253",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "John Strandruds vei",
    streetNumber: " 15",
    postalCode: "1366",
    city: "Lysaker",
    country: "Norway",
  },
  {
    streetName: "Rolf Presthus Vei",
    streetNumber: "86",
    postalCode: "1415",
    city: "Oppegård",
    country: "Norway",
  },
  {
    streetName: "Lensmann Hiorths allé",
    streetNumber: " 5",
    postalCode: "0661",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ullagerveien",
    streetNumber: "9A",
    postalCode: "0585",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hesjeveien,",
    streetNumber: "8a",
    postalCode: "1084",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Caspar Erlandsens vei",
    streetNumber: "16 B",
    postalCode: "1540",
    city: "Vestby",
    country: "Norge",
  },
  {
    streetName: "Ringshusstubben",
    streetNumber: "4 a",
    postalCode: "1176",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Kampheimveien",
    streetNumber: "12 B",
    postalCode: "0685",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Grønland",
    streetNumber: "54",
    postalCode: "3045",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Paal Bergs vei",
    streetNumber: "97",
    postalCode: "0692",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ogmunds vei",
    streetNumber: " 4a",
    postalCode: "0488",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Thorvald Meyers gate",
    streetNumber: " 23a",
    postalCode: "0555",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Tappen",
    streetNumber: "24",
    postalCode: "1337",
    city: "Sandvika",
    country: "Norge",
  },
  {
    streetName: "Kjerulfs gate",
    streetNumber: "39A",
    postalCode: "2000",
    city: "Lillestrøm",
    country: "Norway",
  },
  {
    streetName: "Lybekkveien",
    streetNumber: " 44B",
    postalCode: "0770",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Jerikoveien",
    streetNumber: "67",
    postalCode: "1067",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hasleveien",
    streetNumber: "1C",
    postalCode: "0571",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Holbergsvei",
    streetNumber: "12",
    postalCode: "1412",
    city: "Sofiemyr",
    country: "Norway",
  },
  {
    streetName: "Raschs vei",
    streetNumber: "4b",
    postalCode: "1178",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Trudvangveien",
    streetNumber: "5",
    postalCode: "0364",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dr. Dedichens vei",
    streetNumber: " 100",
    postalCode: "0675",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Åslandhellinga",
    streetNumber: "307",
    postalCode: "1274",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Grenseveien",
    streetNumber: "57a",
    postalCode: "0576",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Trondheimsveien",
    streetNumber: " 87",
    postalCode: "0565",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Trestakkveien",
    streetNumber: " 3A",
    postalCode: "2016",
    city: "Frogner",
    country: "Norway",
  },
  {
    streetName: "Tollbugata",
    streetNumber: "62A",
    postalCode: "3044",
    city: "Drammen",
    country: "Norge",
  },
  {
    streetName: "Bernåsbakken",
    streetNumber: " 31",
    postalCode: "3032",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Munkebekken",
    streetNumber: "324",
    postalCode: "1061",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Nøklesvingen",
    streetNumber: "53",
    postalCode: "0689",
    city: "Oslo",
    country: "no",
  },
  {
    streetName: "Odins vei",
    streetNumber: " 89",
    postalCode: "3472",
    city: "Bødalen",
    country: "Norway",
  },
  {
    streetName: "Åsenhagen 12",
    streetNumber: " C",
    postalCode: "2020",
    city: "Skedsmokorset",
    country: "Norway",
  },
  {
    streetName: "Olav M. Troviks vei 4",
    streetNumber: " H0905",
    postalCode: "0864",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Furulundtoppen",
    streetNumber: " 19",
    postalCode: "0282",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Myrehagen",
    streetNumber: " 33",
    postalCode: "3025",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Grønsundveien",
    streetNumber: "104",
    postalCode: "1394",
    city: "Nesbru",
    country: "Norway",
  },
  {
    streetName: "Lofsrudhøgda 43",
    streetNumber: " a",
    postalCode: "1281",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bruksveien",
    streetNumber: " 66",
    postalCode: "1367",
    city: "Snarøya",
    country: "Norway",
  },
  {
    streetName: "Heggeveien",
    streetNumber: " 28a",
    postalCode: "1481",
    city: "Hagan",
    country: "Norway",
  },
  {
    streetName: "Arendalsgata 9",
    streetNumber: "c/o Ane Line Plassen Nordbak",
    postalCode: "0463",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Solgangsbrisen 3",
    streetNumber: "c/o c/o A. MANENGEN",
    postalCode: "1364",
    city: "Fornebu",
    country: "Norge",
  },
  {
    streetName: "Solhaugveien",
    streetNumber: "18",
    postalCode: "2019",
    city: "Skedsmokorset",
    country: "Norway",
  },
  {
    streetName: "Grindbakken 10",
    streetNumber: "c/o Siri Stonex",
    postalCode: "0764",
    city: "Oslo",
    country: "no",
  },
  {
    streetName: "Schønings gate",
    streetNumber: "3 A",
    postalCode: "0356",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Singasteinvegen",
    streetNumber: "19a",
    postalCode: "0198",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kastanjeveien 26",
    streetNumber: " B",
    postalCode: "3026",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Bakkeløkka",
    streetNumber: " 16D",
    postalCode: "1454",
    city: "Fagerstrand",
    country: "Norway",
  },
  {
    streetName: "Ekornsvingen",
    streetNumber: " 1",
    postalCode: "3440",
    city: "Røyken",
    country: "Norway",
  },
  {
    streetName: "Røykenveien,",
    streetNumber: "322b",
    postalCode: "1389",
    city: "Heggedal",
    country: "Norway",
  },
  {
    streetName: "Vetlandsfaret",
    streetNumber: "7 B",
    postalCode: "0684",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Nedre Kiøsterudsgate",
    streetNumber: " 6b",
    postalCode: "3023",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Skansen",
    streetNumber: " 10b",
    postalCode: "1405",
    city: "Langhus",
    country: "Norway",
  },
  {
    streetName: "Buskerudveien",
    streetNumber: " 79",
    postalCode: "3024",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Flåtansvingen",
    streetNumber: "56",
    postalCode: "3400",
    city: "Lier",
    country: "Norge",
  },
  {
    streetName: "Stoppenkollen",
    streetNumber: "1",
    postalCode: "3413",
    city: "Lier",
    country: "Norge",
  },
  {
    streetName: "Birkelundveien",
    streetNumber: "17",
    postalCode: "1481",
    city: "Hagan",
    country: "Norway",
  },
  {
    streetName: "Olav M. Troviks vei",
    streetNumber: " 68",
    postalCode: "0864",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hattemakerlia 4",
    streetNumber: " A",
    postalCode: "2019",
    city: "Skedsmokorset",
    country: "Norway",
  },
  {
    streetName: "Vestvollen",
    streetNumber: "23c",
    postalCode: "2040",
    city: "Kløfta",
    country: "Norway",
  },
  {
    streetName: "Ensjøveien",
    streetNumber: "21d",
    postalCode: "0661",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Haakon Tveters vei",
    streetNumber: "43",
    postalCode: "0686",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hellastubben",
    streetNumber: " 7",
    postalCode: "2013",
    city: "Skjetten",
    country: "Norway",
  },
  {
    streetName: "Leirfivelveien",
    streetNumber: "57",
    postalCode: "1346",
    city: "Gjettum",
    country: "Norway",
  },
  {
    streetName: "Tangerudveien",
    streetNumber: "60G",
    postalCode: "0982",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hans Nielsen Hauges gate",
    streetNumber: " 38",
    postalCode: "0481",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sørkedalsveien",
    streetNumber: " 9E",
    postalCode: "0369",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Landskronaveien",
    streetNumber: "246",
    postalCode: "2013",
    city: "Skjetten",
    country: "Norge",
  },
  {
    streetName: "Hagastubben",
    streetNumber: "40",
    postalCode: "0980",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Løvenstadvegen 10",
    streetNumber: " A",
    postalCode: "2006",
    city: "Løvenstad",
    country: "Norway",
  },
  {
    streetName: "Vevelstadåsen",
    streetNumber: " 13",
    postalCode: "1405",
    city: "Langhus",
    country: "Norway",
  },
  {
    streetName: "Hartmanns vei",
    streetNumber: "16",
    postalCode: "0284",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ullern alle",
    streetNumber: "51",
    postalCode: "0381",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Trøgstadvegen",
    streetNumber: " 35",
    postalCode: "2050",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Bankveien",
    streetNumber: "21A",
    postalCode: "1362",
    city: "Hosle",
    country: "Norway",
  },
  {
    streetName: "Sørengkaia",
    streetNumber: " 60",
    postalCode: "0194",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Schweigaards gate",
    streetNumber: "54C",
    postalCode: "0656",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Landskronaveien",
    streetNumber: "127",
    postalCode: "2013",
    city: "Skjetten",
    country: "Norway",
  },
  {
    streetName: "fururabben",
    streetNumber: "15",
    postalCode: "1361",
    city: "Østerås",
    country: "Norway",
  },
  {
    streetName: "Per Kvibergs gate",
    streetNumber: "20",
    postalCode: "0478",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Åmotveien",
    streetNumber: "108",
    postalCode: "1389",
    city: "Heggedal",
    country: "Norway",
  },
  {
    streetName: "Stovnerbakken",
    streetNumber: " 2E",
    postalCode: "0980",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "frydenlund",
    streetNumber: "24A",
    postalCode: "3414",
    city: "Lierstranda",
    country: "Norway",
  },
  {
    streetName: "Gjerpen",
    streetNumber: " 8",
    postalCode: "3035",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Vardelia",
    streetNumber: " 20",
    postalCode: "2020",
    city: "Skedsmokorset",
    country: "Norway",
  },
  {
    streetName: "Karl Andersens vei",
    streetNumber: "98",
    postalCode: "1088",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Erling Skjalgssons gate",
    streetNumber: " 34A",
    postalCode: "0267",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Astrids vei",
    streetNumber: " 6",
    postalCode: "1473",
    city: "Lørenskog",
    country: "Norway",
  },
  {
    streetName: "Eikelia",
    streetNumber: "8B",
    postalCode: "1407",
    city: "Vinterbro",
    country: "Norway",
  },
  {
    streetName: "Bygdøy allé 58",
    streetNumber: "c/o Gniks AS",
    postalCode: "0265",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Seilduksgata",
    streetNumber: "10A",
    postalCode: "0553",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Njårds vei",
    streetNumber: "3a",
    postalCode: "1412",
    city: "Sofiemyr",
    country: "Norway",
  },
  {
    streetName: "Etterstadsletta",
    streetNumber: "21A",
    postalCode: "0660",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Høgdaveien",
    streetNumber: "1f",
    postalCode: "0680",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Jonas Lies Vei",
    streetNumber: "41",
    postalCode: "3022",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Minister Ditleffs vei",
    streetNumber: "3C",
    postalCode: "0862",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Martin Skatvedts vei",
    streetNumber: " 10",
    postalCode: "0950",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Åsmohagan",
    streetNumber: " 9",
    postalCode: "2052",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Arbins gate",
    streetNumber: " 3",
    postalCode: "0253",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sognsveien",
    streetNumber: " 4",
    postalCode: "0451",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Skolegata",
    streetNumber: "32B",
    postalCode: "2010",
    city: "Strømmen",
    country: "Norge",
  },
  {
    streetName: "Ringveien",
    streetNumber: " 1b",
    postalCode: "1467",
    city: "Strømmen",
    country: "Norway",
  },
  {
    streetName: "Marcus Thranes vei",
    streetNumber: " 8C",
    postalCode: "1470",
    city: "Lørenskog",
    country: "Norway",
  },
  {
    streetName: "Brønnveien",
    streetNumber: " 5c",
    postalCode: "0283",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ugleveien",
    streetNumber: "9b",
    postalCode: "0853",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Gamle Lommedalsvei",
    streetNumber: "133H",
    postalCode: "1348",
    city: "Rykkinn",
    country: "Norway",
  },
  {
    streetName: "Skøyenveien",
    streetNumber: " 11a",
    postalCode: "0375",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Idas Vei",
    streetNumber: "19",
    postalCode: "0981",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Vetlandsveien",
    streetNumber: "69B",
    postalCode: "0685",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Solbergliveien",
    streetNumber: "116A",
    postalCode: "0683",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Karlstadgata",
    streetNumber: "13, L 301",
    postalCode: "0553",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kranveien",
    streetNumber: " 2",
    postalCode: "0684",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Trudvangveien",
    streetNumber: "27a ",
    postalCode: "1358",
    city: "Jar",
    country: "Norway",
  },
  {
    streetName: "Granliveien",
    streetNumber: " 21i",
    postalCode: "1086",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Voksenhagen",
    streetNumber: " 30",
    postalCode: "0770",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hovinveien",
    streetNumber: "37b",
    postalCode: "0576",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dalsveien",
    streetNumber: " 7",
    postalCode: "0376",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Årvollveien",
    streetNumber: "54 H",
    postalCode: "0590",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Peter Møllers vei",
    streetNumber: "5A",
    postalCode: "0585",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lørenveien",
    streetNumber: "59 a",
    postalCode: "0585",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Helgesens gate",
    streetNumber: " 26C",
    postalCode: "0553",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Vardeveien",
    streetNumber: "15C",
    postalCode: "1413",
    city: "Tårnåsen",
    country: "Norway",
  },
  {
    streetName: "Vestre Almelien",
    streetNumber: "39",
    postalCode: "1358",
    city: "Jar",
    country: "Norway",
  },
  {
    streetName: "Rugveien",
    streetNumber: "34",
    postalCode: "0679",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Inga Bjørnsons vei",
    streetNumber: " 125",
    postalCode: "0969",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Spikerveien",
    streetNumber: "24A",
    postalCode: "0485",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Tollbugata",
    streetNumber: " 34",
    postalCode: "3044",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Kongsberggt",
    streetNumber: "18",
    postalCode: "0468",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Thor Olsens gate",
    streetNumber: "3b",
    postalCode: "0177",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brånåstunet",
    streetNumber: " 10",
    postalCode: "2019",
    city: "Skedsmokorset",
    country: "Norway",
  },
  {
    streetName: "Munkerudveien",
    streetNumber: "18b",
    postalCode: "1163",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Selvbyggerveien",
    streetNumber: "60",
    postalCode: "0591",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "St Edmundsvei.",
    streetNumber: "58",
    postalCode: "0280",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Niels Juels gate",
    streetNumber: "64",
    postalCode: "0259",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kapellveien",
    streetNumber: "18B",
    postalCode: "0487",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Refstad alle",
    streetNumber: "32",
    postalCode: "0586",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Underhaugsvn",
    streetNumber: "12 b",
    postalCode: "1358",
    city: "Jar",
    country: "Norway",
  },
  {
    streetName: "Husebybakken",
    streetNumber: "30 B",
    postalCode: "0379",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bjørkesvingen",
    streetNumber: "6H",
    postalCode: "3408",
    city: "Tranby",
    country: "Norge",
  },
  {
    streetName: "Melumveien",
    streetNumber: "26",
    postalCode: "0760",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brokkenhusgrenda",
    streetNumber: "16",
    postalCode: "1404",
    city: "Siggerud",
    country: "Norge",
  },
  {
    streetName: "Bygdøy allé",
    streetNumber: " 28B",
    postalCode: "0265",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hovinveien",
    streetNumber: "11a",
    postalCode: "0576",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brynsvn",
    streetNumber: "130",
    postalCode: "1352",
    city: "Kolsås",
    country: "Norway",
  },
  {
    streetName: "Klostergata",
    streetNumber: "4",
    postalCode: "0656",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Furuset allé",
    streetNumber: "27c",
    postalCode: "1053",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Østmarkveien",
    streetNumber: "5d",
    postalCode: "0687",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "MYRBRÅTEN",
    streetNumber: "52",
    postalCode: "3031",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Blindernveien 6B",
    streetNumber: "c/o H0902",
    postalCode: "0361",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Risbekkveien",
    streetNumber: "7",
    postalCode: "0778",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Enerhauggata",
    streetNumber: "3",
    postalCode: "0651",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skigata",
    streetNumber: "14",
    postalCode: "1473",
    city: "Lørenskog",
    country: "NO",
  },
  {
    streetName: "Holsts gate",
    streetNumber: "2B",
    postalCode: "0473",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rognerudveien ,",
    streetNumber: "53",
    postalCode: "0681",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "markveien",
    streetNumber: "13",
    postalCode: "0554",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Statsråd Mathiesensvei",
    streetNumber: "4c",
    postalCode: "0598",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ullernfaret",
    streetNumber: " 1A",
    postalCode: "0281",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Fougstads gate",
    streetNumber: "28",
    postalCode: "0173",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Solhaugveien",
    streetNumber: "36",
    postalCode: "2019",
    city: "Skedsmokorset",
    country: "Norway",
  },
  {
    streetName: "Kvernlivegen",
    streetNumber: " 3c",
    postalCode: "2066",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Henrik Sørensens vei",
    streetNumber: " 12",
    postalCode: "1065",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Grenseveien",
    streetNumber: " 52",
    postalCode: "0579",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ankerveien",
    streetNumber: "38 B",
    postalCode: "0785",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Kirkesvingen",
    streetNumber: "5B",
    postalCode: "0962",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Prost Holms Vei",
    streetNumber: "44",
    postalCode: "2019",
    city: "Skedsmokorset",
    country: "Norway",
  },
  {
    streetName: "Arctanders gate",
    streetNumber: "1",
    postalCode: "0192",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Prins Oscars gate",
    streetNumber: "23",
    postalCode: "3011",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Furumovegen",
    streetNumber: " 2",
    postalCode: "2066",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Lørenvangen",
    streetNumber: " 21B",
    postalCode: "0585",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hilton",
    streetNumber: "24A",
    postalCode: "2040",
    city: "Kløfta",
    country: "Norge",
  },
  {
    streetName: "Stockfleths gate",
    streetNumber: "53B",
    postalCode: "0461",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Røyskattstubben",
    streetNumber: "16",
    postalCode: "3420",
    city: "Lierskogen",
    country: "Norway",
  },
  {
    streetName: "Hyllveien",
    streetNumber: "11",
    postalCode: "0274",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fridtjof Nansens vei",
    streetNumber: " 7b",
    postalCode: "1463",
    city: "Fjellhamar",
    country: "Norway",
  },
  {
    streetName: "Gjallarveien",
    streetNumber: "8B",
    postalCode: "1412",
    city: "Sofiemyr",
    country: "Norge",
  },
  {
    streetName: "Sagadammen",
    streetNumber: "26",
    postalCode: "0884",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Gunnar Schjelderups vei",
    streetNumber: "11K",
    postalCode: "0485",
    city: "Oslo",
    country: "no",
  },
  {
    streetName: "Bestumveien",
    streetNumber: " 37D",
    postalCode: "0281",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Fjørtofts gate",
    streetNumber: "9",
    postalCode: "0461",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Konnerudgata",
    streetNumber: "34",
    postalCode: "3045",
    city: "Drammen",
    country: "NO",
  },
  {
    streetName: "Vallefaret",
    streetNumber: " 18",
    postalCode: "0663",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Prost Stabels Vei",
    streetNumber: "245",
    postalCode: "2019",
    city: "Skedsmokorset",
    country: "Norway",
  },
  {
    streetName: "Klokkerstuveien",
    streetNumber: "16",
    postalCode: "2016",
    city: "Frogner",
    country: "Norway",
  },
  {
    streetName: "Lysaker Brygge",
    streetNumber: "16",
    postalCode: "1366",
    city: "Lysaker",
    country: "Norge",
  },
  {
    streetName: "Johnsrudgata",
    streetNumber: " 37",
    postalCode: "1350",
    city: "Lommedalen",
    country: "Norway",
  },
  {
    streetName: "Lybekkveien",
    streetNumber: "8C",
    postalCode: "0772",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Angerstveien",
    streetNumber: "13D",
    postalCode: "1349",
    city: "Rykkinn",
    country: "Norge",
  },
  {
    streetName: "Liakollveien",
    streetNumber: "20",
    postalCode: "1259",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Holmenkollveien",
    streetNumber: "10a",
    postalCode: "0376",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Arups gate",
    streetNumber: "22A",
    postalCode: "0192",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vevelstadåsen",
    streetNumber: "24",
    postalCode: "1405",
    city: "Langhus",
    country: "Norge",
  },
  {
    streetName: "Frogneralleen",
    streetNumber: "5B",
    postalCode: "2016",
    city: "Frogner",
    country: "Norge",
  },
  {
    streetName: "Holumbakken",
    streetNumber: " 30",
    postalCode: "1481",
    city: "Hagan",
    country: "Norway",
  },
  {
    streetName: "Granfaret",
    streetNumber: " 27",
    postalCode: "1405",
    city: "Langhus",
    country: "Norway",
  },
  {
    streetName: "Nordbykroken 33",
    streetNumber: " H0103",
    postalCode: "1474",
    city: "Lørenskog",
    country: "Norway",
  },
  {
    streetName: "Mørtelverksbakken",
    streetNumber: "27",
    postalCode: "0580",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Fagerborggt.",
    streetNumber: "23",
    postalCode: "0360",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ammerudgrenda",
    streetNumber: "56",
    postalCode: "0960",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Lysaker Brygge",
    streetNumber: "2",
    postalCode: "1366",
    city: "Lysaker",
    country: "Norge",
  },
  {
    streetName: "Arnulf Øverlands vei",
    streetNumber: "228",
    postalCode: "0763",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skrabben",
    streetNumber: "1D",
    postalCode: "0682",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hellerudsvingen 22",
    streetNumber: " A",
    postalCode: "0684",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Tordenskiolds Gate",
    streetNumber: "83C",
    postalCode: "3044",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Bredo Stabellsvei , Bredo Stabell",
    streetNumber: "8 A",
    postalCode: "0853",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "St. Edmunds vei",
    streetNumber: " 33",
    postalCode: "0280",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Rudsberghaugen",
    streetNumber: "59",
    postalCode: "2005",
    city: "Rælingen",
    country: "Norway",
  },
  {
    streetName: "Søster Mathildes gate 27",
    streetNumber: " 1",
    postalCode: "1461",
    city: "Lørenskog",
    country: "Norway",
  },
  {
    streetName: "Bamseveien",
    streetNumber: "3B",
    postalCode: "0774",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Mosters vei",
    streetNumber: "29",
    postalCode: "1540",
    city: "Vestby",
    country: "Norge",
  },
  {
    streetName: "Gamle Drammensvei",
    streetNumber: "63",
    postalCode: "1369",
    city: "Stabekk",
    country: "NO",
  },
  {
    streetName: "Havnabakken",
    streetNumber: "2B",
    postalCode: "0874",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Grovavegen",
    streetNumber: " 2A",
    postalCode: "2067",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Ullagerveien",
    streetNumber: "15",
    postalCode: "0585",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ilaveien",
    streetNumber: " 2A",
    postalCode: "1481",
    city: "Hagan",
    country: "Norway",
  },
  {
    streetName: "Rustadgrenda",
    streetNumber: "47",
    postalCode: "0693",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Aslaug Vaas veg",
    streetNumber: "5 F",
    postalCode: "0766",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hans Øverlands Vei",
    streetNumber: "14B",
    postalCode: "1363",
    city: "Høvik",
    country: "Norway",
  },
  {
    streetName: "Schønings gate",
    streetNumber: "43",
    postalCode: "0362",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Maridalsveien",
    streetNumber: "227c",
    postalCode: "0467",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Jacobine Ryes Vei",
    streetNumber: "10",
    postalCode: "0987",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Brochmanns gate",
    streetNumber: "8D",
    postalCode: "0470",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bestemorstien",
    streetNumber: "11",
    postalCode: "1177",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Søster Mathildes gate",
    streetNumber: " 28",
    postalCode: "1461",
    city: "Lørenskog",
    country: "Norway",
  },
  {
    streetName: "Holenveien",
    streetNumber: " 32",
    postalCode: "1479",
    city: "Kurland",
    country: "Norway",
  },
  {
    streetName: "Neslia",
    streetNumber: " 3d",
    postalCode: "1344",
    city: "Haslum",
    country: "Norway",
  },
  {
    streetName: "Vålerveien",
    streetNumber: "24",
    postalCode: "1550",
    city: "Hølen",
    country: "Norway",
  },
  {
    streetName: "Gråkamveien 2P",
    streetNumber: "c/o 0777",
    postalCode: "0777",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sørhalla",
    streetNumber: "5",
    postalCode: "1344",
    city: "Haslum",
    country: "Norge",
  },
  {
    streetName: "Hammerstads Gate",
    streetNumber: "21A",
    postalCode: "0363",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Røykåsterrasse",
    streetNumber: "28",
    postalCode: "1476",
    city: "Rasta",
    country: "Norge",
  },
  {
    streetName: "Sankt Edmunds vei",
    streetNumber: "58",
    postalCode: "0280",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stallerudveien",
    streetNumber: " 93",
    postalCode: "0693",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Kanonhallveien",
    streetNumber: "36E",
    postalCode: "0585",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bogstadveien",
    streetNumber: "64A",
    postalCode: "0366",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hellerudstubben",
    streetNumber: " 18C",
    postalCode: "0671",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Solveien",
    streetNumber: " 57",
    postalCode: "1170",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Søndre vei",
    streetNumber: " 62",
    postalCode: "1397",
    city: "Nesøya",
    country: "Norway",
  },
  {
    streetName: "Maries gate",
    streetNumber: "9B",
    postalCode: "0368",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kavringen brygge",
    streetNumber: "3",
    postalCode: "0252",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frydenbergveien",
    streetNumber: "66B",
    postalCode: "1415",
    city: "Oppegård",
    country: "Norge",
  },
  {
    streetName: "Fjellgata",
    streetNumber: "30 A",
    postalCode: "0566",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Oppsjømarka",
    streetNumber: "41",
    postalCode: "1385",
    city: "Asker",
    country: "Norway",
  },
  {
    streetName: "Spireaveien",
    streetNumber: "4E",
    postalCode: "0580",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Østaveien",
    streetNumber: "26",
    postalCode: "1476",
    city: "Rasta",
    country: "Norway",
  },
  {
    streetName: "Hasleveien",
    streetNumber: "1C",
    postalCode: "0571",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ullern alle",
    streetNumber: "51",
    postalCode: "0381",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Laueveien",
    streetNumber: "21",
    postalCode: "1481",
    city: "Hagan",
    country: "Norway",
  },
  {
    streetName: "Doktor Wendts Gate",
    streetNumber: "4",
    postalCode: "1461",
    city: "Lørenskog",
    country: "Norge",
  },
  {
    streetName: "Haraldrudveien",
    streetNumber: "11",
    postalCode: "0581",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ekebergveien 5d",
    streetNumber: "c/o valeria engelund",
    postalCode: "0192",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kniveveien",
    streetNumber: "75",
    postalCode: "3036",
    city: "Drammen",
    country: "Norge",
  },
  {
    streetName: "Ragnhild Jølsens veg",
    streetNumber: "38C",
    postalCode: "2006",
    city: "Løvenstad",
    country: "Norge",
  },
  {
    streetName: "Ammerud Hellinga , 321",
    streetNumber: "43",
    postalCode: "0959",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Skogliveien",
    streetNumber: " 178A",
    postalCode: "3047",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Ovenstadlia",
    streetNumber: " 88",
    postalCode: "3420",
    city: "Lierskogen",
    country: "Norway",
  },
  {
    streetName: "Stovnerlia",
    streetNumber: " 19",
    postalCode: "0983",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Jonsrudveien",
    streetNumber: "1B",
    postalCode: "0274",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Regattaveien",
    streetNumber: "103",
    postalCode: "1364",
    city: "Fornebu",
    country: "Norway",
  },
  {
    streetName: "Østliveien",
    streetNumber: "24",
    postalCode: "1389",
    city: "Heggedal",
    country: "Norge",
  },
  {
    streetName: "Lokes vei",
    streetNumber: "133",
    postalCode: "1344",
    city: "Haslum",
    country: "Norge",
  },
  {
    streetName: "Åsenveien",
    streetNumber: "3",
    postalCode: "1440",
    city: "Drøbak",
    country: "Norge",
  },
  {
    streetName: "Jordbærsletta",
    streetNumber: "30",
    postalCode: "1410",
    city: "Kolbotn",
    country: "Norway",
  },
  {
    streetName: "Gjerdrumsgata",
    streetNumber: "9c",
    postalCode: "2000",
    city: "Lillestrøm",
    country: "Norway",
  },
  {
    streetName: "Hellaveien",
    streetNumber: " 141",
    postalCode: "1458",
    city: "Fjellstrand",
    country: "Norway",
  },
  {
    streetName: "Strandveien",
    streetNumber: "51",
    postalCode: "1545",
    city: "Hvitsten",
    country: "Norway",
  },
  {
    streetName: "Stavangergata",
    streetNumber: "39",
    postalCode: "0467",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sætreskogveien",
    streetNumber: "22B",
    postalCode: "1415",
    city: "Oppegård",
    country: "Norway",
  },
  {
    streetName: "Tante Ulrikkes Vei",
    streetNumber: "26B",
    postalCode: "0984",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hilton Nord 12",
    streetNumber: " D",
    postalCode: "2040",
    city: "Kløfta",
    country: "Norway",
  },
  {
    streetName: "Ekerudveien",
    streetNumber: "22",
    postalCode: "1476",
    city: "Rasta",
    country: "Norway",
  },
  {
    streetName: "Hoffsjef Løvenskioldsvei",
    streetNumber: "38",
    postalCode: "0382",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karl Staaffs vei",
    streetNumber: " 35",
    postalCode: "0665",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sporveisgata",
    streetNumber: " 6b",
    postalCode: "0354",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bakerovnsberget",
    streetNumber: "24",
    postalCode: "1353",
    city: "Bærums verk",
    country: "Norge",
  },
  {
    streetName: "Laurits Grønlands vei",
    streetNumber: "15",
    postalCode: "3035",
    city: "Drammen",
    country: "Norge",
  },
  {
    streetName: "Holmenveien",
    streetNumber: "28",
    postalCode: "0374",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vækerøveien",
    streetNumber: "122 D",
    postalCode: "0383",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Brønnbakken",
    streetNumber: " 16",
    postalCode: "3038",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "ullevålsveien",
    streetNumber: "94a",
    postalCode: "0451",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kleivveien",
    streetNumber: "49A",
    postalCode: "1356",
    city: "Bekkestua",
    country: "Norway",
  },
  {
    streetName: "Ombergveien",
    streetNumber: "6A",
    postalCode: "0283",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rausjøveien",
    streetNumber: " 96",
    postalCode: "1404",
    city: "Siggerud",
    country: "Norway",
  },
  {
    streetName: "Gunnar Schjelderups vei",
    streetNumber: " 33B",
    postalCode: "0485",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Neuberggata",
    streetNumber: "25A",
    postalCode: "0367",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Reverudåsen",
    streetNumber: "19A",
    postalCode: "1341",
    city: "Slependen",
    country: "Norge",
  },
  {
    streetName: "Flyveien",
    streetNumber: "13",
    postalCode: "0770",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Kanonhallveien",
    streetNumber: " 36F",
    postalCode: "0585",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Odins gate",
    streetNumber: "25 A",
    postalCode: "0266",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Rasmus Winderens vei",
    streetNumber: "16",
    postalCode: "0373",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Labråtan",
    streetNumber: " 20",
    postalCode: "1349",
    city: "Rykkinn",
    country: "Norway",
  },
  {
    streetName: "Gabels gate",
    streetNumber: "23a",
    postalCode: "0272",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skøyenveien",
    streetNumber: "77",
    postalCode: "0375",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hegdehaugsveien",
    streetNumber: "15",
    postalCode: "0352",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hagaliveien",
    streetNumber: "23A",
    postalCode: "1358",
    city: "Jar",
    country: "Norge",
  },
  {
    streetName: "Toppenhaugberget",
    streetNumber: " 10",
    postalCode: "1353",
    city: "Bærums verk",
    country: "Norway",
  },
  {
    streetName: "Hoff Terrasse",
    streetNumber: "2",
    postalCode: "0275",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Schønings gate",
    streetNumber: "41",
    postalCode: "0362",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karl Andersens vei",
    streetNumber: " 98",
    postalCode: "1088",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Oterfaret",
    streetNumber: "24",
    postalCode: "3033",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Sandakerveien",
    streetNumber: "22",
    postalCode: "0473",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ravnkollbakken",
    streetNumber: "20",
    postalCode: "0971",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rustadgrenda",
    streetNumber: " 21",
    postalCode: "0693",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Melumveien",
    streetNumber: "26",
    postalCode: "0760",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Arnstein Arnebergs vei",
    streetNumber: "14A",
    postalCode: "0274",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Midtåsen",
    streetNumber: "53B",
    postalCode: "1166",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Speidersletta",
    streetNumber: "18",
    postalCode: "1350",
    city: "Lommedalen",
    country: "Norge",
  },
  {
    streetName: "Brønnveien",
    streetNumber: "17",
    postalCode: "0283",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Christian Frederiks vei",
    streetNumber: "6C",
    postalCode: "0287",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Torstadåsen",
    streetNumber: "41c",
    postalCode: "1396",
    city: "Billingstad",
    country: "Norway",
  },
  {
    streetName: "Nedre Skogvei",
    streetNumber: "23",
    postalCode: "0281",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Trondheimsveien",
    streetNumber: " 75",
    postalCode: "0565",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Stigerveien",
    streetNumber: " 1",
    postalCode: "3031",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Tromsøgata",
    streetNumber: "3 A",
    postalCode: "0565",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Klostergata",
    streetNumber: "4",
    postalCode: "0656",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vækerøveien",
    streetNumber: "181",
    postalCode: "0751",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vitaminveien",
    streetNumber: "16",
    postalCode: "0483",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lørenveien",
    streetNumber: "46",
    postalCode: "0585",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Skårer Terrasse",
    streetNumber: " 10",
    postalCode: "1473",
    city: "Lørenskog",
    country: "Norway",
  },
  {
    streetName: "Nedre Kalbakkvei",
    streetNumber: "5",
    postalCode: "0950",
    city: "Oslo",
    country: "NO",
  },
  {
    streetName: "Ullevålsveien",
    streetNumber: "82",
    postalCode: "0452",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Arnulf Øverlands vei",
    streetNumber: "87",
    postalCode: "0763",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rimsoppfaret",
    streetNumber: "26",
    postalCode: "1476",
    city: "Rasta",
    country: "Norge",
  },
  {
    streetName: "Ankerveien",
    streetNumber: "105C",
    postalCode: "0766",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nedre Kiøsterudsgate",
    streetNumber: " 12",
    postalCode: "3023",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Stasjonsveien",
    streetNumber: "87",
    postalCode: "1365",
    city: "Blommenholm",
    country: "Norge",
  },
  {
    streetName: "Hans Nordahls Gate",
    streetNumber: "52",
    postalCode: "0481",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Vabråten",
    streetNumber: "95",
    postalCode: "1392",
    city: "Vettre",
    country: "Norge",
  },
  {
    streetName: "Lettvintveien",
    streetNumber: "50D",
    postalCode: "0487",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Haakon Tveters vei",
    streetNumber: "43",
    postalCode: "0686",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hyllveien",
    streetNumber: "11",
    postalCode: "0274",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Boligveien",
    streetNumber: " 43",
    postalCode: "3470",
    city: "Slemmestad",
    country: "Norway",
  },
  {
    streetName: "Bakkergata",
    streetNumber: "20",
    postalCode: "2004",
    city: "Lillestrøm",
    country: "Norway",
  },
  {
    streetName: "Langretta",
    streetNumber: "12",
    postalCode: "1279",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Flåtansvingen",
    streetNumber: "56",
    postalCode: "3413",
    city: "Lier",
    country: "Norge",
  },
  {
    streetName: "Sankt Halvards Gate",
    streetNumber: "1L",
    postalCode: "3015",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Ølstadveien",
    streetNumber: "22",
    postalCode: "3440",
    city: "Røyken",
    country: "Norge",
  },
  {
    streetName: "Enebakkveien",
    streetNumber: "178",
    postalCode: "0680",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brånåstunet",
    streetNumber: "14",
    postalCode: "2019",
    city: "Skedsmokorset",
    country: "Norway",
  },
  {
    streetName: "Jomfrubråtveien",
    streetNumber: "68B",
    postalCode: "1179",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Risalléen",
    streetNumber: "23B",
    postalCode: "0374",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Belgerudveien",
    streetNumber: "5A",
    postalCode: "0957",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Buskerudveien",
    streetNumber: "145B",
    postalCode: "3027",
    city: "Drammen",
    country: "Norge",
  },
  {
    streetName: "Nadderudåsen",
    streetNumber: "30",
    postalCode: "1357",
    city: "Bekkestua",
    country: "Norge",
  },
  {
    streetName: "Gamle Lommedalsvei",
    streetNumber: "133H",
    postalCode: "1348",
    city: "Rykkinn",
    country: "Norway",
  },
  {
    streetName: "Frogneralleen",
    streetNumber: "35",
    postalCode: "2016",
    city: "Frogner",
    country: "Norway",
  },
  {
    streetName: "Fjørtofts gate",
    streetNumber: "9",
    postalCode: "0461",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thomas heftes gate",
    streetNumber: "41A",
    postalCode: "0267",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ekebergveien",
    streetNumber: " 170",
    postalCode: "1177",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Harald Hårfagres vei",
    streetNumber: "18B",
    postalCode: "1412",
    city: "Sofiemyr",
    country: "Norge",
  },
  {
    streetName: "Jordbærsvingen",
    streetNumber: "43",
    postalCode: "1555",
    city: "Son",
    country: "Norge",
  },
  {
    streetName: "Mellomenga",
    streetNumber: "21",
    postalCode: "3404",
    city: "Lier",
    country: "Norge",
  },
  {
    streetName: "Korsvollbråtan",
    streetNumber: "17B",
    postalCode: "0880",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Blåbærsvingen",
    streetNumber: "15",
    postalCode: "0789",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tore Hunds vei",
    streetNumber: "1",
    postalCode: "0576",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fagertunveien",
    streetNumber: "4G",
    postalCode: "0281",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holmenkollveien",
    streetNumber: "80A",
    postalCode: "0784",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Stensgata",
    streetNumber: "35C",
    postalCode: "0358",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sandakerveien",
    streetNumber: "43",
    postalCode: "0477",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ankerveien",
    streetNumber: "222",
    postalCode: "1359",
    city: "Eiksmarka",
    country: "Norway",
  },
  {
    streetName: "Askveien",
    streetNumber: " 42",
    postalCode: "1482",
    city: "Nittedal",
    country: "Norway",
  },
  {
    streetName: "Blåbærtråkket",
    streetNumber: " 6a",
    postalCode: "2053",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Avlangruds vei",
    streetNumber: "10",
    postalCode: "1285",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Oskar Braatens vei",
    streetNumber: "4D",
    postalCode: "1412",
    city: "Sofiemyr",
    country: "Norge",
  },
  {
    streetName: "Haugerudhagan",
    streetNumber: " 12",
    postalCode: "0673",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Nybakveien 51",
    streetNumber: " b",
    postalCode: "2013",
    city: "Skjetten",
    country: "Norway",
  },
  {
    streetName: "Pastor fangensvei,",
    streetNumber: "39 c",
    postalCode: "0870",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Prinsessestien",
    streetNumber: "21",
    postalCode: "1391",
    city: "Vollen",
    country: "Norway",
  },
  {
    streetName: "Sørkedalsveien",
    streetNumber: " 76A",
    postalCode: "0377",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Skarpsnoveien",
    streetNumber: "11",
    postalCode: "3413",
    city: "Lier",
    country: "Norway",
  },
  {
    streetName: "Nils Lauritssøns vei",
    streetNumber: "23",
    postalCode: "0854",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Peter Møllers vei",
    streetNumber: "41",
    postalCode: "0585",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sletteløkka",
    streetNumber: "24A",
    postalCode: "0597",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Tullatoppen",
    streetNumber: "16",
    postalCode: "2016",
    city: "Frogner",
    country: "Norway",
  },
  {
    streetName: "Underhaugsveien",
    streetNumber: "47B",
    postalCode: "1358",
    city: "Jar",
    country: "Norge",
  },
  {
    streetName: "Christian Michelsens gate",
    streetNumber: "10",
    postalCode: "0568",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Østmarkveien",
    streetNumber: "5D",
    postalCode: "0687",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Furumogrenda",
    streetNumber: "15",
    postalCode: "1400",
    city: "Ski",
    country: "Norway",
  },
  {
    streetName: "Løvenskiolds gate",
    streetNumber: "20A",
    postalCode: "0260",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "President Harbitz' gate",
    streetNumber: "23A",
    postalCode: "0259",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holmenveien",
    streetNumber: "28A",
    postalCode: "0374",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Øivinds vei",
    streetNumber: "5",
    postalCode: "0590",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Birkelundveien",
    streetNumber: "63",
    postalCode: "1481",
    city: "Hagan",
    country: "Norway",
  },
  {
    streetName: "Rastastien",
    streetNumber: " 36",
    postalCode: "1476",
    city: "Rasta",
    country: "Norway",
  },
  {
    streetName: "Prost Stabels Vei,",
    streetNumber: "441",
    postalCode: "2019",
    city: "Skedsmokorset",
    country: "Norway",
  },
  {
    streetName: "Rødstenveien",
    streetNumber: "10",
    postalCode: "1420",
    city: "Svartskog",
    country: "Norway",
  },
  {
    streetName: "Frits Kiærs vei",
    streetNumber: " 5b",
    postalCode: "0383",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Damveien",
    streetNumber: "20A",
    postalCode: "0587",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Olleveien",
    streetNumber: "22E",
    postalCode: "1182",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fernanda Nissens gate,",
    streetNumber: "4A",
    postalCode: "0484",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Buholen",
    streetNumber: "52",
    postalCode: "2005",
    city: "Rælingen",
    country: "Norway",
  },
  {
    streetName: "Gamle Åsvei",
    streetNumber: "26C",
    postalCode: "1424",
    city: "Ski",
    country: "Norway",
  },
  {
    streetName: "Sars' Gate",
    streetNumber: "31M",
    postalCode: "0562",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Eikelundveien",
    streetNumber: " 3A",
    postalCode: "0588",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Pettersløkka",
    streetNumber: "27 D",
    postalCode: "3040",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Otto Ruges vei",
    streetNumber: "7",
    postalCode: "1359",
    city: "Eiksmarka",
    country: "Norge",
  },
  {
    streetName: "Nonnegata 15",
    streetNumber: "c/o Nonnegata 15",
    postalCode: "0656",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Magasinparken",
    streetNumber: "24",
    postalCode: "1423",
    city: "Ski",
    country: "Norge",
  },
  {
    streetName: "Olaf Bulls vei",
    streetNumber: " 74",
    postalCode: "0765",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sandakerveien",
    streetNumber: "16",
    postalCode: "0473",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fjellstrandveien",
    streetNumber: "11",
    postalCode: "1458",
    city: "Fjellstrand",
    country: "Norway",
  },
  {
    streetName: "Skogveien",
    streetNumber: " 18B",
    postalCode: "1430",
    city: "Ås",
    country: "Norway",
  },
  {
    streetName: "Lørenveien",
    streetNumber: "46",
    postalCode: "0580",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nordahl Bruns gate",
    streetNumber: "12A",
    postalCode: "3018",
    city: "Drammen",
    country: "Norge",
  },
  {
    streetName: "Thorvald Meyers gate",
    streetNumber: "4A",
    postalCode: "0555",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Tyttebærstien",
    streetNumber: "28",
    postalCode: "1476",
    city: "Rasta",
    country: "Norge",
  },
  {
    streetName: "Åsenhagen 14",
    streetNumber: " F",
    postalCode: "2020",
    city: "Skedsmokorset",
    country: "Norway",
  },
  {
    streetName: "Solbergs gate",
    streetNumber: " 16b",
    postalCode: "3040",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Tunveien",
    streetNumber: " 20F",
    postalCode: "3025",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Nebbejordet",
    streetNumber: " 23",
    postalCode: "1266",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Tante Ulrikkes vei",
    streetNumber: " 50a",
    postalCode: "0984",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Haakon Tveters vei",
    streetNumber: "43",
    postalCode: "0686",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hovdeveien",
    streetNumber: " 5",
    postalCode: "1470",
    city: "Lørenskog",
    country: "Norway",
  },
  {
    streetName: "Iver Holters vei",
    streetNumber: "41",
    postalCode: "1410",
    city: "Kolbotn",
    country: "NO",
  },
  {
    streetName: "Skogryggveien",
    streetNumber: "15d",
    postalCode: "0781",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nils Lauritssøns vei",
    streetNumber: "23",
    postalCode: "0854",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Haugerudveien",
    streetNumber: "78",
    postalCode: "0674",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ensjøveien",
    streetNumber: "21F",
    postalCode: "0655",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Pastor Fangens vei",
    streetNumber: "47b",
    postalCode: "0870",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Østbrenne",
    streetNumber: "9",
    postalCode: "1339",
    city: "Vøyenenga",
    country: "Norge",
  },
  {
    streetName: "Alexander Kiellands gate",
    streetNumber: "7B",
    postalCode: "2000",
    city: "Lillestrøm",
    country: "Norge",
  },
  {
    streetName: "Harald Rømckes vei",
    streetNumber: "12",
    postalCode: "0286",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Steinliveien 5",
    streetNumber: " D",
    postalCode: "1185",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Kristiansands gate",
    streetNumber: "12C",
    postalCode: "0463",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ovenbakken",
    streetNumber: "16A",
    postalCode: "1361",
    city: "Østerås",
    country: "Norway",
  },
  {
    streetName: "Sandakerveien",
    streetNumber: "121",
    postalCode: "0484",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Leirfivelveien",
    streetNumber: " 23",
    postalCode: "1346",
    city: "Gjettum",
    country: "Norway",
  },
  {
    streetName: "Hanevoldveien",
    streetNumber: "26E",
    postalCode: "1384",
    city: "Asker",
    country: "Norway",
  },
  {
    streetName: "Ensjøveien",
    streetNumber: "21l",
    postalCode: "0655",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Langåsveien",
    streetNumber: "10",
    postalCode: "0880",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Midttunveien",
    streetNumber: "15",
    postalCode: "1177",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Thorvald Meyers gate",
    streetNumber: " 2b",
    postalCode: "0555",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Krokusveien",
    streetNumber: "32",
    postalCode: "1475",
    city: "Finstadjordet",
    country: "Norway",
  },
  {
    streetName: "Haakon Tveters vei",
    streetNumber: "43",
    postalCode: "0686",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Riihimækiveien",
    streetNumber: " 17",
    postalCode: "2013",
    city: "Skjetten",
    country: "Norway",
  },
  {
    streetName: "Kjølberggata",
    streetNumber: "13A",
    postalCode: "0653",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ståvihagan 12",
    streetNumber: " B",
    postalCode: "1339",
    city: "Vøyenenga",
    country: "Norway",
  },
  {
    streetName: "Smalgangen",
    streetNumber: " 28",
    postalCode: "0188",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "PASTOR FANGENS VEI",
    streetNumber: "47B",
    postalCode: "0870",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Jon P. Erliens vei",
    streetNumber: " 1A",
    postalCode: "0858",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Vestbyveien",
    streetNumber: "57",
    postalCode: "1440",
    city: "Drøbak",
    country: "Norge",
  },
  {
    streetName: "Henrik Ibsens gate",
    streetNumber: " 48",
    postalCode: "3022",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Wessels gate 15",
    streetNumber: "c/o Wessels gate 15",
    postalCode: "0165",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "St. Olavs gate 5",
    streetNumber: " C",
    postalCode: "0165",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Låveveien",
    streetNumber: " 23A",
    postalCode: "0682",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Kristian Bogneruds vei",
    streetNumber: "37A",
    postalCode: "0956",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Welhavens gate",
    streetNumber: " 9a",
    postalCode: "0350",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Langretta",
    streetNumber: "12",
    postalCode: "1279",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Kurlandstien",
    streetNumber: "8 e",
    postalCode: "1052",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Martin Skatvedts vei",
    streetNumber: " 10",
    postalCode: "0950",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Drammensveien",
    streetNumber: " 97B",
    postalCode: "0273",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hagastubben",
    streetNumber: "40",
    postalCode: "0980",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Konnerudgata",
    streetNumber: " 10A",
    postalCode: "3045",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Sigurd Hoels vei",
    streetNumber: "65",
    postalCode: "0655",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "john colletts alle",
    streetNumber: "57",
    postalCode: "0854",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Fougstads gate",
    streetNumber: "25 b",
    postalCode: "0173",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frøyas vei",
    streetNumber: " 60",
    postalCode: "3472",
    city: "Bødalen",
    country: "Norway",
  },
  {
    streetName: "Åsbrekka",
    streetNumber: "17",
    postalCode: "0790",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Inkognitogata 6",
    streetNumber: "c/o Farrokhi",
    postalCode: "0258",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bergveien",
    streetNumber: "9",
    postalCode: "1450",
    city: "Nesoddtangen",
    country: "Norge",
  },
  {
    streetName: "Ringedammen",
    streetNumber: "1",
    postalCode: "1440",
    city: "Drøbak",
    country: "Norge",
  },
  {
    streetName: "Einars vei",
    streetNumber: "42",
    postalCode: "0575",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Gamle Bygdevei",
    streetNumber: "120",
    postalCode: "1284",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Gamleveien",
    streetNumber: " 3",
    postalCode: "1540",
    city: "Vestby",
    country: "Norway",
  },
  {
    streetName: "roverudsgate",
    streetNumber: "9 ",
    postalCode: "0481",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Heimdalsgata",
    streetNumber: " 8",
    postalCode: "0561",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Nordkappgata",
    streetNumber: "17",
    postalCode: "0479",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rolf E. Stenersens allé",
    streetNumber: "10",
    postalCode: "0858",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kurlandsåsen",
    streetNumber: " 61d",
    postalCode: "1479",
    city: "Kurland",
    country: "Norway",
  },
  {
    streetName: "Sofie Roes Veg",
    streetNumber: "4",
    postalCode: "2040",
    city: "Kløfta",
    country: "Norge",
  },
  {
    streetName: "Starveien",
    streetNumber: "74B",
    postalCode: "1088",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Gråtrostveien",
    streetNumber: "10",
    postalCode: "1555",
    city: "Son",
    country: "Norway",
  },
  {
    streetName: "Nordre Dølibekken",
    streetNumber: "12",
    postalCode: "2050",
    city: "Jessheim",
    country: "Norge",
  },
  {
    streetName: "Grønnegata",
    streetNumber: " 13A",
    postalCode: "0350",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Fornebuveien",
    streetNumber: " 10e",
    postalCode: "1366",
    city: "Lysaker",
    country: "Norway",
  },
  {
    streetName: "Åsløkkveien 42",
    streetNumber: " c",
    postalCode: "1341",
    city: "Slependen",
    country: "Norway",
  },
  {
    streetName: "Borggata",
    streetNumber: " 8",
    postalCode: "0650",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Solbergliveien",
    streetNumber: "97",
    postalCode: "0683",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kleiva",
    streetNumber: "11B",
    postalCode: "1088",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Lindebergveien",
    streetNumber: "23 A",
    postalCode: "1069",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Lilleruts vei",
    streetNumber: "26D",
    postalCode: "1364",
    city: "Fornebu",
    country: "Norway",
  },
  {
    streetName: "Ammerud Hellinga , 321",
    streetNumber: "43",
    postalCode: "0959",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Lillerudveien",
    streetNumber: " 175",
    postalCode: "1454",
    city: "Fagerstrand",
    country: "Norway",
  },
  {
    streetName: "Nordre Dølibekken",
    streetNumber: "12",
    postalCode: "2050",
    city: "Jessheim",
    country: "Norge",
  },
  {
    streetName: "Laura Gundersens gate",
    streetNumber: "6",
    postalCode: "0168",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hareveien",
    streetNumber: " 5A",
    postalCode: "1413",
    city: "Tårnåsen",
    country: "Norway",
  },
  {
    streetName: "Hans Hansens vei",
    streetNumber: "105",
    postalCode: "3022",
    city: "Drammen",
    country: "Norge",
  },
  {
    streetName: "Skogryggveien",
    streetNumber: "17C",
    postalCode: "0781",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bjerkeveien",
    streetNumber: " 11",
    postalCode: "1350",
    city: "Lommedalen",
    country: "Norway",
  },
  {
    streetName: "Midtveien",
    streetNumber: " 7c",
    postalCode: "0583",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Dugnadsveien",
    streetNumber: "19",
    postalCode: "0590",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Heggedalsveien",
    streetNumber: " 275",
    postalCode: "1389",
    city: "Heggedal",
    country: "Norway",
  },
  {
    streetName: "Ørekyteveien",
    streetNumber: " 6B",
    postalCode: "1481",
    city: "Hagan",
    country: "Norway",
  },
  {
    streetName: "Lachmannsvei",
    streetNumber: "40",
    postalCode: "0495",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Åslistubben",
    streetNumber: "25",
    postalCode: "2013",
    city: "Skjetten",
    country: "Norway",
  },
  {
    streetName: "Marta Steinsviks vei",
    streetNumber: " 21",
    postalCode: "1283",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Holenveien",
    streetNumber: " 7a",
    postalCode: "1479",
    city: "Kurland",
    country: "Norway",
  },
  {
    streetName: "Gladengveien 18",
    streetNumber: "c/o Marcus Hauge Norli",
    postalCode: "0661",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Huldretunet",
    streetNumber: "24",
    postalCode: "1461",
    city: "Lørenskog",
    country: "Norge",
  },
  {
    streetName: "Feltspatveien 37",
    streetNumber: " 1155",
    postalCode: "1155",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sars gate",
    streetNumber: "31 M",
    postalCode: "0562",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rosteds gate",
    streetNumber: " 5",
    postalCode: "0178",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Økern Torgvei",
    streetNumber: " 9F",
    postalCode: "0580",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Åsveien",
    streetNumber: "3A",
    postalCode: "1470",
    city: "Lørenskog",
    country: "Norway",
  },
  {
    streetName: "Idas Vei",
    streetNumber: "150",
    postalCode: "0981",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Gesellsvingen",
    streetNumber: "25",
    postalCode: "1348",
    city: "Rykkinn",
    country: "Norway",
  },
  {
    streetName: "Sandbekkfaret",
    streetNumber: "71",
    postalCode: "1479",
    city: "Kurland",
    country: "Norway",
  },
  {
    streetName: "Bogstadveien",
    streetNumber: " 25",
    postalCode: "0355",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Skogryggveien",
    streetNumber: "16 G",
    postalCode: "0781",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Peter Møllers vei",
    streetNumber: "5A",
    postalCode: "0585",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ole Brumms vei",
    streetNumber: "30",
    postalCode: "0979",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nadderudveien",
    streetNumber: "176",
    postalCode: "1359",
    city: "Eiksmarka",
    country: "Norge",
  },
  {
    streetName: "Majorstuveien",
    streetNumber: "9",
    postalCode: "0353",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Myrerskogveien",
    streetNumber: "29B",
    postalCode: "0495",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sigurdsgate",
    streetNumber: "20 b",
    postalCode: "0650",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Børønningen",
    streetNumber: " 11",
    postalCode: "3472",
    city: "Bødalen",
    country: "Norway",
  },
  {
    streetName: "Leiv Eirikssons vei",
    streetNumber: "4 ",
    postalCode: "1463",
    city: "Fjellhamar",
    country: "Norway",
  },
  {
    streetName: "Mørtelverksbakken",
    streetNumber: "27",
    postalCode: "0580",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Rustadveien",
    streetNumber: " 17",
    postalCode: "3470",
    city: "Slemmestad",
    country: "Norway",
  },
  {
    streetName: "Ankerveien",
    streetNumber: "51C",
    postalCode: "0785",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Larsbråtveien",
    streetNumber: " 263",
    postalCode: "0674",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bølerbakken",
    streetNumber: " 3b",
    postalCode: "0690",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Skallumjordet",
    streetNumber: "11",
    postalCode: "1368",
    city: "Stabekk",
    country: "Norway",
  },
  {
    streetName: "Feltspatveien",
    streetNumber: "43",
    postalCode: "1155",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Blåveisringen",
    streetNumber: "5G",
    postalCode: "2052",
    city: "Jessheim",
    country: "Norge",
  },
  {
    streetName: "Sigurd Hoels vei",
    streetNumber: " 98",
    postalCode: "0655",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Rødstenveien",
    streetNumber: "10",
    postalCode: "1420",
    city: "Svartskog",
    country: "Norway",
  },
  {
    streetName: "Keysers gate",
    streetNumber: " 7C",
    postalCode: "0165",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Lyngåsveien",
    streetNumber: " 5",
    postalCode: "0491",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Konventveien",
    streetNumber: "40B",
    postalCode: "0377",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Furumogrenda",
    streetNumber: "15",
    postalCode: "1400",
    city: "Ski",
    country: "Norway",
  },
  {
    streetName: "Almeveien",
    streetNumber: "6 d",
    postalCode: "0855",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bamsefaret",
    streetNumber: "3A",
    postalCode: "1362",
    city: "Hosle",
    country: "Norway",
  },
  {
    streetName: "Østreheimsveien",
    streetNumber: "2M",
    postalCode: "0590",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Åsmohagan",
    streetNumber: " 10",
    postalCode: "2052",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Løvenstadtorget",
    streetNumber: "9",
    postalCode: "2006",
    city: "Løvenstad",
    country: "Norway",
  },
  {
    streetName: "Siebkes gate",
    streetNumber: "5B",
    postalCode: "0562",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Reineveien",
    streetNumber: " 19",
    postalCode: "1454",
    city: "Fagerstrand",
    country: "Norway",
  },
  {
    streetName: "Tjernveien,",
    streetNumber: "18",
    postalCode: "0957",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sagmesterveien",
    streetNumber: "44",
    postalCode: "1414",
    city: "Trollåsen",
    country: "Norway",
  },
  {
    streetName: "Landøyveien",
    streetNumber: "36",
    postalCode: "1394",
    city: "Nesbru",
    country: "Norway",
  },
  {
    streetName: "Kvartsveien",
    streetNumber: " 76",
    postalCode: "1435",
    city: "Ås",
    country: "Norway",
  },
  {
    streetName: "Olleveien",
    streetNumber: " 10C",
    postalCode: "1182",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bergerlia",
    streetNumber: " 12",
    postalCode: "3470",
    city: "Slemmestad",
    country: "Norway",
  },
  {
    streetName: "Veidemannsveien",
    streetNumber: " 27a",
    postalCode: "1407",
    city: "Vinterbro",
    country: "Norway",
  },
  {
    streetName: "Årvollveien",
    streetNumber: "54 H",
    postalCode: "0590",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sofie Roes Veg",
    streetNumber: "20",
    postalCode: "2040",
    city: "Kløfta",
    country: "Norge",
  },
  {
    streetName: "Grevlingåsen",
    streetNumber: " 6",
    postalCode: "1362",
    city: "Hosle",
    country: "Norway",
  },
  {
    streetName: "Vikingveien",
    streetNumber: "6",
    postalCode: "1170",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rådyrveien",
    streetNumber: " 50",
    postalCode: "3032",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Slyngveien",
    streetNumber: " 26g",
    postalCode: "0376",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Operagata",
    streetNumber: " 20",
    postalCode: "0194",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Tyslevveien",
    streetNumber: "40C",
    postalCode: "1163",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Steinborgveien",
    streetNumber: " 38",
    postalCode: "0678",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hoffsveien",
    streetNumber: "65e",
    postalCode: "0377",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rugdefaret",
    streetNumber: "3B",
    postalCode: "1341",
    city: "Slependen",
    country: "Norway",
  },
  {
    streetName: "Brinken 35 leilighet",
    streetNumber: " 510",
    postalCode: "0654",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Brinken 35 leilighet",
    streetNumber: " 510",
    postalCode: "0654",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ingeniørveien",
    streetNumber: " 18",
    postalCode: "0196",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Stovnerveien",
    streetNumber: "36 B",
    postalCode: "0982",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Furulia",
    streetNumber: "13G",
    postalCode: "1405",
    city: "Langhus",
    country: "Norway",
  },
  {
    streetName: "Waldemar Thranes gate",
    streetNumber: "61",
    postalCode: "0173",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Drengsrudhagen",
    streetNumber: "164",
    postalCode: "1385",
    city: "Asker",
    country: "Norway",
  },
  {
    streetName: "Frognerseterveien",
    streetNumber: "47A",
    postalCode: "0776",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Gørbitz gate",
    streetNumber: "10",
    postalCode: "0360",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Innspurten",
    streetNumber: " 6A",
    postalCode: "0663",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hauges vei",
    streetNumber: "17",
    postalCode: "0871",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bergsalléen",
    streetNumber: " 11a",
    postalCode: "0854",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Tøyengata",
    streetNumber: "36C",
    postalCode: "0578",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ingelbrecht Knudssøns gate",
    streetNumber: "4A",
    postalCode: "0365",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Prestegårdsveien,",
    streetNumber: "39",
    postalCode: "0851",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hekkveien",
    streetNumber: "1",
    postalCode: "0571",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karisvingen",
    streetNumber: " 2",
    postalCode: "2053",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Gamle Lommedalsvei",
    streetNumber: "131d",
    postalCode: "1348",
    city: "Rykkinn",
    country: "Norway",
  },
  {
    streetName: "Toftes Gate",
    streetNumber: "50B",
    postalCode: "0556",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ris Skolevei",
    streetNumber: " 19e",
    postalCode: "0373",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hagaveien 12",
    streetNumber: " f",
    postalCode: "0980",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ringstabekkveien",
    streetNumber: "60A",
    postalCode: "1358",
    city: "Jar",
    country: "Norge",
  },
  {
    streetName: "John Colletts allé",
    streetNumber: "81",
    postalCode: "0854",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ødegårds vei",
    streetNumber: "5",
    postalCode: "1470",
    city: "Lørenskog",
    country: "Norge",
  },
  {
    streetName: "Thorleif Haugs vei",
    streetNumber: "49",
    postalCode: "0791",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Peter møllers vei",
    streetNumber: "41",
    postalCode: "0585",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rudsdalen,",
    streetNumber: "14",
    postalCode: "1346",
    city: "Gjettum",
    country: "Norway",
  },
  {
    streetName: "Jegerveien",
    streetNumber: "5",
    postalCode: "0777",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Linna",
    streetNumber: " 17",
    postalCode: "2016",
    city: "Frogner",
    country: "Norway",
  },
  {
    streetName: "Granholtet",
    streetNumber: "16",
    postalCode: "1412",
    city: "Sofiemyr",
    country: "Norway",
  },
  {
    streetName: "Frydens gate",
    streetNumber: " 5D",
    postalCode: "0564",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Dalejordet",
    streetNumber: " 3",
    postalCode: "3014",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Mjølners vei",
    streetNumber: "1E",
    postalCode: "1445",
    city: "Drøbak",
    country: "Norge",
  },
  {
    streetName: "Myrveien",
    streetNumber: "39B",
    postalCode: "1406",
    city: "Ski",
    country: "Norge",
  },
  {
    streetName: "Nannas Vei",
    streetNumber: "15",
    postalCode: "1540",
    city: "Vestby",
    country: "Norway",
  },
  {
    streetName: "Faunveien",
    streetNumber: "3",
    postalCode: "0781",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skanseveien",
    streetNumber: "3B",
    postalCode: "1086",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "sandåsveien",
    streetNumber: "6c",
    postalCode: "0956",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Konvallveien",
    streetNumber: " 28",
    postalCode: "0855",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hans Nielsen Hauges gate",
    streetNumber: " 44c",
    postalCode: "0481",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Moraveien",
    streetNumber: "43",
    postalCode: "1540",
    city: "Vestby",
    country: "Norge",
  },
  {
    streetName: "Furuset allé",
    streetNumber: "27C",
    postalCode: "1053",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Edvard Griegs Allé",
    streetNumber: "19B",
    postalCode: "0479",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sophies vei",
    streetNumber: " 28",
    postalCode: "1542",
    city: "Vestby",
    country: "Norway",
  },
  {
    streetName: "Amtmann Meinichs gate 14",
    streetNumber: " d",
    postalCode: "0482",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ljabruveien",
    streetNumber: " 40C",
    postalCode: "1167",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Nedre Lerkeveg",
    streetNumber: " 6",
    postalCode: "2067",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Solbergskogen",
    streetNumber: " 10",
    postalCode: "1406",
    city: "Ski",
    country: "Norway",
  },
  {
    streetName: "Lofsrudhøgda",
    streetNumber: "19E",
    postalCode: "1281",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Odvar Solbergs vei",
    streetNumber: "134",
    postalCode: "0973",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Valdresgata",
    streetNumber: "5",
    postalCode: "0557",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kastanjeveien",
    streetNumber: "116",
    postalCode: "3026",
    city: "Drammen",
    country: "Norge",
  },
  {
    streetName: "Kastanjeveien",
    streetNumber: "116",
    postalCode: "3026",
    city: "Drammen",
    country: "Norge",
  },
  {
    streetName: "Geitmyrsveien",
    streetNumber: "70",
    postalCode: "0455",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lørenveien",
    streetNumber: "16",
    postalCode: "0585",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Cort Adelers gate",
    streetNumber: " 20",
    postalCode: "0254",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Refstadveien",
    streetNumber: "42",
    postalCode: "0584",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grensegata",
    streetNumber: "21",
    postalCode: "2003",
    city: "Lillestrøm",
    country: "Norge",
  },
  {
    streetName: "Vetlandsveien",
    streetNumber: "93 A",
    postalCode: "0685",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Dronningveien",
    streetNumber: "66",
    postalCode: "1550",
    city: "Hølen",
    country: "Norge",
  },
  {
    streetName: "Stamhusveien",
    streetNumber: "45",
    postalCode: "1181",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hagastubben",
    streetNumber: "40",
    postalCode: "0980",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ullevålsveien",
    streetNumber: "82A",
    postalCode: "0454",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Laueveien",
    streetNumber: "21",
    postalCode: "1481",
    city: "Hagan",
    country: "Norway",
  },
  {
    streetName: "Malmøgata",
    streetNumber: "3",
    postalCode: "0566",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Niels Høeghs Vei",
    streetNumber: "33",
    postalCode: "1465",
    city: "Strømmen",
    country: "Norway",
  },
  {
    streetName: "Normannsgata",
    streetNumber: "75",
    postalCode: "0655",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Elmholt Allé",
    streetNumber: "3A",
    postalCode: "0275",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Andrénbakken",
    streetNumber: "14B",
    postalCode: "1392",
    city: "Vettre",
    country: "Norway",
  },
  {
    streetName: "Lystadvegen",
    streetNumber: " 29",
    postalCode: "2040",
    city: "Kløfta",
    country: "Norway",
  },
  {
    streetName: "Theodor Hansens Vei",
    streetNumber: "1A",
    postalCode: "1410",
    city: "Kolbotn",
    country: "Norway",
  },
  {
    streetName: "Trondheimsveien",
    streetNumber: " 433d",
    postalCode: "0953",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Skogryggveien",
    streetNumber: "4B",
    postalCode: "0781",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Gullberget",
    streetNumber: " 94B",
    postalCode: "1354",
    city: "Bærums verk",
    country: "Norway",
  },
  {
    streetName: "Bolstadhagen",
    streetNumber: "65",
    postalCode: "3028",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Transetveien",
    streetNumber: " 6",
    postalCode: "3440",
    city: "Røyken",
    country: "Norway",
  },
  {
    streetName: "Hovinveien",
    streetNumber: "11A",
    postalCode: "0576",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skanseveien",
    streetNumber: "8b",
    postalCode: "1086",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Alværnlia",
    streetNumber: " 7",
    postalCode: "1453",
    city: "Bjørnemyr",
    country: "Norway",
  },
  {
    streetName: "Lindebergveien",
    streetNumber: " 43a",
    postalCode: "1069",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Husebybakken",
    streetNumber: "30",
    postalCode: "0379",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jerpefaret",
    streetNumber: "35",
    postalCode: "0788",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sømveien",
    streetNumber: "25",
    postalCode: "0493",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Landingsveien",
    streetNumber: " 136",
    postalCode: "0767",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Trondheimsvegen - Jessheim",
    streetNumber: " 98E",
    postalCode: "2050",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Refstad alle",
    streetNumber: "32",
    postalCode: "0586",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Manesjen",
    streetNumber: "4B",
    postalCode: "2050",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Ryghs Vei",
    streetNumber: "7B",
    postalCode: "0786",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Osloveien",
    streetNumber: "20",
    postalCode: "1440",
    city: "Drøbak",
    country: "Norway",
  },
  {
    streetName: "Grusveien",
    streetNumber: "42",
    postalCode: "1158",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Øvre Grønliveien",
    streetNumber: " 20",
    postalCode: "1464",
    city: "Fjellhamar",
    country: "Norway",
  },
  {
    streetName: "Hosleveien",
    streetNumber: " 28",
    postalCode: "1358",
    city: "Jar",
    country: "Norway",
  },
  {
    streetName: "Georg Frølichs vei",
    streetNumber: "36",
    postalCode: "1482",
    city: "Nittedal",
    country: "Norway",
  },
  {
    streetName: "Skarvaveien",
    streetNumber: "68",
    postalCode: "1350",
    city: "Lommedalen",
    country: "Norway",
  },
  {
    streetName: "Vågebyveien",
    streetNumber: "17",
    postalCode: "0569",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sankt Halvards gate",
    streetNumber: "33",
    postalCode: "0192",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Utsiktsveien",
    streetNumber: "11",
    postalCode: "1369",
    city: "Stabekk",
    country: "Norge",
  },
  {
    streetName: "Hamarveien",
    streetNumber: "8b",
    postalCode: "1464",
    city: "Fjellhamar",
    country: "Norway",
  },
  {
    streetName: "Ekornvegen",
    streetNumber: " 24",
    postalCode: "2014",
    city: "Blystadlia",
    country: "Norway",
  },
  {
    streetName: "Oreliveien",
    streetNumber: "12B",
    postalCode: "0580",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Trygve Nilsens vei",
    streetNumber: " 28i",
    postalCode: "1061",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "underhaugsveien",
    streetNumber: "25b",
    postalCode: "1358",
    city: "Jar",
    country: "Norge",
  },
  {
    streetName: "Turbinveien 21",
    streetNumber: "c/o Viktor Pavlov",
    postalCode: "0195",
    city: "Oslo",
    country: "no",
  },
  {
    streetName: "Lillogata",
    streetNumber: "5E",
    postalCode: "0484",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lønnestien",
    streetNumber: "10A",
    postalCode: "1344",
    city: "Haslum",
    country: "Norge",
  },
  {
    streetName: "Lunneveien",
    streetNumber: "3",
    postalCode: "2006",
    city: "Løvenstad",
    country: "Norge",
  },
  {
    streetName: "Trondheimsveien 174",
    streetNumber: "c/o Leilighet H0303",
    postalCode: "0570",
    city: "Oslo",
    country: "no",
  },
  {
    streetName: "Eilert Sundts gate",
    streetNumber: "33",
    postalCode: "0259",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Romeriksgata",
    streetNumber: "49",
    postalCode: "2003",
    city: "Lillestrøm",
    country: "Norway",
  },
  {
    streetName: "Konglefaret ,",
    streetNumber: "28",
    postalCode: "1359",
    city: "Eiksmarka",
    country: "Norway",
  },
  {
    streetName: "Nedre Ullern Terrasse",
    streetNumber: "13C",
    postalCode: "0280",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Torstadskogen",
    streetNumber: "49B",
    postalCode: "1395",
    city: "Hvalstad",
    country: "Norway",
  },
  {
    streetName: "Lindemans gate",
    streetNumber: " 3A",
    postalCode: "0267",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bygdøy allé",
    streetNumber: "69",
    postalCode: "0265",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skolegata",
    streetNumber: "15",
    postalCode: "2010",
    city: "Strømmen",
    country: "Norge",
  },
  {
    streetName: "Jacob Aalls gate",
    streetNumber: "25A",
    postalCode: "0364",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Doktor Kobros vei",
    streetNumber: "6",
    postalCode: "1474",
    city: "Nordbyhagen",
    country: "Norway",
  },
  {
    streetName: "Thorvald Meyers gate",
    streetNumber: "60B",
    postalCode: "0552",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vardepynten",
    streetNumber: "12",
    postalCode: "2020",
    city: "Skedsmokorset",
    country: "NO",
  },
  {
    streetName: "Svalestien",
    streetNumber: " 43",
    postalCode: "1407",
    city: "Vinterbro",
    country: "Norway",
  },
  {
    streetName: "Badebakken",
    streetNumber: " 4",
    postalCode: "0467",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Engebråtenveien",
    streetNumber: "29",
    postalCode: "3470",
    city: "Slemmestad",
    country: "Norway",
  },
  {
    streetName: "Hyllebærstien",
    streetNumber: "19",
    postalCode: "1405",
    city: "Langhus",
    country: "Norway",
  },
  {
    streetName: "Sørli Terrasse",
    streetNumber: "41",
    postalCode: "1473",
    city: "Lørenskog",
    country: "Norge",
  },
  {
    streetName: "Kunterudveien",
    streetNumber: "14C",
    postalCode: "3426",
    city: "Gullaug",
    country: "Norway",
  },
  {
    streetName: "Heimlibakken",
    streetNumber: "12",
    postalCode: "0198",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Oppsalveien",
    streetNumber: "25b",
    postalCode: "0686",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Stensvegen",
    streetNumber: "31A",
    postalCode: "2053",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Sætreskogveien.",
    streetNumber: "22 B",
    postalCode: "1415",
    city: "Oppegård",
    country: "Norway",
  },
  {
    streetName: "Leirfallsgata",
    streetNumber: "5",
    postalCode: "0550",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tjernsliveien",
    streetNumber: "4",
    postalCode: "1415",
    city: "Oppegård",
    country: "Norway",
  },
  {
    streetName: "Sørli Terrasse",
    streetNumber: "32",
    postalCode: "1473",
    city: "Lørenskog",
    country: "Norge",
  },
  {
    streetName: "Carl Lundgrens vei",
    streetNumber: "18",
    postalCode: "0284",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tullatoppen",
    streetNumber: "16",
    postalCode: "2016",
    city: "Frogner",
    country: "Norway",
  },
  {
    streetName: "Langsetveien",
    streetNumber: "26",
    postalCode: "0495",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stjerneveien",
    streetNumber: " 15",
    postalCode: "1482",
    city: "Nittedal",
    country: "Norway",
  },
  {
    streetName: "Aasmund Vinjes vei",
    streetNumber: " 32",
    postalCode: "0373",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bøkkerveien",
    streetNumber: "34B",
    postalCode: "0579",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Etterstadkroken",
    streetNumber: " 5E",
    postalCode: "0659",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Stavangergata",
    streetNumber: "44B",
    postalCode: "0467",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Maridalsveien",
    streetNumber: "90",
    postalCode: "0458",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ombergveien",
    streetNumber: "2",
    postalCode: "0283",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vålveien",
    streetNumber: "6",
    postalCode: "0852",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Øvrevollveien",
    streetNumber: "8",
    postalCode: "1358",
    city: "Jar",
    country: "Norge",
  },
  {
    streetName: "Dueveien",
    streetNumber: " 17",
    postalCode: "2016",
    city: "Frogner",
    country: "Norway",
  },
  {
    streetName: "Hekkveien",
    streetNumber: "11",
    postalCode: "0571",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Robert Millars vei",
    streetNumber: "15",
    postalCode: "1185",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lauritz Hervigs vei",
    streetNumber: "115C",
    postalCode: "3035",
    city: "Drammen",
    country: "Norge",
  },
  {
    streetName: "Danmarks gate",
    streetNumber: "8A",
    postalCode: "0658",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Oscar Borgs vei",
    streetNumber: "11 A",
    postalCode: "1410",
    city: "Kolbotn",
    country: "Norway",
  },
  {
    streetName: "Heimdalsgata",
    streetNumber: "26E",
    postalCode: "0561",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Konows gate",
    streetNumber: "26S",
    postalCode: "0196",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bergensgata",
    streetNumber: "2",
    postalCode: "0468",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ekebergveien",
    streetNumber: " 38",
    postalCode: "0196",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Arnstein Arnebergs vei",
    streetNumber: "10",
    postalCode: "0274",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gamle Drammensvei 63",
    streetNumber: " a",
    postalCode: "1369",
    city: "Stabekk",
    country: "Norway",
  },
  {
    streetName: "Bygdøyveien",
    streetNumber: "29C",
    postalCode: "0287",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Smieveien",
    streetNumber: "15",
    postalCode: "1482",
    city: "Nittedal",
    country: "Norway",
  },
  {
    streetName: "eikskollen,",
    streetNumber: "13c",
    postalCode: "1361",
    city: "Østerås",
    country: "Norway",
  },
  {
    streetName: "Sturlas vei",
    streetNumber: "7c",
    postalCode: "0772",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Vestengsvingen 32",
    streetNumber: "c/o BikeConult AS",
    postalCode: "1182",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kirkeveien",
    streetNumber: " 2",
    postalCode: "1449",
    city: "Drøbak",
    country: "Norway",
  },
  {
    streetName: "Sveiserveien",
    streetNumber: "19",
    postalCode: "0683",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hagavegen",
    streetNumber: " 23",
    postalCode: "2066",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Bidenkapsgate",
    streetNumber: "3",
    postalCode: "0165",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Furulundsveien",
    streetNumber: "4",
    postalCode: "0282",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hans Nordahls Gate",
    streetNumber: "22",
    postalCode: "0481",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Marta Steinsviks Vei",
    streetNumber: "16B",
    postalCode: "1283",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Vangsveien",
    streetNumber: " 23",
    postalCode: "1470",
    city: "Lørenskog",
    country: "Norway",
  },
  {
    streetName: "Vangsveien",
    streetNumber: " 23",
    postalCode: "1470",
    city: "Lørenskog",
    country: "Norway",
  },
  {
    streetName: "Egers Vei",
    streetNumber: "3B",
    postalCode: "1369",
    city: "Stabekk",
    country: "Norway",
  },
  {
    streetName: "Furulundsveien",
    streetNumber: "7C",
    postalCode: "0282",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Økern Torgvei",
    streetNumber: "16",
    postalCode: "0580",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Colletts Gate",
    streetNumber: "86",
    postalCode: "0460",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Kampengata",
    streetNumber: " 21A",
    postalCode: "0654",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bråtasvingen",
    streetNumber: "56i",
    postalCode: "3425",
    city: "Reistad",
    country: "Norway",
  },
  {
    streetName: "Enebakkveien",
    streetNumber: "164",
    postalCode: "0680",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tingstuveien",
    streetNumber: "7A",
    postalCode: "0281",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Thor Olsens gate",
    streetNumber: "3B",
    postalCode: "0177",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holsts gate",
    streetNumber: "2B",
    postalCode: "0473",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kvistveien",
    streetNumber: "2 ",
    postalCode: "0586",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Lalienveien",
    streetNumber: "19",
    postalCode: "1453",
    city: "Bjørnemyr",
    country: "Norway",
  },
  {
    streetName: "Mauritz Hansens gate",
    streetNumber: "1",
    postalCode: "0350",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Boligveien",
    streetNumber: "43",
    postalCode: "3470",
    city: "Slemmestad",
    country: "Norway",
  },
  {
    streetName: "Etterstadsletta",
    streetNumber: "91b",
    postalCode: "0660",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Idas Vei",
    streetNumber: "19",
    postalCode: "0981",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Innspurten",
    streetNumber: "8",
    postalCode: "0663",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grobråtenveien",
    streetNumber: "4B",
    postalCode: "1385",
    city: "Asker",
    country: "Norway",
  },
  {
    streetName: "Helgesens gate",
    streetNumber: "84D",
    postalCode: "0563",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Verkstedalleen",
    streetNumber: "8",
    postalCode: "2010",
    city: "Strømmen",
    country: "Norge",
  },
  {
    streetName: "Dalbakkveien",
    streetNumber: "82",
    postalCode: "0682",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Steinbekkåsen",
    streetNumber: " 20",
    postalCode: "1464",
    city: "Fjellhamar",
    country: "Norway",
  },
  {
    streetName: "Skogveien",
    streetNumber: "47D",
    postalCode: "1406",
    city: "Ski",
    country: "Norge",
  },
  {
    streetName: "Rauerskauveien",
    streetNumber: " 632",
    postalCode: "1482",
    city: "Nittedal",
    country: "Norway",
  },
  {
    streetName: "Arnljot Gellines vei",
    streetNumber: "5A",
    postalCode: "0657",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lerkevegen",
    streetNumber: "1D",
    postalCode: "2006",
    city: "Løvenstad",
    country: "Norway",
  },
  {
    streetName: "Bergstien",
    streetNumber: "2",
    postalCode: "0172",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lilleruts vei",
    streetNumber: " 4",
    postalCode: "1364",
    city: "Fornebu",
    country: "Norway",
  },
  {
    streetName: "Valtoppveien",
    streetNumber: "3",
    postalCode: "1400",
    city: "Ski",
    country: "Norge",
  },
  {
    streetName: "Haakon Tveters vei",
    streetNumber: "43",
    postalCode: "0686",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sankt Jørgens vei",
    streetNumber: "41",
    postalCode: "0662",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brekkeveien",
    streetNumber: " 19",
    postalCode: "0884",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Treschows gate",
    streetNumber: "23C",
    postalCode: "0470",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bentsegata",
    streetNumber: "4",
    postalCode: "0465",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Øvre Smestadvei",
    streetNumber: "30A",
    postalCode: "0378",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sørengkaia",
    streetNumber: "39",
    postalCode: "0194",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Carl Kjelsens Vei",
    streetNumber: "39",
    postalCode: "0874",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Wessels gate",
    streetNumber: " 8",
    postalCode: "0165",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Vassbonnveien",
    streetNumber: "24",
    postalCode: "1410",
    city: "Kolbotn",
    country: "Norway",
  },
  {
    streetName: "Bentsegata",
    streetNumber: "4A",
    postalCode: "0465",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lundstadjordet",
    streetNumber: " 30",
    postalCode: "2067",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Arendalsgata",
    streetNumber: "14",
    postalCode: "0463",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hauges vei",
    streetNumber: "4B",
    postalCode: "0871",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gyssestadkollen",
    streetNumber: "59",
    postalCode: "1341",
    city: "Slependen",
    country: "NO",
  },
  {
    streetName: "Kvernlivegen",
    streetNumber: " 3b",
    postalCode: "2066",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Ottar Birtings gate",
    streetNumber: "5",
    postalCode: "0268",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Eikeleina",
    streetNumber: "12",
    postalCode: "3472",
    city: "Bødalen",
    country: "Norway",
  },
  {
    streetName: "Biskop Jens Nilssøns Gate",
    streetNumber: "18A",
    postalCode: "0659",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hafrsfjordgata",
    streetNumber: "33B",
    postalCode: "0268",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Orreveien",
    streetNumber: "15",
    postalCode: "0789",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Mortensrudveien",
    streetNumber: "18N",
    postalCode: "1283",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Borger Withs gate",
    streetNumber: "31",
    postalCode: "0482",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Holmenkollveien",
    streetNumber: "70D",
    postalCode: "0784",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Vårveien",
    streetNumber: "41B",
    postalCode: "1182",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Linjveien",
    streetNumber: "61a",
    postalCode: "1087",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Øvre Toppenhaug",
    streetNumber: "129",
    postalCode: "1353",
    city: "Bærums verk",
    country: "Norway",
  },
  {
    streetName: "Sleiverudåsen",
    streetNumber: " 12",
    postalCode: "1354",
    city: "Bærums verk",
    country: "Norway",
  },
  {
    streetName: "Selvbyggerveien",
    streetNumber: "21",
    postalCode: "0591",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Televeien",
    streetNumber: " 2A",
    postalCode: "2020",
    city: "Skedsmokorset",
    country: "Norway",
  },
  {
    streetName: "Rypeveien",
    streetNumber: " 29",
    postalCode: "3420",
    city: "Lierskogen",
    country: "Norway",
  },
  {
    streetName: "Fagerborggata",
    streetNumber: "23",
    postalCode: "0360",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Asperudtoppen",
    streetNumber: "66",
    postalCode: "1258",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gardermovegen",
    streetNumber: "12",
    postalCode: "2050",
    city: "Jessheim",
    country: "Norge",
  },
  {
    streetName: "Lindebergveien",
    streetNumber: " 45B",
    postalCode: "1358",
    city: "Jar",
    country: "Norway",
  },
  {
    streetName: "Solhaugveien",
    streetNumber: "71",
    postalCode: "1337",
    city: "Sandvika",
    country: "Norway",
  },
  {
    streetName: "Kjelsåsveien 164A Leil.",
    streetNumber: " 203",
    postalCode: "0884",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Haugerudhagan , 4 etg",
    streetNumber: "12",
    postalCode: "0673",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Gleinåsveien",
    streetNumber: " 3",
    postalCode: "3440",
    city: "Røyken",
    country: "Norway",
  },
  {
    streetName: "Tjernslia",
    streetNumber: "6B",
    postalCode: "1481",
    city: "Hagan",
    country: "Norway",
  },
  {
    streetName: "Konventveien",
    streetNumber: "14A",
    postalCode: "0377",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stockflethsgate",
    streetNumber: "56b",
    postalCode: "0461",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Skogmosletta",
    streetNumber: "2",
    postalCode: "2050",
    city: "Jessheim",
    country: "Norge",
  },
  {
    streetName: "Schouterrassen",
    streetNumber: " 25",
    postalCode: "0573",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Lybekkveien",
    streetNumber: "48B",
    postalCode: "0770",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Islands gate 14",
    streetNumber: " A",
    postalCode: "0658",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ekebergveien",
    streetNumber: "5C",
    postalCode: "0192",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Garderveien",
    streetNumber: " 21",
    postalCode: "1455",
    city: "Nordre frogn",
    country: "Norway",
  },
  {
    streetName: "Lusetjernveien",
    streetNumber: " 4b",
    postalCode: "1253",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Eiksveien",
    streetNumber: "57",
    postalCode: "1359",
    city: "Eiksmarka",
    country: "Norway",
  },
  {
    streetName: "Johan Scharffenbergs vei",
    streetNumber: "93",
    postalCode: "0694",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bjørnebærveien",
    streetNumber: "13",
    postalCode: "3032",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Krusestubben",
    streetNumber: "12",
    postalCode: "2050",
    city: "Jessheim",
    country: "Norge",
  },
  {
    streetName: "Grenseveien 11",
    streetNumber: " A",
    postalCode: "0571",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Dalbakkveien",
    streetNumber: "20B",
    postalCode: "0682",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Stensberggata",
    streetNumber: "23C",
    postalCode: "0170",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ove Kristiansens vei",
    streetNumber: "8A",
    postalCode: "0751",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gangstuveien",
    streetNumber: "10D",
    postalCode: "0952",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gleinåsveien",
    streetNumber: " 3",
    postalCode: "3440",
    city: "Røyken",
    country: "Norway",
  },
  {
    streetName: "Njålveien",
    streetNumber: "28B ",
    postalCode: "1368",
    city: "Stabekk",
    country: "Norway",
  },
  {
    streetName: "Søren Lemmichs gate",
    streetNumber: " 4",
    postalCode: "3015",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Ringveien",
    streetNumber: " 2b",
    postalCode: "1467",
    city: "Strømmen",
    country: "Norway",
  },
  {
    streetName: "birgitte hammer vei 1 etg",
    streetNumber: "1a ",
    postalCode: "1167",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Høgåsveien",
    streetNumber: " 35",
    postalCode: "1259",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Villaveien",
    streetNumber: "24B",
    postalCode: "0371",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Aspehaugveien",
    streetNumber: "43",
    postalCode: "0376",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grevlingåsen",
    streetNumber: "9",
    postalCode: "1386",
    city: "Asker",
    country: "Norge",
  },
  {
    streetName: "Fjellgata",
    streetNumber: " 28B",
    postalCode: "0566",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bærumsveien",
    streetNumber: "156",
    postalCode: "1358",
    city: "Jar",
    country: "Norway",
  },
  {
    streetName: "Rolf Presthus vei",
    streetNumber: "80",
    postalCode: "1415",
    city: "Oppegård",
    country: "Norge",
  },
  {
    streetName: "Darres gate",
    streetNumber: "30",
    postalCode: "0175",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skuiveien",
    streetNumber: "135",
    postalCode: "1339",
    city: "Vøyenenga",
    country: "Norge",
  },
  {
    streetName: "Schønings gate",
    streetNumber: "36",
    postalCode: "0362",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sofies Gate",
    streetNumber: "35",
    postalCode: "0168",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skuiveien",
    streetNumber: "135",
    postalCode: "1339",
    city: "Vøyenenga",
    country: "Norge",
  },
  {
    streetName: "Marmorveien",
    streetNumber: "10B",
    postalCode: "1154",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Edelgranveien",
    streetNumber: "74",
    postalCode: "1356",
    city: "Bekkestua",
    country: "Norway",
  },
  {
    streetName: "Hartmanns vei",
    streetNumber: "42",
    postalCode: "0284",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bjarne Haugens gate",
    streetNumber: " 9",
    postalCode: "1475",
    city: "Finstadjordet",
    country: "Norway",
  },
  {
    streetName: "Inga F. Gjerdrums vei",
    streetNumber: "43",
    postalCode: "1396",
    city: "Billingstad",
    country: "Norge",
  },
  {
    streetName: "Bergerlia",
    streetNumber: " 12",
    postalCode: "3470",
    city: "Slemmestad",
    country: "Norway",
  },
  {
    streetName: "Regattaveien",
    streetNumber: "103",
    postalCode: "1364",
    city: "Fornebu",
    country: "Norway",
  },
  {
    streetName: "Ekraveien",
    streetNumber: "42",
    postalCode: "0756",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frognerveien",
    streetNumber: " 10",
    postalCode: "0257",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Løkenåsveien",
    streetNumber: "73",
    postalCode: "1473",
    city: "Lørenskog",
    country: "Norge",
  },
  {
    streetName: "Kolleveien",
    streetNumber: "10",
    postalCode: "1397",
    city: "Nesøya",
    country: "Norge",
  },
  {
    streetName: "Gjallarveien",
    streetNumber: "8B",
    postalCode: "1412",
    city: "Sofiemyr",
    country: "Norge",
  },
  {
    streetName: "Bernt Knudsens vei",
    streetNumber: " 13b",
    postalCode: "1176",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Nilsemarka",
    streetNumber: " 7",
    postalCode: "3470",
    city: "Slemmestad",
    country: "Norway",
  },
  {
    streetName: "Selvbyggerveien",
    streetNumber: "110",
    postalCode: "0591",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Oppsalveien",
    streetNumber: "25b",
    postalCode: "0686",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Grindbakken",
    streetNumber: "36",
    postalCode: "0764",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Grefsenveien",
    streetNumber: "80B",
    postalCode: "0489",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Gabels gate",
    streetNumber: "1B",
    postalCode: "0272",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hegggoppen",
    streetNumber: "5C",
    postalCode: "3403",
    city: "Lier",
    country: "Norway",
  },
  {
    streetName: "CORoti Sloratoppen,",
    streetNumber: "152",
    postalCode: "1405",
    city: "Langhus",
    country: "Norway",
  },
  {
    streetName: "Strandalleen",
    streetNumber: "8B",
    postalCode: "1368",
    city: "Stabekk",
    country: "Norge",
  },
  {
    streetName: "Oberst Rodes Vei",
    streetNumber: "63F",
    postalCode: "1165",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hansteens gate",
    streetNumber: "16",
    postalCode: "0253",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bankveien",
    streetNumber: " 7e",
    postalCode: "1362",
    city: "Hosle",
    country: "Norway",
  },
  {
    streetName: "Holmenkollveien",
    streetNumber: "104",
    postalCode: "0784",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Etterstadsletta",
    streetNumber: "21A",
    postalCode: "0660",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Holmenkollveien",
    streetNumber: "104",
    postalCode: "0784",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Lerdalsgata",
    streetNumber: "24A",
    postalCode: "1467",
    city: "Strømmen",
    country: "Norway",
  },
  {
    streetName: "Orrhaneveien",
    streetNumber: " 25",
    postalCode: "1555",
    city: "Son",
    country: "Norway",
  },
  {
    streetName: "Vilbergvegen",
    streetNumber: "28",
    postalCode: "2067",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Dovresvingen",
    streetNumber: "24A",
    postalCode: "1184",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Parkveien",
    streetNumber: "30B",
    postalCode: "1405",
    city: "Langhus",
    country: "Norway",
  },
  {
    streetName: "Kniveveien",
    streetNumber: " 75",
    postalCode: "3036",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Konvallveien",
    streetNumber: " 2",
    postalCode: "1456",
    city: "Nesoddtangen",
    country: "Norway",
  },
  {
    streetName: "Lerdalsgata",
    streetNumber: "24A",
    postalCode: "1467",
    city: "Strømmen",
    country: "Norway",
  },
  {
    streetName: "Lerdalsgata",
    streetNumber: "24A",
    postalCode: "1467",
    city: "Strømmen",
    country: "Norway",
  },
  {
    streetName: "Saturnveien",
    streetNumber: "26A",
    postalCode: "0492",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Lerdalsgata",
    streetNumber: "24A",
    postalCode: "1467",
    city: "Strømmen",
    country: "Norway",
  },
  {
    streetName: "Bekkevei",
    streetNumber: "10",
    postalCode: "1458",
    city: "Fjellstrand",
    country: "Norway",
  },
  {
    streetName: "Brunskrubbveien",
    streetNumber: " 49",
    postalCode: "1476",
    city: "Rasta",
    country: "Norway",
  },
  {
    streetName: "Nebbaveien",
    streetNumber: "39",
    postalCode: "1407",
    city: "Vinterbro",
    country: "Norway",
  },
  {
    streetName: "Kløftaveien",
    streetNumber: "27",
    postalCode: "1352",
    city: "Kolsås",
    country: "Norge",
  },
  {
    streetName: "Ellingsrudveien",
    streetNumber: "8B",
    postalCode: "1089",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Myrbråten",
    streetNumber: " 64",
    postalCode: "3031",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Finn Thorsagers vei",
    streetNumber: " 4",
    postalCode: "1360",
    city: "Fornebu",
    country: "Norway",
  },
  {
    streetName: "Lysneveien",
    streetNumber: "8 B",
    postalCode: "1423",
    city: "Ski",
    country: "Norway",
  },
  {
    streetName: "Elgtråkket",
    streetNumber: "3 c",
    postalCode: "2014",
    city: "Blystadlia",
    country: "Norway",
  },
  {
    streetName: "Barken",
    streetNumber: " 10",
    postalCode: "3038",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Haakon Tveters vei",
    streetNumber: "9C",
    postalCode: "0682",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sandakerveien",
    streetNumber: "121",
    postalCode: "0484",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Lillebergsvingen",
    streetNumber: "4",
    postalCode: "0662",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Åråssvingen",
    streetNumber: " 21",
    postalCode: "2007",
    city: "Kjeller",
    country: "Norway",
  },
  {
    streetName: "Malerhaugveien",
    streetNumber: "16",
    postalCode: "0661",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Nordraaks vei",
    streetNumber: " 52B",
    postalCode: "1369",
    city: "Stabekk",
    country: "Norway",
  },
  {
    streetName: "Solstadveien",
    streetNumber: "11",
    postalCode: "1424",
    city: "Ski",
    country: "Norge",
  },
  {
    streetName: "Harald Sohlbergs Vei",
    streetNumber: "2",
    postalCode: "1064",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Konventveien",
    streetNumber: "40B",
    postalCode: "0377",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bernhard Herres Vei",
    streetNumber: "22A",
    postalCode: "0376",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Tumyrveien",
    streetNumber: "53",
    postalCode: "1482",
    city: "Nittedal",
    country: "Norway",
  },
  {
    streetName: "Nedre Lerkeveg",
    streetNumber: " 6",
    postalCode: "2067",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Jotunveien",
    streetNumber: "10",
    postalCode: "1185",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Arnebråtveien",
    streetNumber: " 61",
    postalCode: "0771",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Furulundtoppen",
    streetNumber: "23",
    postalCode: "0282",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Trondheimsveien",
    streetNumber: "26B",
    postalCode: "0560",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Frydenlundgata",
    streetNumber: "8a",
    postalCode: "0169",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fougstads gate",
    streetNumber: "28",
    postalCode: "0173",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ørakerveien",
    streetNumber: "19",
    postalCode: "0284",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Pareliusveien",
    streetNumber: "8A",
    postalCode: "1177",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Skådalsveien",
    streetNumber: "13e",
    postalCode: "0781",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fagerhøyveien",
    streetNumber: "20B",
    postalCode: "1369",
    city: "Stabekk",
    country: "Norge",
  },
  {
    streetName: "Drammensveien",
    streetNumber: "56 A",
    postalCode: "0271",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ensjøveien",
    streetNumber: "6",
    postalCode: "0655",
    city: "Oslo",
    country: "NO",
  },
  {
    streetName: "Torpfjellet",
    streetNumber: "135",
    postalCode: "1389",
    city: "Heggedal",
    country: "Norway",
  },
  {
    streetName: "Verven",
    streetNumber: "49",
    postalCode: "3041",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Prost Stabels vei",
    streetNumber: "562",
    postalCode: "2019",
    city: "Skedsmokorset",
    country: "Norge",
  },
  {
    streetName: "Dalsbergstien",
    streetNumber: "10b",
    postalCode: "0170",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Langerudsvingen",
    streetNumber: "23 b",
    postalCode: "1187",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Fetsundgata",
    streetNumber: " 3",
    postalCode: "0654",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Regattaveien",
    streetNumber: "103",
    postalCode: "1364",
    city: "Fornebu",
    country: "Norway",
  },
  {
    streetName: "Bjerregaards gate",
    streetNumber: "30C",
    postalCode: "0172",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bjerregaards Gate",
    streetNumber: "21",
    postalCode: "0172",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sloreåsen",
    streetNumber: "37E",
    postalCode: "1257",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sagstuveien",
    streetNumber: "41D",
    postalCode: "1405",
    city: "Langhus",
    country: "Norge",
  },
  {
    streetName: "Maria Dehlis vei 59",
    streetNumber: "c/o Mir Alam",
    postalCode: "1084",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Landingsveien",
    streetNumber: "82",
    postalCode: "0767",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Tumyrveien",
    streetNumber: "26A",
    postalCode: "1482",
    city: "Nittedal",
    country: "Norway",
  },
  {
    streetName: "Buskerudveien",
    streetNumber: "88",
    postalCode: "3024",
    city: "Drammen",
    country: "Norge",
  },
  {
    streetName: "Jerikoveien",
    streetNumber: " 61",
    postalCode: "1067",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Svalbardveien",
    streetNumber: "14B",
    postalCode: "0375",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Lofsrudhøgda 43",
    streetNumber: " a",
    postalCode: "1281",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Gamle Herregårdsvei",
    streetNumber: "3a",
    postalCode: "1168",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jordbærsletta",
    streetNumber: "30",
    postalCode: "1410",
    city: "Kolbotn",
    country: "Norway",
  },
  {
    streetName: "Silurveien",
    streetNumber: " 47",
    postalCode: "0380",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Roseveien",
    streetNumber: " 5",
    postalCode: "3043",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Vallerveien",
    streetNumber: " 68",
    postalCode: "1344",
    city: "Haslum",
    country: "Norway",
  },
  {
    streetName: "Heisshageveien",
    streetNumber: "10B",
    postalCode: "2015",
    city: "Leirsund",
    country: "Norge",
  },
  {
    streetName: "Myrhusvegen",
    streetNumber: "45",
    postalCode: "2040",
    city: "Kløfta",
    country: "Norge",
  },
  {
    streetName: "Bygdøy allé",
    streetNumber: "9C",
    postalCode: "0257",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tostrups gate",
    streetNumber: "27",
    postalCode: "0264",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Plogveien",
    streetNumber: "79B",
    postalCode: "0681",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Vahallaveien",
    streetNumber: "8",
    postalCode: "1412",
    city: "Sofiemyr",
    country: "Norge",
  },
  {
    streetName: "Fredliløkka",
    streetNumber: "2",
    postalCode: "3031",
    city: "Drammen",
    country: "Norge",
  },
  {
    streetName: "Løvsetdalen",
    streetNumber: "14 a",
    postalCode: "1188",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sagstuveien",
    streetNumber: "41D",
    postalCode: "1405",
    city: "Langhus",
    country: "Norge",
  },
  {
    streetName: "Operagata",
    streetNumber: "49",
    postalCode: "0194",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hoslebakken",
    streetNumber: " 27",
    postalCode: "1362",
    city: "Hosle",
    country: "Norway",
  },
  {
    streetName: "Dølstuveien",
    streetNumber: "11",
    postalCode: "3470",
    city: "Slemmestad",
    country: "Norway",
  },
  {
    streetName: "Grinibråten",
    streetNumber: "53",
    postalCode: "1339",
    city: "Vøyenenga",
    country: "Norway",
  },
  {
    streetName: "Ullevålsveien",
    streetNumber: "99",
    postalCode: "0359",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Huldreveien",
    streetNumber: "16",
    postalCode: "0781",
    city: "Oslo",
    country: "NO",
  },
  {
    streetName: "Myrerskogveien 54 a",
    streetNumber: "c/o Rønningen Folkehøgskole",
    postalCode: "0495",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lysaker Brygge",
    streetNumber: "5",
    postalCode: "1366",
    city: "Lysaker",
    country: "Norway",
  },
  {
    streetName: "Hermods Vei",
    streetNumber: "16",
    postalCode: "1412",
    city: "Sofiemyr",
    country: "Norway",
  },
  {
    streetName: "Galgeberg",
    streetNumber: "3G",
    postalCode: "0657",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frognerseterveien",
    streetNumber: "47 E",
    postalCode: "0776",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sverdrups gate",
    streetNumber: " 15A",
    postalCode: "0559",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Rudsberghaugen",
    streetNumber: "51",
    postalCode: "2005",
    city: "Rælingen",
    country: "Norway",
  },
  {
    streetName: "Lensmann Klevs vei",
    streetNumber: "206 B",
    postalCode: "2019",
    city: "Skedsmokorset",
    country: "Norway",
  },
  {
    streetName: "Strømsveien",
    streetNumber: " 40",
    postalCode: "1467",
    city: "Strømmen",
    country: "Norway",
  },
  {
    streetName: "Skogblomtveien",
    streetNumber: "108",
    postalCode: "1470",
    city: "Lørenskog",
    country: "Norway",
  },
  {
    streetName: "Roald Amundsens vei",
    streetNumber: " 61",
    postalCode: "1463",
    city: "Fjellhamar",
    country: "Norway",
  },
  {
    streetName: "Otto Valstads vei",
    streetNumber: "11a",
    postalCode: "1395",
    city: "Hvalstad",
    country: "Norway",
  },
  {
    streetName: "Myrveien",
    streetNumber: "41a",
    postalCode: "1406",
    city: "Ski",
    country: "Norge",
  },
  {
    streetName: "Idrettsveien",
    streetNumber: "14B",
    postalCode: "1358",
    city: "Jar",
    country: "Norway",
  },
  {
    streetName: "Pareliusveien",
    streetNumber: "8A",
    postalCode: "1177",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sletteløkka",
    streetNumber: "24A",
    postalCode: "0597",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Dalsbergstien",
    streetNumber: "10b",
    postalCode: "0170",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bernhard Herres Vei",
    streetNumber: "22A",
    postalCode: "0376",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Pettersløkka",
    streetNumber: " 38a",
    postalCode: "3040",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Karupvegen",
    streetNumber: "22",
    postalCode: "2005",
    city: "Rælingen",
    country: "Norway",
  },
  {
    streetName: "Ringstabekkveien",
    streetNumber: "1b",
    postalCode: "1358",
    city: "Jar",
    country: "Norway",
  },
  {
    streetName: "Olaf Mørchs vei",
    streetNumber: "52",
    postalCode: "3038",
    city: "Drammen",
    country: "Norge",
  },
  {
    streetName: "Østerliveien",
    streetNumber: "62",
    postalCode: "1153",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sofies Plass",
    streetNumber: " 3a",
    postalCode: "0169",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Gamle Bygdevei",
    streetNumber: "194A",
    postalCode: "1284",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Søndre Skrenten",
    streetNumber: "3D",
    postalCode: "1410",
    city: "Kolbotn",
    country: "Norway",
  },
  {
    streetName: "Fredlihavna",
    streetNumber: " 21",
    postalCode: "3031",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Brånåslia 7",
    streetNumber: "c/o Hans Erik Johnsen",
    postalCode: "2019",
    city: "Skedsmokorset",
    country: "Norge",
  },
  {
    streetName: "Tolerudveien",
    streetNumber: "27",
    postalCode: "3030",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Høgåsveien",
    streetNumber: "38",
    postalCode: "1352",
    city: "Kolsås",
    country: "Norway",
  },
  {
    streetName: "Ståvihagan",
    streetNumber: "5b",
    postalCode: "1339",
    city: "Vøyenenga",
    country: "Norway",
  },
  {
    streetName: "Granstangen 36",
    streetNumber: " B",
    postalCode: "1051",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Dronningveien",
    streetNumber: "66",
    postalCode: "1550",
    city: "Hølen",
    country: "Norge",
  },
  {
    streetName: "Nedre Frydendal,",
    streetNumber: "112",
    postalCode: "1384",
    city: "Asker",
    country: "Norway",
  },
  {
    streetName: "Vardeveien",
    streetNumber: " 33b",
    postalCode: "1182",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Haakon Tveters vei",
    streetNumber: " 58",
    postalCode: "0686",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hoffsjef Løvenskioldsvei",
    streetNumber: "31D",
    postalCode: "0382",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Grefsenveien",
    streetNumber: "45",
    postalCode: "0485",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Fugleliveien",
    streetNumber: "14",
    postalCode: "0667",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sørkedalsveien",
    streetNumber: "76A",
    postalCode: "0377",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ankerveien",
    streetNumber: "38 B",
    postalCode: "0785",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Einarsvei",
    streetNumber: "21",
    postalCode: "0575",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Asperudlia",
    streetNumber: "30",
    postalCode: "1258",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Haakon Tvetersvei",
    streetNumber: "74",
    postalCode: "0686",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Kilenveien",
    streetNumber: "22",
    postalCode: "1366",
    city: "Lysaker",
    country: "Norway",
  },
  {
    streetName: "Kverndalsvegen",
    streetNumber: "32",
    postalCode: "2050",
    city: "Jessheim",
    country: "Norge",
  },
  {
    streetName: "Årvollveien",
    streetNumber: "62 B",
    postalCode: "0590",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Asperudlia",
    streetNumber: " 16",
    postalCode: "1258",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Reichweins Gate",
    streetNumber: "1G",
    postalCode: "0254",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hans Nordahls Gate",
    streetNumber: "30",
    postalCode: "0481",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Tiriltunga",
    streetNumber: " 160",
    postalCode: "1259",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Kilenveien",
    streetNumber: "22",
    postalCode: "1366",
    city: "Lysaker",
    country: "Norway",
  },
  {
    streetName: "Odden",
    streetNumber: "12 B",
    postalCode: "1397",
    city: "Nesøya",
    country: "Norway",
  },
  {
    streetName: "Nedre Måsan",
    streetNumber: "17 a",
    postalCode: "1385",
    city: "Asker",
    country: "Norway",
  },
  {
    streetName: "Ovenstadveien",
    streetNumber: "45",
    postalCode: "3420",
    city: "Lierskogen",
    country: "NO",
  },
  {
    streetName: "Dalenveien",
    streetNumber: " 105",
    postalCode: "3031",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Arnebråtveien",
    streetNumber: "65",
    postalCode: "0771",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Finn Blackstads vei",
    streetNumber: " 108",
    postalCode: "3021",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Røahagan",
    streetNumber: "1H",
    postalCode: "0754",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Lysåsstubben",
    streetNumber: " 26",
    postalCode: "2052",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Røahagan",
    streetNumber: "1H",
    postalCode: "0754",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Steinborgveien",
    streetNumber: " 38",
    postalCode: "0678",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Dovresvingen",
    streetNumber: "27C",
    postalCode: "1184",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Solbergfjellet",
    streetNumber: "30",
    postalCode: "1385",
    city: "Asker",
    country: "Norge",
  },
  {
    streetName: "Haugerudveien",
    streetNumber: " 65",
    postalCode: "1434",
    city: "Ås",
    country: "Norway",
  },
  {
    streetName: "Hukenbekken",
    streetNumber: "34A",
    postalCode: "1383",
    city: "Asker",
    country: "Norway",
  },
  {
    streetName: "Høvikveien",
    streetNumber: "56d",
    postalCode: "1356",
    city: "Bekkestua",
    country: "Norway",
  },
  {
    streetName: "Gjettumtunet",
    streetNumber: "12",
    postalCode: "1346",
    city: "Gjettum",
    country: "Norway",
  },
  {
    streetName: "Maridalsveien",
    streetNumber: "205",
    postalCode: "0469",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sønsterudveien",
    streetNumber: " 3",
    postalCode: "1410",
    city: "Kolbotn",
    country: "Norway",
  },
  {
    streetName: "Kvernbakken",
    streetNumber: " 3",
    postalCode: "1414",
    city: "Trollåsen",
    country: "Norway",
  },
  {
    streetName: "Korshagen",
    streetNumber: "18 d",
    postalCode: "2020",
    city: "Skedsmokorset",
    country: "Norway",
  },
  {
    streetName: "Dyretråkket",
    streetNumber: " 31",
    postalCode: "1251",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Neuberggata",
    streetNumber: "2a",
    postalCode: "0367",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Haakon Tveters vei",
    streetNumber: "84",
    postalCode: "0686",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "spireaveien",
    streetNumber: "12 a",
    postalCode: "0580",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Elgtråkket",
    streetNumber: " 7E",
    postalCode: "2014",
    city: "Blystadlia",
    country: "Norway",
  },
  {
    streetName: "Stovnerveien",
    streetNumber: "18",
    postalCode: "0980",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Breivikveien",
    streetNumber: "42a",
    postalCode: "1394",
    city: "Nesbru",
    country: "Norway",
  },
  {
    streetName: "Heggeliveien",
    streetNumber: "30 B",
    postalCode: "0375",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Asbjørnsensvei",
    streetNumber: "59",
    postalCode: "1461",
    city: "Rasta",
    country: "Norway",
  },
  {
    streetName: "Venusbakken",
    streetNumber: "15",
    postalCode: "3033",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Trygve Nilsens vei",
    streetNumber: " 32e",
    postalCode: "1061",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Innspurten",
    streetNumber: "4B",
    postalCode: "0663",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Regattaveien",
    streetNumber: "103",
    postalCode: "1364",
    city: "Fornebu",
    country: "Norway",
  },
  {
    streetName: "Holmboes gate",
    streetNumber: "6B",
    postalCode: "0357",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bjerkås Terrasse",
    streetNumber: "11",
    postalCode: "1391",
    city: "Vollen",
    country: "Norway",
  },
  {
    streetName: "Høybråtenveien 23",
    streetNumber: " e",
    postalCode: "1055",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Regattaveien",
    streetNumber: "103",
    postalCode: "1364",
    city: "Fornebu",
    country: "Norway",
  },
  {
    streetName: "Regattaveien",
    streetNumber: "103",
    postalCode: "1364",
    city: "Fornebu",
    country: "Norway",
  },
  {
    streetName: "Elgtråkket",
    streetNumber: "7A",
    postalCode: "2014",
    city: "Blystadlia",
    country: "Norway",
  },
  {
    streetName: "Ole Vigs gate",
    streetNumber: "15 B",
    postalCode: "0366",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Rødtvetveien",
    streetNumber: " 73b",
    postalCode: "0955",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Harbitzalléen",
    streetNumber: " 10D",
    postalCode: "0275",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Heggeveien 50",
    streetNumber: " E",
    postalCode: "1481",
    city: "Hagan",
    country: "Norway",
  },
  {
    streetName: "Peder Grøns vei",
    streetNumber: " 9",
    postalCode: "0881",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Enerveien",
    streetNumber: "2d",
    postalCode: "1450",
    city: "Nesoddtangen",
    country: "Norway",
  },
  {
    streetName: "Nedre Prinsdals",
    streetNumber: "151",
    postalCode: "1263",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Randemfaret",
    streetNumber: "24",
    postalCode: "1543",
    city: "Vestby",
    country: "Norway",
  },
  {
    streetName: "Niels Juels gate",
    streetNumber: "34",
    postalCode: "0272",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Antenneveien",
    streetNumber: "20",
    postalCode: "1154",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kjelsåsveien",
    streetNumber: "129B",
    postalCode: "0491",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Gartnerisvingen",
    streetNumber: "49",
    postalCode: "1481",
    city: "Hagan",
    country: "Norway",
  },
  {
    streetName: "Bekkeliveien",
    streetNumber: "8B",
    postalCode: "1410",
    city: "Kolbotn",
    country: "Norway",
  },
  {
    streetName: "Sognsvannsveien",
    streetNumber: "26E",
    postalCode: "0372",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skigata",
    streetNumber: "14",
    postalCode: "1473",
    city: "Lørenskog",
    country: "no",
  },
  {
    streetName: "Juterudveien",
    streetNumber: "24",
    postalCode: "1341",
    city: "Slependen",
    country: "Norway",
  },
  {
    streetName: "Sognsvannsveien",
    streetNumber: " 26E",
    postalCode: "0372",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Carl Kjelsens vei",
    streetNumber: "12 A",
    postalCode: "0860",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Nils Kristensens gate",
    streetNumber: "9",
    postalCode: "2003",
    city: "Lillestrøm",
    country: "Norge",
  },
  {
    streetName: "Solbakkveien",
    streetNumber: "6A",
    postalCode: "1405",
    city: "Langhus",
    country: "Norway",
  },
  {
    streetName: "Seilduksgata",
    streetNumber: "33a",
    postalCode: "0553",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Damenga",
    streetNumber: "27",
    postalCode: "3032",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Klosterheimveien",
    streetNumber: "16",
    postalCode: "0666",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Grenseveien",
    streetNumber: "54",
    postalCode: "0579",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Kilenveien",
    streetNumber: "22",
    postalCode: "1366",
    city: "Lysaker",
    country: "Norway",
  },
  {
    streetName: "Harbitzalleen",
    streetNumber: "41",
    postalCode: "0275",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Lilleborggata",
    streetNumber: "6",
    postalCode: "0480",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brusagaveien",
    streetNumber: "97",
    postalCode: "1404",
    city: "Siggerud",
    country: "Norway",
  },
  {
    streetName: "Prost Stabels vei",
    streetNumber: " 169",
    postalCode: "2019",
    city: "Skedsmokorset",
    country: "Norway",
  },
  {
    streetName: "Tante Ulrikkes vei",
    streetNumber: "22b",
    postalCode: "0984",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Oksehovedveien",
    streetNumber: "30",
    postalCode: "1365",
    city: "Blommenholm",
    country: "Norway",
  },
  {
    streetName: "Sandakerveien",
    streetNumber: "108A",
    postalCode: "0484",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Smedgata",
    streetNumber: " 35a",
    postalCode: "0651",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hauges gate",
    streetNumber: " 84a",
    postalCode: "3019",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Øygardveien",
    streetNumber: "48",
    postalCode: "1357",
    city: "Bekkestua",
    country: "Norway",
  },
  {
    streetName: "Olav M. Troviks vei",
    streetNumber: "26",
    postalCode: "0864",
    city: "Oslo",
    country: "NO",
  },
  {
    streetName: "Bjørnebærstien",
    streetNumber: " 23",
    postalCode: "1348",
    city: "Rykkinn",
    country: "Norway",
  },
  {
    streetName: "Schubelers gate",
    streetNumber: "7A",
    postalCode: "0577",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Halvdan Svartes Gate",
    streetNumber: "26",
    postalCode: "0268",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Slalåmveien",
    streetNumber: " 8F",
    postalCode: "1350",
    city: "Lommedalen",
    country: "Norway",
  },
  {
    streetName: "Drammensveien",
    streetNumber: "116E",
    postalCode: "0273",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Helgesens Gate",
    streetNumber: "14D",
    postalCode: "0553",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sverdrups gate",
    streetNumber: " 15A",
    postalCode: "0559",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sagmesterveien",
    streetNumber: "49",
    postalCode: "1414",
    city: "Trollåsen",
    country: "Norge",
  },
  {
    streetName: "Tåsenveien",
    streetNumber: "18b",
    postalCode: "0853",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Krusebyveien",
    streetNumber: " 96",
    postalCode: "1542",
    city: "Vestby",
    country: "Norway",
  },
  {
    streetName: "Kristoffer Robins vei",
    streetNumber: "54",
    postalCode: "0978",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Høgåsveien",
    streetNumber: "66",
    postalCode: "1259",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Graagaasveien",
    streetNumber: "5D",
    postalCode: "1187",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Prost Stabels Vei",
    streetNumber: "430",
    postalCode: "2019",
    city: "Skedsmokorset",
    country: "Norway",
  },
  {
    streetName: "Simensbråtveien",
    streetNumber: " 6",
    postalCode: "1182",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Tomtemoen",
    streetNumber: " 8",
    postalCode: "2053",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Sørengkaia",
    streetNumber: "130",
    postalCode: "0194",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Thorvald Meyers gate 34A",
    streetNumber: "c/o Halvor Heier",
    postalCode: "0555",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sørliveien",
    streetNumber: " 56d",
    postalCode: "1482",
    city: "Nittedal",
    country: "Norway",
  },
  {
    streetName: "Slemdalsveien",
    streetNumber: " 91B",
    postalCode: "0373",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ringvegen",
    streetNumber: " 9",
    postalCode: "2005",
    city: "Rælingen",
    country: "Norway",
  },
  {
    streetName: "Vesteråsveien",
    streetNumber: " 34b",
    postalCode: "0382",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Berberissveien",
    streetNumber: "30C",
    postalCode: "3408",
    city: "Tranby",
    country: "Norge",
  },
  {
    streetName: "Nadderudveien",
    streetNumber: "90C",
    postalCode: "1362",
    city: "Hosle",
    country: "Norway",
  },
  {
    streetName: "Vøyensvingen",
    streetNumber: "13",
    postalCode: "0458",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tordenskiolds gate",
    streetNumber: "83B",
    postalCode: "3044",
    city: "Drammen",
    country: "Norge",
  },
  {
    streetName: "Gamle Ringeriksvei",
    streetNumber: "36b",
    postalCode: "1357",
    city: "Bekkestua",
    country: "Norge",
  },
  {
    streetName: "Spikkestad Veien",
    streetNumber: "120",
    postalCode: "3430",
    city: "Spikkestad",
    country: "Norge",
  },
  {
    streetName: "Husejordet",
    streetNumber: "104",
    postalCode: "1386",
    city: "Asker",
    country: "Norway",
  },
  {
    streetName: "Kløftaveien",
    streetNumber: "27",
    postalCode: "1352",
    city: "Kolsås",
    country: "Norway",
  },
  {
    streetName: "Collets gate",
    streetNumber: "57",
    postalCode: "0456",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Skogsåsen",
    streetNumber: "27E",
    postalCode: "1412",
    city: "Sofiemyr",
    country: "Norway",
  },
  {
    streetName: "Sandakerveien",
    streetNumber: "76 A",
    postalCode: "0484",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Edvard Munchs vei",
    streetNumber: " 69",
    postalCode: "1063",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Jordbærveien",
    streetNumber: "51",
    postalCode: "3032",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Runivegen",
    streetNumber: "4",
    postalCode: "2005",
    city: "Rælingen",
    country: "Norway",
  },
  {
    streetName: "Berjåsen",
    streetNumber: " 2",
    postalCode: "3440",
    city: "Røyken",
    country: "Norway",
  },
  {
    streetName: "Etterstadsletta",
    streetNumber: "70",
    postalCode: "0659",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Drammensveien",
    streetNumber: "91D",
    postalCode: "0271",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karl Andersens Vei",
    streetNumber: "51",
    postalCode: "1086",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Frøyas gate",
    streetNumber: "12",
    postalCode: "0273",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Riddervolds gate",
    streetNumber: "3",
    postalCode: "0258",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Røykenveien",
    streetNumber: "180A",
    postalCode: "1386",
    city: "Asker",
    country: "Norway",
  },
  {
    streetName: "Abildsøveien",
    streetNumber: " 43b",
    postalCode: "1187",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Fuglehauggata,",
    streetNumber: "14",
    postalCode: "0260",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Tillesløkka",
    streetNumber: " 8",
    postalCode: "1349",
    city: "Rykkinn",
    country: "Norway",
  },
  {
    streetName: "Iduns vei",
    streetNumber: " 2",
    postalCode: "1466",
    city: "Strømmen",
    country: "Norway",
  },
  {
    streetName: "Bentsegata",
    streetNumber: "2",
    postalCode: "0465",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Guldbrandslia",
    streetNumber: "17",
    postalCode: "1353",
    city: "Bærums verk",
    country: "Norway",
  },
  {
    streetName: "Hans Nielsen Hauges gate",
    streetNumber: "37 A",
    postalCode: "0481",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hekkveien 7",
    streetNumber: "c/o Hekkveien 7",
    postalCode: "0571",
    city: "Oslo",
    country: "no",
  },
  {
    streetName: "Øygardveien",
    streetNumber: "48",
    postalCode: "1357",
    city: "Bekkestua",
    country: "Norway",
  },
  {
    streetName: "Brannfjellveien",
    streetNumber: "104 B",
    postalCode: "1182",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bestumåsen",
    streetNumber: "3A",
    postalCode: "0281",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Kronveien",
    streetNumber: "7C",
    postalCode: "1169",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sars' Gate",
    streetNumber: "2A",
    postalCode: "0562",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bjerkealléen",
    streetNumber: "58",
    postalCode: "0487",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Granliveien",
    streetNumber: "20C",
    postalCode: "1086",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bjørnenga",
    streetNumber: "28",
    postalCode: "1364",
    city: "Fornebu",
    country: "Norge",
  },
  {
    streetName: "Blakstad Hageby",
    streetNumber: "7B",
    postalCode: "1392",
    city: "Vettre",
    country: "Norway",
  },
  {
    streetName: "Solveien",
    streetNumber: " 6",
    postalCode: "1434",
    city: "Ås",
    country: "Norway",
  },
  {
    streetName: "Høymyrmarka",
    streetNumber: "174",
    postalCode: "1391",
    city: "Vollen",
    country: "Norway",
  },
  {
    streetName: "Odins gate",
    streetNumber: "12a",
    postalCode: "0266",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Tittutgrenda",
    streetNumber: "9A",
    postalCode: "0668",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lundaveien",
    streetNumber: "43",
    postalCode: "3032",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Einar Aass vei,",
    streetNumber: "56",
    postalCode: "3046",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Treskeveien",
    streetNumber: " 90",
    postalCode: "0681",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Siebkes gate",
    streetNumber: "4b",
    postalCode: "0562",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Karupvegen",
    streetNumber: "12",
    postalCode: "2005",
    city: "Rælingen",
    country: "Norway",
  },
  {
    streetName: "Ekelundstien",
    streetNumber: "70",
    postalCode: "2004",
    city: "Lillestrøm",
    country: "Norge",
  },
  {
    streetName: "Helsetlia",
    streetNumber: "30",
    postalCode: "1353",
    city: "Bærums verk",
    country: "Norway",
  },
  {
    streetName: "Haugenlia",
    streetNumber: "21",
    postalCode: "1389",
    city: "Heggedal",
    country: "Norge",
  },
  {
    streetName: "Haugane",
    streetNumber: "64",
    postalCode: "3408",
    city: "Tranby",
    country: "Norge",
  },
  {
    streetName: "Myrvollveien",
    streetNumber: "13A",
    postalCode: "1363",
    city: "Høvik",
    country: "Norge",
  },
  {
    streetName: "Riihimækiveien",
    streetNumber: " 39",
    postalCode: "2013",
    city: "Skjetten",
    country: "Norway",
  },
  {
    streetName: "Etterstadsletta",
    streetNumber: " 91b",
    postalCode: "0660",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bergensgata",
    streetNumber: " 6",
    postalCode: "0468",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Tollbugata",
    streetNumber: " 29B",
    postalCode: "3044",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Edvard Munchs Vei",
    streetNumber: "69",
    postalCode: "1063",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "General Birchs gate",
    streetNumber: "30A",
    postalCode: "0454",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Venusbakken",
    streetNumber: "13",
    postalCode: "3033",
    city: "Drammen",
    country: "Norge",
  },
  {
    streetName: "Uelands gate",
    streetNumber: " 59J",
    postalCode: "0460",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Øvre Eikervei",
    streetNumber: " 140b",
    postalCode: "3048",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Strandengveien",
    streetNumber: "86",
    postalCode: "1390",
    city: "Vollen",
    country: "Norway",
  },
  {
    streetName: "Linneavegen",
    streetNumber: "15 a",
    postalCode: "2052",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Birger Olivers vei 14",
    streetNumber: " A",
    postalCode: "1176",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Stovnerveien",
    streetNumber: "18B",
    postalCode: "0980",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Riihimækiveien",
    streetNumber: " 35",
    postalCode: "2013",
    city: "Skjetten",
    country: "Norway",
  },
  {
    streetName: "St jørgens vei",
    streetNumber: "8",
    postalCode: "0662",
    city: "Oslo",
    country: "NO",
  },
  {
    streetName: "Heierstuveien",
    streetNumber: "26",
    postalCode: "0880",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Stålmila",
    streetNumber: " 14",
    postalCode: "1350",
    city: "Lommedalen",
    country: "Norway",
  },
  {
    streetName: "Nebbejordet",
    streetNumber: "11",
    postalCode: "1266",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Glostrupveien",
    streetNumber: "35",
    postalCode: "2013",
    city: "Skjetten",
    country: "Norway",
  },
  {
    streetName: "Hemmestveitbakken",
    streetNumber: "11",
    postalCode: "0378",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Tokerudberget",
    streetNumber: "35",
    postalCode: "0986",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Birkelundveien",
    streetNumber: "17",
    postalCode: "1481",
    city: "Hagan",
    country: "Norway",
  },
  {
    streetName: "Per Waalers Vei",
    streetNumber: "3",
    postalCode: "1366",
    city: "Lysaker",
    country: "Norge",
  },
  {
    streetName: "Gråkamveien",
    streetNumber: "1d ",
    postalCode: "0777",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hofftunet",
    streetNumber: "4",
    postalCode: "0377",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jegerveien",
    streetNumber: "40",
    postalCode: "0777",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Tangen terrasse",
    streetNumber: "63",
    postalCode: "1456",
    city: "Nesoddtangen",
    country: "Norway",
  },
  {
    streetName: "Sars Gate",
    streetNumber: "2a",
    postalCode: "0562",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bjørnekollen",
    streetNumber: " 31",
    postalCode: "1344",
    city: "Haslum",
    country: "Norway",
  },
  {
    streetName: "Edelgranveien",
    streetNumber: "52",
    postalCode: "1356",
    city: "Bekkestua",
    country: "Norway",
  },
  {
    streetName: "Korsgata",
    streetNumber: " 25C",
    postalCode: "0551",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sørumlia",
    streetNumber: "43",
    postalCode: "3426",
    city: "Gullaug",
    country: "Norway",
  },
  {
    streetName: "Vækerøveien",
    streetNumber: "118A",
    postalCode: "0383",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Arnebråtveien",
    streetNumber: "81B",
    postalCode: "0771",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Grensestien",
    streetNumber: "48",
    postalCode: "1251",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ekeveien",
    streetNumber: " 2",
    postalCode: "1446",
    city: "Drøbak",
    country: "Norway",
  },
  {
    streetName: "Vårveien",
    streetNumber: " 42b",
    postalCode: "3024",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Elgtråkket",
    streetNumber: "7A",
    postalCode: "2014",
    city: "Blystadlia",
    country: "Norway",
  },
  {
    streetName: "Hovseterveien",
    streetNumber: "98",
    postalCode: "0768",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Geitramsvegen",
    streetNumber: " 2a",
    postalCode: "2052",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Ekelyveien",
    streetNumber: "1c",
    postalCode: "0374",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ekelyveien",
    streetNumber: "1c",
    postalCode: "0374",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Fjellsetveien",
    streetNumber: " 123",
    postalCode: "2019",
    city: "Skedsmokorset",
    country: "Norway",
  },
  {
    streetName: "Skjellestadjordet",
    streetNumber: " 19",
    postalCode: "1389",
    city: "Heggedal",
    country: "Norway",
  },
  {
    streetName: "Karlstadgata 12",
    streetNumber: " B",
    postalCode: "0553",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Karisveien",
    streetNumber: " 192",
    postalCode: "2013",
    city: "Skjetten",
    country: "Norway",
  },
  {
    streetName: "Bjerregaards gate",
    streetNumber: "30C",
    postalCode: "0172",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Konows gate",
    streetNumber: "26 K",
    postalCode: "0196",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jongsåsveien",
    streetNumber: " 22",
    postalCode: "1338",
    city: "Sandvika",
    country: "Norway",
  },
  {
    streetName: "Lensmann Klevs Vei",
    streetNumber: "43",
    postalCode: "2019",
    city: "Skedsmokorset",
    country: "Norway",
  },
  {
    streetName: "Åråsveien",
    streetNumber: "24E",
    postalCode: "2007",
    city: "Kjeller",
    country: "Norway",
  },
  {
    streetName: "Fredriksborgveien",
    streetNumber: " 21",
    postalCode: "0286",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sørbråten",
    streetNumber: "7A",
    postalCode: "1353",
    city: "Bærums verk",
    country: "Norway",
  },
  {
    streetName: "Langoddveien",
    streetNumber: "107",
    postalCode: "1367",
    city: "Snarøya",
    country: "Norway",
  },
  {
    streetName: "Refstadsvingen 13",
    streetNumber: "c/o 2.etasje, dør til høyre",
    postalCode: "0589",
    city: "Oslo",
    country: "no",
  },
  {
    streetName: "Durudkollen",
    streetNumber: "12",
    postalCode: "1344",
    city: "Haslum",
    country: "Norway",
  },
  {
    streetName: "Solveien",
    streetNumber: "2",
    postalCode: "1434",
    city: "Ås",
    country: "Norge",
  },
  {
    streetName: "Haakon tveters vei",
    streetNumber: "38",
    postalCode: "0686",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Lunneveien",
    streetNumber: "3",
    postalCode: "2006",
    city: "Løvenstad",
    country: "Norge",
  },
  {
    streetName: "Solfallsveien",
    streetNumber: "10A",
    postalCode: "1435",
    city: "Ås",
    country: "Norway",
  },
  {
    streetName: "Lille Husebyvei",
    streetNumber: "6B",
    postalCode: "0377",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kjelsåsveien",
    streetNumber: "83J",
    postalCode: "0491",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Enebakkveien",
    streetNumber: " 25c",
    postalCode: "0657",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Haraldstua",
    streetNumber: "24",
    postalCode: "3030",
    city: "Drammen",
    country: "Norge",
  },
  {
    streetName: "Elisenbergveien",
    streetNumber: "15",
    postalCode: "0265",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Frysjaveien,",
    streetNumber: "55b",
    postalCode: "0884",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Frysjaveien,",
    streetNumber: "55b",
    postalCode: "0884",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bernhard Herres vei",
    streetNumber: " 23b",
    postalCode: "0376",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Pionerstien",
    streetNumber: " 12",
    postalCode: "1062",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Meiselen",
    streetNumber: " 6",
    postalCode: "3030",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Mogens Thorsens gate",
    streetNumber: "11",
    postalCode: "0264",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Valdresgata 9",
    streetNumber: "c/o Christian hanseth",
    postalCode: "0557",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Torpfjellet",
    streetNumber: "99",
    postalCode: "1389",
    city: "Heggedal",
    country: "Norway",
  },
  {
    streetName: "Veitvet veien",
    streetNumber: "6E",
    postalCode: "0596",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bogerudslyngen",
    streetNumber: "10",
    postalCode: "0692",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Nordkappgata",
    streetNumber: "17",
    postalCode: "0479",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nordstrand Terrasse",
    streetNumber: "49",
    postalCode: "1170",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Colletts Gate",
    streetNumber: "60A",
    postalCode: "0456",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Glimmerveien",
    streetNumber: "111",
    postalCode: "1475",
    city: "Finstadjordet",
    country: "Norway",
  },
  {
    streetName: "Martin Skatvedts vei",
    streetNumber: "32",
    postalCode: "0950",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Strandliveien",
    streetNumber: "9",
    postalCode: "1410",
    city: "Kolbotn",
    country: "Norge",
  },
  {
    streetName: "ankerveien",
    streetNumber: "42e",
    postalCode: "0785",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Reichweins gate",
    streetNumber: " 1a",
    postalCode: "0254",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Tjernveien",
    streetNumber: "18",
    postalCode: "0957",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sørkedalsveien",
    streetNumber: "15A",
    postalCode: "0369",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sars Gate",
    streetNumber: "31C",
    postalCode: "0562",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bispeveien",
    streetNumber: "66",
    postalCode: "1362",
    city: "Hosle",
    country: "Norway",
  },
  {
    streetName: "Eventyrveien",
    streetNumber: "18",
    postalCode: "1391",
    city: "Vollen",
    country: "Norway",
  },
  {
    streetName: "Innspurten",
    streetNumber: "4A",
    postalCode: "0663",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Solbergliveien",
    streetNumber: "20A",
    postalCode: "0671",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Randemfaret",
    streetNumber: "24",
    postalCode: "1543",
    city: "Vestby",
    country: "Norway",
  },
  {
    streetName: "Skausnaret",
    streetNumber: "5F",
    postalCode: "1262",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Montebelloveien",
    streetNumber: " 7e",
    postalCode: "0377",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Romsaas Alle",
    streetNumber: "17b",
    postalCode: "2063",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Skogholtveien",
    streetNumber: "26B",
    postalCode: "1178",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hattemakerlia",
    streetNumber: "44",
    postalCode: "2019",
    city: "Skedsmokorset",
    country: "Norway",
  },
  {
    streetName: "Kastanjeveien",
    streetNumber: " 110",
    postalCode: "3026",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Siriusveien",
    streetNumber: "16",
    postalCode: "0492",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Vogts Gate",
    streetNumber: "46",
    postalCode: "0477",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Munkebekken",
    streetNumber: "370",
    postalCode: "1061",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bølerlia",
    streetNumber: " 9",
    postalCode: "0691",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Nordterrassen",
    streetNumber: "13",
    postalCode: "1358",
    city: "Jar",
    country: "Norway",
  },
  {
    streetName: "2. Bera Terrasse",
    streetNumber: "3C",
    postalCode: "3026",
    city: "Drammen",
    country: "Norge",
  },
  {
    streetName: "Bjørnenga",
    streetNumber: " 44",
    postalCode: "1364",
    city: "Fornebu",
    country: "Norway",
  },
  {
    streetName: "Oscars gate",
    streetNumber: "35A",
    postalCode: "0258",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Økernveien",
    streetNumber: "243B",
    postalCode: "0584",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Gamlelinja",
    streetNumber: "41",
    postalCode: "1254",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Haakon Tveters vei",
    streetNumber: "38",
    postalCode: "0686",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Kverndalsvegen",
    streetNumber: "35",
    postalCode: "2067",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Lundaveien",
    streetNumber: "43",
    postalCode: "3032",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Radarveien",
    streetNumber: "86",
    postalCode: "1152",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Kvernveien",
    streetNumber: " 37",
    postalCode: "3043",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Nydalen allé",
    streetNumber: "73",
    postalCode: "0485",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Grefsenveien",
    streetNumber: "80B",
    postalCode: "0489",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Buskerudveien",
    streetNumber: "147",
    postalCode: "3027",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Grenseveien",
    streetNumber: "54",
    postalCode: "0579",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Prost Holsviksvei",
    streetNumber: "27",
    postalCode: "1362",
    city: "Hosle",
    country: "Norway",
  },
  {
    streetName: "Buskerudveien",
    streetNumber: "147",
    postalCode: "3027",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Liadalsveien",
    streetNumber: "12",
    postalCode: "1168",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Liadalsveien",
    streetNumber: "12",
    postalCode: "1168",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sørkedalsveien",
    streetNumber: "239",
    postalCode: "0754",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kjelsåsveien",
    streetNumber: "83 C",
    postalCode: "0491",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Einar Aas Vei",
    streetNumber: "84",
    postalCode: "3046",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Maridalsveien",
    streetNumber: "33D",
    postalCode: "0175",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Kjøvangveien",
    streetNumber: "17",
    postalCode: "1555",
    city: "Son",
    country: "Norge",
  },
  {
    streetName: "Sørkedalsveien",
    streetNumber: "200 C",
    postalCode: "0754",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Krydsbylia",
    streetNumber: "15",
    postalCode: "1350",
    city: "Lommedalen",
    country: "Norway",
  },
  {
    streetName: "Bergliveien",
    streetNumber: " 15B",
    postalCode: "2019",
    city: "Skedsmokorset",
    country: "Norway",
  },
  {
    streetName: "Nøklesvingen",
    streetNumber: "43",
    postalCode: "0689",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "St. Sunnivas gata",
    streetNumber: "3a",
    postalCode: "2004",
    city: "Lillestrøm",
    country: "Norway",
  },
  {
    streetName: "Finn Thorsagers vei",
    streetNumber: " 14",
    postalCode: "1360",
    city: "Fornebu",
    country: "Norway",
  },
  {
    streetName: "Mor go hjertas vei",
    streetNumber: "13",
    postalCode: "0469",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Margarethas vei",
    streetNumber: " 1",
    postalCode: "1473",
    city: "Lørenskog",
    country: "Norway",
  },
  {
    streetName: "Selvbyggerveien",
    streetNumber: "135",
    postalCode: "0591",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Alingsåsveien",
    streetNumber: " 15",
    postalCode: "2013",
    city: "Skjetten",
    country: "Norway",
  },
  {
    streetName: "Sætreskogveien",
    streetNumber: "32B",
    postalCode: "1415",
    city: "Oppegård",
    country: "Norway",
  },
  {
    streetName: "Almeveien",
    streetNumber: " 7",
    postalCode: "1470",
    city: "Lørenskog",
    country: "Norway",
  },
  {
    streetName: "Brobekkveien",
    streetNumber: "26",
    postalCode: "0598",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sætreskogveien,",
    streetNumber: "25D",
    postalCode: "1415",
    city: "Oppegård",
    country: "Norway",
  },
  {
    streetName: "Haakon Tveters vei",
    streetNumber: "43",
    postalCode: "0686",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Åsterudsletta",
    streetNumber: " 36",
    postalCode: "1344",
    city: "Haslum",
    country: "Norway",
  },
  {
    streetName: "Meierivegen",
    streetNumber: "14",
    postalCode: "2040",
    city: "Kløfta",
    country: "Norway",
  },
  {
    streetName: "Solveien",
    streetNumber: "3",
    postalCode: "1177",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lundekroken",
    streetNumber: " 34",
    postalCode: "1396",
    city: "Billingstad",
    country: "Norway",
  },
  {
    streetName: "inga f gjerdrums vei",
    streetNumber: "43",
    postalCode: "1396",
    city: "Billingstad",
    country: "Norway",
  },
  {
    streetName: "Ullernkollen",
    streetNumber: " 1D",
    postalCode: "0382",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ekebergveien",
    streetNumber: "7M",
    postalCode: "0192",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Åreppenkroken",
    streetNumber: " 8",
    postalCode: "2040",
    city: "Kløfta",
    country: "Norway",
  },
  {
    streetName: "Vestre Greverud Terrasse",
    streetNumber: "10D",
    postalCode: "1415",
    city: "Oppegård",
    country: "Norway",
  },
  {
    streetName: "Ullevålsveien",
    streetNumber: "93",
    postalCode: "0359",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lusetjernveien",
    streetNumber: "42",
    postalCode: "1253",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kringsjåveien",
    streetNumber: " 37",
    postalCode: "1415",
    city: "Oppegård",
    country: "Norway",
  },
  {
    streetName: "Lørenveien",
    streetNumber: "69",
    postalCode: "0585",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Per o. Lunds gate",
    streetNumber: " 2",
    postalCode: "1475",
    city: "Finstadjordet",
    country: "Norway",
  },
  {
    streetName: "Tømtesletta",
    streetNumber: "28",
    postalCode: "2013",
    city: "Skjetten",
    country: "Norway",
  },
  {
    streetName: "Tømtesletta",
    streetNumber: "28",
    postalCode: "2013",
    city: "Skjetten",
    country: "Norway",
  },
  {
    streetName: "Fougstads gate",
    streetNumber: "25B",
    postalCode: "0173",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lauritz Hervigs vei",
    streetNumber: " 164C",
    postalCode: "3035",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Sørensvingen",
    streetNumber: " 18",
    postalCode: "3028",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Gamleveien",
    streetNumber: "14",
    postalCode: "1350",
    city: "Lommedalen",
    country: "Norway",
  },
  {
    streetName: "Oskar Braatens vei",
    streetNumber: " 4D",
    postalCode: "1412",
    city: "Sofiemyr",
    country: "Norway",
  },
  {
    streetName: "Cecilie Thoresens vei",
    streetNumber: " 24",
    postalCode: "1153",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ånnerudskogen",
    streetNumber: "46A",
    postalCode: "1383",
    city: "Asker",
    country: "Norway",
  },
  {
    streetName: "Damveien",
    streetNumber: " 9",
    postalCode: "1415",
    city: "Oppegård",
    country: "Norway",
  },
  {
    streetName: "Kirkeveien 78",
    streetNumber: " B",
    postalCode: "0364",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Granstuveien ,",
    streetNumber: "13a",
    postalCode: "1185",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Fredrik Borgens veg",
    streetNumber: " 21",
    postalCode: "2040",
    city: "Kløfta",
    country: "Norway",
  },
  {
    streetName: "Tønnesveien",
    streetNumber: " 20",
    postalCode: "1555",
    city: "Son",
    country: "Norway",
  },
  {
    streetName: "Bekkeveien",
    streetNumber: "10D",
    postalCode: "1365",
    city: "Blommenholm",
    country: "Norway",
  },
  {
    streetName: "Kruses Gate",
    streetNumber: "4",
    postalCode: "0263",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dælenenggata",
    streetNumber: " 28a",
    postalCode: "0567",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Vinterkroken",
    streetNumber: "10a",
    postalCode: "1395",
    city: "Hvalstad",
    country: "Norway",
  },
  {
    streetName: "Nedre Movei",
    streetNumber: " 10",
    postalCode: "1450",
    city: "Nesoddtangen",
    country: "Norway",
  },
  {
    streetName: "Sognsveien",
    streetNumber: " 4",
    postalCode: "0451",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Brinken",
    streetNumber: " 8",
    postalCode: "3426",
    city: "Gullaug",
    country: "Norway",
  },
  {
    streetName: "Graahbakken",
    streetNumber: "7B",
    postalCode: "0286",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tveterveien",
    streetNumber: "4",
    postalCode: "1344",
    city: "Haslum",
    country: "Norge",
  },
  {
    streetName: "Bakkergata",
    streetNumber: " 16",
    postalCode: "2004",
    city: "Lillestrøm",
    country: "Norway",
  },
  {
    streetName: "PASTOR FANGENS VEI",
    streetNumber: "47B",
    postalCode: "0870",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Osterhaus\\' gate",
    streetNumber: " 16",
    postalCode: "0183",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Nydalen Allé",
    streetNumber: "27",
    postalCode: "0484",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Fagerliveien",
    streetNumber: " 17",
    postalCode: "1435",
    city: "Ås",
    country: "Norway",
  },
  {
    streetName: "Fagerliveien",
    streetNumber: " 17",
    postalCode: "1435",
    city: "Ås",
    country: "Norway",
  },
  {
    streetName: "Finstad Hageby",
    streetNumber: "69",
    postalCode: "1423",
    city: "Ski",
    country: "Norge",
  },
  {
    streetName: "Arnebråtveien",
    streetNumber: "42B",
    postalCode: "0771",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Nybakveien",
    streetNumber: " 111",
    postalCode: "2013",
    city: "Skjetten",
    country: "Norway",
  },
  {
    streetName: "Fredheimlia",
    streetNumber: " 53",
    postalCode: "1362",
    city: "Hosle",
    country: "Norway",
  },
  {
    streetName: "Trulsrudskogen",
    streetNumber: " 35",
    postalCode: "1350",
    city: "Lommedalen",
    country: "Norway",
  },
  {
    streetName: "Bekkedalsveien",
    streetNumber: "9A",
    postalCode: "3030",
    city: "Drammen",
    country: "Norge",
  },
  {
    streetName: "Nils Collett Vogts vei",
    streetNumber: "31 ",
    postalCode: "0766",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sarahaugen",
    streetNumber: "15",
    postalCode: "1386",
    city: "Asker",
    country: "Norway",
  },
  {
    streetName: "Solfjellveien",
    streetNumber: "32",
    postalCode: "1389",
    city: "Heggedal",
    country: "Norge",
  },
  {
    streetName: "Moserudveien",
    streetNumber: "15",
    postalCode: "3440",
    city: "Røyken",
    country: "NO",
  },
  {
    streetName: "Ruglandveien",
    streetNumber: " 148B",
    postalCode: "1359",
    city: "Eiksmarka",
    country: "Norway",
  },
  {
    streetName: "Levrebakken",
    streetNumber: " 28",
    postalCode: "1352",
    city: "Kolsås",
    country: "Norway",
  },
  {
    streetName: "Sankt Jørgens vei",
    streetNumber: "69",
    postalCode: "0662",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Myrhaugen",
    streetNumber: "29",
    postalCode: "0752",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bergsvingen",
    streetNumber: "2",
    postalCode: "1358",
    city: "Jar",
    country: "Norge",
  },
  {
    streetName: "Skillebekkveien",
    streetNumber: "128",
    postalCode: "2020",
    city: "Skedsmokorset",
    country: "Norge",
  },
  {
    streetName: "Lachmannsvei",
    streetNumber: "12 a",
    postalCode: "0495",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Åssiden terrasse",
    streetNumber: "38A",
    postalCode: "1160",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Breigata",
    streetNumber: "10",
    postalCode: "0187",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Vestliveien",
    streetNumber: " 15B",
    postalCode: "1415",
    city: "Oppegård",
    country: "Norway",
  },
  {
    streetName: "Frognerseterveien 17",
    streetNumber: " D",
    postalCode: "0775",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Kornmoenga",
    streetNumber: "86",
    postalCode: "1413",
    city: "Tårnåsen",
    country: "Norway",
  },
  {
    streetName: "Ammerudveien",
    streetNumber: "50",
    postalCode: "0958",
    city: "Oslo",
    country: "NO",
  },
  {
    streetName: "Smalgangen",
    streetNumber: " 21",
    postalCode: "0188",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Baglerfaret",
    streetNumber: "7c",
    postalCode: "0677",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Depotgata",
    streetNumber: " 35J",
    postalCode: "2000",
    city: "Lillestrøm",
    country: "Norway",
  },
  {
    streetName: "Lilleruts vei",
    streetNumber: " 65A",
    postalCode: "1364",
    city: "Fornebu",
    country: "Norway",
  },
  {
    streetName: "Kirkeveien",
    streetNumber: " 5",
    postalCode: "0266",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Rudsberghaugen",
    streetNumber: "59",
    postalCode: "2005",
    city: "Rælingen",
    country: "Norway",
  },
  {
    streetName: "Fururabben",
    streetNumber: "21A",
    postalCode: "1361",
    city: "Østerås",
    country: "Norge",
  },
  {
    streetName: "Myrvollveien",
    streetNumber: "24",
    postalCode: "1415",
    city: "Oppegård",
    country: "Norge",
  },
  {
    streetName: "Dronning Eufemias Gate",
    streetNumber: "24",
    postalCode: "0191",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Kaptein Oppegaards Vei",
    streetNumber: "36B",
    postalCode: "1152",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Solveien",
    streetNumber: "130B",
    postalCode: "1167",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Åsenhagen",
    streetNumber: "66E",
    postalCode: "2020",
    city: "Skedsmokorset",
    country: "Norge",
  },
  {
    streetName: "Åsveien",
    streetNumber: " 9",
    postalCode: "1369",
    city: "Stabekk",
    country: "Norway",
  },
  {
    streetName: "Åsløkkveien",
    streetNumber: " 22A",
    postalCode: "1341",
    city: "Slependen",
    country: "Norway",
  },
  {
    streetName: "Bjørnenga",
    streetNumber: "13",
    postalCode: "1364",
    city: "Fornebu",
    country: "Norge",
  },
  {
    streetName: "Hesjeveien",
    streetNumber: "11B",
    postalCode: "1084",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Wilhelm Wilhelmsens Vei",
    streetNumber: "13",
    postalCode: "1362",
    city: "Hosle",
    country: "Norway",
  },
  {
    streetName: "Grenseveien",
    streetNumber: " 57C",
    postalCode: "0576",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Brusagaveien",
    streetNumber: "90",
    postalCode: "1404",
    city: "Siggerud",
    country: "Norway",
  },
  {
    streetName: "Anton Tschudis vei",
    streetNumber: "64 B",
    postalCode: "1344",
    city: "Haslum",
    country: "Norway",
  },
  {
    streetName: "Ljabrubakken",
    streetNumber: "42",
    postalCode: "1165",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tunnelveien",
    streetNumber: " 6",
    postalCode: "3426",
    city: "Gullaug",
    country: "Norway",
  },
  {
    streetName: "Inga Bjørnsons vei",
    streetNumber: " 119",
    postalCode: "0969",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Tussebekken",
    streetNumber: "4",
    postalCode: "1415",
    city: "Oppegård",
    country: "Norge",
  },
  {
    streetName: "Oksenøyveien",
    streetNumber: " 37",
    postalCode: "1366",
    city: "Lysaker",
    country: "Norway",
  },
  {
    streetName: "Vargveien",
    streetNumber: " 20",
    postalCode: "0139",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Konnerudgata",
    streetNumber: " 47",
    postalCode: "3045",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Stjerneveien",
    streetNumber: " 15",
    postalCode: "1482",
    city: "Nittedal",
    country: "Norway",
  },
  {
    streetName: "Innspurten",
    streetNumber: "8a",
    postalCode: "0663",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Trelastveien",
    streetNumber: " 14",
    postalCode: "1415",
    city: "Oppegård",
    country: "Norway",
  },
  {
    streetName: "Årvollveien",
    streetNumber: " 58p",
    postalCode: "0590",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Markalleen",
    streetNumber: "15B",
    postalCode: "1368",
    city: "Stabekk",
    country: "Norge",
  },
  {
    streetName: "Martin Linges Vei",
    streetNumber: "13",
    postalCode: "0692",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Kirkegårdsgata",
    streetNumber: "10A",
    postalCode: "0558",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fagertunveien",
    streetNumber: "64, 1357 Bekkestua",
    postalCode: "1357",
    city: "Bekkestua",
    country: "Norge",
  },
  {
    streetName: "Tokerudkollen",
    streetNumber: "27C",
    postalCode: "1336",
    city: "Sandvika",
    country: "Norway",
  },
  {
    streetName: "Gregers Grams vei",
    streetNumber: "21",
    postalCode: "0382",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Elvetangen",
    streetNumber: " 16",
    postalCode: "1396",
    city: "Billingstad",
    country: "Norway",
  },
  {
    streetName: "Trondheimsveien",
    streetNumber: " 75",
    postalCode: "0565",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Gulliveien",
    streetNumber: "54",
    postalCode: "1405",
    city: "Langhus",
    country: "Norway",
  },
  {
    streetName: "Arildsvingen",
    streetNumber: "16",
    postalCode: "0491",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Dynekilgata",
    streetNumber: "15",
    postalCode: "0569",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hafrsfjordgata",
    streetNumber: " 37a",
    postalCode: "0268",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Tokerudberget",
    streetNumber: "5",
    postalCode: "0986",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Kranveien",
    streetNumber: "17",
    postalCode: "0684",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lindebergåsen",
    streetNumber: "36A",
    postalCode: "1068",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sankt Halvards gate",
    streetNumber: " 5",
    postalCode: "3015",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Kastanjeveien",
    streetNumber: "116",
    postalCode: "3026",
    city: "Drammen",
    country: "Norge",
  },
  {
    streetName: "Blindernveien 6B",
    streetNumber: "c/o H0902",
    postalCode: "0361",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Haugergrende",
    streetNumber: "57",
    postalCode: "1352",
    city: "Kolsås",
    country: "Norway",
  },
  {
    streetName: "Bygdøy allé",
    streetNumber: "125A",
    postalCode: "0273",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Eventyrveien",
    streetNumber: " 22",
    postalCode: "0851",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Myrvollveien",
    streetNumber: "24",
    postalCode: "1415",
    city: "Oppegård",
    country: "Norge",
  },
  {
    streetName: "Bertrand Narvesens vei",
    streetNumber: "2b",
    postalCode: "0661",
    city: "Oslo",
    country: "NO",
  },
  {
    streetName: "Hans Nordahls Gate",
    streetNumber: "86",
    postalCode: "0485",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lillogata",
    streetNumber: "5",
    postalCode: "0484",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Heggtoppen",
    streetNumber: "13",
    postalCode: "3403",
    city: "Lier",
    country: "Norge",
  },
  {
    streetName: "Dalsveien",
    streetNumber: "60 D",
    postalCode: "0775",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lørenveien",
    streetNumber: "69 A",
    postalCode: "0585",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vogts gate 47",
    streetNumber: " B",
    postalCode: "0477",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Klosterheimveien",
    streetNumber: " 10",
    postalCode: "0666",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hoppveien",
    streetNumber: "6",
    postalCode: "0687",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skogveien",
    streetNumber: "33",
    postalCode: "1470",
    city: "Lørenskog",
    country: "Norway",
  },
  {
    streetName: "Tidemands gate",
    streetNumber: " 27",
    postalCode: "0260",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Alfheimveien",
    streetNumber: "3B",
    postalCode: "1358",
    city: "Jar",
    country: "Norge",
  },
  {
    streetName: "Sigurd Hoels vei",
    streetNumber: " 67",
    postalCode: "0655",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hosleveien",
    streetNumber: "22",
    postalCode: "1358",
    city: "Jar",
    country: "Norway",
  },
  {
    streetName: "Thereses gate",
    streetNumber: " 14A",
    postalCode: "0452",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hønsmyra",
    streetNumber: " 12",
    postalCode: "1384",
    city: "Asker",
    country: "Norway",
  },
  {
    streetName: "St. Halvards gate",
    streetNumber: "25F",
    postalCode: "0192",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Solliveien 5",
    streetNumber: " M",
    postalCode: "1482",
    city: "Nittedal",
    country: "Norway",
  },
  {
    streetName: "Rådyrvegen",
    streetNumber: "20",
    postalCode: "2016",
    city: "Frogner",
    country: "Norge",
  },
  {
    streetName: "Langoddveien",
    streetNumber: " 124",
    postalCode: "1367",
    city: "Snarøya",
    country: "Norway",
  },
  {
    streetName: "Grefsenkollveien",
    streetNumber: "20A",
    postalCode: "0490",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Caspar Erlandsens vei",
    streetNumber: "20",
    postalCode: "1543",
    city: "Vestby",
    country: "Norge",
  },
  {
    streetName: "Platous gate 4a",
    streetNumber: "c/o Sharefox AS",
    postalCode: "0190",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nydalsveien",
    streetNumber: "20a",
    postalCode: "0484",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Rolf Presthus vei,,",
    streetNumber: "69",
    postalCode: "1415",
    city: "Oppegård",
    country: "Norway",
  },
  {
    streetName: "Husebybakken",
    streetNumber: "1C",
    postalCode: "0379",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bølerlia",
    streetNumber: "145",
    postalCode: "0689",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bekkelia",
    streetNumber: " 19",
    postalCode: "1481",
    city: "Hagan",
    country: "Norway",
  },
  {
    streetName: "Røahellinga",
    streetNumber: " 21",
    postalCode: "0755",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Engebråtveien",
    streetNumber: "12c",
    postalCode: "0493",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Radarveien",
    streetNumber: "37",
    postalCode: "1152",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Fernanda Nissens Gate",
    streetNumber: "1d",
    postalCode: "0484",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Brennavegen",
    streetNumber: "11",
    postalCode: "2040",
    city: "Kløfta",
    country: "Norge",
  },
  {
    streetName: "Lia",
    streetNumber: "8",
    postalCode: "1385",
    city: "Asker",
    country: "Norge",
  },
  {
    streetName: "Nedre Toppenhaug",
    streetNumber: " 57",
    postalCode: "1353",
    city: "Bærums verk",
    country: "Norway",
  },
  {
    streetName: "Dynekilgata",
    streetNumber: " 15",
    postalCode: "0569",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Inkognito Terrasse 2",
    streetNumber: "c/o (leil 55)",
    postalCode: "0256",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frysjaveien",
    streetNumber: "7A",
    postalCode: "0883",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Siporexveien",
    streetNumber: "8",
    postalCode: "3427",
    city: "Gullaug",
    country: "Norge",
  },
  {
    streetName: "Olaf Bulls vei",
    streetNumber: "9 C",
    postalCode: "0765",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Leiraveien",
    streetNumber: "15",
    postalCode: "2000",
    city: "Lillestrøm",
    country: "Norway",
  },
  {
    streetName: "Malmøyveien",
    streetNumber: " 24b",
    postalCode: "0198",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Korsvollbakken",
    streetNumber: "12",
    postalCode: "0880",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Kirkeveien",
    streetNumber: "166C",
    postalCode: "0450",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Knud Askers vei",
    streetNumber: " 18E",
    postalCode: "1383",
    city: "Asker",
    country: "Norway",
  },
  {
    streetName: "Hurdalsgata",
    streetNumber: " 24",
    postalCode: "2003",
    city: "Lillestrøm",
    country: "Norway",
  },
  {
    streetName: "Vollenlia ,",
    streetNumber: "91",
    postalCode: "1390",
    city: "Vollen",
    country: "Norway",
  },
  {
    streetName: "Kampengata 28C",
    streetNumber: "c/o Calle Duvdal",
    postalCode: "0654",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Soltoppveien",
    streetNumber: "2b",
    postalCode: "1555",
    city: "Son",
    country: "Norway",
  },
  {
    streetName: "Hans Nielsen Hauges Gate",
    streetNumber: "37G",
    postalCode: "0481",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Furulia",
    streetNumber: " 13",
    postalCode: "1555",
    city: "Son",
    country: "Norway",
  },
  {
    streetName: "Lusetjernveien",
    streetNumber: " 54",
    postalCode: "1253",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "A.C. Svarstads gate",
    streetNumber: " 2A",
    postalCode: "2003",
    city: "Lillestrøm",
    country: "Norway",
  },
  {
    streetName: "Gamleveien",
    streetNumber: "46A",
    postalCode: "3030",
    city: "Drammen",
    country: "Norge",
  },
  {
    streetName: "Trondheimsvegen - Jessheim",
    streetNumber: " 102A",
    postalCode: "2050",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Linnesstranda",
    streetNumber: "72",
    postalCode: "3426",
    city: "Gullaug",
    country: "Norge",
  },
  {
    streetName: "Roald Amundsens gate",
    streetNumber: " 50",
    postalCode: "2004",
    city: "Lillestrøm",
    country: "Norway",
  },
  {
    streetName: "Fossbergjordet",
    streetNumber: "10",
    postalCode: "1350",
    city: "Lommedalen",
    country: "Norge",
  },
  {
    streetName: "Lørenveien",
    streetNumber: "16",
    postalCode: "0585",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Haugerudveien",
    streetNumber: " 76",
    postalCode: "0674",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hans Nielsen Hauges Gate",
    streetNumber: "37A ",
    postalCode: "0481",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Konows gate",
    streetNumber: " 28F",
    postalCode: "0196",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Smalgangen",
    streetNumber: "24",
    postalCode: "0188",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Væringvegen",
    streetNumber: "44",
    postalCode: "2040",
    city: "Kløfta",
    country: "Norge",
  },
  {
    streetName: "Øvre Storgate",
    streetNumber: "132",
    postalCode: "3018",
    city: "Drammen",
    country: "Norge",
  },
  {
    streetName: "Gjønnesveien",
    streetNumber: "17B",
    postalCode: "1356",
    city: "Bekkestua",
    country: "Norway",
  },
  {
    streetName: "Tærudstien",
    streetNumber: " 17",
    postalCode: "2020",
    city: "Skedsmokorset",
    country: "Norway",
  },
  {
    streetName: "Pilestredet Park",
    streetNumber: " 18",
    postalCode: "0176",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Elisenbergveien",
    streetNumber: "15",
    postalCode: "0265",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Skogryggveien",
    streetNumber: "14a",
    postalCode: "0781",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Operagata",
    streetNumber: "5",
    postalCode: "0194",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Jegerveien",
    streetNumber: "5H",
    postalCode: "0777",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Faunveien",
    streetNumber: "3",
    postalCode: "0781",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hølandsgata 22B",
    streetNumber: "c/o H0103",
    postalCode: "0655",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bjørnekollen",
    streetNumber: "29",
    postalCode: "1344",
    city: "Haslum",
    country: "Norway",
  },
  {
    streetName: "Manglerudveien",
    streetNumber: "56A",
    postalCode: "0678",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Runivegen",
    streetNumber: "4",
    postalCode: "2005",
    city: "Rælingen",
    country: "Norway",
  },
  {
    streetName: "Stovnerveien",
    streetNumber: "18C",
    postalCode: "0980",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Maridalsveien",
    streetNumber: " 261B",
    postalCode: "0872",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ravnsborgveien",
    streetNumber: "10a",
    postalCode: "1395",
    city: "Hvalstad",
    country: "Norway",
  },
  {
    streetName: "Tungebråtveien",
    streetNumber: "16",
    postalCode: "1165",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Øgårdsveien",
    streetNumber: "43",
    postalCode: "1390",
    city: "Vollen",
    country: "Norway",
  },
  {
    streetName: "Vossegata",
    streetNumber: " 30",
    postalCode: "0475",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Odden",
    streetNumber: "12 B",
    postalCode: "1397",
    city: "Nesøya",
    country: "Norway",
  },
  {
    streetName: "Øvre Storgate",
    streetNumber: "122",
    postalCode: "3018",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Bankveien",
    streetNumber: " 4B",
    postalCode: "1362",
    city: "Hosle",
    country: "Norway",
  },
  {
    streetName: "Normannsgata",
    streetNumber: "31D",
    postalCode: "0655",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Betzy Kjelsbergs vei",
    streetNumber: "5B",
    postalCode: "0486",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Liakollveien",
    streetNumber: "26B",
    postalCode: "1259",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hasselhaugveien",
    streetNumber: "36",
    postalCode: "0851",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Pauline Halls Vei",
    streetNumber: "57",
    postalCode: "1410",
    city: "Kolbotn",
    country: "Norway",
  },
  {
    streetName: "Bygdøy allé",
    streetNumber: "111A",
    postalCode: "0273",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Johan Sverdrups vei",
    streetNumber: " 17",
    postalCode: "3041",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Artilleristubben",
    streetNumber: " 6",
    postalCode: "2067",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Fergemannens vei",
    streetNumber: "16",
    postalCode: "1542",
    city: "Vestby",
    country: "Norway",
  },
  {
    streetName: "Kampheimveien",
    streetNumber: "12 B",
    postalCode: "0685",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sannergata",
    streetNumber: "6E",
    postalCode: "0557",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bogstadveien",
    streetNumber: "39B",
    postalCode: "0366",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Refstadveien",
    streetNumber: "100",
    postalCode: "0589",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Reistadjordet",
    streetNumber: "75",
    postalCode: "1394",
    city: "Nesbru",
    country: "Norge",
  },
  {
    streetName: "Skolegata",
    streetNumber: "15",
    postalCode: "2010",
    city: "Strømmen",
    country: "Norge",
  },
  {
    streetName: "Oscars gate",
    streetNumber: "65",
    postalCode: "0256",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fallanveien",
    streetNumber: "17",
    postalCode: "0495",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "kitty kiellandsvei",
    streetNumber: "8c",
    postalCode: "1344",
    city: "Haslum",
    country: "Norge",
  },
  {
    streetName: "Flaengrenda",
    streetNumber: " 11",
    postalCode: "0953",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Selvbyggerveien",
    streetNumber: "258",
    postalCode: "0591",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Framnesveien",
    streetNumber: " 12",
    postalCode: "0270",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Robert Millars Vei",
    streetNumber: "16F",
    postalCode: "1185",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Fosseveien",
    streetNumber: "3",
    postalCode: "3043",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Ljabrubakken",
    streetNumber: "42",
    postalCode: "1165",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Østliveien",
    streetNumber: "13",
    postalCode: "1389",
    city: "Heggedal",
    country: "Norway",
  },
  {
    streetName: "Haakon Tveters vei",
    streetNumber: "43",
    postalCode: "0686",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Langgata",
    streetNumber: "46",
    postalCode: "0566",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Einars vei",
    streetNumber: " 18",
    postalCode: "0575",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Leiv Eirikssons gate",
    streetNumber: "2c",
    postalCode: "2004",
    city: "Lillestrøm",
    country: "Norway",
  },
  {
    streetName: "Jegerveien",
    streetNumber: "40",
    postalCode: "0777",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Munthes gate",
    streetNumber: " 39",
    postalCode: "0260",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Anton Tschudis vei",
    streetNumber: " 39b",
    postalCode: "1344",
    city: "Haslum",
    country: "Norway",
  },
  {
    streetName: "Hedmarksgata",
    streetNumber: " 10B",
    postalCode: "0658",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Tors vei",
    streetNumber: "45B",
    postalCode: "3040",
    city: "Drammen",
    country: "Norge",
  },
  {
    streetName: "General Laakes Vei",
    streetNumber: "68",
    postalCode: "2010",
    city: "Strømmen",
    country: "Norway",
  },
  {
    streetName: "Frydens Gate",
    streetNumber: "5C",
    postalCode: "0564",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Mellomstykket",
    streetNumber: " 9",
    postalCode: "3032",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Heimdalsveien 18",
    streetNumber: " B",
    postalCode: "1464",
    city: "Fjellhamar",
    country: "Norway",
  },
  {
    streetName: "0613), Teglverksgata 7 Leil 93, 6. etg",
    streetNumber: " (H",
    postalCode: "0553",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sigurd Johannesens vei",
    streetNumber: "5",
    postalCode: "1163",
    city: "Oslo",
    country: "NO",
  },
  {
    streetName: "Abbediengveien",
    streetNumber: " 23",
    postalCode: "0275",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Nedre Berg vei",
    streetNumber: "15",
    postalCode: "1384",
    city: "Asker",
    country: "Norway",
  },
  {
    streetName: "Grorudhaugen",
    streetNumber: "11A",
    postalCode: "1353",
    city: "Bærums verk",
    country: "Norway",
  },
  {
    streetName: "Trondheimsveien",
    streetNumber: "164A",
    postalCode: "0570",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kløftaveien",
    streetNumber: " 71",
    postalCode: "1454",
    city: "Fagerstrand",
    country: "Norway",
  },
  {
    streetName: "Drammensveien",
    streetNumber: "116B",
    postalCode: "0273",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "John Strandruds Vei",
    streetNumber: "13",
    postalCode: "1366",
    city: "Lysaker",
    country: "Norway",
  },
  {
    streetName: "Nedre Ullevål",
    streetNumber: "7",
    postalCode: "0850",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Refstadsvingen 13",
    streetNumber: "c/o 2.etasje, dør til høyre",
    postalCode: "0589",
    city: "Oslo",
    country: "no",
  },
  {
    streetName: "Haakon Tveters vei",
    streetNumber: "37 B",
    postalCode: "0682",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sagdalsveien 21 A",
    streetNumber: "c/o 792",
    postalCode: "2010",
    city: "Strømmen",
    country: "Norge",
  },
  {
    streetName: "Carl Johnsens vei",
    streetNumber: "6",
    postalCode: "1357",
    city: "Bekkestua",
    country: "Norge",
  },
  {
    streetName: "Engervannsveien",
    streetNumber: "31",
    postalCode: "1337",
    city: "Sandvika",
    country: "Norge",
  },
  {
    streetName: "Gøteborggata",
    streetNumber: "51",
    postalCode: "0566",
    city: "Oslo",
    country: "NO",
  },
  {
    streetName: "Nordkappgata",
    streetNumber: "17",
    postalCode: "0479",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Haugerudveien",
    streetNumber: "88",
    postalCode: "0674",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brøttet",
    streetNumber: "24",
    postalCode: "3029",
    city: "Drammen",
    country: "Norge",
  },
  {
    streetName: "Sætreskogveien,",
    streetNumber: "25D",
    postalCode: "1415",
    city: "Oppegård",
    country: "Norway",
  },
  {
    streetName: "Sætreskogveien",
    streetNumber: "22B",
    postalCode: "1415",
    city: "Oppegård",
    country: "Norway",
  },
  {
    streetName: "Nyveien",
    streetNumber: " 41",
    postalCode: "1482",
    city: "Nittedal",
    country: "Norway",
  },
  {
    streetName: "Ringstabekkveien",
    streetNumber: " 31",
    postalCode: "1358",
    city: "Jar",
    country: "Norway",
  },
  {
    streetName: "Oskar Braatens gate",
    streetNumber: "9 H",
    postalCode: "0474",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Rolf Presthus Vei",
    streetNumber: "69",
    postalCode: "1415",
    city: "Oppegård",
    country: "Norway",
  },
  {
    streetName: "Aslakveien",
    streetNumber: "26 A",
    postalCode: "0753",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Nyveien",
    streetNumber: " 41",
    postalCode: "1482",
    city: "Nittedal",
    country: "Norway",
  },
  {
    streetName: "Bergensgata",
    streetNumber: " 5B",
    postalCode: "0468",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Blystadringen",
    streetNumber: " 8G",
    postalCode: "2014",
    city: "Blystadlia",
    country: "Norway",
  },
  {
    streetName: "Rathkes gate",
    streetNumber: " 15",
    postalCode: "0558",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Harastien",
    streetNumber: " 8",
    postalCode: "2014",
    city: "Blystadlia",
    country: "Norway",
  },
  {
    streetName: "Louises gate",
    streetNumber: "24A",
    postalCode: "0169",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sønnaveien",
    streetNumber: "37",
    postalCode: "1476",
    city: "Rasta",
    country: "Norway",
  },
  {
    streetName: "Hattemakerlia",
    streetNumber: "93",
    postalCode: "2019",
    city: "Skedsmokorset",
    country: "Norway",
  },
  {
    streetName: "P. A. Holms Vei",
    streetNumber: "29",
    postalCode: "1164",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Myrvegen",
    streetNumber: " 66B",
    postalCode: "2052",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Lørenveien",
    streetNumber: " 54B",
    postalCode: "0585",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ullern gårds vei",
    streetNumber: " 29",
    postalCode: "0382",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Lønnåsen",
    streetNumber: "1",
    postalCode: "1362",
    city: "Hosle",
    country: "Norge",
  },
  {
    streetName: "Haslum Terrasse",
    streetNumber: "10a",
    postalCode: "1344",
    city: "Haslum",
    country: "Norway",
  },
  {
    streetName: "Fernanda Nissens Gate",
    streetNumber: "7a",
    postalCode: "0484",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gamle Røykenvei",
    streetNumber: "46",
    postalCode: "1386",
    city: "Asker",
    country: "Norway",
  },
  {
    streetName: "Pettersvollen",
    streetNumber: " 51",
    postalCode: "3032",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Lunden",
    streetNumber: "26A",
    postalCode: "0598",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Gamle Enebakkvei",
    streetNumber: "67",
    postalCode: "1188",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gamle Kongevei",
    streetNumber: "56A",
    postalCode: "3040",
    city: "Drammen",
    country: "Norge",
  },
  {
    streetName: "Carl Bergersens vei",
    streetNumber: "38B",
    postalCode: "1481",
    city: "Hagan",
    country: "Norge",
  },
  {
    streetName: "Åsdalsveien",
    streetNumber: "3I",
    postalCode: "1166",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Leangveien",
    streetNumber: "2e",
    postalCode: "1387",
    city: "Asker",
    country: "Norge",
  },
  {
    streetName: "Gaustadveien",
    streetNumber: "13A",
    postalCode: "0372",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Blåklokkevegen",
    streetNumber: "7E",
    postalCode: "2052",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Sandakerveien 10",
    streetNumber: " J",
    postalCode: "0473",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Steinsoppveien",
    streetNumber: "17",
    postalCode: "1476",
    city: "Rasta",
    country: "Norway",
  },
  {
    streetName: "Vinterkroken",
    streetNumber: "10a",
    postalCode: "1395",
    city: "Hvalstad",
    country: "Norway",
  },
  {
    streetName: "Kleivveien",
    streetNumber: "49A",
    postalCode: "1356",
    city: "Bekkestua",
    country: "Norway",
  },
  {
    streetName: "Københavngata",
    streetNumber: " 14C",
    postalCode: "0566",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Kaptein Oppegaards Vei",
    streetNumber: "10F",
    postalCode: "1164",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Eplehagen",
    streetNumber: "2",
    postalCode: "2040",
    city: "Kløfta",
    country: "Norge",
  },
  {
    streetName: "Fjellsetveien",
    streetNumber: " 123",
    postalCode: "2019",
    city: "Skedsmokorset",
    country: "Norway",
  },
  {
    streetName: "Tromsøgata",
    streetNumber: " 5C",
    postalCode: "0565",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Lyngveien",
    streetNumber: "14",
    postalCode: "2016",
    city: "Frogner",
    country: "Norway",
  },
  {
    streetName: "Radarveien",
    streetNumber: "86",
    postalCode: "1152",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Vestre Jansrud",
    streetNumber: " 12",
    postalCode: "1383",
    city: "Asker",
    country: "Norway",
  },
  {
    streetName: "Nygårdstubben",
    streetNumber: " 6",
    postalCode: "1454",
    city: "Fagerstrand",
    country: "Norway",
  },
  {
    streetName: "Stensgata",
    streetNumber: "36b",
    postalCode: "0358",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sofie roes veg",
    streetNumber: "24",
    postalCode: "2040",
    city: "Kløfta",
    country: "Norge",
  },
  {
    streetName: "Øvre Dyrhusbakken",
    streetNumber: "10",
    postalCode: "1390",
    city: "Vollen",
    country: "Norge",
  },
  {
    streetName: "Hilton",
    streetNumber: "150 F",
    postalCode: "2040",
    city: "Kløfta",
    country: "Norge",
  },
  {
    streetName: "Abbedikollen",
    streetNumber: "5B",
    postalCode: "0280",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Johans Vei",
    streetNumber: "3",
    postalCode: "1542",
    city: "Vestby",
    country: "Norway",
  },
  {
    streetName: "Mikjelsbakken",
    streetNumber: "5 D",
    postalCode: "0378",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Johansvei",
    streetNumber: "3",
    postalCode: "1540",
    city: "Vestby",
    country: "Norge",
  },
  {
    streetName: "Heggeveien",
    streetNumber: " 28a",
    postalCode: "1481",
    city: "Hagan",
    country: "Norway",
  },
  {
    streetName: "Svaneveien",
    streetNumber: " 25",
    postalCode: "1555",
    city: "Son",
    country: "Norway",
  },
  {
    streetName: "Fjellveien",
    streetNumber: "7b",
    postalCode: "1410",
    city: "Kolbotn",
    country: "Norge",
  },
  {
    streetName: "Holmestrandgata",
    streetNumber: "4",
    postalCode: "0468",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Frydenlundgata",
    streetNumber: "10a",
    postalCode: "0169",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Voll Terrasse",
    streetNumber: "24",
    postalCode: "1358",
    city: "Jar",
    country: "Norway",
  },
  {
    streetName: "Ringstabekkveien",
    streetNumber: "14c",
    postalCode: "1358",
    city: "Jar",
    country: "NO",
  },
  {
    streetName: "Eikeleina",
    streetNumber: "12",
    postalCode: "3472",
    city: "Bødalen",
    country: "Norway",
  },
  {
    streetName: "Høybråtenveien",
    streetNumber: "43E",
    postalCode: "1086",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ryums vei",
    streetNumber: "74",
    postalCode: "3036",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Tollbugata",
    streetNumber: " 68",
    postalCode: "3044",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Lyngveien",
    streetNumber: "27A",
    postalCode: "1182",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bentsegata",
    streetNumber: "4a",
    postalCode: "0465",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rosenkrantzgata",
    streetNumber: "177",
    postalCode: "3023",
    city: "Drammen",
    country: "Norge",
  },
  {
    streetName: "Martin Linges Vei",
    streetNumber: "13",
    postalCode: "0692",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Mogens Thorsens gate",
    streetNumber: "3",
    postalCode: "0264",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Borgenhaug",
    streetNumber: "6a",
    postalCode: "1367",
    city: "Snarøya",
    country: "Norway",
  },
  {
    streetName: "Haakon Tveters vei",
    streetNumber: "43",
    postalCode: "0686",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Lybekkveien",
    streetNumber: "29E",
    postalCode: "0770",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Lillevannsskogen",
    streetNumber: "29",
    postalCode: "0788",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sandakerveien",
    streetNumber: "70A",
    postalCode: "0484",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hafrsfjordgata",
    streetNumber: " 24B",
    postalCode: "0268",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Schønings gate 4",
    streetNumber: " a",
    postalCode: "0356",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Siloveien",
    streetNumber: " 4",
    postalCode: "0574",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sandakerveien",
    streetNumber: "22 B",
    postalCode: "0473",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lilleborggata",
    streetNumber: "5",
    postalCode: "0480",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Arbins gate",
    streetNumber: " 3",
    postalCode: "0253",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Stjerneveien",
    streetNumber: "13B",
    postalCode: "0779",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Lindbäckveien",
    streetNumber: "28B",
    postalCode: "1163",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vardeheimveien",
    streetNumber: "26",
    postalCode: "1088",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Edelgranveien",
    streetNumber: "52",
    postalCode: "1356",
    city: "Bekkestua",
    country: "Norway",
  },
  {
    streetName: "Ljabrubakken",
    streetNumber: "42",
    postalCode: "1165",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Almenningsveien",
    streetNumber: "32 c",
    postalCode: "1425",
    city: "Ski",
    country: "Norway",
  },
  {
    streetName: "Hestehaugen",
    streetNumber: " 1B",
    postalCode: "1338",
    city: "Sandvika",
    country: "Norway",
  },
  {
    streetName: "Østliveien",
    streetNumber: "10A",
    postalCode: "1415",
    city: "Oppegård",
    country: "Norway",
  },
  {
    streetName: "Løvenskiolds vei",
    streetNumber: "6 C",
    postalCode: "1358",
    city: "Jar",
    country: "Norway",
  },
  {
    streetName: "Dalslia",
    streetNumber: "28",
    postalCode: "1394",
    city: "Nesbru",
    country: "Norway",
  },
  {
    streetName: "Furutoppveien",
    streetNumber: " 4",
    postalCode: "3440",
    city: "Røyken",
    country: "Norway",
  },
  {
    streetName: "Etterstadsletta",
    streetNumber: " 70",
    postalCode: "0659",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ingeniørveien",
    streetNumber: "8",
    postalCode: "0196",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Danmarks gate",
    streetNumber: "18",
    postalCode: "0658",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Teiebærstien",
    streetNumber: " 57",
    postalCode: "1349",
    city: "Rykkinn",
    country: "Norway",
  },
  {
    streetName: "Solhaugveien",
    streetNumber: "9 A",
    postalCode: "1337",
    city: "Sandvika",
    country: "Norway",
  },
  {
    streetName: "Paal Bergs vei",
    streetNumber: " 169B",
    postalCode: "1188",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Teisenveien 3",
    streetNumber: " b",
    postalCode: "0664",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Mylskerudveien",
    streetNumber: "26C",
    postalCode: "1160",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Gamle Bygdevei",
    streetNumber: "242",
    postalCode: "1284",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Skådalsveien",
    streetNumber: "23",
    postalCode: "0781",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sørskogen",
    streetNumber: "54",
    postalCode: "3032",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Jongsåsveien",
    streetNumber: "8B",
    postalCode: "1338",
    city: "Sandvika",
    country: "Norge",
  },
  {
    streetName: "Spireaveien",
    streetNumber: " 4E",
    postalCode: "0580",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Maridalsveien",
    streetNumber: " 227C",
    postalCode: "0467",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Refstadveien",
    streetNumber: " 61",
    postalCode: "0584",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Skiferveien",
    streetNumber: " 8",
    postalCode: "1151",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Jens Holes vei",
    streetNumber: " 10A",
    postalCode: "1336",
    city: "Sandvika",
    country: "Norway",
  },
  {
    streetName: "Andrénbakken",
    streetNumber: "14B",
    postalCode: "1392",
    city: "Vettre",
    country: "Norway",
  },
  {
    streetName: "Bølerbakken",
    streetNumber: "6",
    postalCode: "0690",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Karen Ankers Vei",
    streetNumber: "16",
    postalCode: "1359",
    city: "Eiksmarka",
    country: "Norway",
  },
  {
    streetName: "Sigurd Hoels vei",
    streetNumber: " 75",
    postalCode: "0655",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Raschs vei",
    streetNumber: " 27C",
    postalCode: "1178",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Pasopveien",
    streetNumber: " 5",
    postalCode: "1279",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Schouterrassen",
    streetNumber: " 6",
    postalCode: "0573",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Narums vei",
    streetNumber: " 9",
    postalCode: "3430",
    city: "Spikkestad",
    country: "Norway",
  },
  {
    streetName: "Hans Nordahls gate 40",
    streetNumber: "c/o Leilighet 204",
    postalCode: "0481",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Øvre Elnesvei",
    streetNumber: "19",
    postalCode: "1390",
    city: "Vollen",
    country: "Norway",
  },
  {
    streetName: "Eikelibuen",
    streetNumber: " 20",
    postalCode: "1407",
    city: "Vinterbro",
    country: "Norway",
  },
  {
    streetName: "Karenslyst Allé",
    streetNumber: "40",
    postalCode: "0279",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gjønnesskogen",
    streetNumber: "16",
    postalCode: "1356",
    city: "Bekkestua",
    country: "Norway",
  },
  {
    streetName: "Kristian Bogneruds vei , 37E",
    streetNumber: "37 E",
    postalCode: "0956",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Lundekroken",
    streetNumber: " 22",
    postalCode: "1396",
    city: "Billingstad",
    country: "Norway",
  },
  {
    streetName: "Tangen Terrasse",
    streetNumber: "24",
    postalCode: "1450",
    city: "Nesoddtangen",
    country: "Norway",
  },
  {
    streetName: "Hilton",
    streetNumber: " 148A",
    postalCode: "2040",
    city: "Kløfta",
    country: "Norway",
  },
  {
    streetName: "Birch-Reichenwalds gate",
    streetNumber: "27A",
    postalCode: "0483",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Thorleif Haugs vei",
    streetNumber: "101F",
    postalCode: "0791",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Skåreråsen",
    streetNumber: " 12",
    postalCode: "1473",
    city: "Lørenskog",
    country: "Norway",
  },
  {
    streetName: "Villaveien",
    streetNumber: " 11",
    postalCode: "1385",
    city: "Asker",
    country: "Norway",
  },
  {
    streetName: "Colletts gate",
    streetNumber: "66",
    postalCode: "0460",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fossumbakken",
    streetNumber: " 10",
    postalCode: "1359",
    city: "Eiksmarka",
    country: "Norway",
  },
  {
    streetName: "Refstad allé",
    streetNumber: "32",
    postalCode: "0586",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Halvor Torgersens Vei",
    streetNumber: "1A",
    postalCode: "0778",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Risbekkveien",
    streetNumber: "7",
    postalCode: "0778",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Suhms gate",
    streetNumber: " 18b",
    postalCode: "0362",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Konventveien 8B, 0377",
    streetNumber: " Oslo",
    postalCode: "0377",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Skogholtveien",
    streetNumber: "17F",
    postalCode: "1178",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "underhaugsveien",
    streetNumber: "25b",
    postalCode: "1358",
    city: "Jar",
    country: "Norge",
  },
  {
    streetName: "fürstlia",
    streetNumber: "1",
    postalCode: "1367",
    city: "Snarøya",
    country: "Norge",
  },
  {
    streetName: "Lerkeveien",
    streetNumber: " 6D",
    postalCode: "1053",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Lensmann Klevs vei",
    streetNumber: "191D",
    postalCode: "2019",
    city: "Skedsmokorset",
    country: "Norway",
  },
  {
    streetName: "Måsaveien",
    streetNumber: "15A",
    postalCode: "1406",
    city: "Ski",
    country: "Norge",
  },
  {
    streetName: "Bråtejordet",
    streetNumber: " 61",
    postalCode: "1465",
    city: "Strømmen",
    country: "Norway",
  },
  {
    streetName: "Kirkeveien 38",
    streetNumber: " b",
    postalCode: "1363",
    city: "Høvik",
    country: "Norway",
  },
  {
    streetName: "Hedmarksgata",
    streetNumber: "10B",
    postalCode: "0658",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hellerudveien",
    streetNumber: "90R",
    postalCode: "0687",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Skogveien 164",
    streetNumber: " c",
    postalCode: "1356",
    city: "Bekkestua",
    country: "Norway",
  },
  {
    streetName: "Hagabråten",
    streetNumber: "17",
    postalCode: "1359",
    city: "Eiksmarka",
    country: "Norway",
  },
  {
    streetName: "Pilestredet",
    streetNumber: " 85a",
    postalCode: "0358",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Skådalsveien",
    streetNumber: "23",
    postalCode: "0781",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hestehaugen",
    streetNumber: " 1B",
    postalCode: "1338",
    city: "Sandvika",
    country: "Norway",
  },
  {
    streetName: "Brandstadkollen",
    streetNumber: "25",
    postalCode: "1550",
    city: "Hølen",
    country: "Norge",
  },
  {
    streetName: "Sverre Iversens vei",
    streetNumber: "13",
    postalCode: "0972",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skansen terrasse",
    streetNumber: "22",
    postalCode: "1062",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Thaulows vei",
    streetNumber: " 9c",
    postalCode: "0369",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Lørenvangen",
    streetNumber: "46",
    postalCode: "0580",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Mjølners vei",
    streetNumber: "1F",
    postalCode: "1445",
    city: "Drøbak",
    country: "Norge",
  },
  {
    streetName: "Konghellegata",
    streetNumber: "1",
    postalCode: "0569",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Løkebergkroken",
    streetNumber: "12",
    postalCode: "1344",
    city: "Haslum",
    country: "Norway",
  },
  {
    streetName: "Kleiva 2",
    streetNumber: " a",
    postalCode: "1088",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sofienberggata",
    streetNumber: "25A",
    postalCode: "0558",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Alingsåsveien",
    streetNumber: " 17",
    postalCode: "2013",
    city: "Skjetten",
    country: "Norway",
  },
  {
    streetName: "ivan bjørndalsgate 5,",
    streetNumber: "508",
    postalCode: "0472",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Skogveien",
    streetNumber: "14B",
    postalCode: "1406",
    city: "Ski",
    country: "Norge",
  },
  {
    streetName: "Ole Bygdevekters vei",
    streetNumber: " 11b",
    postalCode: "1434",
    city: "Ås",
    country: "Norway",
  },
  {
    streetName: "Prestmoe",
    streetNumber: "Prestmoen 6 D",
    postalCode: "2040",
    city: "Kløfta",
    country: "Norge",
  },
  {
    streetName: "Åmotveien",
    streetNumber: "53",
    postalCode: "1389",
    city: "Heggedal",
    country: "Norway",
  },
  {
    streetName: "Mogata",
    streetNumber: "8B",
    postalCode: "0464",
    city: "Oslo",
    country: "NO",
  },
  {
    streetName: "Lensmann Haaversens Vei,",
    streetNumber: "84",
    postalCode: "2019",
    city: "Skedsmokorset",
    country: "Norway",
  },
  {
    streetName: "Framnesveien 10",
    streetNumber: "c/o 5.etg toppetasjen (G.Polasek på ringeklokke)",
    postalCode: "0270",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sankt Olavs gate",
    streetNumber: "15",
    postalCode: "2004",
    city: "Lillestrøm",
    country: "Norge",
  },
  {
    streetName: "Maries Gate",
    streetNumber: "9B",
    postalCode: "0368",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Mylskerudveien",
    streetNumber: "26C",
    postalCode: "1160",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Nyveien",
    streetNumber: "50",
    postalCode: "3470",
    city: "Slemmestad",
    country: "Norge",
  },
  {
    streetName: "Waldemar Thranes gate",
    streetNumber: "46B",
    postalCode: "0171",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kampheimveien",
    streetNumber: "12 B",
    postalCode: "0685",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ankerveien",
    streetNumber: "28",
    postalCode: "0785",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hyllveien",
    streetNumber: "5",
    postalCode: "0274",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hoffsjef Løvenskiolds vei",
    streetNumber: "3a",
    postalCode: "0382",
    city: "Oslo",
    country: "NO",
  },
  {
    streetName: "Voksenkollveien",
    streetNumber: "17F",
    postalCode: "0790",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ekebergveien",
    streetNumber: "154B",
    postalCode: "1177",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Olav Kyrres gate",
    streetNumber: " 7B",
    postalCode: "0273",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Tyrihansvegen",
    streetNumber: "34",
    postalCode: "2016",
    city: "Frogner",
    country: "Norge",
  },
  {
    streetName: "Jutulveien",
    streetNumber: " 19",
    postalCode: "2016",
    city: "Frogner",
    country: "Norway",
  },
  {
    streetName: "Austadveien",
    streetNumber: "71a",
    postalCode: "3034",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Lørenvangen",
    streetNumber: "21a",
    postalCode: "0585",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Myråsveien,",
    streetNumber: "19",
    postalCode: "1454",
    city: "Fagerstrand",
    country: "Norway",
  },
  {
    streetName: "Kirkeveien",
    streetNumber: "145",
    postalCode: "0361",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Maridalsveien 205",
    streetNumber: "c/o Helene Holmen",
    postalCode: "0469",
    city: "Oslo",
    country: "no",
  },
  {
    streetName: "Tårnbyveien",
    streetNumber: " 63",
    postalCode: "2013",
    city: "Skjetten",
    country: "Norway",
  },
  {
    streetName: "Wilses gate",
    streetNumber: "6",
    postalCode: "0178",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Blåklokkevegen",
    streetNumber: " 7b",
    postalCode: "2052",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Hølandsgata 22 B",
    streetNumber: "c/o H0103",
    postalCode: "0655",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bergerveien",
    streetNumber: "43",
    postalCode: "1396",
    city: "Billingstad",
    country: "Norway",
  },
  {
    streetName: "Ragna nielsens vei",
    streetNumber: "17",
    postalCode: "0592",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Gamleveien",
    streetNumber: "121",
    postalCode: "1350",
    city: "Lommedalen",
    country: "Norway",
  },
  {
    streetName: "Rødtvetveien",
    streetNumber: " 33",
    postalCode: "0955",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Halvorsens vei",
    streetNumber: " 12b",
    postalCode: "1365",
    city: "Blommenholm",
    country: "Norway",
  },
  {
    streetName: "Møllergata 47A",
    streetNumber: "c/o Marte Skulberg Mikaelsen",
    postalCode: "0179",
    city: "Oslo",
    country: "no",
  },
  {
    streetName: "Ballerud alle",
    streetNumber: "4",
    postalCode: "1363",
    city: "Høvik",
    country: "Norway",
  },
  {
    streetName: "Uelands gate",
    streetNumber: " 14",
    postalCode: "0175",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Underlia",
    streetNumber: "183",
    postalCode: "3021",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Kingos gate",
    streetNumber: " 8",
    postalCode: "0457",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Reidar Bakkes Veg",
    streetNumber: "20",
    postalCode: "2052",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Ekrekroken",
    streetNumber: "43",
    postalCode: "1356",
    city: "Bekkestua",
    country: "Norway",
  },
  {
    streetName: "Prost Petersens vei",
    streetNumber: " 70",
    postalCode: "2019",
    city: "Skedsmokorset",
    country: "Norway",
  },
  {
    streetName: "Refstadsvingen",
    streetNumber: "21",
    postalCode: "0589",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Rådyrveien",
    streetNumber: "5",
    postalCode: "3032",
    city: "Drammen",
    country: "Norge",
  },
  {
    streetName: "Lunaveien",
    streetNumber: "2B",
    postalCode: "1160",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rostockgata",
    streetNumber: "56",
    postalCode: "0194",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Brusetveien",
    streetNumber: "89",
    postalCode: "1395",
    city: "Hvalstad",
    country: "Norway",
  },
  {
    streetName: "Amund Hellands vei",
    streetNumber: " 7",
    postalCode: "1166",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "SØRSKOGEN",
    streetNumber: "69",
    postalCode: "3032",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Tveterveien",
    streetNumber: "46",
    postalCode: "1344",
    city: "Haslum",
    country: "Norge",
  },
  {
    streetName: "Søren Paulsens vei",
    streetNumber: "4",
    postalCode: "3027",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Badebakken",
    streetNumber: "32",
    postalCode: "0467",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Nydalsveien",
    streetNumber: "26C",
    postalCode: "0484",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Prost Holms Vei",
    streetNumber: "44",
    postalCode: "2019",
    city: "Skedsmokorset",
    country: "Norway",
  },
  {
    streetName: "Skoklekroken",
    streetNumber: " 15",
    postalCode: "1452",
    city: "Nesoddtangen",
    country: "Norway",
  },
  {
    streetName: "Pløens Gate",
    streetNumber: "1",
    postalCode: "0181",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ekornrudvegen",
    streetNumber: " 30",
    postalCode: "2052",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Kornstien",
    streetNumber: " 52",
    postalCode: "1479",
    city: "Kurland",
    country: "Norway",
  },
  {
    streetName: "Breidablikkveien",
    streetNumber: "20",
    postalCode: "1167",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Munkerudveien",
    streetNumber: "37",
    postalCode: "1165",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "fururabben",
    streetNumber: "15",
    postalCode: "1361",
    city: "Østerås",
    country: "Norway",
  },
  {
    streetName: "Stangåsveien",
    streetNumber: " 7b",
    postalCode: "1415",
    city: "Oppegård",
    country: "Norway",
  },
  {
    streetName: "Olaf Schous vei 7",
    streetNumber: "c/o Mer Film",
    postalCode: "0572",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "per waalers vei",
    streetNumber: "5",
    postalCode: "1360",
    city: "Fornebu",
    country: "Norway",
  },
  {
    streetName: "Sigurd Holes vei",
    streetNumber: "108",
    postalCode: "0655",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Glitterudveien 4",
    streetNumber: "c/o PB 27",
    postalCode: "1352",
    city: "Kolsås",
    country: "Norge",
  },
  {
    streetName: "Fururabben",
    streetNumber: "13",
    postalCode: "1412",
    city: "Sofiemyr",
    country: "Norge",
  },
  {
    streetName: "Østaveien",
    streetNumber: "103A",
    postalCode: "1476",
    city: "Rasta",
    country: "Norway",
  },
  {
    streetName: "Solvangveien",
    streetNumber: " 9",
    postalCode: "1463",
    city: "Fjellhamar",
    country: "Norway",
  },
  {
    streetName: "Liaveien",
    streetNumber: "55",
    postalCode: "1430",
    city: "Ås",
    country: "Norge",
  },
  {
    streetName: "Danskerudbråten",
    streetNumber: "16",
    postalCode: "1435",
    city: "Ås",
    country: "Norway",
  },
  {
    streetName: "Åstadlia",
    streetNumber: "11",
    postalCode: "1396",
    city: "Billingstad",
    country: "Norway",
  },
  {
    streetName: "Rolf E. Stenersens allé",
    streetNumber: " 12",
    postalCode: "0858",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Amtmann Meinichs gate",
    streetNumber: " 8",
    postalCode: "0482",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Grefsen allé",
    streetNumber: "28",
    postalCode: "0488",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ole Reistads Vei",
    streetNumber: "45E",
    postalCode: "1068",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Lierstranda",
    streetNumber: "59",
    postalCode: "3414",
    city: "Lierstranda",
    country: "Norway",
  },
  {
    streetName: "Nordbyvegen",
    streetNumber: " 3B",
    postalCode: "2069",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Per Kvibergs gate",
    streetNumber: "14",
    postalCode: "0478",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "lundekroken",
    streetNumber: "25",
    postalCode: "1396",
    city: "Billingstad",
    country: "Norway",
  },
  {
    streetName: "Radka Toneffs vei",
    streetNumber: " 32b",
    postalCode: "1410",
    city: "Kolbotn",
    country: "Norway",
  },
  {
    streetName: "Solbakkeveien",
    streetNumber: "26",
    postalCode: "0678",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bøgata",
    streetNumber: " 22",
    postalCode: "0655",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Fjellsetveien",
    streetNumber: " 153",
    postalCode: "2019",
    city: "Skedsmokorset",
    country: "Norway",
  },
  {
    streetName: "Karushøgda",
    streetNumber: "49",
    postalCode: "1481",
    city: "Hagan",
    country: "Norge",
  },
  {
    streetName: "Waldemar Thranes gate",
    streetNumber: " 47B",
    postalCode: "0173",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Skullerudbakken",
    streetNumber: "40",
    postalCode: "1189",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Skogmotoppen",
    streetNumber: " 27B",
    postalCode: "2053",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Kvartsveien",
    streetNumber: " 37",
    postalCode: "1435",
    city: "Ås",
    country: "Norway",
  },
  {
    streetName: "Broveien",
    streetNumber: "15",
    postalCode: "1397",
    city: "Nesøya",
    country: "Norge",
  },
  {
    streetName: "Torshovgata",
    streetNumber: "15b",
    postalCode: "0476",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jonsrudveien",
    streetNumber: "11A",
    postalCode: "0274",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Drammensveien",
    streetNumber: "116E",
    postalCode: "0273",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Wilhelm Wilhelmsens vei",
    streetNumber: "41",
    postalCode: "1362",
    city: "Hosle",
    country: "NO",
  },
  {
    streetName: "Solheimsveien",
    streetNumber: "10",
    postalCode: "1473",
    city: "Lørenskog",
    country: "NO",
  },
  {
    streetName: "Jonsrudveien",
    streetNumber: "11A",
    postalCode: "0274",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Karihaugveien",
    streetNumber: "100",
    postalCode: "1086",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gustav Vigelands vei",
    streetNumber: " 28",
    postalCode: "0274",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Åråssvingen",
    streetNumber: " 11",
    postalCode: "2007",
    city: "Kjeller",
    country: "Norway",
  },
  {
    streetName: "Hagastubben",
    streetNumber: "40",
    postalCode: "0980",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Tiriltunga",
    streetNumber: "244",
    postalCode: "1259",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bitehagen",
    streetNumber: "12b",
    postalCode: "3440",
    city: "Røyken",
    country: "Norge",
  },
  {
    streetName: "Rudsbakken",
    streetNumber: " 1",
    postalCode: "3430",
    city: "Spikkestad",
    country: "Norway",
  },
  {
    streetName: "Nydalen allé",
    streetNumber: "21H0501",
    postalCode: "0484",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Peter Møllers vei",
    streetNumber: "6A",
    postalCode: "0585",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Saturnveien",
    streetNumber: "30c",
    postalCode: "0492",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stovnerbakken",
    streetNumber: "179b",
    postalCode: "0981",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ekelyveien",
    streetNumber: "1d",
    postalCode: "0374",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Våronnveien",
    streetNumber: "14",
    postalCode: "0679",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Snøklokkeveien",
    streetNumber: " 10",
    postalCode: "1475",
    city: "Finstadjordet",
    country: "Norway",
  },
  {
    streetName: "Munkerudbakken",
    streetNumber: " 2",
    postalCode: "1165",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bergliveien",
    streetNumber: " 15B",
    postalCode: "2019",
    city: "Skedsmokorset",
    country: "Norway",
  },
  {
    streetName: "Nedre Skogvei",
    streetNumber: " 5",
    postalCode: "0281",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Damengveien",
    streetNumber: " 6",
    postalCode: "1454",
    city: "Fagerstrand",
    country: "Norway",
  },
  {
    streetName: "Sandakerveien",
    streetNumber: " 96",
    postalCode: "0484",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ostadalsveien",
    streetNumber: "18",
    postalCode: "0753",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Skogvegen",
    streetNumber: "2",
    postalCode: "2005",
    city: "Rælingen",
    country: "Norge",
  },
  {
    streetName: "Åsdalsveien",
    streetNumber: " 7b",
    postalCode: "1166",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Jerpefaret",
    streetNumber: "35c",
    postalCode: "0788",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Arnstein arnebergs vei",
    streetNumber: "14a",
    postalCode: "0274",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Smalgangen",
    streetNumber: " 24",
    postalCode: "0188",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bergerveien",
    streetNumber: "49",
    postalCode: "1396",
    city: "Billingstad",
    country: "Norge",
  },
  {
    streetName: "Furulundsveien",
    streetNumber: "4 A",
    postalCode: "0282",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tingstuveien",
    streetNumber: "7a",
    postalCode: "0281",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ole Reistads vei",
    streetNumber: " 23G",
    postalCode: "1068",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ringkroken",
    streetNumber: " 18b",
    postalCode: "1356",
    city: "Bekkestua",
    country: "Norway",
  },
  {
    streetName: "Grenseveien",
    streetNumber: "11E",
    postalCode: "0571",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ullevålsveien",
    streetNumber: "82a",
    postalCode: "0454",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bakke Søndre",
    streetNumber: "68",
    postalCode: "2040",
    city: "Kløfta",
    country: "Norge",
  },
  {
    streetName: "Motzfeldts Gate",
    streetNumber: "16",
    postalCode: "0187",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Birkelundveien",
    streetNumber: "63",
    postalCode: "1481",
    city: "Hagan",
    country: "Norway",
  },
  {
    streetName: "Trasoppterrassen",
    streetNumber: " 55",
    postalCode: "0672",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Jomfrubråtvn",
    streetNumber: "68b",
    postalCode: "1179",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Mikjelsbakken",
    streetNumber: "5d",
    postalCode: "0378",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fjellveien",
    streetNumber: " 35c",
    postalCode: "1410",
    city: "Kolbotn",
    country: "Norway",
  },
  {
    streetName: "Jomfrustien",
    streetNumber: " 6A",
    postalCode: "1179",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Lindebergåsen",
    streetNumber: "32A",
    postalCode: "1068",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Halvorsens vei",
    streetNumber: " 12b",
    postalCode: "1365",
    city: "Blommenholm",
    country: "Norway",
  },
  {
    streetName: "Solbakkeveien",
    streetNumber: "26",
    postalCode: "0678",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sverdrups gate",
    streetNumber: "4B",
    postalCode: "0559",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Åsliveien",
    streetNumber: "2B",
    postalCode: "1178",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Tokerudberget",
    streetNumber: "5",
    postalCode: "0986",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Parkveien",
    streetNumber: " 64",
    postalCode: "0254",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hartmanns vei",
    streetNumber: " 37b",
    postalCode: "0284",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Montebelloveien",
    streetNumber: " 7e",
    postalCode: "0377",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Damliveien",
    streetNumber: " 6",
    postalCode: "0275",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Selvbyggerveien",
    streetNumber: " 58",
    postalCode: "0591",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Åslyveien",
    streetNumber: " 26",
    postalCode: "3023",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Rosteds gate",
    streetNumber: "1A",
    postalCode: "0178",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Konventveien",
    streetNumber: "14a",
    postalCode: "0377",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Øvre Berglia",
    streetNumber: " 58",
    postalCode: "1353",
    city: "Bærums verk",
    country: "Norway",
  },
  {
    streetName: "Landingsveien",
    streetNumber: "70",
    postalCode: "0767",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kløfterhagen",
    streetNumber: " 47",
    postalCode: "1067",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Nordkappgata",
    streetNumber: "17",
    postalCode: "0479",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ekornsvingen",
    streetNumber: "23",
    postalCode: "3440",
    city: "Røyken",
    country: "Norway",
  },
  {
    streetName: "Alnagata",
    streetNumber: "14",
    postalCode: "0192",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Grevlingåsen",
    streetNumber: "9",
    postalCode: "1386",
    city: "Asker",
    country: "Norge",
  },
  {
    streetName: "Søndre vei",
    streetNumber: " 64",
    postalCode: "1397",
    city: "Nesøya",
    country: "Norway",
  },
  {
    streetName: "Bjerkåsen",
    streetNumber: "30B",
    postalCode: "1365",
    city: "Blommenholm",
    country: "Norge",
  },
  {
    streetName: "Finns vei",
    streetNumber: "6",
    postalCode: "0575",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Paal Bergs Vei",
    streetNumber: "131B",
    postalCode: "1349",
    city: "Rykkinn",
    country: "Norway",
  },
  {
    streetName: "Bergstien",
    streetNumber: " 59",
    postalCode: "3016",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Sigurd Hoels vei",
    streetNumber: "15",
    postalCode: "0655",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Arne Ulstrups vei",
    streetNumber: "17C",
    postalCode: "1363",
    city: "Høvik",
    country: "Norge",
  },
  {
    streetName: "Elvegata",
    streetNumber: " 58",
    postalCode: "2003",
    city: "Lillestrøm",
    country: "Norway",
  },
  {
    streetName: "Nebbejordet",
    streetNumber: " 13",
    postalCode: "1266",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Oberst Rodes vei 15",
    streetNumber: " B",
    postalCode: "1152",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Øvre Ekeberglia",
    streetNumber: "29",
    postalCode: "3420",
    city: "Lierskogen",
    country: "Norway",
  },
  {
    streetName: "Risalléen",
    streetNumber: "23 B",
    postalCode: "0374",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dalbakkveien",
    streetNumber: "82",
    postalCode: "0682",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Finstad Hageby",
    streetNumber: "9",
    postalCode: "1423",
    city: "Ski",
    country: "Norge",
  },
  {
    streetName: "Jørgenstuvegen",
    streetNumber: " 12",
    postalCode: "2067",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Lørenveien",
    streetNumber: "48G",
    postalCode: "0585",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Løkenlia",
    streetNumber: "38",
    postalCode: "1352",
    city: "Kolsås",
    country: "Norge",
  },
  {
    streetName: "Pilestredet Park",
    streetNumber: "20",
    postalCode: "0176",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ørakerstien",
    streetNumber: " 8",
    postalCode: "0284",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Gjøvikgata 1",
    streetNumber: " A",
    postalCode: "0470",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Gjørstads gate",
    streetNumber: "4",
    postalCode: "0367",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Henrik Ibsens vei",
    streetNumber: "117",
    postalCode: "2010",
    city: "Strømmen",
    country: "Norge",
  },
  {
    streetName: "bergensgata",
    streetNumber: "26",
    postalCode: "0468",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Svaneveien",
    streetNumber: "14",
    postalCode: "1367",
    city: "Snarøya",
    country: "Norge",
  },
  {
    streetName: "Pilotveien",
    streetNumber: "10",
    postalCode: "0770",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Peter Møllers Vei",
    streetNumber: "33",
    postalCode: "0585",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Tors vei",
    streetNumber: " 15",
    postalCode: "1465",
    city: "Strømmen",
    country: "Norway",
  },
  {
    streetName: "Finholtvegen",
    streetNumber: "16b",
    postalCode: "2067",
    city: "Jessheim",
    country: "Norge",
  },
  {
    streetName: "Lars Muhles vei",
    streetNumber: "1",
    postalCode: "1338",
    city: "Sandvika",
    country: "Norge",
  },
  {
    streetName: "Kastanjeveien",
    streetNumber: "116",
    postalCode: "3026",
    city: "Drammen",
    country: "Norge",
  },
  {
    streetName: "Hattemakerlia",
    streetNumber: "137",
    postalCode: "2019",
    city: "Skedsmokorset",
    country: "Norge",
  },
  {
    streetName: "Bergheiemveien",
    streetNumber: "21",
    postalCode: "1367",
    city: "Snarøya",
    country: "Norway",
  },
  {
    streetName: "Sørhalla",
    streetNumber: "5",
    postalCode: "1344",
    city: "Haslum",
    country: "Norge",
  },
  {
    streetName: "Ulls vei",
    streetNumber: " 8",
    postalCode: "1540",
    city: "Vestby",
    country: "Norway",
  },
  {
    streetName: "Hennumbråtan",
    streetNumber: " 24",
    postalCode: "3409",
    city: "Tranby",
    country: "Norway",
  },
  {
    streetName: "Schønings gate",
    streetNumber: "41",
    postalCode: "0362",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bakkehaugveien",
    streetNumber: "13A",
    postalCode: "0873",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "",
    streetNumber: "26S Konows gate",
    postalCode: "0196",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Reineveien",
    streetNumber: " 19",
    postalCode: "1454",
    city: "Fagerstrand",
    country: "Norway",
  },
  {
    streetName: "Bjerkealleen",
    streetNumber: "30A",
    postalCode: "2003",
    city: "Lillestrøm",
    country: "Norway",
  },
  {
    streetName: "Kolleveien",
    streetNumber: "10",
    postalCode: "1397",
    city: "Nesøya",
    country: "Norge",
  },
  {
    streetName: "Bjørnelia",
    streetNumber: " 51",
    postalCode: "1453",
    city: "Bjørnemyr",
    country: "Norway",
  },
  {
    streetName: "Frognerveien",
    streetNumber: "62",
    postalCode: "0266",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Valdresgata",
    streetNumber: "3D",
    postalCode: "0557",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brønnveien",
    streetNumber: "17",
    postalCode: "0283",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Løytnantstien",
    streetNumber: "16",
    postalCode: "1400",
    city: "Ski",
    country: "Norge",
  },
  {
    streetName: "Stamhusveien",
    streetNumber: "45",
    postalCode: "1181",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vabråten",
    streetNumber: "95",
    postalCode: "1392",
    city: "Vettre",
    country: "Norge",
  },
  {
    streetName: "Vækerøveien",
    streetNumber: "181 c",
    postalCode: "0751",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vøyensvingen",
    streetNumber: "11b",
    postalCode: "0458",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Neuberggata",
    streetNumber: "25a",
    postalCode: "0367",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Totengata",
    streetNumber: " 5",
    postalCode: "0658",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Vitaminveien",
    streetNumber: "16",
    postalCode: "0483",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "President Harbiz Gate",
    streetNumber: "23A",
    postalCode: "0259",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vestlia",
    streetNumber: " 51",
    postalCode: "1453",
    city: "Bjørnemyr",
    country: "Norway",
  },
  {
    streetName: "Økernveien",
    streetNumber: " 84",
    postalCode: "0575",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Trondheimsveien",
    streetNumber: "174",
    postalCode: "0570",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gangstuveien",
    streetNumber: "10 D",
    postalCode: "0952",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Stavangergata",
    streetNumber: "44B",
    postalCode: "0467",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jacob Aalsgt",
    streetNumber: "25 a",
    postalCode: "0364",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bygdøy Kapellvei",
    streetNumber: "20d",
    postalCode: "0287",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sømveien",
    streetNumber: "25B",
    postalCode: "0493",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Harald Rømckes vei",
    streetNumber: "12",
    postalCode: "0286",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kurlandstien",
    streetNumber: "40C",
    postalCode: "1052",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Midtåsen",
    streetNumber: "53b",
    postalCode: "1166",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Hauges vei 4",
    streetNumber: " A",
    postalCode: "0871",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Øvrevollveien",
    streetNumber: "8",
    postalCode: "1358",
    city: "Jar",
    country: "Norge",
  },
  {
    streetName: "Hagaliveien",
    streetNumber: "23A",
    postalCode: "1358",
    city: "Jar",
    country: "Norge",
  },
  {
    streetName: "Richardaches vei 6",
    streetNumber: " B",
    postalCode: "1452",
    city: "Nesoddtangen",
    country: "Norway",
  },
  {
    streetName: "Markalleen",
    streetNumber: "27B",
    postalCode: "1368",
    city: "Stabekk",
    country: "Norge",
  },
  {
    streetName: "Furulia",
    streetNumber: " 7A",
    postalCode: "1405",
    city: "Langhus",
    country: "Norway",
  },
  {
    streetName: "Skøyenkroken",
    streetNumber: "9",
    postalCode: "0686",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vestfoldgata",
    streetNumber: "4",
    postalCode: "0656",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Oksval Terrasse 14",
    streetNumber: " B",
    postalCode: "1456",
    city: "Nesoddtangen",
    country: "Norway",
  },
  {
    streetName: "Strandalleen",
    streetNumber: "8B",
    postalCode: "1368",
    city: "Stabekk",
    country: "Norge",
  },
  {
    streetName: "Carl Lundgrens Vei",
    streetNumber: "18",
    postalCode: "0284",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ombergveien",
    streetNumber: "6a",
    postalCode: "0283",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nyjordstubben",
    streetNumber: "116",
    postalCode: "1275",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Vålveien",
    streetNumber: "6",
    postalCode: "0852",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "St. Halvards gate 25",
    streetNumber: " F",
    postalCode: "0192",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Oskar Braatens vei",
    streetNumber: "16",
    postalCode: "1472",
    city: "Fjellhamar",
    country: "Norge",
  },
  {
    streetName: "Ombergveien",
    streetNumber: "2",
    postalCode: "0283",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Pilestredet Park",
    streetNumber: "39",
    postalCode: "0176",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Solbergliveien",
    streetNumber: "101",
    postalCode: "0683",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Spikkestadveien",
    streetNumber: "122E",
    postalCode: "3430",
    city: "Spikkestad",
    country: "Norge",
  },
  {
    streetName: "St.Olavs gate",
    streetNumber: "44",
    postalCode: "2004",
    city: "Lillestrøm",
    country: "Norway",
  },
  {
    streetName: "Kleivene",
    streetNumber: " 83",
    postalCode: "3041",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Speidersletta",
    streetNumber: "18",
    postalCode: "1350",
    city: "Lommedalen",
    country: "Norge",
  },
  {
    streetName: "Bergstien",
    streetNumber: "2C",
    postalCode: "0172",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Bergstien",
    streetNumber: "2d",
    postalCode: "0172",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Dragonstien",
    streetNumber: "97A",
    postalCode: "1062",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Kongsberggata",
    streetNumber: "22",
    postalCode: "0468",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vallefaret",
    streetNumber: "7",
    postalCode: "0663",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sankt Halvards gate",
    streetNumber: "33",
    postalCode: "0192",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skogliveien",
    streetNumber: "190b",
    postalCode: "3047",
    city: "Drammen",
    country: "NO",
  },
  {
    streetName: "Godtland",
    streetNumber: "5d",
    postalCode: "2040",
    city: "Kløfta",
    country: "Norway",
  },
  {
    streetName: "Fjellknattveien",
    streetNumber: "3A",
    postalCode: "0495",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Slemdalsveien",
    streetNumber: " 77b",
    postalCode: "0373",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Manglebergveien",
    streetNumber: " 8A",
    postalCode: "0678",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Akebakkeskogen",
    streetNumber: "67a",
    postalCode: "0490",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Skoklefald Terrasse",
    streetNumber: " 61",
    postalCode: "1452",
    city: "Nesoddtangen",
    country: "Norway",
  },
  {
    streetName: "Østhellinga",
    streetNumber: "13",
    postalCode: "0870",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Rykkinnveien",
    streetNumber: "48",
    postalCode: "1349",
    city: "Rykkinn",
    country: "Norway",
  },
  {
    streetName: "Glimmerveien",
    streetNumber: " 1",
    postalCode: "1475",
    city: "Finstadjordet",
    country: "Norway",
  },
  {
    streetName: "Festningsveien",
    streetNumber: "23c",
    postalCode: "1368",
    city: "Stabekk",
    country: "Norway",
  },
  {
    streetName: "Skjellestadåsen",
    streetNumber: " 33",
    postalCode: "1389",
    city: "Heggedal",
    country: "Norway",
  },
  {
    streetName: "Furukollen Terrasse",
    streetNumber: "8A",
    postalCode: "1450",
    city: "Nesoddtangen",
    country: "Norway",
  },
  {
    streetName: "Rustadveien",
    streetNumber: " 17",
    postalCode: "3470",
    city: "Slemmestad",
    country: "Norway",
  },
  {
    streetName: "Grefsenkollveien",
    streetNumber: "12 A",
    postalCode: "0490",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Kjøvangveien",
    streetNumber: "86",
    postalCode: "1555",
    city: "Son",
    country: "Norge",
  },
  {
    streetName: "Hennumbråtan",
    streetNumber: "10",
    postalCode: "3408",
    city: "Tranby",
    country: "Norge",
  },
  {
    streetName: "Øvre Dyrhusbakken",
    streetNumber: "59A",
    postalCode: "1390",
    city: "Vollen",
    country: "Norway",
  },
  {
    streetName: "Blomstervegen",
    streetNumber: " 21",
    postalCode: "2005",
    city: "Rælingen",
    country: "Norway",
  },
  {
    streetName: "Romsaas allé",
    streetNumber: "13",
    postalCode: "2063",
    city: "Jessheim",
    country: "Norge",
  },
  {
    streetName: "Christen Smiths gate",
    streetNumber: "12B",
    postalCode: "3045",
    city: "Drammen",
    country: "Norge",
  },
  {
    streetName: "Hegdehaugsveien",
    streetNumber: "29",
    postalCode: "0352",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Risløkkveien",
    streetNumber: "27B",
    postalCode: "0580",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Jonsokveien",
    streetNumber: "13B",
    postalCode: "1182",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Kringsjåveien",
    streetNumber: "39",
    postalCode: "1415",
    city: "Oppegård",
    country: "Norge",
  },
  {
    streetName: "Nerhavna",
    streetNumber: "27",
    postalCode: "1349",
    city: "Rykkinn",
    country: "Norway",
  },
  {
    streetName: "Tormods vei",
    streetNumber: " 10c",
    postalCode: "1184",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Asta Nørregaards vei",
    streetNumber: "6",
    postalCode: "1415",
    city: "Oppegård",
    country: "Norge",
  },
  {
    streetName: "Langebåtveien",
    streetNumber: "63",
    postalCode: "1455",
    city: "Nordre frogn",
    country: "Norway",
  },
  {
    streetName: "rolfsbuktalleen,",
    streetNumber: "12",
    postalCode: "1364",
    city: "Fornebu",
    country: "Norway",
  },
  {
    streetName: "Haugeråsen",
    streetNumber: "47",
    postalCode: "1480",
    city: "Slattum",
    country: "NO",
  },
  {
    streetName: "Gunnar Schjelderups vei",
    streetNumber: "33A",
    postalCode: "0485",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Heerveien",
    streetNumber: "5B",
    postalCode: "1445",
    city: "Drøbak",
    country: "Norge",
  },
  {
    streetName: "Leiv Holthes veg",
    streetNumber: " 11B",
    postalCode: "2066",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Nonnegata",
    streetNumber: " 4B",
    postalCode: "0656",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Frognerveien",
    streetNumber: "48B",
    postalCode: "0266",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Harald Hårfagres gate",
    streetNumber: " 2",
    postalCode: "0363",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Myrerveien 31",
    streetNumber: "E",
    postalCode: "0494",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Harald Hårfagres gate",
    streetNumber: "5H",
    postalCode: "0363",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Nordbyveien",
    streetNumber: " 211",
    postalCode: "2013",
    city: "Skjetten",
    country: "Norway",
  },
  {
    streetName: "Knud Askers vei",
    streetNumber: "60",
    postalCode: "1383",
    city: "Asker",
    country: "Norge",
  },
  {
    streetName: "Røykåsterrasse",
    streetNumber: "28",
    postalCode: "1476",
    city: "Rasta",
    country: "Norge",
  },
  {
    streetName: "Jess Carlsens gate",
    streetNumber: " 10",
    postalCode: "0183",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hellerudveien",
    streetNumber: "69F",
    postalCode: "0684",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Skolsegglia",
    streetNumber: " 71H",
    postalCode: "2019",
    city: "Skedsmokorset",
    country: "Norway",
  },
  {
    streetName: "Rimsoppfaret",
    streetNumber: "26",
    postalCode: "1476",
    city: "Rasta",
    country: "Norge",
  },
  {
    streetName: "Ostadalsveien",
    streetNumber: "68",
    postalCode: "0753",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ole Wingers vei",
    streetNumber: " 10",
    postalCode: "0956",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Stensgata",
    streetNumber: "43",
    postalCode: "0451",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Framveien",
    streetNumber: "11 a1. etg",
    postalCode: "1177",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Wilses gate",
    streetNumber: "8",
    postalCode: "0178",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rugveien",
    streetNumber: "38",
    postalCode: "0679",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Liaveien",
    streetNumber: "9",
    postalCode: "1555",
    city: "Son",
    country: "Norge",
  },
  {
    streetName: "Skolsegglia",
    streetNumber: "6A",
    postalCode: "2019",
    city: "Skedsmokorset",
    country: "Norge",
  },
  {
    streetName: "Bondistranda",
    streetNumber: " 43",
    postalCode: "1386",
    city: "Asker",
    country: "Norway",
  },
  {
    streetName: "Rolf Hofmos gate",
    streetNumber: "15",
    postalCode: "0655",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Helgesens gate",
    streetNumber: "83A",
    postalCode: "0563",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hegdehaugsveien",
    streetNumber: " 9",
    postalCode: "0352",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Konvallvegen",
    streetNumber: "8",
    postalCode: "2052",
    city: "Jessheim",
    country: "Norge",
  },
  {
    streetName: "Grefsenveien 44",
    streetNumber: "c/o Leil 605",
    postalCode: "0485",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Solbakkeveien",
    streetNumber: "26",
    postalCode: "0678",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Enebakkveien 257",
    streetNumber: "c/o Manjothi",
    postalCode: "1187",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Gartnerveien",
    streetNumber: "5",
    postalCode: "0678",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ljabrubakken",
    streetNumber: "42",
    postalCode: "1165",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Buskerudveien",
    streetNumber: " 88",
    postalCode: "3024",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Bjørkesvingen",
    streetNumber: " 6H",
    postalCode: "3408",
    city: "Tranby",
    country: "Norway",
  },
  {
    streetName: "Rosendalsveien",
    streetNumber: "20B",
    postalCode: "1166",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Johan Sverdrups gate",
    streetNumber: "9",
    postalCode: "2004",
    city: "Lillestrøm",
    country: "Norge",
  },
  {
    streetName: "Michelets vei",
    streetNumber: "41",
    postalCode: "1368",
    city: "Stabekk",
    country: "Norge",
  },
  {
    streetName: "Laurits Grønlands vei",
    streetNumber: "15",
    postalCode: "3035",
    city: "Drammen",
    country: "Norge",
  },
  {
    streetName: "Haugerudhagan",
    streetNumber: "39",
    postalCode: "0673",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ankerveien",
    streetNumber: "40a",
    postalCode: "0785",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ustvedts vei",
    streetNumber: "11 C",
    postalCode: "0871",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Glassverkveien",
    streetNumber: "81",
    postalCode: "1363",
    city: "Høvik",
    country: "Norge",
  },
  {
    streetName: "Barnålveien 17",
    streetNumber: "21",
    postalCode: "0198",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Einar Haflans vei",
    streetNumber: " 30B",
    postalCode: "3036",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Løkkebergsvingen",
    streetNumber: "5",
    postalCode: "3014",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Holteløkka",
    streetNumber: "4",
    postalCode: "2050",
    city: "Jessheim",
    country: "Norge",
  },
  {
    streetName: "Kobbervikhagen",
    streetNumber: " 2",
    postalCode: "3040",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Gardermovegen",
    streetNumber: "12",
    postalCode: "2050",
    city: "Jessheim",
    country: "Norge",
  },
  {
    streetName: "Lindeberglia",
    streetNumber: "34",
    postalCode: "1069",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Elisenbergveien",
    streetNumber: "15",
    postalCode: "0265",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Stensgata",
    streetNumber: " 33B",
    postalCode: "0358",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Grevlingåsen",
    streetNumber: "5A",
    postalCode: "1362",
    city: "Hosle",
    country: "Norway",
  },
  {
    streetName: "Uranienborg Terrasse",
    streetNumber: "9",
    postalCode: "0351",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Blåbærsvingen",
    streetNumber: "15",
    postalCode: "0789",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Svenskerud",
    streetNumber: "65",
    postalCode: "3409",
    city: "Tranby",
    country: "Norway",
  },
  {
    streetName: "Steinliveien",
    streetNumber: "22 e",
    postalCode: "1185",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Oksenøyveien",
    streetNumber: "58",
    postalCode: "1366",
    city: "Lysaker",
    country: "Norway",
  },
  {
    streetName: "Nordbyhagaveien",
    streetNumber: "63",
    postalCode: "1474",
    city: "Lørenskog",
    country: "Norge",
  },
  {
    streetName: "Malerhaugveien",
    streetNumber: "16",
    postalCode: "0661",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bygdøy Allé",
    streetNumber: "69",
    postalCode: "0265",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "St. Jørgens vei",
    streetNumber: "41",
    postalCode: "0662",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ringgata",
    streetNumber: " 1b",
    postalCode: "0577",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Rørvollveien",
    streetNumber: " 11",
    postalCode: "3032",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Rådyrveien",
    streetNumber: "26A",
    postalCode: "1413",
    city: "Tårnåsen",
    country: "Norge",
  },
  {
    streetName: "Bjørkeliveien",
    streetNumber: "28e",
    postalCode: "3430",
    city: "Spikkestad",
    country: "Norway",
  },
  {
    streetName: "Årvollveien",
    streetNumber: " 60f",
    postalCode: "0590",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Deør vei",
    streetNumber: " 65",
    postalCode: "1555",
    city: "Son",
    country: "Norway",
  },
  {
    streetName: "Sletteløkka 42",
    streetNumber: " D",
    postalCode: "0597",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Eikskollen",
    streetNumber: "15A",
    postalCode: "1361",
    city: "Østerås",
    country: "Norway",
  },
  {
    streetName: "Fagertunveien",
    streetNumber: "4G",
    postalCode: "0281",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Fernanda Nissens gate",
    streetNumber: "2a",
    postalCode: "0484",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Haakon Tveters vei",
    streetNumber: "43",
    postalCode: "0686",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Schweigaards gate 85",
    streetNumber: "c/o 5.etasje",
    postalCode: "0656",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rydningen",
    streetNumber: "10",
    postalCode: "1435",
    city: "Ås",
    country: "Norway",
  },
  {
    streetName: "Bjønndalsåsen",
    streetNumber: " 21",
    postalCode: "3430",
    city: "Spikkestad",
    country: "Norway",
  },
  {
    streetName: "Granstubben",
    streetNumber: " 9",
    postalCode: "1358",
    city: "Jar",
    country: "Norway",
  },
  {
    streetName: "Symreveien 10",
    streetNumber: " b",
    postalCode: "2016",
    city: "Frogner",
    country: "Norway",
  },
  {
    streetName: "Landfalløya",
    streetNumber: " 54",
    postalCode: "3023",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Glads vei 31",
    streetNumber: " H101",
    postalCode: "0489",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Glads vei",
    streetNumber: "28C",
    postalCode: "0489",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Snorres gate",
    streetNumber: "11A",
    postalCode: "2004",
    city: "Lillestrøm",
    country: "Norway",
  },
  {
    streetName: "Holmenveien",
    streetNumber: "28 C",
    postalCode: "0374",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Rasmus Engers vei",
    streetNumber: " 2",
    postalCode: "0956",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Herregårdsveien",
    streetNumber: "57A",
    postalCode: "1168",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Hyllveien",
    streetNumber: "9E",
    postalCode: "3408",
    city: "Tranby",
    country: "Norge",
  },
  {
    streetName: "Bregnefaret",
    streetNumber: "26",
    postalCode: "1405",
    city: "Langhus",
    country: "Norway",
  },
  {
    streetName: "Stasjonsvegen",
    streetNumber: "3",
    postalCode: "2040",
    city: "Kløfta",
    country: "Norge",
  },
  {
    streetName: "Schæffers gate",
    streetNumber: "4b",
    postalCode: "0558",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sole alle",
    streetNumber: "61",
    postalCode: "1540",
    city: "Vestby",
    country: "Norge",
  },
  {
    streetName: "Grorudveien",
    streetNumber: " 102B",
    postalCode: "1053",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Ullevålsveien",
    streetNumber: "82",
    postalCode: "0452",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Øvre Solhellinga",
    streetNumber: " 22",
    postalCode: "2067",
    city: "Jessheim",
    country: "Norway",
  },
  {
    streetName: "Bjarne Haugens gate",
    streetNumber: "21",
    postalCode: "1475",
    city: "Finstadjordet",
    country: "Norway",
  },
  {
    streetName: "Oberst Rodes vei",
    streetNumber: " 59e",
    postalCode: "1165",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Handelsvegen",
    streetNumber: "11A",
    postalCode: "2040",
    city: "Kløfta",
    country: "Norway",
  },
  {
    streetName: "Eiksveien",
    streetNumber: "50",
    postalCode: "1359",
    city: "Eiksmarka",
    country: "Norway",
  },
  {
    streetName: "Inges gate",
    streetNumber: "6R",
    postalCode: "0196",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Gamle Drammensvei,",
    streetNumber: "165",
    postalCode: "1365",
    city: "Blommenholm",
    country: "Norway",
  },
  {
    streetName: "Fougstadsgata",
    streetNumber: "28",
    postalCode: "0173",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Vintapperens Vei",
    streetNumber: "5",
    postalCode: "1542",
    city: "Vestby",
    country: "Norge",
  },
  {
    streetName: "Betzy Kjelsbergs vei 15",
    streetNumber: " B",
    postalCode: "0486",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sandakerveien",
    streetNumber: "105",
    postalCode: "0483",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Lønnestien",
    streetNumber: "10A",
    postalCode: "1344",
    city: "Haslum",
    country: "Norge",
  },
  {
    streetName: "Durudkollen",
    streetNumber: "10",
    postalCode: "1344",
    city: "Haslum",
    country: "Norway",
  },
  {
    streetName: "Olstadfaret",
    streetNumber: "11",
    postalCode: "2040",
    city: "Kløfta",
    country: "Norway",
  },
  {
    streetName: "Revetråkket",
    streetNumber: "11",
    postalCode: "3033",
    city: "Drammen",
    country: "Norway",
  },
  {
    streetName: "Løvenskioldsgate",
    streetNumber: "20a",
    postalCode: "0260",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Steinveien",
    streetNumber: "14",
    postalCode: "1453",
    city: "Bjørnemyr",
    country: "Norway",
  },
  {
    streetName: "Karl Staaffs vei",
    streetNumber: "32",
    postalCode: "0665",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Ullevålsveien 63",
    streetNumber: " A",
    postalCode: "0454",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Sofies gate",
    streetNumber: "79B",
    postalCode: "0454",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Arnulf Øverlands vei",
    streetNumber: "87",
    postalCode: "0763",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Sørkedalsveien",
    streetNumber: " 76A",
    postalCode: "0377",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Valdresgata",
    streetNumber: "5",
    postalCode: "0557",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Skrysetveien",
    streetNumber: " 4",
    postalCode: "3440",
    city: "Røyken",
    country: "Norway",
  },
  {
    streetName: "Bergensgata",
    streetNumber: "2",
    postalCode: "0468",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Øvre Smestadvei",
    streetNumber: "30a",
    postalCode: "0378",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Geitmyrsveien",
    streetNumber: "70",
    postalCode: "0455",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Etterstadsletta",
    streetNumber: " 95B",
    postalCode: "0660",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Åssiden Terrasse",
    streetNumber: " 16",
    postalCode: "1160",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Rasmus Winderens vei",
    streetNumber: "16",
    postalCode: "0373",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Munkelia",
    streetNumber: "6G",
    postalCode: "1163",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Simensbråtveien 14",
    streetNumber: " B",
    postalCode: "1182",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Bergljots Vei",
    streetNumber: "1A",
    postalCode: "0575",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Lybekkveien",
    streetNumber: " 44B",
    postalCode: "0770",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Mørkveien",
    streetNumber: "7C",
    postalCode: "3427",
    city: "Gullaug",
    country: "Norway",
  },
  {
    streetName: "Danmarks gate",
    streetNumber: "8a",
    postalCode: "0658",
    city: "Oslo",
    country: "Norge",
  },
  {
    streetName: "Brochmanns gate",
    streetNumber: "8C",
    postalCode: "0470",
    city: "Oslo",
    country: "Norway",
  },
  {
    streetName: "Holmenkollveien",
    streetNumber: "78E",
    postalCode: "0784",
    city: "Oslo",
    country: "Norge",
  },
];
