import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { BsClipboard } from "react-icons/bs";
import { BsClipboardCheck } from "react-icons/bs";

import '../../styles/OrderCard.scss';

export const OrderCard = ({ 
  order,
  onCopyOrderId, 
  onCopyDeliveryToken, 
  onCopyDeliveryId, 
  onCopyShortToken,
  onCopyIbxLinkPath,
}) => {

  const { 
    _links,
    isIbxOrder,
    orderId,
    deliveryId,
    shortToken,
    orderIdCopied,
    tokenCopied,
    deliveryIdCopied,
    shortTokenCopied,
    ibxLinkPathCopied,
  } = order;

  const pbOrderToken = _links.userInformation.href.split("/")[_links.userInformation.href.split("/").length - 1];
  const pbOrderLink = _links.userInformation.href;

  const renderPbContent = () => {
    return (
      <div className="card-row">
        <div className="card-column left-column">
          <div className="card-header">Order Id:</div>
          <div className="card-header">Delivery Token:</div>
          <div className="card-header">Tracking App Link:</div>
        </div>
        <div className="card-column center-column">
          <div className="card-content">{orderId}</div>
          <div className="card-content">{pbOrderToken}</div>
          <div className="card-content"><a href={pbOrderLink} target="_blank" rel="noopener noreferrer">{pbOrderLink}</a></div>
        </div>
        <div className="card-column right-column">
          <div className="card-content">
          <CopyToClipboard text={orderId} onCopy={onCopyOrderId}>
            {orderIdCopied ? <BsClipboardCheck /> : <BsClipboard />}
          </CopyToClipboard>
          </div>
          <div className="card-content">
            <CopyToClipboard text={pbOrderToken} onCopy={onCopyDeliveryToken}>
              {tokenCopied ? <BsClipboardCheck /> : <BsClipboard />}
            </CopyToClipboard>
          </div>
        </div>
      </div>
    );
  };

  const renderIbxContent = () => {
    return (
      <div className="card-row">
        <div className="card-column left-column">
          <div className="card-header">Order Id:</div>
          <div className="card-header">deliveryId:</div>
          <div className="card-header">shortToken:</div>
          <div className="card-header">Tracking App Link:</div>
        </div>
        <div className="card-column center-column">
          <div className="card-content">{orderId}</div>
          <div className="card-content">{deliveryId}</div>
          <div className="card-content">{shortToken}</div>
          <div className="card-content"><a href={_links.userInformation.href} target="_blank" rel="noopener noreferrer" style={{ color: '#FF3E3E' }}>{_links.userInformation.href}</a></div>
        </div>
        <div className="card-column right-column">
          <div className="card-content card-action-column">
            <CopyToClipboard text={orderId} onCopy={onCopyOrderId}>
              {orderIdCopied ? <BsClipboardCheck /> : <BsClipboard />}
            </CopyToClipboard>
          </div>
          <div className="card-content">
            <CopyToClipboard text={deliveryId} onCopy={onCopyDeliveryId}>
              {deliveryIdCopied ? <BsClipboardCheck /> : <BsClipboard />}
            </CopyToClipboard>
          </div>
          <div className="card-content">
            <CopyToClipboard text={shortToken} onCopy={onCopyShortToken}>
              {shortTokenCopied ? <BsClipboardCheck /> : <BsClipboard />}
            </CopyToClipboard>
          </div>
          <div className="card-content">
            <CopyToClipboard text={`/${deliveryId}/${shortToken}`} onCopy={onCopyIbxLinkPath}>
              {ibxLinkPathCopied ? <BsClipboardCheck /> : <BsClipboard />}
            </CopyToClipboard>
          </div>
        </div>
    </div>
    );
  };

  return (
    <div className={`order-card ${isIbxOrder ? 'ibx-order-card' : 'pb-order-card'}`}>
      {isIbxOrder ? renderIbxContent() : renderPbContent()}
    </div>
  );
};
