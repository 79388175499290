import React from "react";
import { Button } from "./common";
import SelectorGroup from "./SelectorGroup";

export default function Verifications({
  brand,
  updateOrderSetting,
  generateOrderSettings,
}) {
  return (
    <>
      <SelectorGroup title="Delivery Verification" brand={brand}>
        <Button
          type="radio"
          name = "contactless"
          value = "Contactless"
          checked={generateOrderSettings.deliveryVerification === "CONTACTLESS"}
          onChange={() => updateOrderSetting("deliveryVerification", "CONTACTLESS")}
          brand={brand}
        />
        <br/>
        <Button
          type="radio"
          name = "allowContactless"
          value = "Allow Contactless"
          checked={generateOrderSettings.deliveryVerification === "ALLOW_CONTACTLESS"}
          onChange={() => updateOrderSetting("deliveryVerification", "ALLOW_CONTACTLESS")}
          brand={brand}
        />
        <br/>
        <Button
          type="radio"
          name = "requirePin"
          value = "Require PIN"
          checked={generateOrderSettings.deliveryVerification === "REQUIRE_PIN"}
          onChange={() => updateOrderSetting("deliveryVerification", "REQUIRE_PIN")}
          brand={brand}
        />
        <br/>
        <Button
          type="radio"
          name = "requireId"
          value = "Require ID"
          checked={generateOrderSettings.deliveryVerification === "REQUIRE_ID"}
          onChange={() => updateOrderSetting("deliveryVerification", "REQUIRE_ID")}
          brand={brand}
        />
        <br/>
        <Button
          type="radio"
          name = "onlyToRecipient"
          value = "Only To Recipient"
          checked={generateOrderSettings.deliveryVerification === "ONLY_TO_RECIPIENT"}
          onChange={() => updateOrderSetting("deliveryVerification", "ONLY_TO_RECIPIENT")}
          brand={brand}
        />
        <br/>
      </SelectorGroup>
      <SelectorGroup title="Minimum age check" brand={brand}>
        <input
          type="text"
          value={generateOrderSettings.minimumAgeCheck || ""}
          onChange={(event) =>
            updateOrderSetting(
              "minimumAgeCheck",
              parseInt(event.target.value, 10)
            )
          }
          style={{
            fontSize: 14,
            borderRadius: 5,
            borderStyle: "solid",
            borderWidth: 1,
            padding: 5,
            marginLeft: 5,
            borderColor: "#ccc",
          }}
        />
      </SelectorGroup>
      <SelectorGroup title="Require signature" brand={brand}>
        <Button
          name="requireSignature"
          value="True"
          onChange={() => updateOrderSetting("requireSignature", true)}
          checked={generateOrderSettings.requireSignature}
          brand={brand}
        />
        <Button
          name="requireSignature"
          value="False"
          onChange={() => updateOrderSetting("requireSignature", false)}
          checked={!generateOrderSettings.requireSignature}
          brand={brand}
        />
      </SelectorGroup>
      <SelectorGroup title="Customer needs to confirm reception" brand={brand}>
        <Button
          name="confirmCustomerReceipt"
          value="True"
          checked={generateOrderSettings.confirmCustomerReceipt}
          onChange={() => updateOrderSetting("confirmCustomerReceipt", true)}
          brand={brand}
        />
        <Button
          name="confirmCustomerReceipt"
          value="False"
          checked={!generateOrderSettings.confirmCustomerReceipt}
          onChange={() => updateOrderSetting("confirmCustomerReceipt", false)}
          brand={brand}
        />
      </SelectorGroup>

    </>
  );
}
