import React, { Component } from "react";

import "../styles/SignInModal.scss";

import { Button } from "./common";
import { Environment } from "../constants";
import Store from "../utils/store";
class SignInModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      environment: Environment.Test,
      error: "",
      signedIn: Store.signedIn(),
    };
  }

  signIn = () => {
    const { email, password, environment } = this.state;

    Store.signIn(email, password, environment)
      .then((result) => {
        Store.signedIn(result);
        this.setState({ signedIn: result });
      })
      .catch((e) => {
        if (e.message) {
          this.setState({
            error: e.message,
          });
        }
        console.error(e);
      });
  };

  switchEnvironment = (environment) => {
    this.setState({ environment });
  };

  render() {
    if (this.state.signedIn) return null;

    return (
      <div className="sign-in-modal">
        <div className="sign-in-modal__content">
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.signIn();
            }}
          >
            <div className="content__inner-content">
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                E-mail:
                <input
                  type="text"
                  value={this.state.email}
                  onChange={(event) =>
                    this.setState({ email: event.target.value })
                  }
                  style={{
                    width: 200,
                    fontSize: 14,
                    borderRadius: 5,
                    borderStyle: "solid",
                    borderWidth: 1,
                    padding: 5,
                    marginLeft: 5,
                    borderColor: "#661aff",
                  }}
                />
              </div>
              <div style={{ height: 10 }} />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                Password:
                <input
                  type="password"
                  value={this.state.password}
                  onChange={(event) =>
                    this.setState({ password: event.target.value })
                  }
                  style={{
                    width: 200,
                    fontSize: 14,
                    borderRadius: 5,
                    borderStyle: "solid",
                    borderWidth: 1,
                    padding: 5,
                    marginLeft: 5,
                    borderColor: "#661aff",
                  }}
                />
              </div>
              <br />
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                Environment:
                <div className="button-group">
                  <Button
                    value="Test"
                    name="environment"
                    onChange={() => this.switchEnvironment(Environment.Test)}
                    checked={this.state.environment === Environment.Test}
                  />
                  <Button
                    value="Local"
                    name="environment"
                    onChange={() => this.switchEnvironment(Environment.Local)}
                    checked={this.state.environment === Environment.Local}
                  />
                </div>
              </div>
              {this.state.environment === Environment.Local &&
                !window.location.href.includes("localhost") && (
                  <div
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                      border: "2px solid #ffeeba",
                      backgroundColor: "#fff3cd",
                      color: "#856305",
                      padding: 8,
                      borderRadius: 4,
                    }}
                  >
                    Localhost might not work if you are using it via the
                    internet. If you have trouble signing in, try to run it
                    locally
                  </div>
                )}
              <div style={{ height: 10 }} />
              <button className="sign-in" type="submit">
                Sign in
              </button>
            </div>
            {this.state.error && (
              <div style={{ color: "red" }}>{this.state.error}</div>
            )}
          </form>
        </div>
      </div>
    );
  }
}

export default SignInModal;
