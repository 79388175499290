import React from "react";
import { Brands } from "../constants";
import "../styles/SelectorGroup.scss";

export default ({ brand, title, style, children }) => (
  <li className={`${brand === Brands.pb ? 'pb-selector-group' : 'ibx-selector-group'} button-group`} style={style}>
    {title && <div className="selector-title">{title}</div>}
    <div className="buttons">{children}</div>
  </li>
);
