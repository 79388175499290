import moment from "moment";
import {
  regionAddresses,
  addresses,
  firstName,
  lastName,
  senderCompanies,
} from "./order-info-content";
import toParcels from "./parcels";
import { osloCorePostcodes } from "./oslo-core-postcodes";
import { Brands } from '../constants';

const MAX_RANDOM_NUMBER_FOR_DELIVER_FROM = {
  6: 13,
  7: 13,
  8: 12,
  9: 11,
  10: 10,
  11: 9,
  12: 8,
  13: 7,
  14: 6,
  15: 5,
  16: 4,
  17: 3,
  18: 2,
  19: 1,
  20: 0,
  21: 0,
};

const PICKUP_POINT = {
  alnaHub: {
    name: "Porterbuddy Hub",
    address: {
      streetName: "Haraldrudveien",
      streetNumber: "11",
      postalCode: "0581",
      city: "Oslo",
      country: "Norge",
    },
    phoneCountryCode: "+47",
    phoneNumber: "40506070",
  },
  filipstad: {
    name: "Porterbuddy Pickup Filipstad",
    address: {
      streetName: "Filipstadveien",
      streetNumber: "15",
      postalCode: "0250",
      city: "Oslo",
      country: "Norge",
    },
    phoneCountryCode: "+47",
    phoneNumber: "40506070",
  },
  ryen: {
    name: "Porterbuddy Pickup Ryen",
    address: {
      streetName: "Sandstuveien",
      streetNumber: "70",
      postalCode: "0680",
      city: "Oslo",
      country: "Norge",
    },
    phoneCountryCode: "+47",
    phoneNumber: "40506070",
  },
  sorenga: {
    name: "Porterbuddy Pickup Sørenga",
    address: {
      streetName: "Sørengkaia",
      streetNumber: "69",
      postalCode: "0194",
      city: "Oslo",
      country: "Norge",
    },
    phoneCountryCode: "+47",
    phoneNumber: "90807060",
  },
  alna: {
    name: "Porterbuddy Pickup Alna",
    address: {
      streetName: "Alnabruveien",
      streetNumber: "15",
      postalCode: "0668",
      city: "Oslo",
      country: "Norge",
    },
    phoneCountryCode: "+47",
    phoneNumber: "90102030",
  },
};

function generateOrigin(pickupPoint, daysAdded, senderIsCompany, customEmail) {
  return {
    name:
      pickupPoint === "none"
        ? `${random(firstName)} ${random(lastName)}`
        : senderIsCompany
        ? senderCompanies[Math.floor(Math.random() * senderCompanies.length)]
        : PICKUP_POINT[pickupPoint].name,
    address:
      pickupPoint === "none"
        ? random(addresses)
        : PICKUP_POINT[pickupPoint].address,
    email: customEmail || "testemail+sender@porterbuddy.com",
    phoneCountryCode: "+47",
    phoneNumber:
      pickupPoint === "none"
        ? generateRandomPhoneNumber()
        : PICKUP_POINT[pickupPoint].phoneNumber,
    pickupWindows: generatePickupWindows(daysAdded),
  };
}

function generateDestination(
  region,
  minimumAgeCheck,
  requireSignature,
  deliveryWindowStart,
  daysAdded,
  customTimeWindowStart,
  customTimeWindowEnd,
  customEmail,
  deliveryVerification,
  confirmCustomerReceipt
) {
  let address = random(regionAddresses[region]);
  return {
    name: `${random(firstName)} ${random(lastName)}`,
    address,
    email: customEmail || "testemail+recipient@porterbuddy.com",
    phoneCountryCode: "+47",
    phoneNumber: generateRandomPhoneNumber(),
    deliveryWindow: generateDeliveryWindows(
      deliveryWindowStart,
      daysAdded,
      customTimeWindowStart,
      customTimeWindowEnd,
      address
    ),
    verifications: {
      minimumAgeCheck,
      requireSignature,
      deliveryVerification,
      confirmCustomerReceipt,
    },
  };
}

function generateRandomPhoneNumber() {
  return String(parseInt(Math.random() * (99999999 - 40000000) + 40000000, 10));
}

function random(list) {
  return list[Math.floor(Math.random() * list.length)];
}

/**
 * 
 * @returns a 13 digit number as a string for instabox orders
 */
 export const generateRandomNumberAsString = () => {
  return String(Math.floor(1000000000000 + Math.random() * 9000000000000));
}

function generatePickupWindows(daysAdded) {
  const start = moment().hour(8).endOf("hour").add(daysAdded, "days").format();
  const end = moment()
    .hour(21)
    .startOf("hour")
    .second(1)
    .add(daysAdded, "days")
    .format();

  return [{ start: start, end: end }];
}

function generateDeliveryWindows(
  deliveryWindowStart,
  daysAdded,
  customTimeWindowStart,
  customTimeWindowEnd,
  address
) {

  const randomHour = parseInt(
    Math.random() *
      (MAX_RANDOM_NUMBER_FOR_DELIVER_FROM[parseInt(moment().format("H"), 10)] -
        1) +
      1,
    10
  );

  let start = "";
  let end = "";

  switch (deliveryWindowStart) {
    case "random":
      start = moment()
        .startOf("hour")
        .add(randomHour, "hour")
        .add(30, "minutes")
        .add(daysAdded, "days")
        .format();

      end = moment(start).add(2, "hour").format();

      break;
    case "randomRealistic": {
      const useTwoHourWindow =
        osloCorePostcodes.includes(address.postalCode) && Math.random() < 0.9;
      const startHour = useTwoHourWindow && Math.random() < 0.5 ? 19 : 17;

      start = moment()
        .startOf("hour")
        .hour(startHour)
        .minute(30)
        .add(daysAdded, "days")
        .format();

      end = moment(start)
        .add(useTwoHourWindow ? 2 : 4, "hour")
        .format();

      break;
    }
    case "custom":
      start = moment(customTimeWindowStart, "HH:mm")
        .add(daysAdded, "days")
        .format();

      end = moment(customTimeWindowEnd, "HH:mm")
        .add(daysAdded, "days")
        .format();

      if (moment(end).isBefore(start)) {
        alert(
          `End time, ${moment(end).format(
            "HH:mm"
          )} is before start time, ${moment(start).format("HH:mm")}`
        );
      }

      break;
    default:
      start = moment(deliveryWindowStart, "HH:mm")
        .add(daysAdded, "days")
        .format();

      end = moment(start).add(2, "hour").format();
      break;
  }

  return { start, end };
}

function generateOrderReferenceFunction(generateOrderReference) {
  return generateOrderReference
    ? `${Math.floor(1000000 * Math.random())}`
    : null;
}

export default function randomGeneratedOrder(
  brand,
  confirmed,
  region,
  deliveryType,
  minimumAgeCheck,
  requireSignature,
  temperatureControlled,
  generateOrderReference,
  pickupPoint,
  timeWindowStart,
  daysAdded,
  packageSize,
  numberOfParcels,
  customTimeWindowStart,
  customTimeWindowEnd,
  senderIsCompany,
  consolidatedOrders,
  firstOrder,
  senderEmail,
  recipientEmail,
  deliveryVerification,
  confirmCustomerReceipt
) {
  const ibxParcel = brand === Brands.ibx;

  return {
    brand,
    confirmed,
    origin: generateOrigin(
      pickupPoint,
      daysAdded,
      senderIsCompany,
      senderEmail
    ),
    destination:
      consolidatedOrders && firstOrder
        ? firstOrder.destination
        : generateDestination(
            region,
            minimumAgeCheck,
            requireSignature,
            timeWindowStart,
            daysAdded,
            customTimeWindowStart,
            customTimeWindowEnd,
            recipientEmail,
            deliveryVerification,
            confirmCustomerReceipt
          ),
    parcels: toParcels(numberOfParcels, packageSize, temperatureControlled, ibxParcel),
    bestAvailableWindow: false,
    product: deliveryType,
    orderReference: generateOrderReferenceFunction(generateOrderReference),
    courierInstructions: "Generated with test orders generator",
  };
}
