import React from 'react';

import '../../styles/SearchBar.scss';
import { Brands } from '../../constants';

export const SearchBar = ({ brand, token, deliveryId, setDeliveryId, shortToken, setShortToken, setToken, onClearSearch }) => {

  const renderPbSearch = () => {
    return (
      <div className="controls-wrapper">
        <span className="search-box-url-text" style={{ whiteSpace: 'nowrap' }}>https://tracking.porterbuddy-test.com/</span>
        <input 
          className="search-bar-input"
          placeholder="1LxmQEoucieSJKnHrOMf7y"
          value={token}
          onChange={(e) => { setToken(e.target.value); }}
          style={{ color: '#661aff' }}
          spellcheck="false"
        />
        <div className="search-bar-button-container">
          <button
            className={`search-bar-button ${brand === Brands.pb ? 'search-bar-pb-clear-button' : 'search-bar-ibx-clear-button'}`}
            onClick={onClearSearch}
          >
            X
          </button>
        </div>
      </div>
    );
  };

  const renderIbxSearch = () => {
    return (
      <div className="ibx-controls-wrapper">
        <span className="search-box-url-text" style={{ whiteSpace: 'nowrap' }}>https://test.ibx.no/</span>
        <input 
          className="search-bar-input"
          placeholder="423458"
          value={deliveryId}
          onChange={(e) => { setDeliveryId(e.target.value); }}
          style={{ color: '#FF3E3E' }}
          spellcheck="false"
        />
        <span className="search-box-url-text">/</span>
        <input 
          className="search-bar-input"
          placeholder="RCEwv"
          value={shortToken}
          onChange={(e) => { setShortToken(e.target.value); }}
          style={{ color: '#FF3E3E' }}
          spellcheck="false"
        />
        <div className="search-bar-button-container">
          <button
            className={`search-bar-button ${brand === Brands.pb ? 'search-bar-pb-clear-button' : 'search-bar-ibx-clear-button'}`}
            onClick={onClearSearch}
          >
            X
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="search-bar-container">
      {brand === Brands.pb ? renderPbSearch() : renderIbxSearch()}
    </div>
  );
};
