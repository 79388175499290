import React from 'react';

import { Brands, Tabs } from '../constants';

export const NavTabs = ({ brand, currentTab, setCurrentTab }) => {

  const NavTab = ({ tabId, tabName, active }) => {
    return (
      <div className={`${brand === Brands.pb ? 'nav-tab-pb' : 'nav-tab-ibx'}`} onClick={() => setCurrentTab(tabId)}>
        <span className={`${active ? (brand === Brands.pb ? 'nav-tab-active-pb' : 'nav-tab-active-ibx') : ''}`}>
          {tabName}
        </span>
      </div>
    );
  };

  return (
    <div className={`nav-container ${brand === Brands.pb ? 'app-pb-brand' : 'app-ibx-brand'}`} style={{ borderBottom: `1px solid ${brand === Brands.pb ? '#661aff' : '#FF3E3E'}` }}>
      <div className="nav-tab-wrapper">
        <NavTab tabId={Tabs.generate} tabName="Generate Orders" active={currentTab === Tabs.generate} />
        <NavTab tabId={Tabs.inspect} tabName="Inspect Order" active={currentTab === Tabs.inspect} />
      </div>
    </div>
  )
}
