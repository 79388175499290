import React from "react";

import { Brands } from '../../constants';

export const Button = ({ name, value, onChange, checked, disabled, brand, style = null }) => {
  const pbStyle = {
    backgroundColor: "#661aff",
    color: "white",
  };
  const ibxStyle = {
    backgroundColor: "#FF1A30",
    color: "white",
  };

  return (
    <button
      name={name}
      onClick={onChange}
      disabled={disabled}
      style={style === null ? (checked ? (brand === Brands.pb ? {...pbStyle} : {...ibxStyle}) : {}) : ({...style})}
      className="button"
    >
      {value}
    </button>
  );
};