import React from "react";
import moment from "moment";
import { Button, Checkbox } from "./common";
import SelectorGroup from "./SelectorGroup";
import { Brands } from '../constants';

export default function OrderSettings({
  brand,
  updateOrderSetting,
  updateOrderSettings,
  generateOrderSettings,
}) {
  return (
    <>
      <SelectorGroup title="Brand" brand={brand}>
        <Button
          name="pb"
          value="Porterbuddy"
          onChange={() => updateOrderSetting("brand", Brands.pb)}
          checked={generateOrderSettings.brand === Brands.pb}
          brand={brand}
        />
        <Button
          name="ibx"
          value="Instabox"
          onChange={() => { 
            updateOrderSettings({
              "brand": Brands.ibx,
              "numberOfParcels": 1,
            });
          }}
          checked={generateOrderSettings.brand === Brands.ibx}
          brand={brand}
        />
      </SelectorGroup>
      <SelectorGroup title="Region" brand={brand}>
        <Button
          name="OSLO"
          value="Oslo"
          onChange={() => updateOrderSetting("region", "OSLO")}
          checked={generateOrderSettings.region === "OSLO"}
          brand={brand}
        />
        <Button
          name="BERGEN"
          value="Bergen"
          onChange={() => updateOrderSetting("region", "BERGEN")}
          checked={generateOrderSettings.region === "BERGEN"}
          brand={brand}
        />
      </SelectorGroup>
      <SelectorGroup title="Pickup point" brand={brand}>
        <Button
          name="pickup"
          value="Random"
          onChange={() => updateOrderSetting("pickupPoint", "none")}
          checked={generateOrderSettings.pickupPoint === "none"}
          brand={brand}
        />
        <Button
          name="pickup"
          value="Alna Hub"
          onChange={() => updateOrderSetting("pickupPoint", "alnaHub")}
          checked={generateOrderSettings.pickupPoint === "alnaHub"}
          brand={brand}
        />
        <Button
          name="pickup"
          value="Ryen"
          onChange={() => updateOrderSetting("pickupPoint", "ryen")}
          checked={generateOrderSettings.pickupPoint === "ryen"}
          brand={brand}
        />
        <Button
          name="pickup"
          value="Filipstad"
          onChange={() => updateOrderSetting("pickupPoint", "filipstad")}
          checked={generateOrderSettings.pickupPoint === "filipstad"}
          brand={brand}
        />
        <Button
          name="pickup"
          value="Sørenga"
          onChange={() => updateOrderSetting("pickupPoint", "sorenga")}
          checked={generateOrderSettings.pickupPoint === "sorenga"}
          brand={brand}
        />
        <Button
          name="pickup"
          value="Alna"
          onChange={() => updateOrderSetting("pickupPoint", "alna")}
          checked={generateOrderSettings.pickupPoint === "alna"}
          brand={brand}
        />
      </SelectorGroup>
      <SelectorGroup
        style={{
          color:
            generateOrderSettings.pickupPoint === "none" ? "grey" : "black",
          cursor:
            generateOrderSettings.pickupPoint === "none" ? "no-drop" : "auto",
        }}
        brand={brand}
      >
        <Checkbox
          disabled={generateOrderSettings.pickupPoint === "none"}
          style={{
            cursor:
              generateOrderSettings.pickupPoint === "none" ? "no-drop" : "auto",
          }}
          onChange={() =>
            updateOrderSetting(
              "senderIsCompany",
              !generateOrderSettings.senderIsCompany
            )
          }
          checked={generateOrderSettings.senderIsCompany}
          title="Sender is company (Komplett, Snus, Farmasiet...)"
        />
        {generateOrderSettings.pickupPoint === "none" && (
          <span style={{ fontSize: 10 }}>
            Disabled (Pickup location must be a hub)
          </span>
        )}
      </SelectorGroup>
      <SelectorGroup title="Delivery type" brand={brand}>
        <Button
          name="type"
          value="Delivery"
          onChange={() => updateOrderSetting("deliveryType", "delivery")}
          checked={generateOrderSettings.deliveryType === "delivery"}
          brand={brand}
        />
        <Button
          name="type"
          value="Large"
          onChange={() => updateOrderSetting("deliveryType", "large")}
          checked={generateOrderSettings.deliveryType === "large"}
          brand={brand}
        />
      </SelectorGroup>
      <SelectorGroup title="Generate random order reference(partner id)" brand={brand}>
        <Button
          name="return"
          value="True"
          onChange={() => updateOrderSetting("generateOrderReference", true)}
          checked={generateOrderSettings.generateOrderReference}
          brand={brand}
        />
        <Button
          name="return"
          value="False"
          onChange={() => updateOrderSetting("generateOrderReference", false)}
          checked={!generateOrderSettings.generateOrderReference}
          brand={brand}
        />
      </SelectorGroup>
      <SelectorGroup title="Package size" brand={brand}>
        <Button
          name="package_size"
          value="Small"
          onChange={() => updateOrderSetting("packageSize", "SMALL")}
          checked={generateOrderSettings.packageSize === "SMALL"}
          brand={brand}
        />
        <Button
          name="package_size"
          value="Medium"
          onChange={() => updateOrderSetting("packageSize", "MEDIUM")}
          checked={generateOrderSettings.packageSize === "MEDIUM"}
          brand={brand}
        />
        <Button
          name="package_size"
          value="Large"
          onChange={() => updateOrderSetting("packageSize", "LARGE")}
          checked={generateOrderSettings.packageSize === "LARGE"}
          brand={brand}
        />
        <Button
          name="package_size"
          value="Extra large"
          onChange={() => updateOrderSetting("packageSize", "EXTRA_LARGE")}
          checked={generateOrderSettings.packageSize === "EXTRA_LARGE"}
          brand={brand}
        />
        <Button
          value="Random"
          name="package_size"
          onChange={() => updateOrderSetting("packageSize", "")}
          checked={generateOrderSettings.packageSize === ""}
          brand={brand}
        />
      </SelectorGroup>
      <SelectorGroup title="Number of parcels" brand={brand}>
        <Button
          name="number_of_parcels"
          value="1"
          onChange={() => updateOrderSetting("numberOfParcels", 1)}
          checked={generateOrderSettings.numberOfParcels === 1}
          brand={brand}
        />
        {
          brand === Brands.pb && 
          (
            <>
              <Button
                name="number_of_parcels"
                value="2"
                onChange={() => updateOrderSetting("numberOfParcels", 2)}
                checked={generateOrderSettings.numberOfParcels === 2}
                brand={brand}
              />
              <Button
                name="number_of_parcels"
                value="3"
                onChange={() => updateOrderSetting("numberOfParcels", 3)}
                checked={generateOrderSettings.numberOfParcels === 3}
                brand={brand}
              />
              <Button
                name="number_of_parcels"
                value="4"
                onChange={() => updateOrderSetting("numberOfParcels", 4)}
                checked={generateOrderSettings.numberOfParcels === 4}
                brand={brand}
              />
              <Button
                name="number_of_parcels"
                value="5"
                onChange={() => updateOrderSetting("numberOfParcels", 5)}
                checked={generateOrderSettings.numberOfParcels === 5}
                brand={brand}
              />
              <Button
                name="number_of_parcels"
                value="Random"
                onChange={() => updateOrderSetting("numberOfParcels", "")}
                checked={generateOrderSettings.numberOfParcels === ""}
                brand={brand}
              />
            </>
          )
        }
      </SelectorGroup>
      <SelectorGroup title="Temperature controlled" brand={brand}>
        <Button
          name="temperature"
          value="True"
          onChange={() => updateOrderSetting("temperatureControlled", true)}
          checked={generateOrderSettings.temperatureControlled}
          brand={brand}
        />
        <Button
          name="temperature"
          value="False"
          onChange={() => updateOrderSetting("temperatureControlled", false)}
          checked={!generateOrderSettings.temperatureControlled}
          brand={brand}
        />
      </SelectorGroup>
      <SelectorGroup title="Custom time window" brand={brand}>
        <Button
          name="timeWindow"
          value="Random realistic"
          onChange={() =>
            updateOrderSetting("timeWindowStart", "randomRealistic")
          }
          checked={generateOrderSettings.timeWindowStart === "randomRealistic"}
          brand={brand}
        />
        <br />
        <Button
          name="timeWindow"
          value="Random"
          onChange={() => updateOrderSetting("timeWindowStart", "random")}
          checked={generateOrderSettings.timeWindowStart === "random"}
          brand={brand}
        />
        <br />
        <Button
          name="timeWindow"
          value="09:30 - 11:30"
          onChange={() => updateOrderSetting("timeWindowStart", "09:30")}
          checked={generateOrderSettings.timeWindowStart === "09:30"}
          disabled={
            parseInt(moment().format("H"), 10) >= 9 &&
            generateOrderSettings.daysAdded === 0
          }
          brand={brand}
        />
        <br />
        <Button
          name="timeWindow"
          value="11:30 - 13:30"
          onChange={() => updateOrderSetting("timeWindowStart", "11:30")}
          checked={generateOrderSettings.timeWindowStart === "11:30"}
          disabled={
            parseInt(moment().format("H"), 10) >= 11 &&
            generateOrderSettings.daysAdded === 0
          }
          brand={brand}
        />
        <br />
        <Button
          name="timeWindow"
          value="13:30 - 15:30"
          onChange={() => updateOrderSetting("timeWindowStart", "13:30")}
          checked={generateOrderSettings.timeWindowStart === "13:30"}
          disabled={
            parseInt(moment().format("H"), 10) >= 13 &&
            generateOrderSettings.daysAdded === 0
          }
          brand={brand}
        />
        <br />
        <Button
          type="radio"
          value="15:30 - 17:30"
          name="timeWindow"
          onChange={() => updateOrderSetting("timeWindowStart", "15:30")}
          checked={generateOrderSettings.timeWindowStart === "15:30"}
          disabled={
            parseInt(moment().format("H"), 10) >= 15 &&
            generateOrderSettings.daysAdded === 0
          }
          brand={brand}
        />
        <br />
        <Button
          type="radio"
          value="17:30 - 19:30"
          name="timeWindow"
          onChange={() => updateOrderSetting("timeWindowStart", "17:30")}
          checked={generateOrderSettings.timeWindowStart === "17:30"}
          disabled={
            parseInt(moment().format("H"), 10) >= 17 &&
            generateOrderSettings.daysAdded === 0
          }
          brand={brand}
        />
        <br />
        <Button
          type="radio"
          value="19:30 - 21:30"
          name="timeWindow"
          onChange={() => updateOrderSetting("timeWindowStart", "19:30")}
          checked={generateOrderSettings.timeWindowStart === "19:30"}
          disabled={
            parseInt(moment().format("H"), 10) >= 19 &&
            generateOrderSettings.daysAdded === 0
          }
          brand={brand}
        />
        <br />
        <Button
          type="radio"
          value="Custom"
          name="timeWindow"
          onChange={() => updateOrderSetting("timeWindowStart", "custom")}
          checked={generateOrderSettings.timeWindowStart === "custom"}
          brand={brand}
        />
        <input
          type="text"
          value={generateOrderSettings.customTimeWindowStart}
          placeholder="12:00"
          onChange={(event) =>
            updateOrderSetting("customTimeWindowStart", event.target.value)
          }
          style={{
            width: 50,
            fontSize: 14,
            borderRadius: 5,
            borderStyle: "solid",
            borderWidth: 1,
            paddingTop: 2,
            paddingBottom: 2,
            paddingRight: 6,
            paddingLeft: 6,
            marginLeft: 5,
            borderColor: "#ccc",
          }}
          disabled={generateOrderSettings.timeWindowStart !== "custom"}
        />
        {" - "}
        <input
          type="text"
          value={generateOrderSettings.customTimeWindowEnd}
          placeholder="13:00"
          onChange={(event) =>
            updateOrderSetting("customTimeWindowEnd", event.target.value)
          }
          style={{
            width: 50,
            fontSize: 14,
            borderRadius: 5,
            borderStyle: "solid",
            borderWidth: 1,
            paddingTop: 2,
            paddingBottom: 2,
            paddingRight: 6,
            paddingLeft: 6,
            borderColor: "#ccc",
          }}
          disabled={generateOrderSettings.timeWindowStart !== "custom"}
        />
        <br />
        In days:
        <input
          type="text"
          value={generateOrderSettings.daysAdded}
          onChange={(event) =>
            updateOrderSetting("daysAdded", parseInt(event.target.value, 10))
          }
          style={{
            fontSize: 14,
            borderRadius: 5,
            borderStyle: "solid",
            borderWidth: 1,
            padding: 5,
            marginLeft: 5,
            borderColor: "#ccc",
          }}
        />
      </SelectorGroup>
      <SelectorGroup title="Confirmed" brand={brand}>
        <Button
          name="confirmed"
          value="True"
          onChange={() => updateOrderSetting("confirmed", true)}
          checked={generateOrderSettings.confirmed}
          brand={brand}
        />
        <Button
          name="confirmed"
          value="False"
          onChange={() => updateOrderSetting("confirmed", false)}
          checked={!generateOrderSettings.confirmed}
          brand={brand}
        />
      </SelectorGroup>
    </>
  );
}
