import Fetch from "./fetch";
import {
  setAuthHeadersCookie,
  getAuthHeadersCookie,
  deleteCookie,
} from "./cookie";
import firebase from "firebase/app";
import "firebase/auth";
import { Environment } from "../constants";

const envSettings = {
  [Environment.Local]: {
    apiUrl: "http://localhost:5000",
    firebaseKey: "AIzaSyCBomPE274DRpnTKIUeK7XVn2DA1aiShQY",
  },
  [Environment.Test]: {
    apiUrl: "https://api.porterbuddy-test.com",
    firebaseKey: "AIzaSyCBomPE274DRpnTKIUeK7XVn2DA1aiShQY",
  },
};
const firebaseInstances = {};

function initializeFirebase() {
  firebaseInstances.local = initFirebase(
    envSettings.local.firebaseKey,
    "local"
  );
  firebaseInstances.test = initFirebase(envSettings.test.firebaseKey, "test");
}

function initFirebase(firebaseKey, env) {
  const firebaseConfig = {
    apiKey: firebaseKey,
  };

  return firebase.initializeApp(firebaseConfig, env);
}

function firebaseApp(env) {
  return firebaseInstances[env];
}

async function isSignedIn(env) {
  if (store.signedIn) {
    userHasSenderRole(env).then((res) => {
      if (!res) {
        Store.signOut();
      }
    });
  }
}

async function signIn(email, password, environment) {
  const app = firebaseApp(environment);
  const response = await firebase
    .auth(app)
    .signInWithEmailAndPassword(email, password);

  const user = response.user;

  const token = await user.getIdToken();
  const sessionToken = await sessionLogin(environment, token);
  if (!sessionToken) {
    return false;
  }

  Store.sessionToken(sessionToken);
  Store.userEmail(email);
  Store.environment(environment);

  setAuthHeadersCookie(sessionToken, email, environment);
  const isSender = await userHasSenderRole(environment);
  if (!isSender) {
    deleteCookie();

    store.user.email = "";
    store.sessionToken = "";
    store.environment = Environment.Test;
    store.signedIn = false;
    alert("You must be a sender to sign in.");
    return false;
  }

  window.location = "/";
  return true;
}

async function sessionLogin(environment, idToken) {
  try {
    const response = await Fetch.noAuth.post({
      url: `/auth/session-login`,
      body: { idToken: idToken },
    });

    if (!response.ok) {
      return null;
    }

    return (await response.json()).token;
  } catch (e) {
    console.error("Session login request failed", e);
    return null;
  }
}

async function userHasSenderRole(environment) {
  if (!environment) {
    return false;
  }

  try {
    const response = await Fetch.get({
      url: `/user/role`,
    });

    if (response.ok) {
      const roleResp = await response.json();
      if (
        (roleResp.role || "").toUpperCase() === "SENDER" ||
        (roleResp.role || "").toUpperCase() === "SENDER_ADMIN"
      ) {
        return true;
      }
    }
  } catch (e) {
    console.error("Role request failed", e);
  }
  return false;
}

function signedIn(signedIn) {
  if (signedIn) {
    store.signedIn = signedIn;
  }

  return store.signedIn;
}

function signOut() {
  deleteCookie();

  store.user.email = "";
  store.sessionToken = "";
  store.environment = Environment.Test;
  store.signedIn = false;

  window.location = "/";
}

function sessionToken(token) {
  if (token) {
    store.sessionToken = token;
  }

  return store.sessionToken;
}

function userEmail(email) {
  if (email) {
    store.user.email = email;
  }

  return store.user.email;
}

function environment(environment) {
  if (environment) {
    store.environment = environment;
  }

  return store.environment;
}

// // initialize store
function getAuthHeadersFromCookieAndUpdateStore() {
  const authHeaders = JSON.parse(getAuthHeadersCookie());
  if (authHeaders) {
    sessionToken(authHeaders.sessionToken);
    userEmail(authHeaders.uid);
    environment(authHeaders.environment);
    signedIn(true);
  }
}
// end of initialize store

const store = {
  sessionToken: "",
  user: {
    email: "",
  },
  environment: Environment.Test,
  signedIn: false,
};

const Store = {
  envSettings,
  signIn,
  signOut,
  sessionToken,
  userEmail,
  signedInAs: store.user.email,
  environment,
  signedIn,
  getAuthHeadersFromCookieAndUpdateStore,
  initializeFirebase,
  isSignedIn,
};

export default Store;
