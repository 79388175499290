import { osloAddresses } from "./oslo-addresses";
import osloRegionNotOsloCity from "./oslo-not-oslo-addresses.json";

export const bergenAddresses = [
  {
    streetName: "Edvard Griegs plass",
    streetNumber: "1",
    postalCode: "5015",
    city: "Bergen",
    country: "Norge",
  },
  {
    streetName: "Øvre Riplegården",
    streetNumber: "84",
    postalCode: "5161",
    city: "Bergen",
    country: "Norge",
  },
  {
    streetName: "Damsgårdsveien",
    streetNumber: "69",
    postalCode: "5058",
    city: "Bergen",
    country: "Norge",
  },
  {
    streetName: "Nattlandsveien",
    streetNumber: "49",
    postalCode: "5093",
    city: "Bergen",
    country: "Norge",
  },
  {
    streetName: "Pinnelien",
    streetNumber: "7",
    postalCode: "5053",
    city: "Bergen",
    country: "Norge",
  },
  {
    streetName: "Schweigårds Vei",
    streetNumber: "11a",
    postalCode: "5063",
    city: "Bergen",
    country: "Norge",
  },
  {
    streetName: "armauer hansens vei",
    streetNumber: "15",
    postalCode: "5081",
    city: "bergen",
    country: "Norge",
  },
  {
    streetName: "Absalon Beyers gate",
    streetNumber: "10",
    postalCode: "5034",
    city: "Bergen",
    country: "Norge",
  },
  {
    streetName: "Michael krohns gate",
    streetNumber: "63A",
    postalCode: "5057",
    city: "Bergen",
    country: "Norge",
  },
  {
    streetName: "Nattlandsveien",
    streetNumber: "49",
    postalCode: "5093",
    city: "Bergen",
    country: "Norge",
  },
  {
    streetName: "Øvre Sædalsvegen",
    streetNumber: "165F",
    postalCode: "5099",
    city: "Bergen",
    country: "Norge",
  },
  {
    streetName: "Van der Weldesvei",
    streetNumber: "3",
    postalCode: "5232",
    city: "Bergen",
    country: "Norge",
  },
  {
    streetName: "Sophus Pihls gate",
    streetNumber: "7",
    postalCode: "5034",
    city: "Bergen",
    country: "Norge",
  },
  {
    streetName: "Johannes Bruns Gate",
    streetNumber: "5",
    postalCode: "5008",
    city: "Bergen",
    country: "Norge",
  },
  {
    streetName: "Jægerbakken",
    streetNumber: "23",
    postalCode: "5042",
    city: "BERGEN",
    country: "Norge",
  },
  {
    streetName: "Morvikbrekkene",
    streetNumber: "1b",
    postalCode: "5124",
    city: "Bergen",
    country: "Norge",
  },
  {
    streetName: "Edvard Griegsvei 16",
    postalCode: "5053",
    city: "Bergen",
    country: "Norge",
  },
];

export const addresses = [...osloRegionNotOsloCity, ...osloAddresses];

export const firstName = [
  "Jan",
  "Per",
  "Bjørn",
  "Ole",
  "Kjell",
  "Lars",
  "Arne",
  "Knut",
  "Svein",
  "Hans",
  "Odd",
  "Tor",
  "Geir",
  "Terje",
  "Thomas",
  "Morten",
  "John",
  "Erik",
  "Anders",
  "Rune",
  "Martin",
  "Andreas",
  "Trond",
  "Tore",
  "Harald",
  "Olav",
  "Gunnar",
  "Jon",
  "Rolf",
  "Leif",
  "Tom",
  "Stian",
  "Kristian",
  "Nils",
  "Øyvind",
  "Helge",
  "Espen",
  "Einar",
  "Marius",
  "Kåre",
  "Daniel",
  "Magnus",
  "Fredrik",
  "Christian",
  "Steinar",
  "Eirik",
  "Håkon",
  "Øystein",
  "Henrik",
  "Frode",
  "Arild",
  "Ivar",
  "Jørgen",
  "Kjetil",
  "Frank",
  "Stein",
  "Johan",
  "Sverre",
  "Magne",
  "Petter",
  "Dag",
  "Alf",
  "Egil",
  "Vidar",
  "Stig",
  "Karl",
  "Jonas",
  "Pål",
  "Kenneth",
  "Tommy",
  "Roger",
  "Ola",
  "Kristoffer",
  "Erling",
  "Håvard",
  "Thor",
  "Reidar",
  "Eivind",
  "Asbjørn",
  "Finn",
  "Jens",
  "Roy",
  "Alexander",
  "Kim",
  "Torbjørn",
  "Bjarne",
  "Roar",
  "Simen",
  "Vegard",
  "Arvid",
  "Jarle",
  "Johannes",
  "Robert",
  "Sondre",
  "Mathias",
  "Ove",
  "Trygve",
  "Sigurd",
  "Sindre",
  "Jostein",
  "Joakim",
  "Erlend",
  "Jørn",
  "Oddvar",
  "Andre",
  "Atle",
  "Ronny",
  "Mats",
  "Emil",
  "Ragnar",
  "Inge",
  "Henning",
  "Aleksander",
  "Lasse",
  "Paul",
  "Even",
  "Kai",
  "Sander",
  "Markus",
  "Adrian",
  "Johnny",
  "Bjørnar",
  "Arve",
  "Tobias",
  "Åge",
  "Øivind",
  "Sebastian",
  "David",
  "Sven",
  "Torstein",
  "Christoffer",
  "Robin",
  "Peder",
  "Bård",
  "Benjamin",
  "Audun",
  "Birger",
  "Glenn",
  "Ståle",
  "Kurt",
  "Simon",
  "Peter",
  "Roald",
  "Sigmund",
  "Steffen",
  "Mads",
  "Torgeir",
  "Jakob",
  "Michael",
  "Arnt",
  "Willy",
  "Joachim",
  "Ørjan",
  "Carl",
  "Runar",
  "Bernt",
  "Marcus",
  "Harry",
  "Tormod",
  "Halvor",
  "Dagfinn",
  "Arnfinn",
  "Raymond",
  "Truls",
  "William",
  "Magnar",
  "Olaf",
  "Richard",
  "Jonny",
  "Mohammad",
  "Christer",
  "Elias",
  "Oddbjørn",
  "Cato",
  "Ruben",
  "Ketil",
  "Endre",
  "Oskar",
  "Ulf",
  "Herman",
  "Kevin",
  "Henry",
  "Georg",
  "Christopher",
  "Kent",
  "Yngve",
  "Bent",
  "Nikolai",
  "Otto",
  "Ottar",
  "Are",
  "Aksel",
  "Haakon",
  "Patrick",
  "Dan",
  "Bjarte",
  "Karsten",
  "Åsmund",
  "Vetle",
  "Sigbjørn",
  "Jo",
  "Isak",
  "Tomas",
  "Ingar",
  "Ali",
  "Preben",
  "Edvard",
  "Asle",
  "Amund",
  "Mikael",
  "Oliver",
  "Sivert",
  "Børge",
  "Aage",
  "Fred",
  "Gaute",
  "Sveinung",
  "Ernst",
  "Oddmund",
  "Jacob",
  "Thorbjørn",
  "Bengt",
  "Sten",
  "Trym",
  "Nicolai",
  "Bendik",
  "Arthur",
  "Jarl",
  "Torleif",
  "Alfred",
  "Hallvard",
  "Halvard",
  "Tony",
  "Jim",
  "Jesper",
  "Mikkel",
  "Viggo",
  "Idar",
  "Anton",
  "Oscar",
  "Ingvar",
  "Ken",
  "Leiv",
  "Mohamed",
  "Jonathan",
  "Snorre",
  "Vegar",
  "Gustav",
  "Thore",
  "Victor",
  "Remi",
  "Philip",
  "Kasper",
  "Tord",
  "Vebjørn",
  "Kolbjørn",
  "Eskil",
  "Stefan",
  "Iver",
  "Joar",
  "Gjermund",
  "Edvin",
  "Rasmus",
  "Viktor",
  "Dennis",
  "Sigve",
  "Freddy",
  "Mohammed",
  "Ahmed",
  "Brage",
  "Gudmund",
  "Arnold",
  "Svenn",
  "Kjartan",
  "Gisle",
  "Chris",
  "Hugo",
  "Sjur",
  "Filip",
  "Niklas",
  "Torfinn",
  "Øistein",
  "Odin",
  "Thorleif",
  "Aslak",
  "Asgeir",
  "Ivan",
  "Gard",
  "Arnstein",
  "Julian",
  "Gøran",
  "Ronald",
  "Jan-Erik",
  "Børre",
  "Agnar",
  "Axel",
  "Edgar",
  "Svend",
  "Ulrik",
  "Ingvald",
  "Yngvar",
  "Didrik",
  "Kristen",
  "Kaare",
  "Paal",
  "Gabriel",
  "August",
  "Hallgeir",
  "Kyrre",
  "Theodor",
  "Jone",
  "Karstein",
  "Tim",
  "Albert",
  "Ragnvald",
  "Ludvig",
  "Erland",
  "Klaus",
  "Kay",
  "Eilif",
  "Mikal",
  "Noah",
  "Matias",
  "Brede",
  "Guttorm",
  "Ådne",
  "Ingolf",
  "Jøran",
  "Konrad",
  "Jack",
  "Eric",
  "Arnulf",
  "Bernhard",
  "Tarjei",
  "Wilhelm",
  "Leon",
  "Oddgeir",
  "Toralf",
  "Norvald",
  "Samuel",
  "Rene",
  "Hassan",
  "Krister",
  "Werner",
  "Casper",
  "Hermann",
  "Omar",
  "Rolv",
  "Hjalmar",
  "Nicholas",
  "Adam",
  "Fritz",
  "Walter",
  "Hilmar",
  "Sturla",
  "Charles",
  "Vemund",
  "Fabian",
  "Edmund",
  "Muhammad",
  "Torjus",
  "Jahn",
  "Heine",
  "Brynjar",
  "Felix",
  "Lukas",
  "Mustafa",
  "Njål",
  "Torgrim",
  "Ibrahim",
  "Niels",
  "Torkel",
  "Torleiv",
  "Abdul",
  "Nicolay",
  "Malvin",
  "Bo",
  "Max",
  "Tron",
  "Villy",
  "Brian",
  "Morgan",
  "Allan",
  "Torger",
  "Hermod",
  "Leo",
  "Carsten",
  "Eldar",
  "Jonatan",
  "Gunvald",
  "Tage",
  "Thorstein",
  "Claus",
  "Aron",
  "Jørund",
  "Gerhard",
  "Rudi",
  "Jimmy",
  "James",
  "Lars-Erik",
  "Rikard",
  "Birk",
  "Søren",
  "Elling",
  "Håvar",
  "Fridtjof",
  "Osvald",
  "Jann",
  "Amir",
  "Lennart",
  "Ahmad",
  "Josef",
  "Ludvik",
  "Patrik",
  "Jose",
  "Thorvald",
  "Ørnulf",
  "Stephen",
  "Wiggo",
  "Eigil",
  "Rudolf",
  "Abdi",
  "Ingebrigt",
  "Oddvin",
  "Mattis",
  "Sture",
  "Stephan",
  "Ben",
  "Ansgar",
  "Frederik",
  "Ole-Martin",
  "Lucas",
  "Alv",
  "Eyvind",
  "Mehmet",
  "Steven",
  "Sigvald",
  "Sølve",
  "Torvald",
  "Ask",
  "Nicolas",
  "Nicklas",
  "Eilert",
  "Eskild",
  "Normann",
  "Baard",
  "Said",
  "Teodor",
  "Alan",
  "Esben",
  "Andrew",
  "Mark",
  "Syver",
  "Brynjulf",
  "Hans-Petter",
  "Aasmund",
  "Bror",
  "Haldor",
  "Remy",
  "Steve",
  "Joacim",
  "Mons",
  "Hogne",
  "Ingve",
  "Torben",
  "Ian",
  "Eystein",
  "Kaj",
  "Halfdan",
  "Holger",
  "Alex",
  "Torje",
  "Edward",
  "Ole-Kristian",
  "Adnan",
  "Rino",
  "Leander",
  "Gjert",
  "Kim-Andre",
  "Syed",
  "Hasan",
  "Sigfred",
  "Lauritz",
  "Osman",
  "Carlos",
  "Hallstein",
  "Jean",
  "Ronnie",
  "Asmund",
  "Juan",
  "Frits",
  "Marco",
  "Gudbrand",
  "Luis",
  "Ismail",
  "Svein-Erik",
  "Niclas",
  "Torkild",
  "Thanh",
  "Julius",
  "Tonny",
  "Khalid",
  "Torkil",
  "Mattias",
  "Narve",
  "Flemming",
  "Viljar",
  "Tom-Erik",
  "Annar",
  "Anthony",
  "Mario",
  "Per-Arne",
  "Kennet",
  "Olve",
  "Scott",
  "Frithjof",
  "Tarald",
  "Samir",
  "Phillip",
  "Tor-Erik",
  "Kato",
  "Hussein",
  "Severin",
  "Evald",
  "Gorm",
  "Joseph",
  "Jonn",
  "Bjørn-Erik",
  "Johann",
  "Laurits",
  "Abdirahman",
  "Sean",
  "Skjalg",
  "Jomar",
  "Abdullah",
  "Bertil",
  "Oddleif",
  "Benny",
  "Andres",
  "Leonard",
  "Antonio",
  "Martinus",
  "Torolf",
  "Thoralf",
  "Ahmet",
  "Bjørge",
  "Theo",
  "Fredrick",
  "Levi",
  "Borgar",
  "Ferdinand",
  "Lorentz",
  "George",
  "Torodd",
  "Arnljot",
  "Abdullahi",
  "Jardar",
  "Thormod",
  "Bastian",
  "Minh",
  "Sigvart",
  "Hamza",
  "Ole-Christian",
  "Tor-Arne",
  "Odvar",
  "Anfinn",
  "Adolf",
  "Andrzej",
  "Ingmar",
  "Louis",
  "Mathis",
  "Ole-Petter",
  "Christen",
  "Hamid",
  "Marvin",
  "Olai",
  "Jarand",
  "Joel",
  "Halvdan",
  "Per-Erik",
  "Bilal",
  "Piotr",
  "Vilhelm",
  "Eddie",
  "Edin",
  "Knud",
  "Gert",
  "Oluf",
  "Artur",
  "Ole-Jørgen",
  "Yusuf",
  "Tønnes",
  "Krzysztof",
  "Reinert",
  "Ted",
  "Nikolas",
  "Noralf",
  "Øyvin",
  "Halvar",
  "Vincent",
  "Enok",
  "Herbjørn",
  "Linus",
  "Sam",
  "Widar",
  "Jamal",
  "Erich",
  "Gunder",
  "Joen",
  "Gulbrand",
  "Michel",
  "Tosten",
  "Poul",
  "Ellev",
  "Siur",
  "Arent",
  "Biørn",
  "Haagen",
  "Hendrich",
  "Abraham",
  "Johanes",
  "Henrich",
  "Anne",
  "Inger",
  "Kari",
  "Marit",
  "Ingrid",
  "Liv",
  "Eva",
  "Berit",
  "Astrid",
  "Bjørg",
  "Hilde",
  "Anna",
  "Solveig",
  "Marianne",
  "Randi",
  "Ida",
  "Nina",
  "Maria",
  "Elisabeth",
  "Kristin",
  "Bente",
  "Heidi",
  "Silje",
  "Hanne",
  "Gerd",
  "Linda",
  "Tone",
  "Tove",
  "Elin",
  "Anita",
  "Wenche",
  "Ragnhild",
  "Camilla",
  "Ellen",
  "Karin",
  "Hege",
  "Ann",
  "Else",
  "Mona",
  "Marie",
  "Aud",
  "Monica",
  "Julie",
  "Kristine",
  "Turid",
  "Laila",
  "Reidun",
  "Stine",
  "Helene",
  "Åse",
  "Jorunn",
  "Sissel",
  "Mari",
  "Line",
  "Lene",
  "Mette",
  "Grethe",
  "Trine",
  "Unni",
  "Malin",
  "Grete",
  "Thea",
  "Gunn",
  "Emma",
  "May",
  "Ruth",
  "Lise",
  "Emilie",
  "Anette",
  "Kirsten",
  "Sara",
  "Nora",
  "Linn",
  "Eli",
  "Siri",
  "Cecilie",
  "Irene",
  "Marte",
  "Gro",
  "Britt",
  "Ingeborg",
  "Kjersti",
  "Janne",
  "Siv",
  "Sigrid",
  "Karoline",
  "Karen",
  "Vilde",
  "Martine",
  "Tonje",
  "Andrea",
  "Sofie",
  "Torill",
  "Synnøve",
  "Rita",
  "Jenny",
  "Cathrine",
  "Elise",
  "Maren",
  "Hanna",
  "Lillian",
  "Lena",
  "Brit",
  "Vigdis",
  "Therese",
  "Frida",
  "Amalie",
  "Ingvild",
  "Ingunn",
  "Bodil",
  "Charlotte",
  "Signe",
  "Lisbeth",
  "Sandra",
  "Christine",
  "Victoria",
  "Marthe",
  "Caroline",
  "Mia",
  "Tina",
  "Merete",
  "Oda",
  "Trude",
  "Vibeke",
  "Henriette",
  "Johanne",
  "Lisa",
  "Gunvor",
  "Katrine",
  "Mary",
  "Torunn",
  "Kirsti",
  "Beate",
  "Marita",
  "Christina",
  "Sonja",
  "Hedda",
  "Susanne",
  "Tuva",
  "Aslaug",
  "Gry",
  "Kristina",
  "Aase",
  "Toril",
  "Renate",
  "Kine",
  "Guro",
  "Maja",
  "Helga",
  "Mathilde",
  "Ane",
  "Aina",
  "Jeanette",
  "Sunniva",
  "Ingebjørg",
  "Eline",
  "Solfrid",
  "Rigmor",
  "Margit",
  "Gunhild",
  "Veronica",
  "Sølvi",
  "Ella",
  "Elsa",
  "Linnea",
  "Synne",
  "Birgit",
  "Kaja",
  "Anja",
  "Pernille",
  "Monika",
  "Gudrun",
  "Olaug",
  "Agnes",
  "Mina",
  "Aurora",
  "Magnhild",
  "Sarah",
  "Mariann",
  "Jorun",
  "Åshild",
  "Anne-Lise",
  "Pia",
  "Lill",
  "Kathrine",
  "Ina",
  "Julia",
  "Selma",
  "Celine",
  "Olga",
  "Margrethe",
  "Inga",
  "Hannah",
  "Karina",
  "Iselin",
  "Amanda",
  "Sigrun",
  "Miriam",
  "Erna",
  "Helen",
  "Torhild",
  "Benedicte",
  "Marta",
  "Birgitte",
  "Lilly",
  "Edith",
  "Evy",
  "Eirin",
  "Rikke",
  "Anniken",
  "Borghild",
  "June",
  "Karianne",
  "Tiril",
  "Martha",
  "Helle",
  "Carina",
  "Malene",
  "Svanhild",
  "Lina",
  "Klara",
  "Merethe",
  "Edel",
  "Annette",
  "Jane",
  "Anny",
  "Guri",
  "Sidsel",
  "Tordis",
  "Johanna",
  "Målfrid",
  "Leah",
  "Ellinor",
  "Hjørdis",
  "Tine",
  "Kamilla",
  "Rebecca",
  "Ester",
  "Live",
  "Kjellaug",
  "Astri",
  "Vera",
  "Siw",
  "Evelyn",
  "Maiken",
  "Dina",
  "Michelle",
  "Brita",
  "Jannicke",
  "Ine",
  "Alexandra",
  "Rebekka",
  "Oddny",
  "Dagny",
  "Torild",
  "Marion",
  "Eldbjørg",
  "Ann-Kristin",
  "Ada",
  "Hildegunn",
  "Madeleine",
  "Sofia",
  "Helena",
  "Greta",
  "Tora",
  "Judith",
  "Yvonne",
  "Elena",
  "Natalie",
  "Mathea",
  "Nathalie",
  "Ronja",
  "Oddbjørg",
  "Oddrun",
  "Vivian",
  "Frøydis",
  "Gina",
  "Madelen",
  "Haldis",
  "Borgny",
  "Ragna",
  "Alma",
  "Celina",
  "Laura",
  "Andrine",
  "Henny",
  "Josefine",
  "Solbjørg",
  "Lotte",
  "Rakel",
  "Ranveig",
  "Arnhild",
  "Hildur",
  "Lea",
  "Louise",
  "Stina",
  "Mai",
  "Benedikte",
  "Veronika",
  "Asbjørg",
  "Isabel",
  "Anne-Marie",
  "Unn",
  "Iris",
  "Petra",
  "Natalia",
  "Inger-Lise",
  "Olivia",
  "Oddveig",
  "Mille",
  "Margrete",
  "Alice",
  "Margot",
  "Ingjerd",
  "Tanja",
  "Annie",
  "Lone",
  "Torbjørg",
  "Kate",
  "Esther",
  "Sylvi",
  "Marina",
  "Katarzyna",
  "Emily",
  "Isabell",
  "Sylvia",
  "Alfhild",
  "Viktoria",
  "Rannveig",
  "Gunnhild",
  "Agnieszka",
  "Barbro",
  "Erika",
  "Nelly",
  "Magdalena",
  "May-Britt",
  "Isabella",
  "Margareth",
  "Aleksandra",
  "Ylva",
  "Oline",
  "Gjertrud",
  "Iren",
  "Margaret",
  "Mariell",
  "Christin",
  "Fatima",
  "Audhild",
  "Marlene",
  "Vivi",
  "Nadia",
  "Hedvig",
  "Elizabeth",
  "Siren",
  "Annika",
  "Anine",
  "Diana",
  "Matilde",
  "Erle",
  "Kaia",
  "Sanna",
  "Jorid",
  "Runa",
  "Mie",
  "Maya",
  "Gøril",
  "Vanja",
  "Alva",
  "Annbjørg",
  "Katarina",
  "Amina",
  "Nancy",
  "Sigrunn",
  "Angelica",
  "Thi",
  "Joanna",
  "Elisabet",
  "Karine",
  "Paula",
  "Ingvill",
  "Rønnaug",
  "Norunn",
  "Asta",
  "Ana",
  "Malgorzata",
  "Irina",
  "Emilia",
  "Iben",
  "Regine",
  "Oddlaug",
  "Connie",
  "Marielle",
  "Gerda",
  "Lovise",
  "Barbara",
  "Jeanett",
  "Lisbet",
  "Hilda",
  "Isabelle",
  "Jessica",
  "Sophie",
  "Melissa",
  "Elna",
  "Cornelia",
  "Venke",
  "Bergljot",
  "Herdis",
  "Susann",
  "Anne-Grethe",
  "Adele",
  "Milla",
  "Signy",
  "Mildrid",
  "Ulla",
  "Elida",
  "Vanessa",
  "Åsta",
  "Dagmar",
  "Ewa",
  "Kristi",
  "Harriet",
  "Julianne",
  "Valborg",
  "Tomine",
  "Karolina",
  "Terese",
  "Arna",
  "Stella",
  "Sanne",
  "Mali",
  "Dagrun",
  "Birthe",
  "Kitty",
  "Cecilia",
  "Elina",
  "Margunn",
  "Maia",
  "Brynhild",
  "Susan",
  "Katharina",
  "Bettina",
  "Ann-Mari",
  "Tilde",
  "Beathe",
  "Alina",
  "Liss",
  "Birte",
  "Jannike",
  "Carmen",
  "Torun",
  "Elisa",
  "Jennifer",
  "Mira",
  "Kjerstin",
  "Åsa",
  "Thale",
  "Ingun",
  "Åsne",
  "Gunnvor",
  "Eira",
  "Linea",
  "Mariam",
  "Idun",
  "Christel",
  "Brith",
  "Maryam",
  "Ulrikke",
  "Torgunn",
  "Svetlana",
  "Anne-Karin",
  "Amelia",
  "Una",
  "Inger-Johanne",
  "Nanna",
  "Halldis",
  "Aisha",
  "Veslemøy",
  "Anneli",
  "Fanny",
  "Hermine",
  "Zahra",
  "Gretha",
  "Eileen",
  "Beata",
  "Ågot",
  "Kim",
  "Synøve",
  "Gitte",
  "Tatiana",
  "Annlaug",
  "Janicke",
  "Reidunn",
  "Elvira",
  "Elly",
  "Alvhild",
  "Ann-Karin",
  "Lydia",
  "Rachel",
  "Fride",
  "Doris",
  "Erica",
  "Sina",
  "Agnete",
  "Pauline",
  "Renata",
  "Ingfrid",
  "Ann-Helen",
  "Aida",
  "Kristiane",
  "Embla",
  "Solvor",
  "Sophia",
  "Jill",
  "Kari-Anne",
  "Marian",
  "Patricia",
  "Maj",
  "Ebba",
  "Kjellrun",
  "Nicole",
  "Catharina",
  "Ingerid",
  "Vilma",
  "Tale",
  "Jolanta",
  "Anni",
  "Anett",
  "Kjellfrid",
  "Daniela",
  "Kaisa",
  "Eldrid",
  "Katrin",
  "Elen",
  "Silja",
  "Magda",
  "Alida",
  "Kerstin",
  "Lilli",
  "Anne-Britt",
  "Claudia",
  "Alise",
  "Dorota",
  "Mirjam",
  "Marlen",
  "Rose",
  "Lykke",
  "Catherine",
  "Yasmin",
  "Sigfrid",
  "Paulina",
  "Katja",
  "Luna",
  "Nicoline",
  "Sol",
  "Simone",
  "Silvia",
  "Adriana",
  "Frøya",
  "Snefrid",
  "Sylwia",
  "Samira",
  "Catrine",
  "Betty",
  "Thora",
  "Anne-Grete",
  "Irmelin",
  "Elzbieta",
  "Ann-Christin",
  "Gudny",
  "Angela",
  "Vilja",
  "Elfrid",
  "Juliane",
  "Solvår",
  "Lin",
  "Anlaug",
  "Jofrid",
  "Dorthe",
  "Agnethe",
  "Ramona",
  "Gunnlaug",
  "Annelise",
  "Ingelin",
  "Christiane",
  "Stephanie",
  "Cassandra",
  "Magny",
  "Irma",
  "Edna",
  "Vibecke",
  "Iwona",
  "Edle",
  "Turi",
  "Kajsa",
  "Janet",
  "Valentina",
  "Ingri",
  "Maud",
  "Justyna",
  "Herborg",
  "Gyda",
  "Nikoline",
  "Fatma",
  "Teresa",
  "Wenke",
  "Filippa",
  "Lotta",
  "Tara",
  "Emine",
  "Anne-Berit",
  "Aasta",
  "Gabriela",
  "Åslaug",
  "Juni",
  "Anne-Mette",
  "Leila",
  "Fadumo",
  "Tea",
  "Leonora",
  "Gudveig",
  "Rut",
];

export const lastName = [
  "Aabakken",
  "Aabel",
  "Aaberg",
  "Aaboen",
  "Aaby",
  "Aabøl",
  "Aadahl",
  "Aadalen",
  "Aadnes",
  "Aaen",
  "Aafedt",
  "Aag",
  "Aagaard",
  "Aagesdatter",
  "Aagesen",
  "Aagkleiven",
  "Aak",
  "Aaker",
  "Aakerström",
  "Aakesen",
  "Aakran",
  "Aakre",
  "Aaland",
  "Aalberg",
  "Aalbu",
  "Aalbuhagen",
  "Aale",
  "Aalgaard",
  "Aalheim",
  "Aalkyterud",
  "Aall",
  "Aalrud",
  "Aalschytrud",
  "Aalschytterud",
  "Aalset",
  "Aalseth",
  "Aalstad",
  "Aalstadeie",
  "Aalton",
  "Aaløkken",
  "Aam",
  "Aamillom",
  "Aamland",
  "Aamo",
  "Aamodt",
  "Aamodthaugen",
  "Aamold",
  "Aamot",
  "Aamoth",
  "Aanby",
  "Aandahl",
  "Aandal",
  "Aandalen",
  "Aandeeiet",
  "Aanden",
  "Aanensdtr",
  "Aanenson",
  "Aanerud",
  "Aanes",
  "Aanesrud",
  "Aanestad",
  "Aanetsen",
  "Aannestad",
  "Aanrud",
  "Aanrudhagen",
  "Aanstad",
  "Aarbakke",
  "Aarflot",
  "Aarli",
  "Aarlid",
  "Aarnes",
  "Aaron",
  "Aars",
  "Aarsby",
  "Aarseth",
  "Aarstad",
  "Aarstein",
  "Aarthun",
  "Aartun",
  "Aarum",
  "Aarø",
  "Aarøe",
  "Aarøen",
  "Aas",
  "Aas-Torkildsen",
  "Aasaareie",
  "Aasbakken",
  "Aasberg",
  "Aasbø",
  "Aasbøe",
  "Aase",
  "Aasebø",
  "Aaseli",
  "Aasen",
  "Aaseng",
  "Aasengeie",
  "Aasenhus",
  "Aasenhuus",
  "Aaser",
  "Aaset",
  "Aaseth",
  "Aasethagen",
  "Aasgaard",
  "Aasgård",
  "Aashagen",
  "Aasheim",
  "Aaslaksdatter",
  "Aasland",
  "Aaslid",
  "Aaslund",
  "Aasmo",
  "Aasmundstad",
  "Aasnes",
  "Aasness",
  "Aason",
  "Aass",
  "Aasstuen",
  "Aassveen",
  "Aasveen",
  "Aateigen",
  "Aavangen",
  "Aavatsmark",
  "Aavik",
  "Abbey",
  "Abbott",
  "Abel",
  "Abell",
  "Abendroth",
  "Abglevik",
  "Abjor",
  "Ableidinger",
  "Abo",
  "Abote",
  "Abraham",
  "Abrahamsdatter",
  "Abrahamsen",
  "Abrahamsen jr",
  "Abrahamson",
  "Abrams",
  "Absner",
  "Achappel",
  "Acheleye",
  "Achter",
  "Ackerlund",
  "Ackerman",
  "Ackert",
  "Adahl",
  "Adamrød",
  "Adams",
  "Adamson",
  "Adcock",
  "Adde",
  "Addison",
  "Adkins",
  "Adler",
  "Admic",
  "Brli",
  "Baaberg",
  "Baade",
  "Baadshaug",
  "Baalson",
  "Baanrud",
  "Baarddatter",
  "Baardlistuen",
  "Baardshaug",
  "Baardsnes",
  "Baardson",
  "Baargaard",
  "Baarnaas",
  "Baarsrud",
  "Baarstad",
  "Baasum",
  "Babcock",
  "Babcook",
  "Babler",
  "Bach",
  "Bache",
  "Bachke",
  "Bachman",
  "Bachmann",
  "Bachmeier",
  "Backe",
  "Backer",
  "Backer-Grøndahl",
  "Backhaus",
  "Backlund",
  "Backstrom",
  "Bacon",
  "Bader",
  "Badger",
  "Badstuen",
  "Badsvik",
  "Badten",
  "Baeder",
  "Baeklev",
  "Bagaas",
  "Bagg",
  "Bagge",
  "Bagger",
  "Baggerud",
  "Bagley",
  "Baglien",
  "Baglo",
  "Baglovald",
  "Bagosgjelten",
  "Bagroen",
  "Bagstad",
  "Bagstevoldeie",
  "Bagås",
  "Bah",
  "Bahho",
  "Bahler",
  "Bahmanou",
  "Bahnub",
  "Bahr",
  "Bailey",
  "Bain",
  "Baine",
  "Bainey",
  "Baingorough",
  "Bair",
  "Baird",
  "Bake",
  "Bakemsløkken",
  "Baker",
  "Bakerovnen",
  "Bakfor",
  "Bakk",
  "Bakka",
  "Bakkaholet",
  "Bakkamoen",
  "Bakke",
  "Bakkeberg",
  "Bakkeby",
  "Bakkeeiet",
  "Bakkeenget",
  "Bakkehaug",
  "Bakkehaugen",
  "Bakkeli",
  "Bakkelien",
  "Bakkelund",
  "Bakkelykkja",
  "Bakkeløkken",
  "Bakkemellom",
  "Bakkemo",
  "Bakkemoen",
  "Bakkemyren",
  "Bakken",
  "Bakkene",
  "Bakkeneie",
  "Bakkeneiet",
  "Bakkenhaug",
  "Bakkepallen",
  "Bakker",
  "Bakkerud",
  "Bakkeskoug",
  "Bakkestuen",
  "Bakkestulen",
  "Bakkesveen",
  "Bakketreet",
  "Bakkhaug",
  "Bakkken",
  "Bakkløkk",
  "Bakko",
  "Bakkom",
  "Bakkum",
  "Baklie",
  "Baklien",
  "Bakløkken",
  "Bakmoen",
  "Bakmyren",
  "Bakos",
  "Bakstad",
  "Baksås",
  "Baksæter",
  "Bakåsmoen",
  "Balatoni",
  "Balberg",
  "Balberson",
  "Balbin",
  "Balcan",
  "Balcer",
  "Balch",
  "Baldishol",
  "Baldvin",
  "Baldwin",
  "Bale",
  "Balke",
  "Balken",
  "Ball",
  "Ballantyne",
  "Ballard",
  "Ballentine",
  "Ballingall",
  "Ballintine",
  "Ballsrud",
  "Balnes",
  "Balstad",
  "Baltatú",
  "Baltz",
  "Baltzersen",
  "Balux",
  "Banach",
  "Banbury",
  "Baney",
  "Bang",
  "Bangen",
  "Bangs",
  "Bangsberg",
  "Bangseie",
  "Bangstad",
  "Baniky",
  "Bankers",
  "Bankert",
  "Banks",
  "Banning",
  "Banta",
  "Bantley",
  "Banzhof",
  "Barb",
  "Barbara",
  "Barbara-Nelson",
  "Barbee",
  "Barber",
  "Barbrostuen",
  "Barbøl",
  "Barca",
  "Barchet",
  "Barclay",
  "Barcus",
  "Bardalen",
  "Bardeen",
  "Bardlistuen",
  "Bardo",
  "Bardonslien",
  "Bare",
  "Barekke",
  "Barflaten",
  "Barger",
  "Barhaug",
  "Barhaugen",
  "Barheim",
  "Barikmo",
  "Barkalden",
  "Barkenæs",
  "Barker",
  "Barkley",
  "Barknes",
  "Barksdale",
  "Barlund",
  "Barlundeie",
  "Barlundseiet",
  "Barlundsstuen",
  "Barlundstuen",
  "Barmo",
  "Barn",
  "Barna",
  "Barnaal",
  "Barnes",
  "Barnett",
  "Barnhart",
  "Barnål",
  "Barr",
  "Barras",
  "Barrer",
  "Barreto",
  "Barrett",
  "Barrie",
  "Barrington",
  "Barrow",
  "Barrusten",
  "Barrustmoen",
  "Barsema",
  "Barsness",
  "Barstad",
  "Barta",
  "Bartels",
  "Barth",
  "Bartholomew",
  "Bartnes",
  "Bartsch",
  "Bartulis",
  "Barvik",
  "Barøy",
  "Basey",
  "Basgard",
  "Bashutski",
  "Basioli",
  "Basmoen",
  "Basse",
  "Bassendowski",
  "Bassøe",
  "Bastesen",
  "Bastians",
  "Basum",
  "Batchelor",
  "Bateman",
  "Bates",
  "Bath",
  "Bathen",
  "Bathurst",
  "Batke",
  "Battersby",
  "Batty",
  "Baucholt",
  "Baucker",
  "Baudhuin",
  "Bauer",
  "Baugen",
  "Baugh",
  "Baugslien",
  "Bauholsbakken",
  "Bauker",
  "Baukerstuen",
  "Baukhol",
  "Baukol",
  "Baum",
  "Baumann",
  "Baumer",
  "Baumgardner",
  "Baumgart",
  "Baumgarten",
  "Baumgartner",
  "Baumhofer",
  "Baun",
  "Baur",
  "Bausman",
  "Baustad",
  "Bautz",
  "Bavero",
  "Bawden",
  "Baxley",
  "Baxter",
  "Bay",
  "Bay-Forr",
  "Bayard",
  "Bayba",
  "Bayliss",
  "Bayuk",
  "Baze",
  "Beach",
  "Beadles",
  "Beaken",
  "Beal",
  "Beale",
  "Bean",
  "Bear",
  "Beardsley",
  "Bearer",
  "Bearson",
  "Beasley",
  "Beastrom",
  "Beaton",
  "Beatson",
  "Beattie",
  "Beaucarge",
  "Beaudin",
  "Beaulieu",
  "Beaver",
  "Bebelin",
  "Bech",
  "Bechen",
  "Bechstrøm",
  "Bechstøm",
  "Bechtold",
  "Beck",
  "Becken",
  "Becker",
  "Beckert",
  "Beckett",
  "Beckius",
  "Beckman",
  "Beckstad",
  "Beddel",
  "Bednar",
  "Bednarz",
  "Beech",
  "Beecher",
  "Beeching",
  "Beede",
  "Beedle",
  "Beeler",
  "Beem",
  "Beeman",
  "Beer",
  "Beerman",
  "Begby",
  "Begg",
  "Behlin",
  "Behling",
  "Behrens",
  "Behring",
  "Beiber",
  "Beichman",
  "Beichmann",
  "Beier",
  "Beikler",
  "Beinerud",
  "Beise",
  "Beisterfield",
  "Beitdokken",
  "Beitlich",
  "Beitmyren",
  "Beito",
  "Beitrusten",
  "Bekk",
  "Bekkadalen",
  "Bekkedal",
  "Bekkedalen",
  "Bekkeli",
  "Bekkelien",
  "Bekkelund",
  "Bekkem",
  "Bekkemellem",
  "Bekkemellom",
  "Bekkemellum",
  "Bekken",
  "Bekkeneie",
  "Bekkesiklet",
  "Bekkesletten",
  "Bekkestuen",
  "Bekkesveen",
  "Bekkevold",
  "Bekkhus",
  "Bekkum",
  "Beksrud",
  "Belaas",
  "Belak",
  "Belcher",
  "Belcourt",
  "Belden",
  "Beleau",
  "Belesi",
  "Belgmager",
  "Belgum",
  "Belhke",
  "Belisle",
  "Belitz",
  "Belkonen",
  "Bell",
  "Belle",
  "Bellev",
  "Bellingmo",
  "Belloit",
  "Bellon",
  "Bellseth",
  "Belrudsbakken",
  "Belsheim",
  "Belsjø",
  "Belsrud",
  "Belsrudbakken",
  "Belsrudjordet",
  "Belsås",
  "Beltzig",
  "Bemberg",
  "Bemowski",
  "Benard",
  "Bender",
  "Bendesby",
  "Bendiksen",
  "Bendixby",
  "Bendixen",
  "Bendsdatter",
  "Bendtsdatter",
  "Bendtsen",
  "Benedickson",
  "Benedict",
  "Benfer",
  "Bengtson",
  "Benjamin",
  "Benjaminsdatter",
  "Benjaminsen",
  "Benjaminson",
  "Benkestok",
  "Benkestokk",
  "Benko",
  "Benlien",
  "Bennestvedt",
  "Bennet",
  "Bennett",
  "Bennis",
  "Bennlien",
  "Benoit",
  "Benolken",
  "Benrud",
  "Benso",
  "Benson",
  "Benstige",
  "Bentdal",
  "Bentehagen",
  "Benterud",
  "Bentestuen",
  "Bentgarden",
  "Benthen",
  "Bentley",
  "Benton",
  "Cabral",
  "CabreraMyhre",
  "Cada",
  "Cade",
  "Cadena",
  "Cady",
  "Caetano",
  "Cain",
  "Caldwell",
  "Caley",
  "Calhoun",
  "Calkins",
  "Call",
  "Callaghan",
  "Callahan",
  "Callan",
  "Callas",
  "Callies",
  "Calvillo",
  "Cam",
  "Cameron",
  "Cammon",
  "Camp",
  "Campano",
  "Campbell",
  "Campell",
  "Camps",
  "Canady",
  "Canestorp",
  "Caney",
  "Canfield",
  "Canham",
  "Cannell",
  "Canning",
  "Cannizzo",
  "Cannon",
  "Cannone",
  "Cantero",
  "Cantner",
  "Canton",
  "Cantu",
  "Caosari",
  "Capallera",
  "Capel",
  "Capler",
  "Cappelen",
  "Capper",
  "Carbno",
  "Carbonelli",
  "Carcieri",
  "Card",
  "Cardelli",
  "Carew",
  "Carey",
  "Cargill",
  "Carl",
  "Carla",
  "Carleg",
  "Carlen",
  "Carlien",
  "Carlier",
  "Carlsdatter",
  "Carlsen",
  "Carlsgoard",
  "Carlson",
  "Carlson John Lawrence",
  "Carlsrud",
  "Carlsson Hurtig",
  "Carlstad",
  "Carlsten",
  "Carlton",
  "Carnahan",
  "Carney",
  "Carnier",
  "Carol",
  "Caroll",
  "Caron",
  "Carpenter",
  "Carpentier",
  "Carper",
  "Carr",
  "Carrell",
  "Carrie",
  "Carriere",
  "Carrigan",
  "Carrol",
  "Carroll",
  "Carson",
  "Carstens",
  "Carswell",
  "Carta",
  "Carter",
  "Cartier",
  "Cartwright",
  "Caryl",
  "Casa",
  "Casagrande",
  "da Veiga",
  "Daae",
  "Daarstad",
  "Dacosta",
  "Daelenbach",
  "Daffer",
  "Daffinrud",
  "Daffinsen",
  "Dagfindsdatter",
  "Dagfinrud",
  "Dagsgaard",
  "Dagsgard",
  "Dagsgård",
  "Dagsveen",
  "Dahken",
  "Dahl",
  "Dahl-Hansen",
  "Dahl-Haugland",
  "Dahlberg",
  "Dahlborgen",
  "Dahlby",
  "Dahle",
  "Dahleen",
  "Dahleie",
  "Dahlelien",
  "Dahlen",
  "Dahler",
  "Dahlerup",
  "Dahlgreen",
  "Dahlgren",
  "Dahlhaug",
  "Dahlin",
  "Dahlke",
  "Dahlquist",
  "Dahlseng",
  "Dahlsrud",
  "Dahlsveen",
  "Dahlsveen Mauset",
  "Dahlum",
  "Dahm",
  "Dahø",
  "Daigle",
  "Daily",
  "Daines",
  "Dal",
  "Dalafarmen",
  "Dalagar",
  "Dalager",
  "Daland",
  "Dalaust",
  "Dalbak",
  "Dalbakk",
  "Dalbakken",
  "Dalberg",
  "Dalborgen",
  "Dalbu",
  "Dalby",
  "Dale",
  "Daleli",
  "Dalelien",
  "Dalemoe",
  "Dalen",
  "Dalene",
  "Daleng",
  "Daleslåen",
  "Dalestuen",
  "Dalfarmen",
  "Dalhaug",
  "Dalheim",
  "Dalhgren",
  "Dalhus",
  "Daljordet",
  "Dall",
  "Dallarudi",
  "Dallas",
  "Dallmann",
  "Dalnes",
  "Dalom",
  "Dalosen",
  "Dalrymple",
  "Dalsbotten",
  "Dalseg",
  "Dalsegbrend",
  "Dalsegbrenden",
  "Dalsegeiet",
  "Dalsegg",
  "Dalseghagen",
  "Dalseghaugen",
  "Dalsegjordet",
  "Dalsegstuen",
  "Dalsegvolden",
  "Dalseie",
  "Dalseng",
  "Dalshaugen",
  "Dalsheim",
  "Dalsholen",
  "Dalsjaugen",
  "Dalslien",
  "Dalslåen",
  "Dalsomo",
  "Dalstuen",
  "Dalsve",
  "Dalton",
  "Dalåsbakken",
  "Dalåsen",
  "Dambach",
  "Dame",
  "Damhaug",
  "Dammen",
  "Dampe",
  "Damrow",
  "Damschen",
  "Damskov",
  "Damsleth",
  "Damstuen",
  "Danchertsen",
  "Dancke",
  "Danebot",
  "Danelius",
  "Dangel",
  "Daniel",
  "Daniel Bertheusen",
  "Daniello",
  "Daniels",
  "Danielsdatter",
  "Danielsdt",
  "Danielsen",
  "Danielson",
  "Danielson? Seattle",
  "Danilesen",
  "Dankert",
  "Eager",
  "Eaker",
  "Eano",
  "Early",
  "East",
  "Eastburn",
  "Eastby",
  "Easton",
  "Eaton",
  "Ebbing",
  "Ebeltoft",
  "Eberding",
  "Eberg",
  "Eberhardt",
  "Eberle",
  "Eberling",
  "Ebert",
  "Eberting",
  "Eblen",
  "Eby",
  "Echlenborg",
  "Eckberg",
  "Eckblad",
  "Ecke",
  "Eckerle",
  "Eckert",
  "Eckes",
  "Eckley",
  "Ecklund",
  "Eckman",
  "Ed",
  "Eddinger",
  "Eddy",
  "Ededun",
  "Edelberg",
  "Edenfield",
  "Edevold",
  "Edgor",
  "Edin",
  "Edisdatter",
  "Edisen",
  "Edissen",
  "Edith",
  "Edland",
  "Edler von Scheibler",
  "Edmonds",
  "Edmonston",
  "Edmunds",
  "Edmundson",
  "Edna",
  "Edson",
  "Edsvold",
  "Edvardsen",
  "Edvinsen",
  "Edward",
  "Edwards",
  "Edøy",
  "Eek",
  "Eeklee",
  "Effinger",
  "Efstad",
  "Eg",
  "Egan",
  "Ege",
  "Egeberg",
  "Egeland",
  "Egenes",
  "Eger",
  "Egernes",
  "Egers",
  "Egerson",
  "Egertson",
  "Egge",
  "Eggebraaten",
  "Eggen",
  "Eggereide",
  "Eggers",
  "Eggert",
  "Eggerud",
  "Eggeseth",
  "Eggesvik",
  "Eggum",
  "Egidius",
  "Eglum",
  "Egnund",
  "Egset",
  "Egstad",
  "Egstadbakken",
  "Egstadstuen",
  "Ehle",
  "Ehlers",
  "Ehlke",
  "Ehmen",
  "Ehrhardt",
  "Eia",
  "Eichhoff",
  "Eichmann",
  "Eicken",
  "Eickhoff",
  "Eid",
  "Eidal",
  "Eide",
  "Eidem",
  "Eidet",
  "Eidevold",
  "Eidhammer",
  "Eidissen",
  "Eidius",
  "Eidnes",
  "Eidshaug",
  "Eidskrem",
  "Eidsmoen",
  "Eidsvold",
  "Eidsvåg",
  "Eidså",
  "Eie",
  "Eiemoen",
  "Eiesar",
  "Eifer",
  "Eigeland",
  "Eik",
  "Eikas",
  "Eikefjord",
  "Eikeland",
  "Eikemo",
  "Eikenes",
  "Eikerol",
  "Eikje",
  "Eikmeier",
  "Eikre",
  "Eikreim",
  "Eikrem",
  "Eikstad",
  "Eilefstad",
  "Faa",
  "Faareng",
  "Faarengseiet",
  "Faavang",
  "Faber",
  "Faberg",
  "Fabian",
  "Fabricius",
  "Faders",
  "Fadum",
  "Faehnrich",
  "Fagan",
  "Fagerbakk",
  "Fagerberg",
  "Fagerhaug",
  "Fagerhaugh",
  "Fagerheim",
  "Fagerhus",
  "Fagerli",
  "Fagerlidal",
  "Fagerlien",
  "Fagerlund",
  "Fagernes",
  "Fagersand",
  "Fagerstrom",
  "Fagertun",
  "Fagervold",
  "Fagerås",
  "Fagg",
  "Fagliasso",
  "Fagstad",
  "Fagundes",
  "Fahlin",
  "Fahlstrøm",
  "Fahnestock",
  "Fahsland",
  "Faille",
  "Fairburn",
  "Fairchild",
  "Fairly",
  "Fairweather",
  "Fakes",
  "Fakstad",
  "Faksvåg",
  "Falch",
  "Falck",
  "Faldet",
  "Faleide",
  "Falk",
  "Falkenberg",
  "Falkenhaug",
  "Falklev",
  "Falkner",
  "Fall",
  "Fallan",
  "Falldalen",
  "Fallei",
  "Fallert",
  "Fallet",
  "Falliera",
  "Falling",
  "Fallon",
  "Fallsen",
  "Falmark",
  "Falmår",
  "Falnes",
  "Falstad",
  "Falster",
  "Faltinson",
  "Fandrem",
  "Fangen",
  "Fanning",
  "Far",
  "Farberg",
  "Farbergeie",
  "Farbergseie",
  "Fardal",
  "Farden",
  "Faret",
  "Fargher",
  "Fargo",
  "Faria",
  "Farley",
  "Farlibakken",
  "Farmen",
  "Farmeneiet",
  "Farmer",
  "Farneff",
  "Farnsworth",
  "Farr",
  "Farrar",
  "Farrell",
  "Farrington",
  "Farron",
  "Farstad",
  "Fartegnsdtr",
  "Fartegnson Skage",
  "Farteinson",
  "Farup",
  "Farverstuen",
  "Fasmer",
  "Fassett",
  "Fasting",
  "Fatheree",
  "Faubert",
  "Fauchald",
  "Fauchslager",
  "Fauglie",
  "Faugstad",
  "Faugstadmo",
  "Faukstad",
  "Faukstadeie",
  "Faukstadeiet",
  "Faukstadmo",
  "Fauli",
  "Faulkner",
  "Faurchalk",
  "Fausgård",
  "Fauske",
  "Fauskevog",
  "Fauskrud",
  "Faust",
  "Fautødegården",
  "Favreau",
  "Fay",
  "Fayas",
  "Faye",
  "Fealy",
  "Feber",
  "Fedelleck",
  "Federman",
  "Fedje",
  "Fedreheim",
  "Feeley",
  "Fegetti",
  "Fegth",
  "Feier",
  "Feierås",
  "Feilberg",
  "Feiring",
  "Feist",
  "Fejlberg",
  "Felch",
  "Felde",
  "Felder",
  "Feldhake",
  "Feldman",
  "Feldmann",
  "Feldt",
  "Felien",
  "Felix",
  "Feljan",
  "Felland",
  "Felle",
  "Fellese",
  "Felling",
  "Fellner",
  "Felt",
  "Feltham",
  "Feltman",
  "Feltmann",
  "Femer",
  "Femmer",
  "Femundsenden",
  "Fennel",
  "Fenstad",
  "Fenzl",
  "Feragen",
  "Ferangen",
  "Ferden",
  "Ferdinand",
  "Fergus",
  "Fering",
  "Ferlauto",
  "Ferley",
  "Ferm",
  "Fermann",
  "Fernander",
  "Fernquist",
  "Fernstrom",
  "Ferraioli",
  "Ferrar",
  "Ferrel",
  "Ferrerie",
  "Ferries",
  "Ferrin",
  "Ferris",
  "Ferry",
  "Ferrøe",
  "Ferstad",
  "Gaalaas",
  "Gaarder",
  "Gaardhammer",
  "Gaare",
  "Gaaseidnes",
  "Gaasrudengen",
  "Gaastad",
  "Gabhoel",
  "Gabhol",
  "Gable",
  "Gableman",
  "Gabrelcik",
  "Gabrielsdatter",
  "Gabrielsdr",
  "Gabrielsen",
  "Gabrielson",
  "Gacek",
  "Gackle",
  "Gad",
  "Gade",
  "Gadebusch",
  "Gaden",
  "Gaffney",
  "Gage",
  "Gagnat",
  "Gagner",
  "Gagnon",
  "Gahers",
  "Gaines",
  "Gaisberg",
  "Galaaen",
  "Galarneau",
  "Galberg",
  "Galbogen",
  "Galbraith",
  "Gald",
  "Galde",
  "Gale",
  "Galge",
  "Galgum",
  "Gall",
  "Gallagher",
  "Galle",
  "Gallefoss",
  "Gallegher",
  "Gallie",
  "Gallop",
  "Galskøil",
  "Galspo",
  "Galstad",
  "Galte",
  "Galterud",
  "Galterudhaugen",
  "Galterudsveen",
  "Galtrud",
  "Galtrudhaugen",
  "Galtung",
  "Galås",
  "Galåsen",
  "Gamache",
  "Gamble",
  "Gamborg",
  "Gambruket",
  "Gamez",
  "Gamlem",
  "Gamlen",
  "Gamleplassen",
  "Gammalhushaugen",
  "Gammelby",
  "Gammelgaard",
  "Gammelgårdslien",
  "Gammell",
  "Gammelsetra",
  "Gammelsæter",
  "Gampholen",
  "Gander",
  "Ganger",
  "Gangl",
  "Gangler",
  "Gangneth",
  "Gann",
  "Gantris",
  "Ganyo",
  "Gaphol",
  "Garahan",
  "Garant",
  "Garberg",
  "Garbish",
  "Garbogen",
  "Garbom",
  "Garcia",
  "Gardbekken",
  "Gardbogen",
  "Gardebogen",
  "Garder",
  "Gardli",
  "Gardner",
  "Gardnier",
  "Garetson",
  "Garey",
  "Gargrave",
  "Garland",
  "Garlet",
  "Garli",
  "Garmager",
  "Garman",
  "Garmann",
  "Garmo",
  "Garmostredet",
  "Garmostrædet",
  "Garmosurden",
  "Garmosøygarden",
  "Garner",
  "Garnet",
  "Garon",
  "Garr",
  "Garrett",
  "Garriat",
  "Garrison",
  "Garrity",
  "Garrow",
  "Garscia",
  "Garset",
  "Garske",
  "Garte",
  "Garthus",
  "Garthuuseie",
  "Gartland",
  "Gartner",
  "Garver",
  "Garverhaugen",
  "Garves",
  "Garvin",
  "Gashell",
  "Gasman Johnsen",
  "Gasmann",
  "Gaspar",
  "Gast",
  "Gastelum",
  "Gates",
  "Gathe",
  "Gatzemaier",
  "Gatzke",
  "Gaub",
  "Gaudeau",
  "Gaudet",
  "Gauerke",
  "Gaugin",
  "Gaukerud",
  "Gauksheim",
  "Gaukstad",
  "Gaul",
  "Gaulke",
  "Gault",
  "Gaupar",
  "Gaupholeie",
  "Gausdal",
  "Gausen",
  "Gauslaa",
  "Gaustad",
  "Gaustom",
  "Gaustum",
  "Gaustumvolden",
  "Gauteson Dall til Valen",
  "Gauthier",
  "Gautøyen",
  "Gavin",
  "Gawlik",
  "Gayer",
  "Gayl",
  "Gaynor",
  "Gazedewich",
  "Gbappa",
  "Gbrown",
  "Gearing",
  "Gebert",
  "Gebhardt",
  "Gebro",
  "Gee",
  "Geelhood",
  "Geelmuyden",
  "Geer",
  "Geerdes",
  "Gehan",
  "Gehring",
  "Gehrts",
  "Geigenmuller",
  "Geiger",
  "Geilen",
  "Geilo",
  "Geilosjordet",
  "Geirmundsdatter",
  "Geisdorf",
  "Geiselman",
  "Geisklid",
  "Geisler",
  "Geisner",
  "Geiszler",
  "Geitberget",
  "Geitryggen",
  "Geitsiden",
  "Geitvik",
  "Geizpuscher",
  "Gelatt",
  "Gella",
  "Gellner",
  "Gelmuyden",
  "Gelow",
  "Genck",
  "Gendreau",
  "Genenecoc",
  "Gennett",
  "Genz",
  "Geoglin",
  "Georg",
  "George",
  "Georgeson",
  "Geraghty",
  "Geraline",
  "Gerard",
  "Gerbrandt",
  "Gere",
  "Gerhardine",
  "Gerhardsen",
  "Gerken",
  "Gerlid",
  "German",
  "Germundsen",
  "Gerrard",
  "Gerst",
  "Gerth",
  "Gertner",
  "Gertonson",
  "Gerzanich",
  "Getgosol",
  "Getschmann",
  "Getschow",
  "Getsiden",
  "Getz",
  "Geul",
  "Geving",
  "Gevorbenhaugen",
  "Geworbenhaugen",
  "Geyer",
  "Haag",
  "Haaga",
  "Haagensdatter",
  "Haagensdtr",
  "Haagensen",
  "Haagenson",
  "Haagenstad",
  "Haaheller",
  "Haakedal",
  "Haakensdatter Dahl",
  "Haakensdatter Ramstad Dah",
  "Haakenson",
  "Haakensveen",
  "Haakonsdatter",
  "Haakonsen",
  "Haaland",
  "Haande",
  "Haanes",
  "Haanshus",
  "Haard",
  "Haarset",
  "Haarseth",
  "Haarstad",
  "Haarstadhaugen",
  "Haas",
  "Haatveit",
  "Haavardslien",
  "Haave",
  "Haaveeie",
  "Haaveløkken",
  "Haaversen",
  "Haavi",
  "Haavig-Gjelseth",
  "Habberstad",
  "Habel",
  "Haberkorn",
  "Haberland",
  "Habermas",
  "Haberstad",
  "Hacket",
  "Hackett",
  "Hackey",
  "Haddock",
  "Hadeland",
  "Hadley",
  "Hadnot",
  "Haeck",
  "Haer",
  "Hafeld",
  "Hafellen",
  "Hafellmellom",
  "Hafeman",
  "Hafer",
  "Hafferty",
  "Haffner",
  "Hafos",
  "Hafsahl",
  "Hafsal",
  "Hafslo",
  "Hafso",
  "Hafstad",
  "Haftorn",
  "Haga",
  "Hagaløkken",
  "Hagan",
  "Hagaset",
  "Hagastuen",
  "Hagasveen",
  "Hagberg",
  "Hage",
  "Hagebakken",
  "Hageberg",
  "Hageberget",
  "Hagedorn",
  "Hageeiet",
  "Hageenget",
  "Hagehaugen",
  "Hagejordet",
  "Hagelien",
  "Hagelund",
  "Hageløkken",
  "Hagemann",
  "Hagemeister",
  "Hagemoen",
  "Hagen",
  "Hagen Hagen",
  "Hagene",
  "Hagenes",
  "Hagenlund",
  "Hager",
  "Hageroen",
  "Hagerud",
  "Hagerup",
  "Hagestad",
  "Hagestande",
  "Hagestuen",
  "Hagestuløkken",
  "Hagesveen",
  "Hagevold",
  "Hagevolden",
  "Hageødegaarden",
  "Hageødegården",
  "Haggelien",
  "Hagie",
  "Haglund",
  "Hagness",
  "Hagoes",
  "Hagå",
  "Hagødegården",
  "Hahn",
  "Haig",
  "Haines",
  "Hairup",
  "Haisch",
  "Haitz",
  "Hajicek",
  "Hakkala",
  "Hakkerud",
  "Haklien",
  "Halbakken",
  "Halberg",
  "Halberget",
  "Halbostad",
  "Hald",
  "Halda",
  "Halden",
  "Haldogaard",
  "Haldorsen",
  "Haldorson",
  "Haldos",
  "Hale",
  "Halgrimsdatter",
  "Halgrimsen Sonstebyeie",
  "Halket",
  "Hall",
  "Halla",
  "Hallaeie",
  "Hallager",
  "Halland",
  "Hallandsvigen",
  "Hallandsviken",
  "Hallangen",
  "Hallastuen",
  "Hallaugplassen",
  "Hallbjorn",
  "Hallborg",
  "HALLE",
  "Hallene",
  "Hallenget",
  "Hallerø",
  "Hallett",
  "Halliday",
  "Hallingby",
  "Hallingsbye",
  "Hallingstad",
  "Halllum",
  "Hallowell",
  "Hallquist",
  "Hallset",
  "Hallsette",
  "Hallsteinstande",
  "Hallström",
  "Hallum",
  "Hallvardson Limmesand",
  "Halmar",
  "Halme",
  "Halmrast",
  "Halmrastr",
  "Halmø",
  "Halonen",
  "Hals",
  "Halsa",
  "Halsbakken",
  "Halse",
  "Halsen",
  "Halsenø Kloster",
  "Halset",
  "Halseth",
  "Halsnes",
  "Halstad",
  "Halstadtrø",
  "Halstead",
  "Halsteensen",
  "Halsteinsen",
  "Halsteinstad",
  "Halsteinstande",
  "Halsten",
  "Halstendtr",
  "Halstensdtad",
  "Halstensen",
  "Halstenstad",
  "Halstenstadstuen",
  "Halstenstadsveen",
  "Halstvedt",
  "Haltorp",
  "Halvardsdtr",
  "Halvardson Smør",
  "Halvardsson",
  "Halvardsson SMØR",
  "Halveg Dahlsveen",
  "Halversen",
  "Halverson",
  "Halvfarelia",
  "Halvfarelien",
  "Halvhuden",
  "Halvhunden",
  "Halvordslien",
  "Halvorsdatter",
  "Halvorsen",
  "Halvorsen Stavin",
  "Halvorslien",
  "Halvorslisveen",
  "Halvorslivolden",
  "Halvorson",
  "Halvorson Lie",
  "Halvorssen",
  "Halvorstuen",
  "Halvosen",
  "Ham",
  "Hamacher",
  "Hamberg",
  "Hamblin",
  "Hamblit",
  "Hamborg",
  "Hames",
  "Hamillton",
  "Hamilton",
  "Hammad",
  "Hammarlund",
  "Hamme",
  "Hammell",
  "Hammen",
  "Hammer",
  "Hammerdal",
  "Hammereie",
  "Hammeren",
  "Hammerfoss",
  "Hammerkleiven",
  "Hammerkleven",
  "Hammersborg",
  "Hammerseng",
  "Hammersengstuen",
  "Hammerseter",
  "Hammershaug",
  "Hammerstad",
  "Hammerstuen",
  "Hammervold",
  "Hammervoll",
  "Hammerås",
  "Hammond",
  "Hamn",
  "Hamnes",
  "Hampeløkken",
  "Hampton",
  "Hamran",
  "Hamre",
  "Hamrom",
  "Hamsaas",
  "Hamsun",
  "Hanastad",
  "Hanbakken",
  "Hanchett",
  "Hancock",
  "Handbakken",
  "Hande",
  "Handeland",
  "Handewitt",
  "Handingsmann",
  "Hane",
  "Haneborg",
  "Hanechan",
  "Hanegan",
  "Hanekneeie",
  "Hanestad",
  "Hanestadhaugen",
  "Hanestadhavn",
  "Hanestadstuen",
  "Hanevold",
  "Hanewald",
  "Hanford",
  "Hangaard",
  "Hangard",
  "Hangardseie",
  "Hangård",
  "Hankewich",
  "Hanks",
  "Hann",
  "Hanna",
  "Hannay",
  "Hanneken",
  "Hanner",
  "Hannert",
  "Hannestad",
  "Hannigan",
  "Hanning",
  "Hanninghanning",
  "Hanor",
  "Hans",
  "Hansbakken",
  "Hansdattar",
  "Hansdatter",
  "Hansdatter Hoffstad",
  "Hansdtr",
  "Hansdt",
  "Hanse",
  "Hanselman",
  "Hansen",
  "Hansen-Dahl",
  "Hansen-Møller",
  "Hansen-Zahl",
  "Hanslien",
  "Hanson",
  "Hanssen",
  "Hansson",
  "Hansson Meling",
  "Hansstuen",
  "Hanssveen",
  "Hanssøn",
  "Hanssøn Foss",
  "Hanstad",
  "Hansteen",
  "Hansveen",
  "Hansåsen",
  "Hansæl",
  "Hansælen",
  "Hantsch",
  "Hanum",
  "Hanzal",
  "Hanø",
  "Haraldsdatter",
  "Haraldsdtr",
  "Haraldseid",
  "Haraldsen",
  "Haraldset",
  "Haraldson Bolt Til Tronsta",
  "Haraldson Tjugeskjegg av DANMARK",
  "Haraldsrud",
  "Haraldsson",
  "Haraldstad",
  "Harang",
  "Harangen",
  "Harasemchuk",
  "Haraset",
  "Harbakk",
  "Harbert",
  "Harbig",
  "Harboe",
  "Harby",
  "Hardbakken",
  "Hardekoph",
  "Harden",
  "Hardeng",
  "Hardenget",
  "Harder",
  "Hardh",
  "Harding",
  "Hardy",
  "Harebekk",
  "Haredalen",
  "Hareenget",
  "Harehaugen",
  "Harehenget",
  "Harehullet",
  "Harer",
  "Haresten",
  "Hargrave",
  "Harildstad",
  "Harildstadeie",
  "Harildstadeiet",
  "Harildstaeiet",
  "Harilstad",
  "Harings",
  "Harjo",
  "Harket",
  "Harkjær",
  "Harland",
  "Harlaug",
  "Harlaugeie",
  "Harlauglien",
  "Harlaugseter",
  "Harlaugsæter",
  "Harless",
  "Harlidstad",
  "Harmer",
  "Harms",
  "Ibsen",
  "Icanberry",
  "Ice",
  "Ida",
  "Ide",
  "Ides",
  "Igeland",
  "Igielski",
  "Ihla",
  "Ihlang",
  "Ihle",
  "Ihleeie",
  "Ihlehagen",
  "Ihlehaugen",
  "Ihlen",
  "Ihler",
  "Ihlestuen",
  "Ihls",
  "Ihlstuen",
  "Ihlsveen",
  "Ihrig",
  "Ilasewich",
  "Ilaug",
  "Ile",
  "Ilene",
  "Iler",
  "Iliff",
  "Illevold",
  "Ilsaas",
  "Ilseng",
  "Ilseth",
  "Ilstad",
  "Ilstadeiet",
  "Ilstadhagen",
  "Ilstadkleiven",
  "Ilstadkleven",
  "Ilstadvolden",
  "Ilsås",
  "im Bayerischen Nordgau",
  "im Hofe",
  "im Hofe",
  "Jørgensen",
  "Jaastad",
  "Jaavall",
  "Jablonski",
  "Jacabson",
  "Jackie",
  "Jacklin",
  "Jackman",
  "Jackobsen",
  "Jackson",
  "Jacobdatter",
  "Jacobs",
  "Jacobsdatter",
  "Jacobsdatter Docken",
  "Jacobsdatter Slinda",
  "Jacobsdtr",
  "Jacobsen",
  "Jacobsen Oldreid",
  "Jacobsjordet",
  "Jacobslien",
  "Jacobson",
  "Jacobsson",
  "Jacobstad",
  "Jacobus",
  "Jaekel",
  "Jaenke",
  "Jaer",
  "Jagenberg",
  "Jager",
  "Jagtøyen",
  "Jahn",
  "Jahnsen",
  "Jahr",
  "Jahre",
  "Jahren",
  "Jahrhagen",
  "Jahrmann",
  "Jahrshagen",
  "Jahrshaugen",
  "Jakcobson",
  "Jakel",
  "Jakku",
  "Jakobs",
  "Jakobsdatter",
  "Jakobseb",
  "Jakobsen",
  "Jakobshus",
  "Jakobsløkken",
  "Jakobson",
  "Jakobsson",
  "Jakobstad",
  "Jakobstuen",
  "Jalling",
  "Jalonack",
  "Jambois",
  "Jambor",
  "James",
  "Jamieson",
  "Jamt",
  "Jan",
  "Jandt",
  "Jane",
  "Janeschitz",
  "Janet",
  "Janette",
  "Janice",
  "Janieson",
  "Janissen",
  "Janke",
  "Jansdatter",
  "Jansen",
  "Janson",
  "Janssen",
  "Jansson",
  "Jante",
  "Janusz",
  "Jappée",
  "Jarker",
  "Jar",
  "Jarett",
  "Jarkvam",
  "Jarlsberg",
  "Jarman",
  "Jarmann",
  "Jarnes",
  "Kaae",
  "Kaaen",
  "Kaaen Dee",
  "Kaafjeld",
  "Kaalstad",
  "Kaarbø",
  "Kaare",
  "Kaarfeld",
  "Kaarstein",
  "Kaarvand",
  "Kaas",
  "Kaasa",
  "Kaasen",
  "Kabat",
  "Kacsir",
  "Kaczmarek",
  "Kadash",
  "Kadlec",
  "Kaehler",
  "Kaelin",
  "Kaer",
  "Kaewf",
  "Kaggerud",
  "Kaggerudeie",
  "Kaggerudhagen",
  "Kaggerudrønningen",
  "Kagrud",
  "Kahl",
  "Kahn",
  "Kahoe",
  "Kahrs",
  "Kaihoi",
  "Kaiser",
  "Kakken",
  "Kalal",
  "Kalbak",
  "Kalbekksvea",
  "Kalberg",
  "Kalbu",
  "Kaldal",
  "Kaldbakk",
  "Kaldbakken",
  "Kaldhusdal",
  "Kaldor",
  "Kaleberg",
  "Kalechek",
  "Kalka",
  "Kalkoske",
  "Kalland",
  "Kallbaken",
  "Kallberg",
  "Kalleberg",
  "Kallenberger",
  "Kallerud",
  "Kalleråsen",
  "Kallestad",
  "Kallevig",
  "Kallfoss",
  "Kallock",
  "Kallum",
  "Kallunki",
  "Kallåsen",
  "Kalløkkebakken",
  "Kalløllen",
  "Kalnes",
  "Kalrud",
  "Kalset",
  "Kalsrud",
  "Kalstad",
  "Kalstadeie",
  "Kalstadeiet",
  "Kalstadrud",
  "Kalstadstuen",
  "Kalstadvaasæter",
  "Kalstarud",
  "Kalsveen",
  "Kalsås",
  "Kaltrud",
  "Kaluza",
  "Kalv Torsnes Ivarson Norhei",
  "Kalverudbakken",
  "Kalvik",
  "Kalvløkken",
  "Kaløkken",
  "Kambitz",
  "Kaminski",
  "Kammann",
  "Kammel",
  "Kammeyer",
  "Kamp",
  "Kampen",
  "Kamperschroer",
  "Kampestuen",
  "Kampesveen",
  "Kampesæter",
  "Kampflaten",
  "Kamph",
  "Kamprud",
  "Kamrud",
  "Kamstrup",
  "Kanarian",
  "Kanarr",
  "Kandish",
  "Kane",
  "Kanelopoulos",
  "Kanera",
  "Kanestrøm",
  "Kanieski",
  "Kankenrud",
  "Kankerud",
  "Kankerudhaugen",
  "Kanklefritz",
  "Kankrud",
  "Kankrudhaugen",
  "Kanne",
  "Kanniainen",
  "Kanstrup",
  "Kanten",
  "Kantrud",
  "Kanyetzny",
  "Kapaun",
  "Kapellrud",
  "Kapellslåen",
  "Kapelrud",
  "Kapelslåen",
  "Kaperud",
  "Kapetinger",
  "Kaplan",
  "Kappel",
  "Kappelrud",
  "Kappelslåen",
  "Kapphahn",
  "Kapskarmo",
  "Karabbe",
  "Karcher",
  "Kardash",
  "Karen",
  "Karenersen",
  "Karensstuen",
  "Karenstuen",
  "Karesuando",
  "Kari",
  "Karich",
  "Karihaugen",
  "Karin",
  "Karl",
  "Karla",
  "Karlbom",
  "Karlen",
  "Karlhovedson Kaupanger",
  "Karling",
  "Karlsdatter",
  "Karlsdtr Nortun",
  "Karlsen",
  "Karlsholmen",
  "Karlson",
  "Karlsrud",
  "Karlsson",
  "Karlstad",
  "Karlstadstuen",
  "Karlstrøm",
  "Karmach",
  "Karoline",
  "Karoliussen",
  "Karset",
  "Karseth",
  "Karst",
  "Kart",
  "Karterud",
  "Karthaug",
  "Kasa",
  "Kasbo",
  "Kasper",
  "Kasprowicz",
  "Kasterud",
  "Kastet",
  "Kastrud",
  "Kastrudhagen",
  "Kate",
  "Katevoll",
  "Kathleen-Johnson",
  "Kathrina",
  "Kathy",
  "Katrin",
  "Kattomten",
  "Katz",
  "Kaufeldt",
  "Kaulum",
  "Kaupang",
  "Kaurin",
  "Kaurstad",
  "Kaurstadbakken",
  "Kaurstadeie",
  "Kaurstadeier",
  "Kaurstadeiet",
  "Kaurstadhagen",
  "Kaurstadlokken",
  "Kaurstadløkken",
  "Kaurstadmoen",
  "Kaurstadrønningen",
  "Kaurstadsstuen",
  "Kaurstadstuen",
  "Kaus",
  "Kausar",
  "Kausari",
  "Kauserud",
  "Kaushagen",
  "Kausløkken",
  "Kausrønningen",
  "Kausstuen",
  "Kaustad",
  "Kausvold",
  "Kauth",
  "Kavacs",
  "Kawczynski",
  "Kawlewski",
  "Kay",
  "Kazen",
  "Kazuka",
  "Keaeding",
  "Keathley",
  "Keatinge",
  "Keats",
  "Keck",
  "Kedge",
  "Keenan",
  "Keene",
  "Keffner",
  "Kegler",
  "Kegler’",
  "Kehlet",
  "Keich",
  "Keil",
  "Keiser",
  "Keiseraas",
  "Keisler",
  "Keith",
  "Kelce",
  "Kell",
  "Kellenbarger",
  "Keller",
  "Kellett",
  "Kelley",
  "Kelly",
  "Kelso",
  "Kemley",
  "Kemnitz",
  "Kemp",
  "Kempe",
  "Kempenich",
  "Kempkey",
  "Kenady",
  "Keneston",
  "Kennedy",
  "Kenner",
  "Kenneth",
  "Kenny",
  "Kenstowicz",
  "Kent",
  "Keohane",
  "Kepler",
  "Kerbaugh",
  "Kercheval",
  "Kerkloh",
  "Kerper",
  "Kerr",
  "Kerri",
  "Kersten",
  "Kersting",
  "Kervel",
  "Keslin",
  "Kessey",
  "Kessinger",
  "Kestelot",
  "Kester",
  "Ketchum",
  "Ketilsson",
  "Ketinbaer",
  "Kettelson",
  "Kettilsdotter",
  "Kettilson",
  "Kettinbur",
  "Kettler",
  "Keubler",
  "Kevin",
  "Keyland",
  "Keyser",
  "Khan",
  "Kibsgård",
  "Kidd",
  "Kidder",
  "Kidlien",
  "Kieckhafer",
  "Kiefat",
  "Kielland",
  "Kiem",
  "Kieper",
  "L",
  "La Domus",
  "La Lave",
  "Laankan",
  "Laargaard",
  "Laaveg",
  "Labard",
  "Labelle",
  "Laberge",
  "Laborne",
  "Labreche",
  "Labree",
  "Lacey",
  "Lachance",
  "Lacky",
  "Lacni",
  "Lacy",
  "Ladbrook",
  "Ladder",
  "Lade",
  "Ladegaard",
  "Ladengen",
  "Laderud",
  "Ladewig",
  "Ladierre",
  "Ladrud",
  "Lae",
  "Laengen",
  "Laenn",
  "Lafa",
  "LaFarelle",
  "Lafferty",
  "Lafontaine",
  "Lagerquist",
  "Lagerud",
  "Lagesen",
  "Lageson",
  "Lagestuen",
  "Lagethon",
  "Lagetonsveen",
  "Lagette",
  "Lagmannssveen",
  "Lagoni",
  "Lagrud",
  "Lagsettrønningen",
  "Lagtuen",
  "Laheld",
  "Lahlum",
  "Lahy",
  "Lail",
  "Laingen",
  "Laird",
  "Lake",
  "Lakosky",
  "Lakselvsletten",
  "Lalande",
  "Lalander",
  "Lalim",
  "Lalonde",
  "Lalum",
  "Lamb",
  "Lambert",
  "Lamberton",
  "Lambertsen",
  "Lambrecht",
  "Lammen",
  "Lammert",
  "Lamngley",
  "LaMond",
  "Lamont",
  "Lamorn",
  "LaMotte",
  "Lamp",
  "Lampe",
  "Lampert",
  "Lampson",
  "Lamson",
  "Lanaaseie",
  "Lancaster",
  "Land",
  "Landaas",
  "Landau",
  "Landberg",
  "Lande",
  "Landem",
  "Lander",
  "Landet",
  "Landgard",
  "Landgraf",
  "Landgraf63",
  "Landgraff",
  "Landgren",
  "Landgård",
  "Landgårdshagen",
  "Landgårdstuen",
  "Landheim",
  "Landmark",
  "Landon",
  "Landree",
  "Landrey",
  "Landrø",
  "Landsaasen",
  "Landsem",
  "Landskov",
  "Landstad",
  "Landsverk",
  "Landtz",
  "Landås",
  "Landåseie",
  "Landåsen",
  "Landøimoen",
  "Lane",
  "Lanes",
  "Lang",
  "Lang-Ree",
  "Langaard",
  "Langaneker",
  "Langard",
  "Langardshagen",
  "Langardstuen",
  "Langbakke",
  "Langbakken",
  "Langballe",
  "Langbecker",
  "Langdalen",
  "Langdon",
  "Lange",
  "Langeel",
  "Langehaug",
  "Langeland",
  "Langen",
  "Langeng",
  "Langerud",
  "Langerude",
  "Langevin",
  "Langfeldt",
  "Langfoss",
  "Langgård",
  "Langgårdsstuen",
  "Langhaugen",
  "Langher",
  "Langhough",
  "Langhus",
  "Langille",
  "Langjordet",
  "Langland",
  "Langleite",
  "Langli",
  "Langlo",
  "Langløkka",
  "Langløkken",
  "Langmoen",
  "Langnes",
  "Lango",
  "Langodden",
  "Langroe",
  "Langrusten",
  "Langset",
  "Langseteie",
  "Langseth",
  "Langsethagen",
  "Langsethhagen",
  "Langsetrønningen",
  "Langslet",
  "Langsveen",
  "Langvandet",
  "Langvatslid",
  "Langveg",
  "Langvegg",
  "Langvig",
  "Langvik",
  "Langvågen",
  "Langåker",
  "Langås",
  "Langø",
  "Langødegard",
  "Langørgen",
  "Langøygard",
  "Lankin",
  "Lannem",
  "Lannon",
  "Lanoue",
  "Lanz",
  "Lanza",
  "Laon",
  "Lapaglia",
  "Lapin",
  "Laplant",
  "Lapp",
  "Lappegaard",
  "Larabee",
  "Laratt",
  "Lardy",
  "Lare",
  "Lares",
  "Laresen",
  "Largo",
  "Largy",
  "Lariby",
  "Lariviere",
  "Larkin",
  "LaRochelle",
  "Larrabe",
  "Lars",
  "Larsdattar",
  "Larsdatter",
  "Larsdatter Kirkeby",
  "Larsdatter Midthus",
  "Larsdatter Røvig",
  "Larsdhaugen",
  "larsdotter",
  "Larsdtr",
  "Larsdt",
  "Larsen",
  "Larsen Alfheim",
  "Larsen Bildenejet",
  "Larsen Oseth",
  "Larsen Røvig",
  "Larseng",
  "Larsgaard",
  "Larshagen",
  "Larshaugen",
  "Larshus",
  "Larskarihaugen",
  "Larsløkken",
  "Larson",
  "Larson Hallsete",
  "Larssen",
  "Larsslett",
  "Larsson",
  "Larsson Hallsette",
  "Larsstuen",
  "Larssveen",
  "Larud",
  "Larue",
  "Larwick",
  "Lash",
  "Lasier",
  "Lasken",
  "Laskey",
  "Lassen",
  "Lasson",
  "Lassstuen",
  "Lasstuen",
  "Lataski",
  "Lau",
  "Laube",
  "Laudenbach",
  "Laudert",
  "Lauer",
  "Laugnæs",
  "Laugsand",
  "Laugtug",
  "Laukvam",
  "Laumann",
  "Laumb",
  "Maagaard",
  "Maanstad",
  "Maanum",
  "Maartman",
  "Maarud",
  "Maasjo",
  "Maaum",
  "Maberg",
  "Mable",
  "Mabry",
  "Mac-Lean",
  "MacAulay",
  "MacDonald",
  "MacDougall",
  "Mace",
  "Macfarlane",
  "Macho",
  "Maciejny",
  "Macinnis",
  "Mack",
  "Mackey",
  "Mackie",
  "Macklette",
  "Maclaren",
  "Macois",
  "Madalene",
  "Maddens",
  "Maddock",
  "Maddox",
  "Madeiros",
  "Madich",
  "Madison",
  "Madland",
  "Madrid",
  "Madsberg",
  "Madsdatter",
  "Madsdtr",
  "Madsen",
  "Madshus",
  "Madslieie",
  "Madslien",
  "Madson",
  "Madssen",
  "Madsson",
  "Madsstuen",
  "Madssvebakken",
  "Madssveen",
  "Madssvehagen",
  "Mae",
  "Maed",
  "Maehlen",
  "Maelen",
  "Maerg",
  "Maes",
  "Mageland",
  "Magelee",
  "Mageli",
  "Magelihagen",
  "Magelihaugen",
  "Magelinesset",
  "Magelsen",
  "Magelssen",
  "Magenson",
  "Magenton",
  "Magerud",
  "Magerøy",
  "Magistad",
  "Magisås",
  "Magnesen",
  "Magnhildstuen",
  "Magnord",
  "Magnus",
  "Magnus Dotter",
  "Magnusdatter",
  "Magnusdtr",
  "Magnusen",
  "Magnushaugen",
  "Magnuson",
  "Magnussen",
  "Magnusson",
  "Magone",
  "Mahanna",
  "Mahel",
  "Mahen",
  "Mahitka",
  "Mahle",
  "Mahlen",
  "Mahlke",
  "Mahlum",
  "Mahoney",
  "Mai",
  "Maier",
  "Mailer",
  "Maines",
  "Mainz",
  "Mairich",
  "Maisel",
  "Major",
  "Majormoen",
  "Makela",
  "Maki",
  "Malach",
  "Maland",
  "Malavarca",
  "Malcom",
  "Malde",
  "Malenhaugen",
  "Maleno",
  "Malerbakken",
  "Malerløkken",
  "Malerud",
  "Malet",
  "Malick",
  "Malin",
  "Mallaug",
  "Mallaugshaug",
  "Mallaugstredet",
  "Maller",
  "Mallett",
  "Mallinckrodt",
  "Malling",
  "Mallory",
  "Mallos",
  "Malloy",
  "Malm",
  "Malmbakken",
  "Malmbekken",
  "Malmberg",
  "Malme",
  "Malmkvist",
  "Malmo",
  "Malmros",
  "Malmroth",
  "Malmstrom",
  "Malody",
  "Malone",
  "Malones",
  "Maloney",
  "Malonæs",
  "Maloug",
  "Malpezzi",
  "Malstrom",
  "Maltby",
  "Malterud",
  "Malthe",
  "Maltun",
  "Malungen",
  "Maly",
  "MaManus",
  "Mamen",
  "Manassesen",
  "Mancheski",
  "Mancin",
  "Mancini",
  "Mancl",
  "Mancuso",
  "Mandal",
  "Mandel",
  "Mandigo",
  "Manengen",
  "Manetsch",
  "Mangelsen",
  "Manger",
  "Mangerud",
  "Mangle",
  "Mangrud",
  "Mangset",
  "Mangsetlykkja",
  "Manion",
  "Manke",
  "Mankell",
  "Manley",
  "Mann",
  "Mannich",
  "Manning",
  "Mannstad",
  "Mansigh",
  "Manske",
  "Manson",
  "Manstad",
  "Manthei",
  "Manthey",
  "Mantz",
  "Manum",
  "Manz",
  "Marble",
  "Marcello",
  "March",
  "Marchand",
  "Marcino",
  "Marck",
  "Marcum",
  "Marcus",
  "Marcusdatter",
  "Marcussen",
  "Mardilda",
  "Mardorf",
  "Marenstua",
  "Marenstuen",
  "Maret",
  "Marg",
  "Margaret",
  "Margeria",
  "Margrete",
  "Margrethe",
  "Marianne",
  "Maribo",
  "Marie",
  "Mariendal",
  "Marila",
  "Marilyn",
  "Marine",
  "Marinsen",
  "Marinson",
  "Mariplass",
  "Maristad",
  "Marite",
  "Marjorie",
  "Mark",
  "Markaryd",
  "Marken",
  "Markeng",
  "Markengbakken",
  "Markengebakken",
  "Markengsløkken",
  "Markensbakken",
  "Markerud",
  "Markhart",
  "Markhus",
  "Marking",
  "Marko",
  "Marks",
  "Marksten",
  "Markstone",
  "Markusen",
  "Markushagen",
  "Markuson",
  "Markussen",
  "Marlene",
  "Marlosøygarden",
  "Marlow",
  "Marmon",
  "Maroney",
  "Marquardt",
  "Marquart",
  "Marquette",
  "Marquez",
  "Marr",
  "Marrier",
  "Marron",
  "Marschall",
  "Marsh",
  "Marshall",
  "Marshman",
  "Marson",
  "Marstad",
  "Marstein",
  "Marsteinsdokka",
  "Marsteinsdokken",
  "Marsteinstrædet",
  "Marsteintreet",
  "Marster",
  "Marstrand",
  "Martel",
  "Martell",
  "Marten",
  "Marteness",
  "Martens",
  "Martenson",
  "Martha",
  "Marthaler",
  "Marthinsen",
  "Marthinussen",
  "Marthisen",
  "N",
  "Naadland",
  "Naarstad",
  "Naas",
  "Naastad",
  "Nabben",
  "Nabset",
  "Nachtagal",
  "Naddumseter",
  "Nadumseter",
  "Naeseth",
  "Naesset",
  "Naessig",
  "Naetzle",
  "Nafset",
  "Nagel",
  "Nagelgaard",
  "Nageli",
  "Nagell",
  "Naglhus",
  "Naguib",
  "Nagy",
  "Nahrang",
  "Naill",
  "Najordet",
  "Nakanishi",
  "Nakhua",
  "Nakhuen",
  "Nakken",
  "Nakling",
  "Nakling15 11 1856",
  "Nannestad",
  "Nansen",
  "Nanstad",
  "Nape",
  "Napier",
  "Naresivicius",
  "Narholo",
  "Narjord",
  "Narlock",
  "Narmo",
  "Narud",
  "Narum",
  "Narum Beyer",
  "Narumshagen",
  "Narvesdatter",
  "Narvesdatter Klaegstad",
  "Narvesen",
  "Narvestad",
  "Nase",
  "Nash",
  "Nashaug",
  "Naslund",
  "Nason",
  "Nasset",
  "Nasvik",
  "Natland",
  "Natvig",
  "Natzel",
  "Natås",
  "Nauste",
  "Nauten",
  "Nautnes",
  "Nave",
  "Navelsaker",
  "Naverud",
  "Nawers",
  "Naylor",
  "Nborreson",
  "Nbøe",
  "Nccoy",
  "Neale",
  "Nebbe",
  "Nebbe-Bø",
  "Nebben",
  "Nebbeneset",
  "Nebbeneshaugen",
  "Nebelung",
  "Nebergall",
  "Neby",
  "Nebylien",
  "Necke",
  "Nederberg",
  "Nedgarden",
  "Nedland",
  "Nedrebø",
  "Nedrelid",
  "Nedrud",
  "nee Weinland",
  "Neeck",
  "Neegard",
  "Neeley",
  "Neels",
  "Neergaard",
  "Negaard",
  "Negaardseiet",
  "Negard",
  "Negley",
  "Negård",
  "Negårdshaugen",
  "Negårdssveen",
  "Neidfeldt",
  "Neifert",
  "Neilson",
  "Neiser",
  "Nellessen",
  "Nelly",
  "Nelson",
  "Nelson18 3 1939",
  "Nemes",
  "Nepløkken",
  "Nepomuceno",
  "Neprud",
  "Nepstad",
  "Nera Skimmeland",
  "Neraas",
  "Neraasen",
  "NeraSkimmeland",
  "Nerbråten",
  "Nerbu",
  "Nerby",
  "Nerbø",
  "Nerdal",
  "Nerdalen",
  "Nerdrum",
  "Nerfont",
  "Nergaard",
  "Nergaarden",
  "O'connell",
  "O'Conner",
  "O'connor",
  "O'dell",
  "O'malley",
  "O'Neal",
  "O'neil",
  "O'Neill",
  "O'rourke",
  "O'Shea",
  "O'toole",
  "Oa",
  "Oak",
  "Oakey",
  "Oakland",
  "Ochoa",
  "Ochsner",
  "Oclannd",
  "Odal",
  "Odde",
  "Odden",
  "Oddeneiet",
  "Oddernes",
  "Oddli",
  "Oddløkken",
  "Oddo",
  "Oddseter",
  "Oddsæter",
  "Oddsæther",
  "Ode",
  "Odeen",
  "Odegaard",
  "Odegaarden",
  "Odegard",
  "Odegarden",
  "Odell",
  "Oden",
  "Odenbakken",
  "Odeneie",
  "Odenhaugen",
  "Odenmoen",
  "Odenrud",
  "Odenrudeie",
  "Odenrudeiet",
  "Odenrudmoen",
  "Odenrudstrand",
  "Odenrudstranden",
  "Odenødegård",
  "Odermann",
  "Odgrimsen",
  "Odinsen",
  "Odland",
  "Odlaug",
  "Odlo",
  "Odlobråten",
  "Odlodokken",
  "Odloeier",
  "Odloeiet",
  "Odlohagen",
  "Odlohaugen",
  "Odlolien",
  "Odlomoen",
  "Odlorønningen",
  "Odlorønningnen",
  "Odlosveen",
  "Odnes",
  "Odnessveen",
  "Oe",
  "Oehlenschläger",
  "Oelien",
  "Oelson",
  "Oerød",
  "Oesteraas",
  "Oestvang",
  "of Flanderen",
  "of Italy",
  "of Normandy",
  "of Senlis",
  "Offendahl",
  "Offerdal",
  "Offermann",
  "Offigstad",
  "Offigstadhaugen",
  "Offigstadstuen",
  "Offigstadødegard",
  "Offigstadødegården",
  "Offte",
  "Offutt",
  "Ofigsbø",
  "Ofrim",
  "Ofstad",
  "Ofstedalshaugen",
  "Oftedal",
  "Ogle",
  "Ogmundson Giske",
  "Ogren",
  "Ohman",
  "Ohnemur",
  "Ohnstad",
  "Ohre",
  "Ohren",
  "Ohrvik",
  "Oien",
  "Oistad",
  "Oium",
  "Paalsdtr",
  "Paalsrud",
  "Paasche",
  "Paaske",
  "Pabian",
  "Pace",
  "Pachernigg",
  "Pack",
  "Packer",
  "Packernigg",
  "Packineau",
  "Padesky",
  "Padrucco",
  "Paes",
  "Page",
  "Pagels",
  "Paguette",
  "Paigne",
  "Painow",
  "Painter",
  "Paisley",
  "Palazzari",
  "Palecek",
  "Palken",
  "Pallesen",
  "Pallsveen",
  "Palm",
  "Palmer",
  "Palmroth",
  "Palms",
  "Palmstrøm",
  "Palsen",
  "Paludan",
  "Palur",
  "Pam",
  "Pancich",
  "Pangh",
  "Pankowski",
  "Panopolos",
  "Pantaleo",
  "Panyan",
  "Papp",
  "Paradise",
  "Parent",
  "Parghi",
  "Parins",
  "Paris",
  "Parish",
  "Parissi",
  "Park",
  "Parkenson",
  "Parker",
  "Parkin",
  "Parks",
  "Parkvold",
  "Parnel",
  "Parow",
  "Parr",
  "Parrot",
  "Parry",
  "Parsley",
  "Parsons",
  "Partridge",
  "Parvey",
  "Pasche",
  "Pasnick",
  "Pass",
  "Pastor",
  "Pat",
  "Paterson",
  "Patnode",
  "Patoy",
  "Patraw",
  "Patricia",
  "Patrick",
  "Patrow",
  "Patsy",
  "Patterson",
  "Pattison",
  "Patton",
  "Paukama",
  "Paul",
  "Paula",
  "Pauleen",
  "Pauley",
  "Paulhaugen",
  "Paulhus",
  "Pauline",
  "Paulley",
  "Paulsdattar",
  "Paulsdatter",
  "Paulsen",
  "Paulshus",
  "Paulsjorden",
  "Paulson",
  "Paulsrud",
  "Paulsrudbakken",
  "Paulsrude",
  "Paulsrudmoen",
  "Paulus",
  "Pauly",
  "Paureng",
  "Paus",
  "Paust",
  "Pavels",
  "Paver",
  "Pavlas",
  "Pavlisich",
  "Pawelka",
  "Pawelski",
  "Quaale",
  "Quackenbusk",
  "Quaile",
  "Quale",
  "Quall",
  "Qually",
  "Quammen",
  "Rnning",
  "Raa",
  "Raabe",
  "Raabel",
  "Raabye",
  "Raad",
  "Raade",
  "Raadim",
  "Raaen",
  "Raanaabrækken",
  "Raanaas",
  "Raanes",
  "Raastad",
  "Raaum",
  "Rabben",
  "Rabby",
  "Rabensøe",
  "Rabstad",
  "Rachlew",
  "Racine",
  "Radant",
  "Radatz",
  "Radde",
  "Rader",
  "Radford",
  "Radke",
  "Radtke",
  "Raen",
  "Raether",
  "Rafaelsdatter",
  "Rafn",
  "Rafuse",
  "Ragan",
  "Raggad",
  "Ragna",
  "Ragnes",
  "Ragnhildsrud",
  "Ragnvaldsdtr",
  "Ragnvaldson Jarl",
  "Ragnvaldsson Orkneyjarl",
  "Rahn",
  "Raif",
  "Rain",
  "Rainero",
  "Raitz von Frentz",
  "Raivo",
  "Rakkestad",
  "Raknerud",
  "Rakstad",
  "Ralford",
  "Ralle",
  "Rallo",
  "Ralph",
  "Rambech",
  "Rambek",
  "Ramberg",
  "Rambo",
  "Rambur",
  "Rambøl",
  "Ramey",
  "Ramirez",
  "Ramlo",
  "Ramløv",
  "Ramme",
  "Ramsay",
  "Ramsberg",
  "Ramsdell",
  "Ramse",
  "Ramseth",
  "Ramsett",
  "Ramsey",
  "Ramshart",
  "Ramsland",
  "Ramsli",
  "Ramslo",
  "Ramstad",
  "Ramstad Dahl",
  "Ramstead",
  "Ramsund",
  "Ramsø",
  "Ramsøe",
  "Rand",
  "Randaberg",
  "Randall",
  "Randby",
  "Randell",
  "Randen",
  "Randers",
  "Randgård",
  "Randheim",
  "Randkleiv",
  "Randkleivsbakken",
  "Randkleivsdalen",
  "Randkleivsdokken",
  "Randkleivseie",
  "Randkleivseier",
  "Randkleivseiet",
  "Randkleivshaugen",
  "Randkleivsnesset",
  "Randkleivsrønningen",
  "Randkleivssveen",
  "Randkleivssviken",
  "Randkleivsviken",
  "Randkleivsånna",
  "Randklev",
  "Randklevsbakken",
  "Randklevsdokken",
  "Randle",
  "Randmæle",
  "Randolph",
  "Randsted",
  "Randstedmoen",
  "Randulf",
  "Raneim",
  "RAngard",
  "Ranger",
  "Rangnes",
  "Rangul",
  "Ranheim",
  "Rank",
  "Ranke",
  "Rankin",
  "Ranney",
  "Rannstad",
  "Rannveigson På Aurland",
  "Ranseen",
  "Ranson",
  "Ranstrom",
  "Rantala",
  "Rantanen",
  "Rantzau-Bøckmann",
  "Ranum",
  "Ranvik",
  "Raphan",
  "Rappel",
  "Rappen",
  "Rapstad",
  "Rasbek",
  "Rasch",
  "Rasen",
  "Rasley",
  "Rasmusdatter",
  "Rasmusen",
  "Rasmusgaard",
  "Rasmuslien",
  "Rasmussen",
  "Rasmusson",
  "Rasmusstuen",
  "Rasta",
  "Rasten",
  "Rastesen",
  "Rath",
  "Rathbone",
  "Rathbun",
  "Rathe",
  "Rathje",
  "Raths",
  "Rattfeldt",
  "Rau",
  "Raubakken",
  "Raud",
  "Raun",
  "Rausand",
  "Rausch",
  "Ravenberg",
  "Ravensborg",
  "Ravn",
  "Ravneberg",
  "Ravnum",
  "Ravnumeie",
  "Raw",
  "Rawlings",
  "Ray",
  "Rayl",
  "Raymond",
  "Read",
  "Reader",
  "Ready",
  "Reagan",
  "Reamer",
  "Rear",
  "Reardon",
  "Reavers",
  "Reber",
  "Rebikka",
  "Redalen",
  "Redalen96",
  "Redlende",
  "Redmon",
  "Redmond",
  "Redom",
  "Redstone",
  "Ree",
  "Ree-Eie",
  "Reed",
  "Reeder",
  "Reefer",
  "Reenaas",
  "Rees",
  "Reese",
  "Reesman",
  "Reeves",
  "Refling",
  "Refling-Hagen",
  "Refnes",
  "Refsdahl",
  "Refseth",
  "Refsholen",
  "Refsum",
  "Refvik",
  "Regan",
  "Regelstad",
  "Regenwaldsdtr",
  "Regeway",
  "Regier",
  "Reginastuen",
  "Regland",
  "Rehbein",
  "Reher",
  "Rehm",
  "Reich",
  "Reiche",
  "Reichel",
  "Reichenwald",
  "Reichett",
  "Reid",
  "Reidarsdatter",
  "Reidarsen",
  "Reidarson fra Gimsar",
  "Reidburn",
  "Reien",
  "Reiersdt",
  "Reiersen",
  "Reiersgård",
  "Reierstøl",
  "Svik",
  "Saas",
  "Saastad",
  "Sabby",
  "Sabe",
  "Sabi",
  "Sabila",
  "Sabin",
  "Sabo",
  "Saboe",
  "Sacher",
  "Sackfield",
  "Sacrider",
  "Sadaraka",
  "Sadek",
  "Sadelmaker",
  "Sadie",
  "Sadler",
  "Sady",
  "Saeger",
  "Saeter",
  "Saetre",
  "Safberg",
  "Saffer",
  "Sagart",
  "Sagbakken",
  "Sagboden",
  "Sagdal",
  "Sagdalen",
  "Sage",
  "Sagen",
  "Sageng",
  "Sagflaten",
  "Saghagen",
  "Saghaug",
  "Saghaugen",
  "Sagheim",
  "Sagli",
  "Sagmoen",
  "Sagodden",
  "Sagranden",
  "Sagrusten",
  "Sagsletten",
  "Sagstuen",
  "Sagsveen",
  "Saign",
  "Sakariasdtr",
  "Sakariasen",
  "Sakariassen",
  "Sakariasson",
  "Sakesgard",
  "Sakker",
  "Sakshaug",
  "Saksrud",
  "Saksum",
  "Saksvoll",
  "Salbakk",
  "Salberg",
  "Salbuvik",
  "Salhus",
  "Saling",
  "Salisbury",
  "Saljordet",
  "Salle",
  "Salmakerenget",
  "Salmakermoen",
  "Salmakerstuen",
  "Salo",
  "Salomonsen",
  "Salomonstuen",
  "Salte",
  "Saltee",
  "Salter",
  "Salthammer",
  "Saltnes",
  "Saltvedt",
  "Salvesen",
  "Salzman",
  "Samb",
  "Sambu",
  "Samir",
  "Sammeieen",
  "Sammons",
  "Sample",
  "Sampson",
  "Samrud",
  "Samsahl",
  "Samson",
  "Samuelsdt",
  "Samuelsen",
  "Samuelshaugen",
  "Samuelson",
  "Samuelstuen",
  "Samulelson",
  "Samulson",
  "Sanbo",
  "Sanborn",
  "Sanbumoen",
  "Sanchez",
  "Sand",
  "Sanda",
  "Sandager",
  "Sandagereiet",
  "Sandagerjordet",
  "Sandaker",
  "Sandal",
  "Sandanger",
  "Sandaune",
  "Sandbakken",
  "Sandbeck",
  "Sandbeg",
  "Sandbekken",
  "Sandberg",
  "Sandbo",
  "Sandbosveen",
  "Sandbotten",
  "Sandbu",
  "Sandbubrekken",
  "Sandbueie",
  "Sandbueiet",
  "Sandbuhaugen",
  "Sandbuløkken",
  "Sandbumoen",
  "Sandbuplassen",
  "Sandburønningen",
  "Sandbushagen",
  "Sandbushaugen",
  "Sandbusløkken",
  "Sandbusveen",
  "Sandbusønstebø",
  "Sandbusøyen",
  "Sandbuvangen",
  "Sandbæk",
  "Sande",
  "Sandebakken",
  "Sandeen",
  "Sandeggen",
  "Sandelin",
  "Sandell",
  "Sandem",
  "Sandemo",
  "Sanden",
  "Sandengen",
  "Sander",
  "Sanders",
  "Sandersen",
  "Sanderson",
  "Sanderud",
  "Sandgro",
  "Sandhaugen",
  "Sandholt",
  "Sandig",
  "Sanding",
  "Sandkjosmo",
  "Sandli",
  "Sandlund",
  "Sandmel",
  "Sandnebben",
  "Sandnes",
  "Sandneseie",
  "Sandnesenget",
  "Sandneshagen",
  "Sando",
  "Sandodden",
  "Sandom",
  "Sandrud",
  "Sandrum",
  "Sands",
  "Sandsbusløkken",
  "Sandseie",
  "Sandsengen",
  "Sandsmark",
  "Sandsrumpen",
  "Sandstad",
  "Sandstedt",
  "Sandstrom",
  "Sandstuen",
  "Sandsundet",
  "Sandum",
  "Sandvatn",
  "Sandve",
  "Sandver",
  "Sandvig",
  "Sandvigen",
  "Sandvik",
  "Sandviken",
  "Sandviksberget",
  "Sandviksjordet",
  "Sandvikskleven",
  "Sandviksmoen",
  "Sandvold",
  "Sandvoll",
  "Sandwatin",
  "Sandy",
  "Sandø",
  "Sandøy",
  "Sanford",
  "Sann",
  "Sannan",
  "Sanne",
  "Sannem",
  "Sannerud",
  "Sannes",
  "Sansom",
  "Santee",
  "Santhi",
  "Santiago",
  "Santin",
  "Santoro",
  "Santos",
  "Sanvik",
  "Sapucaia",
  "Sapuppo",
  "Sargeant",
  "Sargent",
  "Sartain",
  "Sartorelli",
  "Sasen",
  "Sassong",
  "Saszik",
  "Sather",
  "Satherlie",
  "Sathre",
  "Satre",
  "Satrom",
  "Satter",
  "Sauarlia",
  "Sauby",
  "Saude",
  "Saudland",
  "Sauer",
  "Saug",
  "Saugeie",
  "Saugstad",
  "Saugstuen",
  "Saunders",
  "Saupstad",
  "Saur",
  "Saurdiff",
  "Saure",
  "Saurer",
  "Saus",
  "Sausker",
  "Sauter",
  "Sauve",
  "Sauvie",
  "Sauwogeau",
  "Savella",
  "Savers",
  "Sawvell",
  "Sawyer",
  "Saxe",
  "Saxlundeie",
  "Saxum",
  "Sayles",
  "Scales",
  "Scanlon",
  "Schaaf",
  "Schaaning",
  "Schacko",
  "Schaefer",
  "Schaeffer",
  "Schafer",
  "Schaffer",
  "Schaft",
  "Schafter",
  "Schailtel",
  "Schalk",
  "Schall",
  "Schallock",
  "Schammer Swan",
  "Schanche",
  "Schancke",
  "Schandorff",
  "Schanke",
  "Schanzenbach",
  "Scharbillig",
  "Scharfer",
  "Scharffenberg",
  "Scharrer",
  "Scharschow",
  "Schatz",
  "Schau",
  "Schauer",
  "Schauerud",
  "Schaufuss",
  "Schaufuß",
  "Schauler",
  "Schaunaman",
  "Scheck",
  "Schecky",
  "Scheel",
  "Scheer",
  "Scheflo",
  "Schei",
  "Scheibler",
  "Scheidler",
  "Scheie",
  "Scheiwiller",
  "Schelderup",
  "Schelling",
  "Schelvan",
  "Schelvig",
  "Schemp",
  "Schendel",
  "Schenk",
  "Schenkey",
  "Schepp",
  "Schepper",
  "Scherer",
  "Schermerhorn",
  "Scherpen",
  "Schetlein",
  "Scheu",
  "Schia",
  "Schibbye",
  "Schibsted",
  "Schie",
  "Schiefloe",
  "Schiele",
  "Schiell",
  "Schieren",
  "Schieve",
  "Schiff",
  "Schilke",
  "Schilling",
  "Schinnes",
  "Schinrud",
  "Schipke",
  "Schirenbeck",
  "Schirk",
  "Schirmer",
  "Schistad",
  "Schive",
  "Schiver",
  "Schiøller",
  "Schiørstad",
  "Schjager",
  "Schjelderudp",
  "Schjelderup",
  "Schjeldrup",
  "Schjelle",
  "Schjermann",
  "Schjerpen",
  "Schjerven",
  "Schjervik",
  "Schjøll",
  "Schjønsby",
  "Schjørn",
  "Schjøtt-Revers",
  "Schlacter",
  "Schlecht",
  "Schledewitz",
  "Schleeter",
  "Schlegel",
  "Schleger",
  "Schleicher",
  "Schlener",
  "Schlenker",
  "Schlesselman",
  "Schletty",
  "Schlich",
  "Schlicker",
  "Schlief",
  "Schlipphack",
  "Schlosser",
  "Schlueter",
  "Schlüter",
  "Schlytter",
  "Schlösser",
  "Schmandt",
  "Schmaus",
  "Schmedling",
  "Schmid",
  "Schmidt",
  "Schmidtz",
  "Schmit",
  "Schmitt",
  "Schmitz",
  "Schnaetzer",
  "Schneider",
  "Schneider Roberts",
  "Schnell",
  "Schnmabel",
  "Schoeberle",
  "Schoeler",
  "Schoeller",
  "Schoen",
  "Schoenbecker",
  "Schoenberg",
  "Schoenecker",
  "Schoenrock",
  "Scholdager",
  "Scholden",
  "Scholer",
  "Scholl",
  "Scholler",
  "Scholze",
  "Schomaker",
  "Schomburg",
  "Schommer",
  "Schonhowd",
  "Schonsby",
  "Schoolcraft",
  "Schotnes",
  "Schou",
  "Schou-Eriksen",
  "Schouberg",
  "Schoville",
  "Schow",
  "Schrader",
  "Schragmüller",
  "Schram",
  "Schramm",
  "Schrank",
  "Schredder",
  "Schreiber",
  "Schreiner",
  "Schreuder",
  "Schribner",
  "Schriver",
  "Schroder",
  "Schroeder",
  "Schroeter",
  "Schroman",
  "Schröder",
  "Schrøder",
  "Schrøder-Nielsen",
  "Schrøder-Nilsen",
  "Schubert",
  "Schue",
  "Schueler",
  "Schueller",
  "Schuenmann",
  "Schuetz",
  "Schuhmann",
  "Schuknecht",
  "Schulerud",
  "Schulhus",
  "Schullets",
  "Schulstad",
  "Schult",
  "Schulte",
  "Schultz",
  "Schulus",
  "Schulz",
  "Schulze",
  "Schumacher",
  "Schuman",
  "Schumann",
  "Schumann Andersen",
  "Schumeister",
  "Schunk",
  "Schupp",
  "Schuster",
  "Schutemier",
  "Schutz",
  "Schvendi",
  "Schwabe",
  "Schwager",
  "Schwanke",
  "Schwanner",
  "Schwartz",
  "Schwarz",
  "Schwarzburg",
  "Schwarzkof",
  "Schwecht",
  "Schweigert",
  "Schweinsberger",
  "Schweiss",
  "Schweitzer",
  "Schwencke",
  "Schwendemann",
  "Schwenig",
  "Schwenin",
  "Schwichenberg",
  "Schyberg",
  "Schye",
  "Schytte",
  "Schäfer",
  "Schøller",
  "Schønheyder",
  "Schøyen",
  "Sclosser",
  "Scorannazzi",
  "Score",
  "Scott",
  "Scroggins",
  "Scroggs",
  "Scudder",
  "Scully",
  "Scult-Jenssen",
  "Sculthorpe",
  "Scveingard",
  "Scwandt",
  "Sea",
  "Seaba",
  "Seager",
  "Seales",
  "Seaman",
  "Searcy",
  "Searle",
  "Searles",
  "Seath",
  "Seaver",
  "Sebion",
  "Sebu",
  "Sebusæter",
  "Seckara",
  "Sedahl",
  "Sedal",
  "Sedsvold",
  "Seeberg",
  "Seede",
  "Seegård",
  "Seehusen",
  "Seehuusen",
  "Seeley",
  "Seeman",
  "Seffens",
  "Sefring",
  "Segaard",
  "Segado",
  "Segaran",
  "Segelstad",
  "Segelstadbakken",
  "Segelstaddalen",
  "Segelstadeie",
  "Segelstadeiee",
  "Segelstadeier",
  "Segelstadeiet",
  "Segelstadlien",
  "Segelstadløkken",
  "Segelstadmoen",
  "Segelstadsveen",
  "Segla",
  "Seglem",
  "Sehlie",
  "Sehlstrøm",
  "Seidel",
  "Seidelin",
  "Seidl",
  "Seidler",
  "Seielstad",
  "Seier",
  "Seieroe",
  "Seierstad",
  "Seigler",
  "Seim",
  "Seip",
  "Seippel",
  "Sejerstad",
  "Sekel",
  "Sekkenes",
  "Sekse",
  "Sektnan",
  "Sel",
  "Seland",
  "Selberg",
  "Selbulonsen",
  "Selen",
  "Self",
  "Selfjord",
  "Selfors",
  "Selin",
  "Selinger",
  "Seljevold",
  "Seljord",
  "Selke",
  "Sell",
  "Selle",
  "Selleg",
  "Sellereite",
  "Sellevold",
  "Sellstrøm",
  "Sellwood",
  "Selmer",
  "Selmo",
  "Selmonson",
  "Selnes",
  "Selsjord",
  "Selsjordet",
  "Selsmo",
  "Selstad",
  "Selsverket",
  "Selvaag",
  "Selvnes",
  "Sem",
  "Semb",
  "Semeleng",
  "Semingsdtr",
  "Semingsen",
  "Semmen",
  "Semmens",
  "Semmingsen",
  "Semrau",
  "Senasac",
  "Sendrésen",
  "Sendsjøviken",
  "Senecal",
  "Senger",
  "Sennechen",
  "Senneseth",
  "Sennett",
  "Senstad",
  "Sentell",
  "Senum",
  "Senyk",
  "Senzel",
  "Septon",
  "Serfling",
  "Sergej",
  "Servais",
  "Sesamie",
  "Sesbø",
  "Sesvold",
  "Seter",
  "Seterdal",
  "Setersdalen",
  "Seterøy",
  "Sether",
  "Sethern",
  "Sethre",
  "Setler",
  "Setnes",
  "Taagen",
  "Taaget",
  "Tabako",
  "Tabbert",
  "Taber",
  "Tacket",
  "Taft",
  "Taftøy",
  "Tagesdatter",
  "Tagestad",
  "Tagestadbakken",
  "Tagestadeie",
  "Tagestadhagen",
  "Tagestadløkken",
  "Taggart",
  "Taggert",
  "Tagh",
  "Tagne",
  "Taie",
  "Taitaque",
  "Tajet",
  "Takseth",
  "Takvam",
  "Talbott",
  "Talford",
  "Talgje",
  "Tallakson",
  "Tallaug",
  "Tallaugeie",
  "Talleraas",
  "Tallerås",
  "Talley",
  "Tallhaugbakken",
  "Tallos",
  "Tallås",
  "Talnes",
  "Talseth",
  "Talvik",
  "Tambarskjelve Lade",
  "Tamberstuen",
  "Tambrustuen",
  "Tamburhaugen",
  "Tamburstuen",
  "Tammen",
  "Tammy",
  "Tanch",
  "Tanche",
  "Tandberg",
  "Tande",
  "Tandehagen",
  "Tandehaugen",
  "Tandeløkken",
  "Tandhaugen",
  "Tanem",
  "Tang",
  "Tangen",
  "Tangerud",
  "Tangnæss",
  "Tangrud",
  "Tangvoll",
  "Tank",
  "Tannaaneset",
  "Tannes",
  "Tannfetten",
  "Tannhagen",
  "Tannvik",
  "Tansley",
  "Tape",
  "Tara",
  "Taraldhagen",
  "Taraldsen",
  "Taraldshaugen",
  "Taraldsrud",
  "Taraldsstuen",
  "Tarara",
  "Taraskiewicz",
  "Tarbet",
  "Tarbox",
  "Tarhow",
  "Tarillion",
  "Tarnowski",
  "Tarpley",
  "Tarr",
  "Tarud",
  "Taskilla",
  "Tastad",
  "Tate",
  "Tatley",
  "Tatlow",
  "Tatman",
  "Tauentzin",
  "Taug",
  "Taugland",
  "Taugløkken",
  "Tausan",
  "Tautges",
  "Tautra",
  "Tax",
  "Taxeraas",
  "Taxt",
  "Taylor",
  "Tchida",
  "Teague",
  "Teare",
  "Teel",
  "Tegen",
  "Tegtmeier",
  "Teich",
  "Teidt",
  "Teien",
  "Teig",
  "Teige",
  "Teigeiet",
  "Teigen",
  "Teigestuen",
  "Teighagen",
  "Teigland",
  "Teigmoen",
  "Teigmorken",
  "Teigoyen",
  "Teigplassen",
  "Teigstuen",
  "Teigum",
  "Teigøien",
  "Teigøyen",
  "Teinlagsveen",
  "Teiset",
  "Tek",
  "Tekset",
  "Teksum",
  "Telebond",
  "Telep",
  "Telle",
  "Tellner",
  "Temmen",
  "Temple",
  "Templin",
  "Temte",
  "Temåsås",
  "Tenbensel",
  "Tenborg",
  "Tenbusschen",
  "Tengesdal",
  "Tengley",
  "Tengs",
  "Tennebekk",
  "Tennebekk-Beyer",
  "Tennebø",
  "Tenneson",
  "Tennet",
  "Tennis",
  "Tennvassåsen",
  "Tenold",
  "Teodorsen",
  "Teppa",
  "Teresa",
  "Terhune",
  "Terjesen",
  "Terp",
  "Terry",
  "Tesdal",
  "Teska",
  "Teske",
  "Tesli",
  "Teslow",
  "Tesnow",
  "Tessand",
  "Tessar",
  "Tesse",
  "Tessebøll",
  "Tessem",
  "Tessin",
  "Tessmer",
  "Tessnes",
  "Tessneset",
  "Tessnesset",
  "Testart",
  "Tetlie",
  "Tetrault",
  "Tett",
  "Tetterud",
  "Tetzloff",
  "Teuke",
  "Teveldal",
  "Tews",
  "Thackeray",
  "Thaemert",
  "Thain",
  "Thallaug",
  "Thallaugeie",
  "Thallaugeiet",
  "Thallaugringen",
  "Thams",
  "Thanum",
  "Tharaldsen",
  "Tharaldson",
  "Tharaldsteen",
  "Thauberger",
  "Thaulov",
  "Thaulow",
  "Thayer",
  "Thede",
  "Theige",
  "Theigen",
  "Theil",
  "Theilen",
  "Theilmann",
  "Theis",
  "Theiset",
  "Theissen",
  "Thelen",
  "Thelma",
  "Themistocleous",
  "Thenk",
  "Theodorsen",
  "Uasar",
  "Udal",
  "Uehlin",
  "Ueland",
  "Ufer",
  "Ufs",
  "Ufsløk",
  "Ugdal",
  "Ugland",
  "Uglestad",
  "Uglow",
  "Uhlen",
  "ukjent",
  "Ulakleiv",
  "Uland",
  "Ulateig",
  "Ulateigløkken",
  "Ulateigsløkken",
  "Ulberg",
  "Ulbergeiet",
  "Uldalen",
  "Uldalslien",
  "Uleberg",
  "Ulekleiv",
  "Ulekleivsholsen",
  "Ulem",
  "Ulen",
  "Uleteigsløkken",
  "Ulevolden",
  "Ulff",
  "Ulfnes",
  "Ulfsbol",
  "Ulheim",
  "V Magnusson",
  "Vaa",
  "Vaag",
  "Vaagan",
  "Vaage",
  "Vaaleneiet",
  "Vaaten",
  "Vabakken",
  "Vabø",
  "Vadal",
  "Vadet",
  "Vadseth",
  "Vadstein",
  "Vaet",
  "Vaggesten",
  "Vahl",
  "Vaide",
  "Vail",
  "Valaaker",
  "Valadez",
  "Valaker",
  "Valamo",
  "Valan",
  "Valaske",
  "Valberg",
  "Valbergsløkken",
  "Valbjørg",
  "Valbjørgsdokken",
  "Valbjørgseie",
  "Valbjørgsløkken",
  "Valby",
  "Valde",
  "Valdeeie",
  "Valdeiet",
  "Valdemarsdtr",
  "Valdemarsen",
  "Valdersvær",
  "Valdeøygarden",
  "Valdresmoen",
  "Valemshaugen",
  "Valen",
  "Valencia",
  "Valentine",
  "Valentinsen",
  "Valentinstuen",
  "Valeree",
  "Valerius",
  "Valestrand",
  "Valla",
  "Vallberg",
  "Valle",
  "Valleraune",
  "Valley",
  "Vallumstuen",
  "Valnum",
  "Valseth",
  "Valstad",
  "Valsveen",
  "Valumshaugen",
  "Valumstuen",
  "Valvatne",
  "Valås",
  "Vambheim",
  "Vammer",
  "Vammervold",
  "Van",
  "Van Akkeren",
  "van Amber",
  "Van Antwerp",
  "Van Arnum",
  "Van Ausdall",
  "Van Brunt",
  "Van Camp",
  "Van Curen",
  "van der Voort",
  "Van Der Wel",
  "van der Werff",
  "Van Dermeer",
  "VAN Dyke",
  "Van Dyyken",
  "Van Fleet",
  "van Heek",
  "Van Helden",
  "Van Impe",
  "Van Kleek",
  "Van Kortie",
  "Van Lieshout",
  "Van Meirheige",
  "VAN Ness",
  "Van Order",
  "Van Pelt",
  "Van Riper",
  "Van Sleet",
  "van Vooren",
  "van Wallegham",
  "van Walleghem",
  "van Wesel",
  "Vanbinsbergen",
  "Vanderline",
  "Vandyck",
  "Vandyke",
  "Vanebu",
  "Vang",
  "Vangberg",
  "Vange",
  "Vangen",
  "Vangensten",
  "Vanghagen",
  "Vangnes",
  "Vangsletten",
  "Vangslien",
  "Vangsmo",
  "Vangsnes",
  "Vangstangen",
  "Vangstrøn",
  "Vangstuen",
  "Vaniske",
  "Vannebo",
  "Vansickle",
  "Varaberg",
  "Vardbergseiet",
  "Vardeberg",
  "Vardenær",
  "Vardsveen",
  "Varem",
  "Varg",
  "Varholdt",
  "Varhus",
  "Varnes",
  "Varness",
  "Varney",
  "Varns",
  "Varpestuen",
  "Varphaugen",
  "Varpstuen",
  "Varteig",
  "Vasara",
  "Vasare",
  "Vasaunet",
  "Vasdal",
  "Vasicek",
  "Vaslieneie",
  "Vasrud",
  "Vasrustløkken",
  "Vassbakken",
  "Vassbotn",
  "Vassdokken",
  "Vassend",
  "Vassenden",
  "Vasshaug",
  "Vasshus",
  "Vassnes",
  "Vassplassen",
  "Vassrusten",
  "Vassrustsletten",
  "Waade",
  "Waadeland",
  "Waag",
  "Waage",
  "Waagø",
  "Waal",
  "Waale",
  "Waalehaugen",
  "Waalen",
  "Waaler",
  "Waalmann",
  "Waarum",
  "Wacher",
  "Wacker",
  "Wad",
  "Wadahl",
  "Wadal",
  "Waddell",
  "Waddle",
  "Wade",
  "Wadekamper",
  "Wadel",
  "Wadsworth",
  "Waggone",
  "Waglen",
  "Wagner",
  "Waha",
  "Wahl",
  "Wahlberg",
  "Wahlgren",
  "Wahlmann",
  "Wahlquist",
  "Wahr-Hansen",
  "Wainwright",
  "Wairg",
  "Waks",
  "Walberg",
  "Walbø",
  "Walch",
  "Wald",
  "Waldeck",
  "Walden",
  "Walderaune",
  "Walderhaug",
  "Walders",
  "Waldron",
  "Waldum",
  "Waldun",
  "Waldusky",
  "Walen",
  "Walhovd",
  "Walkama",
  "Walkenhorst",
  "Walker",
  "Wall",
  "Wallace",
  "Wallander",
  "Walle",
  "Wallem",
  "Wallen",
  "Wallene",
  "Waller",
  "Walleser",
  "Wallgren",
  "Wallin",
  "Wallis",
  "Wallmark",
  "Wallsten",
  "Wallumstuen",
  "Walmsnes",
  "Walrath",
  "Walsh",
  "Walson",
  "Walstad",
  "Walstrand",
  "Walstrøm",
  "Walter",
  "Walters",
  "Walters Nilsen",
  "Walthon",
  "Walton",
  "Walvatne",
  "Wambheim",
  "Wamhoff",
  "Wammervold",
  "Wampstad",
  "Wanda",
  "Wandal",
  "Wanderaas",
  "Wanderås",
  "Wang",
  "Wang-Hansen",
  "Wangberg",
  "Wangen",
  "Wangensteen",
  "Wangensten",
  "Wangrud",
  "Wangsoness",
  "Wanless",
  "Wannebo",
  "Wanner",
  "Wannigman",
  "Wanvig",
  "Wanvik",
  "Ward",
  "Warden",
  "Warder",
  "Wardum",
  "Wareberg",
  "Warhol",
  "Warig",
  "Warmuth",
  "Warner",
  "Warnier",
  "Warren",
  "Warrendorf",
  "Warshauer",
  "Wartelle",
  "Warwich",
  "Wasberg",
  "Wasenius",
  "Washburn",
  "Xstensdatter",
  "Yaddof",
  "Yaeger",
  "Yahnke",
  "Yakata",
  "Yakey",
  "Yancey",
  "Yandessel",
  "Yanke",
  "Yarger",
  "Yarick",
  "Zaar",
  "Zabel",
  "Zabka",
  "Zach",
  "Zachariassen",
  "Zacharies",
  "Zachary",
  "Zachow",
  "Zahl",
  "Zahn",
  "Zakariasdatter",
  "Zakariasen",
  "Zakariassen",
  "Zako",
  "Zalenski",
  "Zalusky",
  "Åbakken",
  "Åberg",
  "Ådalen",
  "Ådnanesdtr Risvik",
  "Ådnes",
  "Åen",
  "Åfenghagen",
  "Ågren",
  "Ågristad",
  "Ågård",
  "Åhman",
  "Åkerjordet",
  "Åkermoen",
  "Åkervik",
  "Åkildbakken",
  "Åkvik",
  "Ålborg",
  "Ålefjær",
  "Ålkitrud",
  "Ålseth",
  "Ålstad",
  "Åløkken",
  "Ødegaard",
  "Ødegaarden",
  "Ødegaardshaugen",
  "Ødegard",
  "Ødegård",
  "Ødegården",
  "Ødegårdsberget",
  "Ødegårdsflaten",
  "Ødegårdshagen",
  "Ødegårdshaugen",
  "Ødegårdsjordet",
  "Ødegårdsstuen",
  "Ødegårdssveen",
  "Ødegårdstuen",
  "Ødejordet",
  "Ødeli",
  "Ødemoen",
  "Ødemoringen",
  "Ødemyr",
  "Ødenmoen",
  "Øderud",
  "Øfeldt",
  "Øfstdahl",
  "Øfstedahl",
  "Øfstedal",
  "Øfstegaard",
  "Øfstegard",
  "Øfstegardshagen",
  "Øfstegård",
  "Øfstegårdsjordet",
  "Øfstegårdstykket",
  "Øfstehage",
  "Øfstehagen",
  "Øfsteng",
  "Øfstgard",
  "Øfstgård",
  "Øfstgårdkleven",
  "Øfstgårdskleven",
  "Øfstgårdsrønningen",
  "Øfstgårdsstykket",
  "Øfsti",
  "Øfstsgard",
  "Øgarden",
  "Øgle",
  "Øhrn",
  "Øi",
  "Øian",
  "Øiastuen",
];

export const regionAddresses = {
  OSLO: addresses,
  BERGEN: bergenAddresses,
};

export const senderCompanies = [
  "Komplett",
  "Clas Ohlson",
  "Fjellsport.no",
  "GolfShopen.no",
  "Jernia",
  "Coop Obs!",
  "Eplehuet",
  "Farmasiet",
  "Snus.com",
  "SnusHjem.no",
  "Snus lageret",
  "Barnas hus",
  "Villoid",
  "Farner Jacobsen",
  "WoolLand",
  "Barbershop.no",
  "byTiMo",
  "Fighter Sport",
  "sapatos",
  "playfinity",
  "blome",
  "Moniker",
  "KAFFAbutikk",
  "TeleOutlet",
  "Bystrikk",
  "Junkyard",
  "blush",
];
