export function getAuthHeadersCookie() {
  const value = "; " + document.cookie;
  const parts = value.split("; authHeaders_order-generator=");

  if (parts.length === 2) {
    return parts
      .pop()
      .split(";")
      .shift();
  }

  return null;
}

export function setAuthHeadersCookie(
  sessionToken,
  email,
  environment
) {
  const cookie = {
    sessionToken: sessionToken,
    uid: email,
    environment: environment
  };

  let date = new Date();
  date.setDate(date.getDate() + 14);

  document.cookie =
    "authHeaders_order-generator=" +
    JSON.stringify(cookie) +
    ";expires=" +
    date.toUTCString() +
    ";path=/";
}

export function deleteCookie() {
  document.cookie =
    "authHeaders_order-generator=; expires=Thu, 01 Jan 1970 00:00:01 GMT;";
}
