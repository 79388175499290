import React, { Component } from "react";

import "../styles/App.scss";
import SignInModal from "./SignInModal";
import { Checkbox, LoadingBar, OrderCard } from './common';
import { Header } from "./Header";
import { NavTabs } from "./NavTabs";
import OrderSettings from "./OrderSettings";
import Verifications from "./Verifications";
import { InspectOrder } from './InspectOrder';

import { Brands, Tabs } from '../constants';

import Fetch from "../utils/fetch";
import randomGeneratedOrder from "../utils/order-info";
import Store from "../utils/store";



class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      numberOfOrderToBeGenerated: 10,
      fetchesCompleted: 0,
      createdOrders: [],
      orderIsLoading: false,
      numberOfOrdersSuccess: 0,
      generateOrdersInSequence: true,
      consolidatedOrders: false,
      setSenderEmailToLoggedInUser: false,
      setRecipientEmailToLoggedInUser: false,
      generateOrderSettings: {
        brand: 'porterbuddy',
        confirmed: true,
        region: "OSLO",
        deliveryType: "delivery",
        temperatureControlled: false,
        generateOrderReference: false,
        pickupPoint: "alnaHub",
        senderIsCompany: true,
        timeWindowStart: "19:30",
        customTimeWindowStart: "",
        customTimeWindowEnd: "",
        daysAdded: 0,
        packageSize: "",
        numberOfParcels: "",
        minimumAgeCheck: null,
        deliveryVerification: "CONTACTLESS",
        confirmCustomerReceipt: false,
      },
      currentTab: Tabs.generate,
      orderToInspect: null,
      searchToken: '',
      searchDeliveryId: '',
      searchShortToken: '',
    };

    Store.initializeFirebase();
    Store.getAuthHeadersFromCookieAndUpdateStore();
  }

  componentDidMount() {
    // check if signed in
    Store.isSignedIn(Store.environment());

    if (Store.userEmail()) {
      const localState = localStorage.getItem(Store.userEmail());
      if (localState) {
        try {
          const state = JSON.parse(localState);
          this.setState(state);
        } catch (e) {
          console.log(`Cant parse localStorage object`);
        }
      }
    }
  }

  componentDidUpdate() {
    if (Store.userEmail()) {
      /* Only save important stuff inside the localstorage (e.g. user input & result) */
      const {
        numberOfOrderToBeGenerated,
        createdOrders,
        numberOfOrdersSuccess,
        generateOrdersInSequence,
        consolidatedOrders,
        setSenderEmailToLoggedInUser,
        setRecipientEmailToLoggedInUser,
      } = this.state;
      localStorage.setItem(
        Store.userEmail(),
        JSON.stringify({
          numberOfOrderToBeGenerated,
          createdOrders,
          numberOfOrdersSuccess,
          generateOrdersInSequence,
          consolidatedOrders,
          setSenderEmailToLoggedInUser,
          setRecipientEmailToLoggedInUser,
        })
      );
    }
  }

  onGenerateOrders = async () => {
    const { numberOfOrderToBeGenerated } = this.state;

    // if consolidated is chosen, we take the destination from the first order
    // and set every other order's destination with that.
    // that means we need to get the first created order before creating the second order
    let firstOrder = null; 
    let ordersToBeCreated = [];

    // Note: using for loop because Array.from not working as intended.
    for (let i = 0; i < numberOfOrderToBeGenerated; i++) {
      const order = this.generateRandomOrderObject(firstOrder);
      ordersToBeCreated.push(order);
      if (i === 0) firstOrder = { ...order };
    }

    const promisedOrders = ordersToBeCreated.map((order) => this.createOrderInBackend(order));

    this.setState({
      orderIsLoading: true,
      fetchesCompleted: 0,
      numberOfOrdersSuccess: 0,
      createdOrders: [],
    });

    await Promise.all(promisedOrders);

    this.setState({ orderIsLoading: false });

  };

  generateRandomOrderObject = (firstOrder) => {
    const {
      consolidatedOrders,
      setSenderEmailToLoggedInUser,
      setRecipientEmailToLoggedInUser,
    } = this.state;

    const {
      brand,
      confirmed,
      region,
      deliveryType,
      temperatureControlled,
      generateOrderReference,
      pickupPoint,
      timeWindowStart,
      daysAdded,
      packageSize,
      numberOfParcels,
      customTimeWindowStart,
      customTimeWindowEnd,
      senderIsCompany,
      minimumAgeCheck,
      requireSignature,
      deliveryVerification,
      confirmCustomerReceipt,
    } = this.state.generateOrderSettings;

    return randomGeneratedOrder(
      brand,
      confirmed,
      region,
      deliveryType,
      minimumAgeCheck,
      requireSignature,
      temperatureControlled,
      generateOrderReference,
      pickupPoint,
      timeWindowStart,
      daysAdded,
      packageSize,
      numberOfParcels,
      customTimeWindowStart,
      customTimeWindowEnd,
      senderIsCompany,
      consolidatedOrders,
      firstOrder,
      setSenderEmailToLoggedInUser
        ? Store.userEmail()
        : "testemail+sender@porterbuddy.com",
      setRecipientEmailToLoggedInUser
        ? Store.userEmail()
        : "testemail+recipient@porterbuddy.com",
      deliveryVerification,
      confirmCustomerReceipt
    );
  };

  createOrderInBackend = async (orderToCreate) => {
    const createOrderResponse = await Fetch.post({
      url: "/order",
      body: orderToCreate,
    });

    // TODO: may want to handle other fail cases like no response here....


    // sign out if response is unauthorized.
    if (createOrderResponse.status === 401) {
      Store.signOut();
      return;
    }

    // this is to show the loading / fetching status
    this.setState({ fetchesCompleted: this.state.fetchesCompleted + 1 });

    // if everything was good
    if (createOrderResponse.ok) {
      const order = await createOrderResponse.json();
      const newOrderObject = this.createOrderObjectFromApiResponse(order);

      this.setState({
        numberOfOrdersSuccess: this.state.numberOfOrdersSuccess + 1,
        createdOrders: [...this.state.createdOrders, newOrderObject],
      });
    }

    return createOrderResponse;
  };

  createOrderObjectFromApiResponse = (order) => {
    const shortToken = order._links.userInformation.href.split("/")[order._links.userInformation.href.split("/").length - 1];
    const deliveryId = order._links.userInformation.href.split("/")[order._links.userInformation.href.split("/").length - 2];
    const isIbxOrder = order._links.userInformation.href.includes('instabox');

    let newOrder = {
      ...order,
      // inject some more info to the order
      isIbxOrder,
      deliveryId,
      shortToken,
      orderIdCopied: false,
      tokenCopied: false,
      deliveryIdCopied: false,
      shortTokenCopied: false,
      ibxLinkPathCopied: false,
    };

    return newOrder;

  };

  updateOrderSetting = (key, newValue) => {
    this.setState({
      generateOrderSettings: {
        ...this.state.generateOrderSettings,
        [key]: newValue,
      },
    });
  };

  updateOrderSettings = (newSettings) => {
    this.setState({
      generateOrderSettings: {
        ...this.state.generateOrderSettings,
        ...newSettings,
      },
    });
  };

  setOrderToInspect = (order) => {
    this.setState({
      orderToInspect: order,
    });
  };

  setSearchToken = (token) => {
    this.setState({
      searchToken: token,
    });
  };

  setSearchDeliveryId = (deliveryId) => {
    this.setState({
      searchDeliveryId: deliveryId,
    });
  };

  setSearchShortToken = (shortToken) => {
    this.setState({
      searchShortToken: shortToken,
    });
  };

  renderLoading = () => {
    const { fetchesCompleted, numberOfOrderToBeGenerated } = this.state;
    return (
      <div className="App-loading">
        <div className="loading__content">
          <div className="loader" />
          <div style={{ height: 30 }} />
          <LoadingBar
            current={fetchesCompleted}
            max={numberOfOrderToBeGenerated}
          />
        </div>
      </div>
    );
  };

  renderOrdersToGenerate = () => {
    const { generateOrderSettings } = this.state;
    const { brand } = generateOrderSettings;
    return (
      <div>
        Number of order to generate:{" "}
        <input
          type="text"
          value={this.state.numberOfOrderToBeGenerated}
          onChange={(value) =>
            this.setState({
              numberOfOrderToBeGenerated: value.target.value,
            })
          }
          disabled={this.state.orderIsLoading}
          style={{
            fontSize: 14,
            borderRadius: 5,
            borderStyle: "solid",
            borderWidth: 1,
            padding: 5,
            marginLeft: 5,
            borderColor: brand === Brands.pb ? "#661aff" : '#FF3E3E',
          }}
        />
      </div>
    );
  };

  renderOptionsAndVerifications = () => {
    const { generateOrderSettings } = this.state;
    const { brand } = generateOrderSettings;
    return (
      <div className="settings">
        <ul className="settings-list">
          <OrderSettings
            brand={brand}
            updateOrderSetting={this.updateOrderSetting}
            updateOrderSettings={this.updateOrderSettings}
            generateOrderSettings={generateOrderSettings}
          />
          <Verifications
            brand={brand}
            updateOrderSetting={this.updateOrderSetting}
            generateOrderSettings={generateOrderSettings}
          />
        </ul>
      </div>
    );
  };

  renderCheckboxOptions = () => {
    const {
      generateOrdersInSequence,
      consolidatedOrders,
      setSenderEmailToLoggedInUser,
      setRecipientEmailToLoggedInUser,
    } = this.state;
    return (
      <>
        <Checkbox
          onChange={() =>
            this.setState({
              consolidatedOrders: !consolidatedOrders,
            })
          }
          checked={consolidatedOrders}
          title="Create Samlevert orders"
        />
        <Checkbox
          onChange={() =>
            this.setState({
              generateOrdersInSequence: !generateOrdersInSequence,
            })
          }
          checked={generateOrdersInSequence}
          title="Generate orders in sequence"
        />
        {Store.userEmail() && (
          <>
            <Checkbox
              onChange={() =>
                this.setState({
                  setSenderEmailToLoggedInUser:
                    !setSenderEmailToLoggedInUser,
                })
              }
              checked={setSenderEmailToLoggedInUser}
              title={`Set sender email to ${Store.userEmail()}`}
            />
            <Checkbox
              onChange={() =>
                this.setState({
                  setRecipientEmailToLoggedInUser:
                    !setRecipientEmailToLoggedInUser,
                })
              }
              checked={setRecipientEmailToLoggedInUser}
              title={`Set recipient email to ${Store.userEmail()}`}
            />
          </>
        )}
        {(setSenderEmailToLoggedInUser ||
          setRecipientEmailToLoggedInUser) && (
          <div style={{ color: "red" }}>
            Obs! You might receive lots of emails
          </div>
        )}
      </>
    );
  };

  renderGenerateOrders = () => {
    const { generateOrderSettings, createdOrders } = this.state;
    const { brand } = generateOrderSettings;
    return (
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <button
          onClick={this.onGenerateOrders}
          className={brand === 'porterbuddy' ? 'pb-generate-btn' : 'ibx-generate-btn'}
          type="button"
        >
          Generate orders
        </button>
        { createdOrders.length > 0 &&
          (
            <button
              onClick={() => this.setState({ createdOrders: [], numberOfOrdersSuccess: 0 })}
              className={'clear-orders-btn'}
              type="button"
            >
              Clear&nbsp;Orders
            </button>
          )
        }
      </div>
    );
  };

  renderOrdersCreatedStatus = () => {
    const { numberOfOrdersSuccess, numberOfOrderToBeGenerated } = this.state;
    if (numberOfOrdersSuccess === 0 ) return null;
    return (
      <div>
        {numberOfOrdersSuccess} of{" "}
        {numberOfOrderToBeGenerated} orders were created successfully.
      </div>
    );
  };

  renderGeneratedOrders = () => {
    return this.state.createdOrders.map((createdOrder) => {

      const { orderId } = createdOrder;

      function onCopy(itemKey) {
        this.setState({
          createdOrders: this.state.createdOrders.map(order => { 
            if (order.orderId === orderId) {
              return {
                ...order,
                orderIdCopied: false,
                tokenCopied: false,
                deliveryIdCopied: false,
                shortTokenCopied: false,
                ibxLinkPathCopied: false,
                [itemKey]: true,
              }
            }
            return order;
          })
        });
      }

      return (
        <OrderCard
          key={orderId}
          order={createdOrder}
          onCopyOrderId={onCopy.bind(this, 'orderIdCopied')}
          onCopyDeliveryToken={onCopy.bind(this, 'tokenCopied')}
          onCopyDeliveryId={onCopy.bind(this, 'deliveryIdCopied')}
          onCopyShortToken={onCopy.bind(this, 'shortTokenCopied')}
          onCopyIbxLinkPath={onCopy.bind(this, 'ibxLinkPathCopied')}
        />
      );
    });
  };

  renderGeneratePageContent = () => {
    return (
      <>
        {this.state.orderIsLoading && this.renderLoading()}
        {this.renderOrdersToGenerate()}
        {this.renderOptionsAndVerifications()}
        <div style={{ width: '55%', margin: '0 auto', display: "flex", flexDirection: "column" }}>
          {this.renderCheckboxOptions()}
          <div style={{ height: 20 }} />
          {this.renderGenerateOrders()}
          {this.renderOrdersCreatedStatus()}
          <div style={{ height: 20 }} />
          {this.state.generateOrdersInSequence && this.renderGeneratedOrders()}
        </div>
      </>
    );
  };

  renderInspectPageContent = () => {
    const { 
      generateOrderSettings,
      orderToInspect,
      searchToken,
      searchDeliveryId,
      searchShortToken,
    } = this.state;
    const { brand } = generateOrderSettings;

    return (
      <InspectOrder
        brand={brand}
        order={orderToInspect}
        setOrder={this.setOrderToInspect}
        token={searchToken}
        setToken={this.setSearchToken}
        deliveryId={searchDeliveryId}
        setDeliveryId={this.setSearchDeliveryId}
        shortToken={searchShortToken}
        setShortToken={this.setSearchShortToken}
        updateOrderSetting={this.updateOrderSetting}
      />
    );
  };

  renderAppContent = () => {
    const { currentTab } = this.state;

    switch (currentTab) {
      case Tabs.inspect:
        return this.renderInspectPageContent();
      case Tabs.generate:
      default:
        return this.renderGeneratePageContent();
    }
  };

  render() {
    const { generateOrderSettings } = this.state;
    const { brand } = generateOrderSettings;

    return (
      <div className="App">
        <SignInModal />
        <Header brand={brand} />
        <NavTabs brand={brand} currentTab={this.state.currentTab} setCurrentTab={(tab) => this.setState({ currentTab: tab })} />
        <div className="App-content">
          {this.renderAppContent()}          
        </div>
      </div>
    );
  }
}

export default App;
