export const osloCorePostcodes = `
0015
0021
0026
0045
0048
0050
0104
0125
0151
0152
0153
0154
0155
0157
0158
0159
0160
0161
0162
0164
0165
0166
0167
0168
0169
0170
0171
0172
0173
0174
0175
0176
0177
0178
0179
0180
0181
0182
0183
0184
0185
0186
0187
0188
0190
0191
0192
0193
0194
0195
0196
0207
0240
0251
0253
0254
0255
0256
0257
0258
0259
0260
0262
0263
0264
0265
0266
0267
0268
0270
0271
0272
0273
0274
0275
0276
0277
0278
0279
0280
0281
0282
0283
0284
0286
0287
0306
0313
0316
0340
0349
0350
0351
0352
0353
0354
0355
0356
0357
0358
0359
0360
0361
0362
0363
0364
0365
0366
0367
0368
0369
0370
0371
0372
0373
0374
0375
0376
0377
0378
0379
0380
0381
0382
0383
0445
0450
0451
0452
0454
0455
0456
0457
0458
0459
0460
0461
0462
0463
0464
0465
0467
0468
0469
0470
0472
0473
0474
0475
0476
0477
0478
0479
0480
0481
0482
0483
0484
0485
0486
0487
0488
0489
0490
0491
0492
0493
0494
0495
0514
0515
0516
0517
0518
0520
0550
0551
0552
0553
0554
0555
0556
0557
0558
0559
0560
0561
0562
0563
0564
0565
0566
0567
0568
0569
0570
0571
0572
0573
0574
0575
0576
0577
0578
0579
0580
0581
0582
0583
0584
0585
0586
0587
0588
0589
0590
0591
0592
0593
0594
0595
0596
0597
0598
0601
0606
0620
0650
0651
0652
0653
0654
0655
0656
0657
0658
0659
0660
0661
0662
0663
0664
0665
0666
0667
0668
0669
0670
0671
0672
0673
0674
0675
0676
0677
0678
0679
0680
0681
0682
0683
0684
0685
0686
0687
0688
0689
0690
0691
0692
0693
0694
0750
0751
0752
0753
0754
0755
0756
0757
0760
0763
0764
0765
0766
0767
0768
0770
0771
0772
0773
0774
0775
0776
0777
0778
0779
0781
0782
0783
0784
0785
0786
0787
0788
0789
0790
0791
0850
0851
0852
0853
0854
0855
0856
0857
0858
0860
0861
0862
0863
0864
0870
0871
0872
0873
0874
0875
0876
0877
0880
0881
0882
0883
0884
0903
0950
0951
0952
0953
0954
0955
0956
0957
0958
0959
0960
0962
0963
0969
0970
0971
0972
0973
0975
0976
0977
0978
0979
0980
0981
0982
0983
0984
0985
0986
0987
0988
1051
1052
1053
1054
1055
1056
1061
1062
1063
1064
1065
1067
1068
1069
1071
1081
1083
1084
1086
1087
1088
1089
1109
1150
1151
1152
1153
1154
1155
1156
1157
1158
1160
1161
1162
1163
1164
1165
1166
1167
1168
1169
1170
1172
1176
1177
1178
1179
1181
1182
1184
1185
1187
1188
1189
1203
1207
1250
1251
1252
1253
1254
1255
1256
1257
1258
1259
1262
1263
1266
1270
1271
1272
1273
1274
1275
1278
1279
1281
1283
1284
1285
1286
1290
1291
1294
1305
1308
1316
1318
1319
1321
1332
1344
1356
1357
1358
1359
1361
1362
1369
`.split('\n').map(line => line.trim()).filter(line => !!line);
