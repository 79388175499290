import { generateRandomNumberAsString } from './order-info';

function getParcelTemplate(packageSize, ibxParcel = false) {
  let parcel = {};
  switch (packageSize) {
    case "SMALL":
      parcel = {
        description: "Nettbestilling, SMALL",
        widthCm: 20,
        heightCm: 10,
        depthCm: 10,
        weightGrams: 500,
      };
      if (ibxParcel) parcel.parcelShipmentIdentifier = `INSTA${generateRandomNumberAsString()}`;
      return parcel;
    case "MEDIUM":
      parcel = {
        description: "Nettbestilling, MEDIUM",
        widthCm: 30,
        heightCm: 30,
        depthCm: 30,
        weightGrams: 3000,
      };
      if (ibxParcel) parcel.parcelShipmentIdentifier = `INSTA${generateRandomNumberAsString()}`;
      return parcel;
    case "LARGE":
      parcel = {
        description: "Nettbestilling, LARGE",
        widthCm: 50,
        heightCm: 50,
        depthCm: 50,
        weightGrams: 10000,
      };
      if (ibxParcel) parcel.parcelShipmentIdentifier = `INSTA${generateRandomNumberAsString()}`;
      return parcel;
    case "EXTRA_LARGE":
      parcel = {
        description: "Nettbestilling, EXTRA_LARGE",
        widthCm: 160,
        heightCm: 80,
        depthCm: 80,
        weightGrams: 22000,
      };
      if (ibxParcel) parcel.parcelShipmentIdentifier = `INSTA${generateRandomNumberAsString()}`;
      return parcel;
    default:
      // default to LARGE
      parcel = {
        description: "Nettbestilling",
        widthCm: 50,
        heightCm: 50,
        depthCm: 50,
        weightGrams: 10000,
      };
      if (ibxParcel) parcel.parcelShipmentIdentifier = `INSTA${generateRandomNumberAsString()}`;
      return parcel;
  }
}

export default function toParcels(
  numberOfPackages,
  packageSize,
  temperatureControlled,
  ibxParcel,
) {
  if (!numberOfPackages) {
    numberOfPackages = parseInt(Math.random() * (6 - 1) + 1, 10);
  }

  if (!packageSize) {
    packageSize = ["SMALL", "MEDIUM", "LARGE", "EXTRA_LARGE"][
      parseInt(Math.random() * (4 - 0) + 0, 10)
    ];
  }

  return new Array(numberOfPackages).fill(0).map(() => {
    return {
      ...getParcelTemplate(packageSize, ibxParcel),
      weightGrams: 1000,
      constraints: temperatureControlled
        ? {
            temperature: {
              maximumCelsius: 25.0,
              minimumCelsius: 10.0,
            },
          }
        : undefined,
    };
  });
}
