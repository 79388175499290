import Store from "./store";

const baseApiUrl = () => {
  const env = Store.environment();
  const envSettings = Store.envSettings[env];

  return envSettings.apiUrl;
}

const _fetch = (method, authenticated) => ({ url, body }) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
  };

  if (authenticated) {
    headers["Authorization"] = "Bearer " + Store.sessionToken();
    headers["uid"] = Store.userEmail();
  }

  return fetch(baseApiUrl() + url, {
    method,
    headers: headers,
    body: JSON.stringify(body),
  });
};

export default {
  get: _fetch("GET", true),
  post: _fetch("POST", true),
  put: _fetch("PUT", true),
  delete: _fetch("DELETE", true),
  noAuth: {
    get: _fetch("GET", false),
    post: _fetch("POST", false),
    put: _fetch("PUT", false),
    delete: _fetch("DELETE", false),
  },
};
