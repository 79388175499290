import React from "react";


export const Checkbox = ({
  onChange,
  checked,
  title,
  disabled = false,
  style = {},
}) => (
  <label>
    <input
      type="checkbox"
      onChange={onChange}
      checked={checked}
      disabled={disabled}
      style={style}
    />
    {title}
  </label>
);


