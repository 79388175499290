import React from 'react';

export const LoadingBar = ({ current, max }) => {
  return (
    <div
      style={{
        height: 30,
        width: "100%",
        backgroundColor: "white",
        borderRadius: 5,
        display: "flex",
        alignItems: "center",
      }}
    >
      <div style={{ zIndex: 1, position: "absolute", marginLeft: 10 }}>
        {current} of {max} completed
      </div>
      <div
        style={{
          height: "100%",
          width: `${Math.floor((current / max) * 100)}%`,
          backgroundColor: "#096574",
        }}
      />
    </div>
  );
}