import React, { useState, useEffect, useCallback } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { BsClipboard } from "react-icons/bs";
import { BsClipboardCheck } from "react-icons/bs";

import { Button, CodeEditor, SearchBar } from './common';
import SelectorGroup from "./SelectorGroup";
import Fetch from "../utils/fetch";
import { Brands } from '../constants';


export const InspectOrder = ({
  brand,
  order,
  setOrder,
  token,
  setToken,
  deliveryId,
  setDeliveryId,
  shortToken,
  setShortToken,
  updateOrderSetting,
}) => {

  const [isCodeCopied, setIsCodeCopied] = useState(false);

  useEffect(() => {
    onSearch().then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, deliveryId, shortToken]);

  const getOrderByToken = useCallback(async (token) => {
    const response = await Fetch.get({ url: `/delivery/token/${token}` });

    if (response.status === 200) {
      const order = await response.json();
      return order;
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const getOrderBydeliveryIdAndShortToken = useCallback(async (deliveryId, shortToken) => {
    const response = await Fetch.get({ url:`/delivery/${deliveryId}/tracking?shortToken=${shortToken}` });

    if (response.status === 200) {
      const order = await response.json();
      return order;
    }

    return null;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deliveryId, shortToken]);

  const onSearch = async () => {
    let response;

    if (brand === Brands.pb) {
      if (token.length > 20) {
        response = await getOrderByToken(token);
      }
    } else {
      if (deliveryId.length === 6 && shortToken.length === 5)
      response = await getOrderBydeliveryIdAndShortToken(deliveryId, shortToken);
    }

    setOrder(response);
  };

  const onClearSearch = () => {
    setToken('');
    setDeliveryId('');
    setShortToken('');
    setOrder(null);
    setIsCodeCopied(false);
  };

  const onCopyCode = () => {
    if (order) setIsCodeCopied(true);
  }

  return (
    <div className="inspect-content">
      <div className="inspect-controls-row">
        <SelectorGroup brand={brand} >
          <span className="search-box-label">Order Brand: </span>
          <Button
            name="pb"
            value="Porterbuddy"
            onChange={() => updateOrderSetting("brand", Brands.pb)}
            checked={brand === Brands.pb}
            brand={brand}
          />
          <Button
            name="ibx"
            value="Instabox"
            onChange={() => updateOrderSetting("brand", Brands.ibx)}
            checked={brand === Brands.ibx}
            brand={brand}
          />
        </SelectorGroup>
        <SelectorGroup brand={brand} style={{ maxWidth: '100%' }}>
          <div><span className="search-box-label">Search by: </span> { brand === Brands.pb ? 
            (<span className="code-span">token</span>) : 
            (<span><span className="code-span">deliveryId</span> and <span className="code-span">shortToken</span></span>) }
          </div>
          <SearchBar
            brand={brand}
            token={token}
            setToken={setToken}
            deliveryId={deliveryId}
            setDeliveryId={setDeliveryId}
            shortToken={shortToken}
            setShortToken={setShortToken}
            onSearch={onSearch}
            onClearSearch={onClearSearch}
          />
        </SelectorGroup>
      </div>
      <SelectorGroup brand={brand} style={{ width: '98.5%', maxWidth: '1200px' }}>
        <div className={`search-copy-to-clip-container ${!order ? 'search-disabled' : ''} `}>
          <span className="search-box-label" style={{ margin: '5px' }}><small>Copy order to clipboard</small></span>
          <CopyToClipboard text={JSON.stringify(order, null, 2)} onCopy={onCopyCode}>
            {isCodeCopied ? <BsClipboardCheck /> : <BsClipboard />}
          </CopyToClipboard>
        </div>
        <CodeEditor
          code={order ? JSON.stringify(order, null, 2) : ''}
        />
      </SelectorGroup>
    </div>
  );
};