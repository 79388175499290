export const Environment = Object.freeze({
    Local: 'local',
    Test: 'test',
});

export const Brands = Object.freeze({
    pb: 'porterbuddy',
    ibx: 'instabox-hd',
});

export const Tabs = Object.freeze({
    generate: 'generate',
    inspect: 'inspect'
});